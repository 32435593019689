import React      from 'react';
import PropTypes  from 'prop-types';
import Highlight  from 'react-highlighter';
import $          from 'jquery';
import RadioInput from 'components/ui/form/radio_input';

class TarificationOptionList extends React.Component {
  componentDidMount() {
    const selectedItem = $('.selected');
    if (selectedItem && selectedItem[0]) {
      const offset = selectedItem.position().top;
      $('.tarification-option-list').animate({ scrollTop: offset - 150 }, 500);
    }
  }

  render() {
    const { values, selected, keyword, onOptionClick } = this.props;
    return (
      <ul
        style={ {
          overflowY: 'scroll',
          maxHeight: '400px',
          padding:   0,
          listStyle: 'none',
          margin:    0,
        } }
        className="tarification-option-list"
      >
        { values.map(value => (
          <li
            key={ `${ value.value }_${ value.value === selected ? 'selected' : '' }` }
            className={ `${ value.value === selected ? 'selected' : '' }` }
          >
            <RadioInput
              input={ {
                value:    value.value,
                onChange: onOptionClick,
                checked:  value.value === selected,
              } }
              Label={ () => (
                <Highlight search={ keyword }>
                  { value.label }
                </Highlight>
              ) }
            />
          </li>
        )) }
      </ul>
    );
  }
}

TarificationOptionList.defaultProps = {
  values:   null,
  selected: null,
  keyword:  null,
};

TarificationOptionList.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  values:        PropTypes.array,
  selected:      PropTypes.any,
  keyword:       PropTypes.string,
};

export default TarificationOptionList;
