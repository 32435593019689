import React     from 'react';
import PropTypes from 'prop-types';
import $         from 'jquery';
import some      from 'lodash/some';
import Icon      from 'components/ui/icon';
import Button    from 'components/ui/buttons/button';

class TarificationVehicleProtectionOptionList extends React.Component {
  componentDidMount() {
    const selectedItem = $('.selected');
    if (selectedItem && selectedItem[0]) {
      const offset = selectedItem.position().top;
      $('.tarification-option-list').animate({ scrollTop: offset - 150 }, 500);
    }
  }

  render() {
    const { values, selected, keyword, onOptionClick } = this.props;
    const hasDegression = some(values, (value) => value.degression);
    const hasDeductibleType = some(values, (value) => value.deductible_type);
    const hasDeductibleAmount = some(values, (value) => value.deductible_amount);
    const hasPrice = some(values, (value) => value.price);

    return (
      <table className="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-middle">
        <thead>
          <tr>
            <th>{ t('quick_tarification.product_configuration_formula') }</th>
            { hasDegression && (<th>{ t('quick_tarification.product_configuration_degression') }</th>) }
            { hasDeductibleType && (<th>{ t('quick_tarification.product_configuration_deductible_type') }</th>) }
            { hasDeductibleAmount && (<th>{ t('quick_tarification.product_configuration_deductible_amount') }</th>) }
            { hasPrice && (<th>{ t('quick_tarification.price') }</th>) }
            <th></th>
          </tr>
        </thead>
        <tbody>
          { values.map(value => (
            <tr
              key={ `${ value.value }_${ value.value === selected ? 'selected' : '' }` }
              className={ value.value === selected ? 'active' : null }
            >
              <td>{ value.formula }</td>
              { hasDegression && (<td>{ value.degression }</td>) }
              { hasDeductibleType && (<td>{ value.deductible_type }</td>) }
              { hasDeductibleAmount && (<td className="uk-text-nowrap">{ value.deductible_amount }</td>) }
              { hasPrice && (<td className="uk-text-nowrap">{ value.price }</td>) }
              <td className="uk-text-center">
                { value.value === selected && (
                  <Icon icon="check" />
                ) || (
                  <Button
                    onClick={ () => onOptionClick(value.value) }
                    type="button"
                    secondary
                    small
                  >
                    { t('general.actions.select') }
                  </Button>
                ) }
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    );
  }
}

TarificationVehicleProtectionOptionList.defaultProps = {
  values:   null,
  selected: null,
  keyword:  null,
};

TarificationVehicleProtectionOptionList.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  values:        PropTypes.array,
  selected:      PropTypes.any,
  keyword:       PropTypes.string,
};

export default TarificationVehicleProtectionOptionList;
