import React from 'react';
import TarificationInsuranceCompanySyncButton
             from 'components/tarifications/table/insurance_company_sync_button';

const TarificationQuoteOutdated = ({ tarification }) => {
  const { insurer_quote_id, insurance_company: { smart_link_name }, insurer_quote_outdated } = tarification;
  if (insurer_quote_outdated) {
    return (
      <div className="uk-alert uk-alert-warning">
        { t_html('wizard.warning.tarification_company_sync_outdated_html', {
          smart_link_name,
          insurer_quote_id,
        }) }
        { tarification.authorizations.save_quote && (
          <TarificationInsuranceCompanySyncButton
            tarification={ tarification }
            disabled={ tarification.computing }
            small
            className="mr-5 ml-10 warning inline-button"
            label={ t('general.actions.resync_on', { tool_name: smart_link_name }) }
          />
        ) }
      </div>
    );
  }
};

export default TarificationQuoteOutdated;
