import React          from 'react';
import { connect }    from 'react-redux';
import { NavLink }    from 'react-router-dom';
import * as ROUTES    from 'components/account/routes/constants';
import Icon           from 'components/ui/icon';

const AccountNavigation = ({ hasIntermediaries, ...rest }) => (
  <ul className="brokerage-navigation">
    <li>
      <NavLink to={ ROUTES.SETTINGS } activeClassName="active" className="nav-item" data-purpose="settings_link">
        <span>
          <Icon icon="settings" />
          { t('providers.account.navigation.settings') }
        </span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to={ ROUTES.INTEGRATIONS }
        activeClassName="active"
        className="nav-item"
        data-purpose="integrations_link"
      >
        <span>
          <Icon icon="integrations" />
          { t('providers.account.navigation.integrations') }
        </span>
      </NavLink>
    </li>
    <li>
      <NavLink to={ ROUTES.MEMBERS_INDEX } activeClassName="active" className="nav-item" data-purpose="members_link">
        <span>
          <Icon icon="members" />
          { t('providers.account.navigation.members') }
        </span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to={ ROUTES.SUBSCRIPTION }
        activeClassName="active"
        className="nav-item"
        data-purpose="subscription_link"
      >
        <span>
          <Icon icon="credit-card" />
          { t('providers.account.navigation.subscription') }
        </span>
      </NavLink>
    </li>
    { hasIntermediaries && (
      <li>
        <NavLink
          to={ ROUTES.INTERMEDIARIES }
          activeClassName="active"
          className="nav-item"
          data-purpose="intermediaries_link"
        >
        <span>
          <Icon icon="sales" />
          { t('providers.account.navigation.intermediaries') }
        </span>
        </NavLink>
      </li>
    ) }
  </ul>
);

const mapStateToProps = (state) => {
  return {
    hasIntermediaries: state.current_user.profile.authorizations.has_intermediaries,
  };
};

export default connect(mapStateToProps)(AccountNavigation);
