import React                          from 'react';
import { useDispatch, useSelector }   from 'react-redux';
import { updateConfig }               from 'models/config/store_actions';
import ApplicationActionCableConsumer from 'components/application/action_cable_consumer';

const ConfigSocket = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.current_user.profile);
  const isAgent = currentUser ? currentUser.authorizations.is_agent : true;

  const handleOnReceived = ({ global_message, global_message_type, maintenance_mode }, forAgent) => {
    dispatch(
      updateConfig(
        forAgent ? {
          maintenance_mode,
        } : {
          global_message,
          global_message_type,
          maintenance_mode,
        }),
    );
  };

  return (
    <ApplicationActionCableConsumer
      channel="ConfigChannel"
      onReceived={ (message) => handleOnReceived(message, isAgent) }
    />
  );
};

export default ConfigSocket;
