import React from 'react';

const loadOnClick = () => (Component) => {
  class HasLoaderOnClick extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
      };
      this.onComponentClick = this.onComponentClick.bind(this);
      this.toggleLoader = this.toggleLoader.bind(this);
    }

    toggleLoader() {
      this.state && this.setState({
        loading: !this.state.loading,
      });
    }

    onComponentClick(ev) {
      const { onClick, disabled } = this.props;
      const { loading } = this.state;
      if (disabled || loading) {
        return;
      }
      this.setState({
        loading: true,
      });
      const result = onClick(ev);
      if (result instanceof Promise) {
        result.then(() => {
          this.toggleLoader && this.toggleLoader();
        }).catch(() => {
          this.toggleLoader && this.toggleLoader();
        });
      } else {
        this.toggleLoader && this.toggleLoader();
      }
    }

    componentWillUnmount() {
      this.toggleLoader = null;
      //To prevent setting state if initial resolve is making this component unmount
    }

    render() {
      const { onClick, disabled, loading: loadingFromProps, ...props } = this.props;
      const { loading } = this.state;
      return (
        <Component
          { ...props }
          onClick={ this.onComponentClick }
          loading={ loading || loadingFromProps }
          disabled={ loading || disabled || loadingFromProps }
        />
      );
    }
  }

  return HasLoaderOnClick;
};

export default loadOnClick;
