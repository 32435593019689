import { updateCurrentAccount } from 'models/current_account/store_actions';
import { identifyLogrocketUser }         from 'services/logrocket';

const setCurrentUser = (current_user) => {
  return {
    type: 'CURRENT_USER/UPDATE',
    current_user,
  };
};

export const removeCurrentUser = () => (
  { type: 'CURRENT_USER/DELETE' }
);


export const setUserInStore = user => {
  return dispatch => {
    identifyLogrocketUser(user);
    dispatch(updateCurrentAccount(user.account));
    dispatch(setCurrentUser(user));
  };
};

export const storeIntermediaryToken = token => {
  return {
    type: 'CURRENT_USER/STORE_INTERMEDIARY_TOKEN',
    token,
  };
};
