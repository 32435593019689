import Alert from 'services/alert';
import Api   from 'services/api';
import {
  addMemberFromCurrentAccount,
  updateCurrentAccount,
  updateMemberInCurrentAccount,
}            from 'models/current_account/store_actions';

export const getAccount = (params = {}) => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Api.get('account', { params }).then((response) => {
        const { account } = response.data;
        dispatch(updateCurrentAccount(account));
        resolve(account);
      }).catch(() => {
        Alert.error(t('account.account_get_error'));
        reject();
      });
    })
  );
};

export const updateAccount = params => (
  dispatch => (
    new Promise((resolve, reject) => {
      Api.put('account', { account: params }).then((response) => {
        const { account } = response.data;
        Alert.success(t('account.update_success'));
        dispatch(updateCurrentAccount(account));
        resolve(account);
      }).catch((response) => {
        Alert.error(t('account.update_error'));
        reject(response.data.errors);
      });
    })
  )
);

export const createAccountMember = params => (
  dispatch => (
    new Promise((resolve, reject) => {
      Api.post('account/members', { member: params }).then((response) => {
        Alert.success(t('account.member_create_success'));
        const { member } = response.data;
        dispatch(addMemberFromCurrentAccount(member));
        resolve(member);
      }).catch((response) => {
        Alert.error(response.data.message ? response.data.message : t('account.member_create_error'));
        reject(response.data);
      });
    })
  )
);

export const updateAccountMember = (memberId, params) => (
  dispatch => (
    new Promise((resolve, reject) => {
      Api.put(`account/members/${ memberId }`, { member: params }).then((response) => {
        Alert.success(t('account.member_update_success'));
        const { member } = response.data;
        dispatch(updateMemberInCurrentAccount(member));
        resolve(member);
      }).catch(() => {
        Alert.error(t('account.members_update_error'));
        reject(error);
      });
    })
  )
);

export const resendAccountMemberInvitation = memberId => (
  new Promise((resolve, reject) => {
    Api.post(`account/members/${ memberId }/invitations`).then(() => {
      Alert.success(t('account.member_resend_invitation_success'));
      resolve();
    }).catch(() => {
      Alert.error(t('account.members_update_error'));
      reject();
    });
  })
);

export const updateAccountLogo = (formData) => {
  return new Promise((resolve, reject) => {
    Api.put('account/logo',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
      resolve(response.data.account);
    }).catch((error) => {
      Alert.error(t('general.forms.image_upload.image_upload_error'));
      reject(error);
    });
  });
};

export const deleteAccountLogo = () => {
  return new Promise((resolve, reject) => {
    Api.delete('account/logo').then((response) => {
      Alert.success(t('general.forms.image_upload.image_remove_success'));
      resolve(response.data.account);
    }).catch((error) => {
      Alert.error(t('general.forms.image_upload.image_remove_error'));
      reject(error);
    });
  });
};

export const deleteAccountMember = (memberId) => {
  return new Promise((resolve, reject) => {
    Api.delete(`account/members/${ memberId }`).then(() => {
      Alert.success(t('account.member_delete_invitation_success'));
      resolve();
    }).catch((error) => {
      Alert.error(t('account.member_delete_invitation_error'));
      reject(error);
    });
  });
};
