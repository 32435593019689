import camelize              from 'camelize-keys';
import { DEVELOPMENT, TEST } from 'constants/application_constants';

export const identifyUser = (user, account, noIntercom = false) => {
  if (TEST) {
    return;
  }
  if (user !== null) {
    analytics.identify(user.id, camelize(user.segment_traits), {
      Intercom: {
        user_hash:           user.intercom_hash,
        language_override:   user.locale,
        hideDefaultLauncher: noIntercom || user.hide_intercom_chat,
      },
    });
    if (account !== null) {
      analytics.group(account.segment_id, camelize(account.segment_traits));
    }
  }
};

export const trackPage = () => {
  if (TEST) {
    return;
  }
  analytics.page();
};

export const trackEvent = (eventName, properties = {}) => {
  if (TEST) {
    return;
  }
  if (DEVELOPMENT) {
    log(`trackEvent ${ eventName }`, properties);
  }
  analytics.track(eventName, properties);
};

export const aliasUser = (user) => {
  if (TEST) {
    return;
  }
  analytics.alias(user.id);
};
