import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { updateCurrentAccount } from 'models/current_account/store_actions';
import { getAccountFinancialDocuments } from 'models/financial_documents/requests';
import Loader from 'components/ui/loader';
import BillingAddressFormContainer from 'components/billing_address_form/container';
import Alert from 'services/alert';
import Button from 'components/ui/buttons/button';
import { openLinkOrDownloadFile } from 'services/file';

class AccountSubscriptionInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.fetchInvoices = this.fetchInvoices.bind(this);
    this.billingAddressSuccess = this.billingAddressSuccess.bind(this);
    this.state = {
      financial_documents: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchInvoices();
  }

  billingAddressSuccess(account) {
    const { dispatch } = this.props;
    dispatch(updateCurrentAccount(account));
    Alert.success(t('providers.account.subscription.billing_address.success'));
  }

  fetchInvoices() {
    this.setState({
      loading: true,
    });
    getAccountFinancialDocuments()
      .then((response) => {
        this.setState({
          financial_documents: response.data.financial_documents,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { financial_documents, loading } = this.state;
    const lines = financial_documents.map((financial_document) => (
      <tr key={financial_document.id}>
        <td>{financial_document.kind_text}</td>
        <td>
          {financial_document.kind === 'invoice' && (
            <React.Fragment>
              {t('providers.account.subscription.invoices.from')}{' '}
              <Moment format="LL">{financial_document.period_start}</Moment>{' '}
              {t('providers.account.subscription.invoices.to')}{' '}
              <Moment format="LL">{financial_document.period_end}</Moment>
            </React.Fragment>
          )}
          {financial_document.kind === 'credit_note' && (
            <React.Fragment>
              {t('providers.account.subscription.invoices.on_date')}{' '}
              <Moment format="LL">{financial_document.due_date}</Moment>
            </React.Fragment>
          )}
        </td>
        <td>{financial_document.status_text}</td>
        <td>
          <Button
            secondary
            small
            margin="0"
            onClick={() => {
              openLinkOrDownloadFile(financial_document.pdf_url, 'invoice.pdf');
            }}
            icon="download"
          >
            {t('providers.account.subscription.invoices.download')}
          </Button>
        </td>
      </tr>
    ));
    return (
      <div>
        <div className="brokerage-section">
          <h2 className="brokerage-section-title mb-20">
            {t('providers.account.subscription.billing_address.title')}
          </h2>
          <div className="uk-width-1-3">
            <BillingAddressFormContainer
              onSuccess={this.billingAddressSuccess}
            />
          </div>
          <h2 className="brokerage-section-title mt-20">
            {t('providers.account.subscription.invoices.title')}
          </h2>
          {loading && <Loader />}
          {!loading && lines.length > 0 && (
            <table className="uk-table">
              <thead>
                <tr>
                  <th width="100px">
                    {t('providers.account.subscription.invoices.kind')}
                  </th>
                  <th width="400px">
                    {t('providers.account.subscription.invoices.period')}
                  </th>
                  <th>{t('providers.account.subscription.invoices.status')}</th>
                  <th width="150px">
                    {t('providers.account.subscription.invoices.download')}
                  </th>
                </tr>
              </thead>
              <tbody>{lines}</tbody>
            </table>
          )}
          {!loading && lines.length === 0 && (
            <p className="no-item">
              {t('providers.account.subscription.invoices.no_invoice')}
            </p>
          )}
        </div>
      </div>
    );
  }
}

AccountSubscriptionInvoices.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(AccountSubscriptionInvoices);
