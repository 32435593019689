import React                                                                 from 'react';
import PropTypes                                                             from 'prop-types';
import { useDispatch, useSelector }                                          from 'react-redux';
import { getSelectedCoversFromContractRequest }                              from 'models/wizard/reducer';
import confirmBeforeAction
                                                                             from 'components/ui/buttons/helpers/confirm_before_action';
import Button                                                                from 'components/ui/buttons/button';
import { updateContractRequestPreferences, updateProductsAndRequiredFields } from 'models/wizard/dispatch_actions';
import { getProductsFromContractRequest }                                    from 'models/wizard/constants';

const ConfirmBeforeActionButton = confirmBeforeAction()(Button);

const CoverDisabled = ({ cover }) => {
  const dispatch = useDispatch();
  const contractRequest = useSelector(state => state.wizard.contract_request);
  const selectedProducts = getProductsFromContractRequest(contractRequest)
  const tarifications = useSelector(state => state.wizard.contract_request.tarifications);
  const selectedCovers = useSelector(state => getSelectedCoversFromContractRequest(state));
  const covers = useSelector(state => state.wizard.contract_request.risk_object.available_cover_categories);
  const addCoverAndRefreshTarification = () => {
    selectedCovers[cover] = true;
    dispatch(updateContractRequestPreferences(contractRequest.id, selectedCovers, covers));
    dispatch(updateProductsAndRequiredFields(contractRequest, selectedProducts, tarifications));
  };
  return (
    <div className="uk-alert uk-alert-warning">
      { t('wizard.covers.cover_disabled') }
      <ConfirmBeforeActionButton
        small
        confirmTitle={ t('wizard.covers.add_cover_confirm_title') }
        confirmText={ t('wizard.covers.add_cover_confirm_text') }
        confirmButtonLabel={ t('wizard.covers.add_cover_confirm_button') }
        cancelButtonLabel={ t('general.actions.cancel') }
        className="ml-10 btn warning active uk-display-inline"
        onClick={ addCoverAndRefreshTarification }
      >
        { t('wizard.covers.activate') }
      </ConfirmBeforeActionButton>
    </div>
  );
};

CoverDisabled.defaultProps = {
  cover: 'vehicle_protection',
};

CoverDisabled.propTypes = {
  cover: PropTypes.string,
};

export default CoverDisabled;
