import React                         from 'react';
import { connect }                   from 'react-redux';
import { SubmissionError }           from 'redux-form';
import UserInvitationForm            from 'components/user/invitation/invitation_form';
import { getUrlSearchParam }         from 'services/url';
import {
  getInvitation,
  acceptInvitation,
}                                    from 'models/invitations/requests';
import { prepareErrorsForReduxForm } from 'services/rails';
import WarningMessage                from 'components/ui/warning_message';
import { setUserInStore }            from 'models/current_user/store_actions';
import { getCurrentLocale }          from 'services/i18n';
import LanguageSwitcher              from 'components/language_switcher/language_switcher';
import OpenbrokerLogo                from 'images/open_broker_logo_dark.svg';
import Button                        from 'components/ui/buttons/button';
import Loader                        from 'components/ui/loader';

class UserInvitationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token:      getUrlSearchParam('invitation_token'),
      invitation: null,
      loading:    true,
    };
    this.onInvitationSubmit = this.onInvitationSubmit.bind(this);
  }

  componentDidMount() {
    const { token } = this.state;
    getInvitation(token).then(({ invitation }) => {
      this.setState({
        invitation,
        loading: false,
      });
    }).catch(() => {
      this.setState({
        loading: false,
      });
    });
  }

  onInvitationSubmit(values) {
    const { dispatch } = this.props;
    return acceptInvitation(values).then((user) => {
      dispatch(setUserInStore(user));
    }).catch((error) => {
      throw new SubmissionError({ user: prepareErrorsForReduxForm(error) });
    });
  }

  render() {
    const { token, invitation, loading } = this.state;
    return (
      <div className="account-container">
        <LanguageSwitcher />
        <div className="account-form">
          <p className="uk-text-center m-0">
            <img src={ OpenbrokerLogo } alt="Openbroker" className="logo" />
          </p>
          <h1 className="title">{ t('general.signin.title') }</h1>
          <p className="mb-20 uk-text-center">
            { t('general.invitation.explanation') }
          </p>
          { loading && (
            <div className="uk-text-center">
              <Loader />
            </div>
          ) }
          { !loading && (
            <React.Fragment>
              { invitation && (
                <UserInvitationForm
                  className="mt-20"
                  onSubmit={ this.onInvitationSubmit }
                  initialValues={ {
                    invitation_token: token,
                    user:             { ...invitation },
                  } }
                />
              ) }
              { !invitation && (
                <React.Fragment>
                  <WarningMessage
                    className="mt-20"
                    message={ t('general.invitation.expired') }
                    data-purpose="invalid_token_message"
                  />
                  <p className="uk-text-center">
                    <Button
                      link
                      secondary
                      to={ `/${ getCurrentLocale() }/user/sign_in` }
                    >
                      { t('general.actions.back') }
                    </Button>
                  </p>
                </React.Fragment>
              ) }
            </React.Fragment>
          ) }
        </div>
      </div>
    );
  }
}

export default connect()(UserInvitationContainer);
