import { WizardNavigation } from 'models/wizard_navigation/navigation';
import { setNavigation }    from 'models/wizard_stepper/store_actions';

export const createNavigationAndSetInStore = (contract_request, selected_products, schema) => {
  return (dispatch) => {
    const wizardNavigation = new WizardNavigation({ schema }).generate({
      contract_request,
      selected_products,
    });
    dispatch(setNavigation(wizardNavigation.navigation));
    return wizardNavigation;
  };
};
