export const defaultState = {
  warnings:                null,
  contract_request:        {
    selected_cover_categories:                   ['public_liability'],
    product_configuration_formula:               'full_omnium',
    product_configuration_deductible_type:       'amount',
    product_configuration_deductible_amount:     '0',
    product_configuration_derogation_percentage: 'max',
    product_configuration_degression:            'more_than_36_months',
    payment_staggering:                          'annually',
    can_have_secondary_driver:                   false,
    customer:                                    {
      kind: 'person',
    },
    authorizations:                              {
      pdf_export:               false,
      customer_pdf_export:      false,
      need_analysis_pdf_export: false,
      activities_pdf_export:    false,
    },
  },
  tarifications:           [],
  required_fields:         {},
  default_required_fields: {},
  loaders:                 {
    tarifications:    false,
    contract_request: false,
    required_fields:  false,
  },
};
