import React            from 'react';
import InlineContainer  from 'components/ui/form/inline_container';
import FormErrorMessage from 'components/ui/form/form_error_message';

const CheckboxInput = ({
  input: {
           onChange,
           value,
           ...restInput
         },
  label,
  meta = {},
  disabled,
  purpose,
  onValueChange,
  inline,
  warning,
  id,
  onCheck,
  containerClass,
}) => {

  const { touched, error, warning: warningMeta, asyncValidating } = meta;

  const onChangeCheckbox = (ev) => {
    onChange(ev);
    if (onCheck) {
      onCheck(ev.target.checked);
    }
    if (onValueChange) {
      onValueChange(ev.target.checked);
    }
  };
  const checkbox = (
    <React.Fragment>
      <label htmlFor={ restInput.name }>
        <input
          id={ restInput.name }
          className={ `uk-checkbox ${ error ? 'uk-form-danger' : '' }` }
          type="checkbox"
          data-purpose={ purpose }
          disabled={ disabled || asyncValidating }
          checked={ value }
          onChange={ onChangeCheckbox }
          { ...restInput }
        />
        { label }
      </label>
      <FormErrorMessage { ...meta } />
    </React.Fragment>
  );

  if (!inline) {
    return checkbox;
  }

  return (
    <InlineContainer
      labelFor={ id }
      warning={ warning }
      field={ checkbox }
      meta={ meta }
      inlineContainerClass={ containerClass }
    />
  );
};

export default CheckboxInput;
