import React, { useEffect, useState } from 'react';
import Button from 'components/ui/buttons/button';
import { getBrands } from 'models/brands/requests';
import { goToNewFolder } from 'models/folders/constants';
import { useSelector } from 'react-redux';

const CreateFolderButton = ({ alignLeft, forLead, disabled }) => {
  const multiOnly = useSelector((state) => state.account.multi_only);
  console.log('multiOnly', multiOnly);
  const [canCreateMultiRequest, setCanCreateMultiRequest] = useState(false);
  const [loading, setLoading] = useState(true);
  const fetchBrands = () => {
    getBrands()
      .then((response) => {
        setCanCreateMultiRequest(
          !!response.data.brands.find((brand) => {
            return brand.slug === 'multi_tarif';
          }),
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error('got error while fetching brands', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  if (!canCreateMultiRequest && !multiOnly) {
    return null;
  }

  return (
    <div className={`uk-inline ${alignLeft ? 'uk-margin-auto-left' : ''}`}>
      <Button
        primary
        onClick={() => {
          goToNewFolder(null, null, null, forLead);
        }}
        data-purpose={forLead ? 'create_lead_button' : 'create_folder_button'}
        disabled={loading || disabled}
        loading={loading}
      >
        {forLead ? t('leads.new_lead') : t('folders.new_folder')}
      </Button>
    </div>
  );
};
export default CreateFolderButton;
