import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { fetchLeads } from 'models/leads/request';
import { convertForTable } from 'models/leads/constants';
import Pagination from 'components/pagination';
import Table from 'components/lead/table/table';
import LeadEmptyState from 'components/lead/lead_empty_state';
import { currentUserIsIntermediary } from 'models/current_user/reducer';
import CreateLeadState from 'components/lead/create_lead_state';
import MultiButton from 'components/ui/form/multi_button_input';
import CreateFolderButton from 'components/folder/create_folder_button';
import FilterBar from 'components/filter_bar';
import Header from '../../header';

const directions = {
  asc: 'desc',
  desc: 'asc',
};

class LeadTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      leads: [],
      filters: {},
      pagination: null,
      activeCustomer: null,
      selectedCustomer: null,
      loaded: false,
      sort_direction: 'desc',
      sort_by: 'updated_at',
      status: props.isIntermediary ? 'draft_lead' : 'lead',
    };
    this.fetchLeads = this.fetchLeads.bind(this);
    this.setSelectedCustomer = this.setSelectedCustomer.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.mounted = false;
  }

  fetchLeads(page = 1, params = {}) {
    const { selectedCustomer, sort_direction, sort_by, status } = this.state;
    const { isIntermediary } = this.props;
    const { searchTerm } = params;
    this.setState({
      loading: true,
    });
    fetchLeads(
      page,
      selectedCustomer
        ? {
            customer_id: selectedCustomer.id,
            sort_direction,
            sort_by,
            search_term: searchTerm,
            ...params,
          }
        : {
            sort_direction,
            sort_by,
            status,
            search_term: searchTerm,
            ...params,
          },
    )
      .then(({ leads, pagination }) => {
        if (!this.mounted) {
          return;
        }
        this.setState({
          leads: convertForTable(leads, isIntermediary),
          loading: false,
          loaded: true,
          pagination,
        });
      })
      .catch(() => {
        if (!this.mounted) {
          return;
        }
        this.setState({
          loading: false,
          loaded: true,
        });
      });
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchLeads();
  }

  setSelectedCustomer(selectedCustomer) {
    this.setState({ selectedCustomer }, () => {
      this.fetchLeads();
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  sortBy(sort_by) {
    if (this.state.sort_by === sort_by) {
      return this.setState(
        {
          sort_direction: directions[this.state.sort_direction],
          sort_by,
        },
        this.fetchLeads,
      );
    }
    return this.setState(
      {
        sort_by,
      },
      this.fetchLeads,
    );
  }

  setFilters(newFilter) {
    console.log('newFilter', newFilter);
  }

  render() {
    const {
      leads,
      pagination,
      loading,
      selectedCustomer,
      loaded,
      sort_by,
      sort_direction,
      status,
    } = this.state;
    const { isIntermediary, canSeeIndex } = this.props;
    const columns = [
      {
        label: t('leads.table.header.risk_object'),
        accessor: 'risk_object',
        htmlAttrs: {
          className: 'uk-table-shrink uk-text-center p-10',
        },
        sortKey: 'risk_object',
      },
      {
        label: t('leads.table.header.reference'),
        accessor: 'technical_reference',
        htmlAttrs: {
          className: 'uk-width-small',
        },
        sortKey: 'technical_reference',
      },
      {
        label: t('leads.table.header.customer'),
        accessor: 'customer',
        htmlAttrs: {
          className: 'uk-width-small',
        },
        sortKey: 'customer_name',
      },
      {
        label: t('leads.table.header.provider_intermediary'),
        accessor: 'manager_user_display_name',
        htmlAttrs: {
          className: 'uk-width-small',
        },
        sortKey: 'manager_user_display_name',
      },
      {
        label: t('leads.table.header.status'),
        accessor: 'status',
        htmlAttrs: {
          className: 'uk-width-small uk-text-right',
        },
        sortKey: 'status',
      },
      {
        label: t('leads.table.header.updated_at'),
        accessor: 'updated_at',
        htmlAttrs: {
          className: 'uk-width-small uk-text-right',
        },
        sortKey: 'updated_at',
      },
    ];
    return (
      <React.Fragment>
        <Header title={t('leads.menu_title')} />
        {leads.length > 0 && loaded && isIntermediary && (
          <div className="uk-flex mb-20">
            <CreateFolderButton
              forLead
              className="uk-button-primary large"
              alignLeft
            />
          </div>
        )}
        <div className="block p-20 top-border" data-purpose="lead_index">
          {canSeeIndex && (
            <React.Fragment>
              <div className="uk-flex uk-flex-middle">
                <div className="uk-width-2-5 uk-margin-auto-right">
                  <FilterBar
                    onChange={({ searchTerm }) => {
                      this.fetchLeads(this.state.page, { searchTerm });
                    }}
                    filters={['searchTerm']}
                    className="uk-width-1-1"
                  />
                </div>
                <div>
                  <MultiButton
                    input={{
                      value: status,
                      onChange: (value) =>
                        this.setState(
                          update(this.state, {
                            status: {
                              $set: value,
                            },
                          }),
                          () => {
                            this.fetchLeads(this.state.page, { status: value });
                          },
                        ),
                    }}
                    list={[
                      {
                        label: t('leads.status.draft_lead'),
                        value: 'draft_lead',
                      },
                      {
                        label: t('leads.status.lead'),
                        value: 'lead',
                      },
                      {
                        label: t('leads.status.active'),
                        value: 'active',
                      },
                    ]}
                  />
                </div>
              </div>
              <Table
                columns={columns}
                data={leads}
                loading={loading}
                sortedBy={sort_by}
                sortDirection={sort_direction}
                sortBy={this.sortBy}
                showActions={!isIntermediary}
              />
            </React.Fragment>
          )}
          {!leads.length && loaded && (
            <React.Fragment>
              {canSeeIndex && <LeadEmptyState status={status} />}
              {!canSeeIndex && <CreateLeadState />}
            </React.Fragment>
          )}
        </div>
        {pagination && leads.length > 0 && (
          <Pagination pagination={pagination} onPageChange={this.fetchLeads} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isIntermediary: currentUserIsIntermediary(state),
    canSeeIndex: state.current_user.profile.authorizations.list_leads,
  };
};

export default connect(mapStateToProps)(LeadTable);
