import React from 'react';
import {
  INSURGATE_DOMAIN,
  API_PROTOCOL,
} from 'constants/application_constants';

const BrandList = ({ brands }) => {
  return (
    <>
      <div className="brand-list-header">
        <div>
          <h5>{t('components.brand_list.title')}</h5>
          <p>{t('components.brand_list.subtitle')}</p>
        </div>
      </div>
      <div className="brand-list">
        {brands.map((brand) => {
          const link =
            brand.link || `${API_PROTOCOL}://${brand.slug}.${INSURGATE_DOMAIN}`;
          return (
            <div className="item-container">
              <div
                className="brand-item mb-10"
                data-cy="brand_item"
                key={brand.slug}
              >
                <a href={link}>
                  <img
                    className="brand-item-logo"
                    src={brand.logo_square_url}
                    alt={brand.name}
                  />
                </a>
                <h6 class="mb-1">
                  <a href={link}>{brand.name}</a>
                </h6>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BrandList;
