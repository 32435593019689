import React     from 'react';
import PropTypes from 'prop-types';
import Button    from 'components/ui/buttons/button';

const RadioInput = ({
  input,
  Label,
  disabled,
  onRadioChange,
}) => {
  const onClick = (ev) => {
    ev.preventDefault();
    input.onChange(input.value);
    if (onRadioChange) {
      onRadioChange(ev);
    }
  };

  return (
    <Button
      fullWidth
      disabled={ disabled }
      secondary={ input.checked }
      outline={ !input.checked }
      align="flex-start"
      textCenter
      onClick={ onClick }
      icon={ input.checked ? 'check' : '' }
    >
      <Label />
    </Button>
  );
};

RadioInput.defaultProps = {
  disabled:      false,
  onChange:      null,
  onRadioChange: null,
};

RadioInput.propTypes = {
  input:         PropTypes.object.isRequired,
  Label:         PropTypes.any.isRequired,
  disabled:      PropTypes.bool,
  onRadioChange: PropTypes.func,
};

export default RadioInput;
