import React                        from 'react';
import compact                      from 'lodash/compact';
import flatten                      from 'lodash/flatten';
import copy                         from 'copy-to-clipboard';
import { connect }                  from 'react-redux';
import Alert                        from 'services/alert';
import IntermediaryDeleteButton
                                    from 'components/intermediaries/delete_intermediary_button';
import { EditIntermediaryButton }   from 'components/intermediaries/open_intermediary_form_button';
import openDialogOnClick
                                    from 'components/ui/buttons/helpers/open_dialog_on_click';
import Button                       from 'components/ui/buttons/button';
import TextInput                    from 'components/ui/form/text_input';
import { getIntermediaryLoginPath } from 'models/intermediaries/constants';

const OpenLoginPathInDialog = openDialogOnClick()(({ onClick }) => {
  return (
    <Button
      secondary
      small
      className="uk-text-nowrap"
      onClick={ onClick }
    >
      { t('providers.intermediaries.see_login_path') }
    </Button>
  );
});

const IntermediariesTable = ({ intermediaries, products, riskObjects }) => {

  const getProducts = (ids) => {
    return compact(ids.map((id) => products.find(product => product.id === id))).map(product => product.display_name).join(', ');
  };
  const getRiskObjects = (ids) => {
    return compact(ids.map((id) => riskObjects.find(riskObject => riskObject.id === id))).map(riskObject => riskObject.kind_text).join(', ');
  };

  return (
    <table className="uk-table uk-table-middle">
      <thead>
        <tr>
          <th>{ t('providers.intermediaries.form.name') }</th>
          <th>{ t('activerecord.attributes.user.email') }</th>
          <th>{ t('providers.intermediaries.form.active_brought_contract_requests_count') }</th>
          <th>{ t('providers.intermediaries.form.available_products') }</th>
          <th>{ t('providers.intermediaries.form.available_risk_objects') }</th>
          <th className="uk-text-nowrap">{ t('providers.intermediaries.table.login_path') }</th>
          <th>{ t('employees.actions') }</th>
        </tr>
      </thead>
      <tbody>
        { intermediaries.map((intermediary) => {
          const loginPath = getIntermediaryLoginPath(intermediary.login_token);
          return (
            <tr key={ intermediary.id }>
              <td>{ intermediary.name }</td>
              <td>{ intermediary.email }</td>
              <td>{ intermediary.active_brought_contract_requests_count }</td>
              <td>{ getProducts(intermediary.available_product_ids) }</td>
              <td>{ getRiskObjects(intermediary.available_risk_object_ids) }</td>
              <td>
                <OpenLoginPathInDialog
                  dialogTitle={ t('providers.intermediaries.table.login_path') }
                >
                  <TextInput
                    input={ {
                      value: loginPath,
                    } }
                    disabled
                  />
                  <p className="mt-20 mb-20 uk-text-center">
                    <Button
                      primary
                      onClick={ () => {
                        copy(loginPath);
                        Alert.success(t('providers.intermediaries.copy_success'));
                      } }
                    >
                      { t('providers.intermediaries.copy_path') }
                    </Button>
                  </p>
                </OpenLoginPathInDialog>
              </td>
              <td>
                <div className="uk-flex uk-flex-middle">
                  <IntermediaryDeleteButton
                    intermediary={ intermediary }
                    className="mr-20"
                  />
                  <EditIntermediaryButton
                    intermediary={ intermediary }
                  />
                </div>
              </td>
            </tr>
          );
        }) }
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    products:    state.intermediaries.products,
    riskObjects: flatten(state.config.risk_types.map((type) => type.risk_objects)),
  };
};

export default connect(mapStateToProps)(IntermediariesTable);
