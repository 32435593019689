import Api                       from 'services/api';
import Alert                     from 'services/alert';
import { handleProfileResponse } from 'models/user/requests';

export const registerAccount = account => (
  new Promise((resolve, reject) => {
    Api.post('account/registrations', { registration: account }).then((response) => {
      Alert.success(t('account.account_create_success'));
      resolve(handleProfileResponse(response));
    }).catch((error) => {
      Alert.error(t('account.account_create_error'));
      reject(error.data.errors);
    });
  })
);
