import React, { useState }                           from 'react';
import PropTypes                                     from 'prop-types';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import SubmitButton                                  from 'components/ui/form/submit_button';
import Alert                                         from 'services/alert';
import CreditCardFormFields                          from 'components/credit_card/form/fields';
import Callout                                       from 'components/ui/callout';
import { postPaymentMethod }                         from 'models/payment_methods/requests';

const CreditCardForm = ({ onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [submitting, setSubmitting] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardNumberElement) }).then(({ paymentMethod }) => {
      if (paymentMethod) {
        postPaymentMethod(paymentMethod.id).then((creditCard) => {
          setSubmitting(false);
          onSuccess(creditCard, paymentMethod);
        }).catch((error) => {
          setSubmitting(false);
          setErrorMessage(error.error_message);
        });
      } else {
        setSubmitting(false);
        Alert.error(t('credit_card_form.error_message'));
      }
    });
  };

  return (
    <form onSubmit={ handleSubmit } id="credit_card_form">
      { errorMessage && (
        <Callout className="alert">
          <p>{ errorMessage }</p>
        </Callout>
      ) }
      <CreditCardFormFields />
      <SubmitButton
        margin="20px 0"
        disabled={ submitting }
        loading={ submitting }
        large
      />
    </form>
  );
};

CreditCardForm.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default CreditCardForm;
