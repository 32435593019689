import React        from 'react';
import PropTypes    from 'prop-types';
import {
  hasImageExtension,
}                   from 'services/image';
import Alert        from 'services/alert';
import FileUploader from 'components/file_uploader';


class LogoSelector extends React.Component {
  constructor(props) {
    super(props);
    this.onImageSelected = this.onImageSelected.bind(this);
    this.selectImage = this.selectImage.bind(this);
  }

  onImageSelected(image) {
    if (!image) {
      return Promise.reject();
    }
    if (!hasImageExtension(image.name)) {
      Alert.error(t('general.forms.image_upload.image_select_error'));
      return Promise.reject();
    }
    return this.selectImage(image);
  }

  selectImage(image) {
    const { uploadImage, paramContainer } = this.props;
    const formData = new FormData();
    formData.append(`${ paramContainer }[logo]`, image, `${ paramContainer }_logo_${ Date.now() }`);
    return uploadImage(formData);
  }

  render() {
    return (
      <FileUploader uploadFile={ this.onImageSelected } />
    );
  }
}

LogoSelector.propTypes = {
  paramContainer: PropTypes.string.isRequired,
  uploadImage:    PropTypes.func,
};

export default LogoSelector;
