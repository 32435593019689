import React                         from 'react';
import PropTypes                     from 'prop-types';
import { SubmissionError }           from 'redux-form';
import { connect }                   from 'react-redux';
import { prepareErrorsForReduxForm } from 'services/rails';
import SettingsForm                  from 'components/account/settings/form';
import { updateAccount }    from 'models/current_account/requests';

const AccountSettings = ({ account, dispatch }) => {
  const onBrokerageSettingsSubmit = values => (
    dispatch(updateAccount(values)).then(() => {
      location.reload();
    }).catch((errors) => {
      throw new SubmissionError(prepareErrorsForReduxForm(errors));
    })
  );

  return (
    <SettingsForm initialValues={ account } onSubmit={ onBrokerageSettingsSubmit } />
  );
};

AccountSettings.propTypes = {
  account:  PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ account }) => {
  return {
    account,
  };
};

export default connect(mapStateToProps)(AccountSettings);
