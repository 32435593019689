import React          from 'react';
import WarningTooltip from 'components/wizard/warning_tooltip';

const InlineContainer = ({
  warning,
  label,
  labelFor,
  meta = {},
  field,
  inlineContainerClass,
  children,
  labelAddition,
  labelClass,
  tooltip,
  withValues,
  inlineLabel = true,
  purpose,
}) => {
  const LabelAddition = labelAddition;
  return (
    <div
      className={ `inline-fields ${ inlineContainerClass || '' }` }
      data-purpose={ purpose ? `${ purpose.replace(/\./g, '_') }_container` : '' }
    >
      <label htmlFor={ labelFor } className={ labelClass }>
        { inlineLabel && label }
        { warning && !meta.dirty && (
          <WarningTooltip
            name={ warning }
            className="error"
          />
        ) }
        { tooltip && tooltip }
        { labelAddition && <LabelAddition { ...withValues } /> }
      </label>
      { field || children }
    </div>
  );
};

export default InlineContainer;
