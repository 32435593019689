import React            from 'react';
import Helmet           from 'react-helmet';
import LanguageSwitcher from 'components/language_switcher/language_switcher';
import Box              from 'components/ui/box';
import Logo             from 'components/main_header/logo';
import Title            from 'components/ui/titles';

import NoAccountFoundIllustration from 'images/no_account_found_illustration.svg';

const NoAccountFound = () => (
  <div className="session-container no-account">
    <Helmet>
      <body className="content-no-padding" />
    </Helmet>
    <div className="inner">
      <div className="uk-text-center mb-20">
        <Logo className="logo-80" dark />
      </div>
      <Title
        size="h1"
        align="center"
        data-purpose="session_container_title"
        className="title"
        margin="20px 0"
      >
        { t('account.no_account_found_title') }
      </Title>
      <Box justify="center" margin="40px 0"><img src={ NoAccountFoundIllustration } alt="" /></Box>
      <div className="no-account">
        { t('account.no_account_found_explanation') }
      </div>
      <LanguageSwitcher />
    </div>
  </div>
);


export default NoAccountFound;
