import update from 'immutability-helper';

const defaultState = null;

const listWithUpdatedMember = (members, newMember) => (
  members.map((member) => {
    if (member.id === newMember.id) {
      return newMember;
    }
    return member;
  })
);

const currentAccountReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'ACCOUNT/UPDATE':
      return update(state, {
        $set: action.account,
      });
    case 'ACCOUNT/ADD_MEMBER':
      return update(state, {
        members: { $push: [action.member] },
      });
    case 'ACCOUNT/REMOVE_MEMBER':
      return update(state, {
        members: { $set: state.members.filter(member => member.id !== action.memberId) },
      });
    case 'ACCOUNT/UPDATE_MEMBER':
      return update(state, {
        members: { $set: listWithUpdatedMember(state.members, action.member) },
      });
    case 'ACCOUNT/SET_OFFICES':
      return update(state, {
        offices: { $set: action.offices },
      });
    default:
      return state;
  }
};

export default currentAccountReducer;


export const hasActiveSubscription = (state) => {
  return state.account && state.account.subscription && state.account.subscription.active;
};
