import Api   from 'services/api';
import Alert from 'services/alert';

export const postPaymentMethod = payment_method_id => (
  new Promise((resolve, reject) => {
    Api.post('/account/payment_method', {
      payment_method_id,
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
      Alert.error(t('credit_card_form.error_message'));
    });
  })
);
