export const getCarForInformexSchema = () => {
  return {
    informex_form: {
      type: 'group',
      visible: {
        property: 'vehicle.source',
        assertion: 'equal',
        value: 'informex',
      },
      properties: {
        doors_count: {
          type: 'hidden',
          component: 'text',
        },
        displacement: {
          type: 'hidden',
          component: 'text',
        },
        seats_count: {
          type: 'hidden',
          component: 'text',
        },
        pva_number: {
          type: 'hidden',
          component: 'text',
        },
        eu_pva_number: {
          type: 'hidden',
          component: 'text',
        },
        car_completed_from_informex: {
          type: 'custom',
          component: 'car_from_informex_message',
          withValues: {
            vin_number: 'vehicle.vin_number',
          },
        },
        car_resume: {
          type: 'custom',
          component: 'car_resume',
        },
        date_of_first_circulation: {
          label: t('activerecord.attributes.car.date_of_first_circulation'),
          component: 'date',
          validations: ['required']
        },
        brand: {
          label: t('activerecord.attributes.car.brand'),
          component: 'text',
          validations: ['required']
        },
        fuel_type: {
          label: t('activerecord.attributes.car.fuel_type'),
          component: 'text',
          validations: ['required']
        },
        power: {
          label: t('activerecord.attributes.car.power'),
          component: 'text',
          validations: ['required']
        },
        model: {
          label: t('activerecord.attributes.car.model'),
          component: 'text',
          validations: ['required']
        },
        build_year: {
          label: t('activerecord.attributes.car.build_year'),
          component: 'text',
          validations: ['required']
        },
      },
    },
  };
};
