import React                      from 'react';
import PropTypes                  from 'prop-types';
import find                       from 'lodash/find';
import { connect }                from 'react-redux';
import { change }                 from 'redux-form';
import TarificationFormOptionItem from 'components/tarifications/form/tarification_form_option_item';

const TarificationFormOptions = ({ dispatch, fields, optionsConfiguration, disabled, cover, tarification, form }) => {
  const optionChanged = () => {
    if (cover) {
      dispatch(change('providerTarificationForm', `${ cover }.annual_price`, null));
    } else {
      tarification.covers.map((subCover, subIndex) => {
        dispatch(change('providerTarificationForm', 'tarification.annual_price', null));
        return dispatch(change('providerTarificationForm', `tarification.covers[${ subIndex }].annual_price`, null));
      });
    }
  };
  return fields.map((option, index) => {
    const optionObject = fields.get(index);
    const optionConfig = find(optionsConfiguration, config => config.id === optionObject.id);

    if (optionConfig) {
      return (
        <TarificationFormOptionItem
          form={ form }
          key={ index }
          disabled={ disabled }
          className="uk-hi"
          option={ option }
          optionChanged={ optionChanged }
          optionConfig={ optionConfig }
          optionObject={ optionObject }
          index={ index }
        />
      );
    }
    return null;
  });
};

TarificationFormOptions.defaultProps = {
  disabled:             false,
  optionsConfiguration: {},
  cover:                null,
};

TarificationFormOptions.propTypes = {
  tarification:         PropTypes.object.isRequired,
  dispatch:             PropTypes.func.isRequired,
  fields:               PropTypes.object.isRequired,
  optionsConfiguration: PropTypes.array,
  disabled:             PropTypes.bool,
  cover:                PropTypes.string,
};

export default connect()(TarificationFormOptions);
