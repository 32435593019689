import React, { useState }       from 'react';
import PropTypes                 from 'prop-types';
import { useSelector }           from 'react-redux';
import toPairs                   from 'lodash/toPairs';
import NumberService             from 'services/number';
import SubscriptionFormContainer from 'components/subscription/form_container';
import AppModal                  from 'components/ui/app_modal';
import Button                    from 'components/ui/buttons/button';
import snakeCase                 from 'lodash/snakeCase';

import './grid.scss';

const SubscriptionGrid = ({ currentPlanId }) => {
  const config = useSelector((state) => state.config);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState();

  const selectPlan = (planId) => {
    setSelectedPlanId(planId);
    setModalIsOpen(true);
  };

  const plansHtml = () => (
    toPairs(config.plans).map((pair) => {
      const planConfig = pair[1];
      return (
        <div className="uk-card uk-card-hover uk-card-default uk-grid-collapse uk-margin uk-flex-middle uk-grid">
          <div className="uk-width-expand@m">
            <div className="uk-card-body">
              <h3 className="uk-card-title">
                { t('components.subscription.grid.multitarif_plan') }
                { ' ' }
                { t(`components.subscription.grid.interval_${planConfig.interval}`) }
              </h3>
              <p>{ t_html(`components.subscription.grid.plan_description_${snakeCase(planConfig.stripe_id)}`) }</p>
              { currentPlanId === 'free' && (
                <Button primary className="subscription-button" data-purpose="select_plan_button" onClick={ () => { selectPlan(planConfig.stripe_id); } }>
                  { t('components.subscription.grid.activate') }
                </Button>
              ) }
            </div>
          </div>
          <div className="uk-width-auto@m mr-40 price-label uk-text-center">
            { NumberService.formatCurrency(planConfig.price_per_month_with_vat, '€') }
            <sup>*</sup>
            <span className="per-month">
              { ' / ' }
              { t('components.subscription.grid.month') }
            </span>
            { planConfig.interval === 'year' && (
              <span className="annual-bill">
                { t('components.subscription.grid.yearly_billed', { price: NumberService.formatCurrency(planConfig.price_with_vat, '€') }) }
              </span>
            ) }
          </div>
        </div>
      );
    })
  );

  return (
    <div className="subscription-grid">
      <div className="uk-grid">
        <div className="uk-width-1-2@m">
          <div className="uk-card uk-card-hover uk-card-default uk-grid-collapse uk-margin uk-flex-middle uk-grid">
            <div className="uk-width-expand@m">
              <div className="uk-card-body">
                <h3 className="uk-card-title">{ t('components.subscription.grid.free_plan') }</h3>
                <p>{ t('components.subscription.grid.plan_description_free') }</p>
              </div>
            </div>
            <div className="uk-width-auto@m mr-40 price-label">
              { t('components.subscription.grid.free') }
            </div>
          </div>
          { plansHtml() }
        </div>
      </div>
      <p className="mt-20 uk-text-bold">
        <sup>*</sup>
        { ' ' }
        { t('components.subscription.grid.excl_vat') }
      </p>
      <AppModal isOpen={ modalIsOpen } setIsOpen={ setModalIsOpen } width="large">
        <SubscriptionFormContainer onSuccess={ () => { setModalIsOpen(false); } } selectedPlan={ selectedPlanId } />
      </AppModal>
    </div>
  );
};

SubscriptionGrid.propTypes = {
  currentPlanId: PropTypes.string.isRequired
};

export default SubscriptionGrid;
