import { DESKTOP_APP }          from 'constants/application_constants';
import Api                      from 'services/api';
import { saveFileInDesktopApp } from 'services/desktop_app';

const convertFileToB64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      return resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

export const openLinkOrDownloadFile = (path, name) => {
  return new Promise((resolve, reject) => {
    if (DESKTOP_APP()) {
      Api.get(path, {
        responseType: 'arraybuffer',
      }).then((response) => {
        convertFileToB64(new Blob([response.data])).then((b64) => {
          return resolve(saveFileInDesktopApp(b64, name));
        }).catch(reject);
      }).catch(reject);
    } else {
      window.open(path, '_blank');
      resolve();
    }
  });
};
