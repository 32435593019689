import React     from 'react';
import PropTypes from 'prop-types';

import 'components/ui/loader/styles.scss';

const Loader = ({ middleOfPage }) => {
  const loader = (
    <div className="uk-text-center loader-container">
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );

  return middleOfPage ? <span className="middle-of-content"><span className="content">{ loader }</span></span> : loader;
};

Loader.defaultProps = {
  middleOfPage: false,
};

Loader.propTypes = {
  middleOfPage: PropTypes.bool,
};

export default Loader;
