import React from 'react';
import 'components/header/header.scss';

const Header = ({ title, titleAddition, actions }) => {
  return (
    <div className="header">
      <div className="inner">
        <h3 className="page-header-title">
          {title} {titleAddition}
        </h3>
        {actions && <span className="actions">{actions}</span>}
      </div>
      <span className="bg" />
    </div>
  );
};

export default Header;
