/* global location */

export const convertAccountMembersToManagerList = (members) => {
  if (!members) {
    return [];
  }
  return members.map((member) => {
    return {
      value: member.id,
      label: member.display_name,
    };
  });
};

export const subdomainIsAAccount = () => {
  const { hostname } = location;
  const splitted = hostname.split('.');
  return splitted[0] !== 'app';
};
