import React                           from 'react';
import PropTypes                       from 'prop-types';
import flatten                         from 'lodash/flatten';
import compact                         from 'lodash/compact';
import some                            from 'lodash/some';
import Icon                            from 'components/ui/icon';
import Title                           from 'components/ui/titles';
import OfferProductTarificationOptions from 'components/tarifications/table/product_tarification_options';
import { getCurrentLocale }            from 'services/i18n';

const TarificationsCoversOptionsTooltip = ({ covers, show }) => {
  if (!covers || !covers.length || !show) {
    return null;
  }
  const selectedItems = covers.filter(item => item.selected);
  const notSelectedItems = covers.filter(item => !item.selected);
  const warnings = flatten(compact(covers.map(cover => cover.selected && cover.warnings)));
  const hasOption = some(selectedItems, (item) => item.options_configuration && item.selected_options);
  if (!hasOption && notSelectedItems.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <Icon icon="information" />
      <div data-uk-drop="pos: bottom-center">
        <div className="uk-card uk-card-body uk-card-default tarification-option--vertical uk-text-left p-20">
          { warnings.length > 0 && (
            <div className="uk-alert m-0">
              { warnings.map((warning, index) => (
                <React.Fragment key={ `extra-informations-${ index }` }>
                  { warning[getCurrentLocale()] }
                  <br />
                </React.Fragment>
              ))
              }
            </div>
          ) }
          {
            selectedItems.map((selectedItem, id) => (
              <React.Fragment key={ `${ id }_${ selectedItem.internal_insurer_reference }` }>
                <Title
                  size="h5"
                  className="mt-10 mb-10"
                >
                  { selectedItem.name }
                </Title>
                { selectedItem.options_configuration && selectedItem.selected_options && (
                  <OfferProductTarificationOptions
                    optionsConfiguration={ selectedItem.options_configuration }
                    selectedOptions={ selectedItem.selected_options }
                  />
                ) }
              </React.Fragment>
            ))
          }
          {
            notSelectedItems.map(noSelected => (
              <Title
                size="h5"
                className="mt-10 mb-10 error"
                key={ noSelected.name }
              >
                <Icon icon="uncheck-circle error" className="mr-10" />
                { noSelected.name }
              </Title>
            ))
          }
        </div>
      </div>
    </React.Fragment>
  );
};

TarificationsCoversOptionsTooltip.defaultProps = {
  covers: null,
  show:   false,
};

TarificationsCoversOptionsTooltip.propTypes = {
  covers: PropTypes.array,
  show:   PropTypes.bool,
};

export default TarificationsCoversOptionsTooltip;
