import React from 'react';

const RecomputeWarning = () => {
  return (
    <div className="uk-text-center global-message warning p-20 mb-20">
      { t('components.tarifications.recompute_warning.warning') }
    </div>
  );
};

export default RecomputeWarning;
