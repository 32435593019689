import React                   from 'react';
import PropTypes               from 'prop-types';
import Loader                  from 'components/ui/loader';
import TarificationWaitWarning from 'components/tarifications/table/wait_warning';

const TarificationsTableColumnsWithLoader = ({ columns }) => (
  <td colSpan={ columns.length } className="uk-text-center loader-row">
    <Loader />
    <TarificationWaitWarning />
  </td>
);

TarificationsTableColumnsWithLoader.propTypes = {
  columns: PropTypes.array.isRequired,
};

export default TarificationsTableColumnsWithLoader;
