import React         from 'react';
import PropTypes     from 'prop-types';
import { connect }   from 'react-redux';
import Title         from 'components/ui/titles';
import LockedFeature from 'images/subscribtion_required.svg';
import Button        from 'components/ui/buttons/button';

const SubscriptionRequired = ({ title, canSelectPlan }) => {
  return (
    <div data-purpose="subscription_required" className="subscription-required-panel">
      <Title size="h2" align="center">
        { title || t('subscriptions.tarification.subscription_required_title') }
      </Title>
      <img src={ LockedFeature } alt="locked feature" />
      { canSelectPlan && (
        <Button
          primary
          link
          data-purpose="select_plan_button"
          to="/account/subscription"
        >
          { t('subscriptions.select_a_plan') }
        </Button>
      ) }
    </div>
  );
};

SubscriptionRequired.defaultProps = {
  canSelectPlan: false,
};

SubscriptionRequired.propTypes = {
  title:         PropTypes.string,
  canSelectPlan: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    canSelectPlan: state.current_user.profile.authorizations.account_admin,
  };
};

export default connect(mapStateToProps)(SubscriptionRequired);
