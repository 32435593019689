import React             from 'react';
import PropTypes         from 'prop-types';
import openDialogOnClick from 'components/ui/buttons/helpers/open_dialog_on_click';

const OpenDialogOnClickButton = openDialogOnClick()(() => {
  return null;
});

const confirmBeforeAction = () => (Component) => {
  class ShouldConfirmBeforeAction extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        shouldConfirm: false,
      };

      this.onComponentClick = this.onComponentClick.bind(this);
      this.onConfirm = this.onConfirm.bind(this);
      this.toggleShouldConfirm = this.toggleShouldConfirm.bind(this);

    }

    componentWillUnmount() {
      this.toggleShouldConfirm = null;
    }

    onConfirm(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      const { onClick, isSwitch, onChange } = this.props;
      this.toggleShouldConfirm && this.toggleShouldConfirm();
      if (isSwitch) {
        return onChange.call();
      }
      return onClick(ev);
    }

    onComponentClick(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      this.toggleShouldConfirm && this.toggleShouldConfirm();
      return Promise.resolve();
    }

    toggleShouldConfirm() {
      this.ref.toggleDialog();
    }

    render() {
      const { confirmTitle, confirmText, confirmButtonLabel, cancelButtonLabel, isSwitch, isLink, confirmButtonClass, onCancel, ...props } = this.props;
      const { loading } = this.state;
      let switchProps = null;

      if (isSwitch) {
        const { onChange, ...restSwitchProps } = props;
        switchProps = {
          onChange: this.toggleShouldConfirm,
          ...restSwitchProps,
        };
      }

      return (
        <React.Fragment>
          { isSwitch && <Component { ...switchProps } /> }
          { !isSwitch && <Component { ...props } onClick={ this.onComponentClick } loading={ loading } /> }
          <OpenDialogOnClickButton
            dialogTitle={ confirmTitle || t('general.actions.confirm_title') }
            ref={ (dialog) => this.ref = dialog }
            data-purpose={ props['data-purpose'] }
          >
            <div
              className="uk-text-center"
              data-purpose="confirm_dialog"
            >
              <p>{ confirmText || t('general.actions.confirm_content') }</p>
              <button
                type="button"
                id="cancel"
                className="uk-button"
                onClick={ (ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  if (this.toggleShouldConfirm) {
                    this.toggleShouldConfirm();
                  }
                  if (onCancel) {
                    onCancel();
                  }
                } }
              >
                { cancelButtonLabel || t('general.actions.no_button') }
              </button>
              <button
                type="button"
                onClick={ this.onConfirm }
                id="confirm"
                className={ `uk-button uk-button-primary ${ confirmButtonClass }` }
              >
                { confirmButtonLabel || t('general.actions.yes_button') }
              </button>
            </div>
          </OpenDialogOnClickButton>
        </React.Fragment>
      );
    }
  }

  ShouldConfirmBeforeAction.defaultProps = {
    confirmTitle:       undefined,
    confirmText:        undefined,
    isSwitch:           false,
    isLink:             false,
    confirmButtonLabel: undefined,
    cancelButtonLabel:  undefined,
    confirmButtonClass: undefined,
  };

  ShouldConfirmBeforeAction.propTypes = {
    confirmTitle:       PropTypes.string,
    confirmText:        PropTypes.any,
    confirmButtonLabel: PropTypes.string,
    cancelButtonLabel:  PropTypes.string,
    confirmButtonClass: PropTypes.string,
    isSwitch:           PropTypes.bool,
    isLink:             PropTypes.bool,
  };

  return ShouldConfirmBeforeAction;
};

export default confirmBeforeAction;
