import Api   from 'distributor/services/api';
import Alert from 'services/alert';

export const createIntegration = (integrationToCreate, integrationFor) => {
  return new Promise((resolve, reject) => {
    Api.post('/integrations', {
      integration: integrationToCreate,
      ...integrationFor,
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('distributor.models.integrations.requests.create_integration_error'));
      reject(error);
    });
  });
};

export const updateIntegration = (integrationToUpdate) => {
  return new Promise((resolve, reject) => {
    Api.put(`/integrations/${ integrationToUpdate.id }`, {
      integration: integrationToUpdate,
    }).then((response) => {
      Alert.success(t('distributor.models.integrations.requests.update_integration_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('distributor.models.integrations.requests.update_integration_error'));
      reject(error);
    });
  });
};

export const deleteIntegration = (integrationToDelete) => {
  return new Promise((resolve, reject) => {
    Api.delete(`/integrations/${ integrationToDelete.id }`).then((response) => {
      Alert.success(t('distributor.models.integrations.requests.delete_integration_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('distributor.models.integrations.requests.delete_integration_error'));
      reject(error);
    });
  });
};
