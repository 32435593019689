import isElectron from 'is-electron';

export const locales = () => [
  {
    id: 'fr',
    value: 'fr',
    label: t('general.locales.fr'),
  },
  {
    id: 'nl',
    value: 'nl',
    label: t('general.locales.nl'),
  },
];

export const notificationFrequencies = () => [
  {
    id: 'every_event',
    value: 'every_event',
    label: t('general.notification_frequency.every_event'),
  },
  {
    id: 'nothing',
    value: 'nothing',
    label: t('general.notification_frequency.nothing'),
  },
];

export const getOnlyNums = (val) => {
  if (!val) {
    return val;
  }
  return val.replace(/[^\d]/g, '');
};

export const normalizeDate = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = getOnlyNums(value);
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8,
  )}`;
};

const formatPrice = (price) => {
  const onlyNums = Number(getOnlyNums(price));
  return onlyNums.toLocaleString('fr-FR');
};

export const normalizePrice = (value) => {
  if (!value) {
    return value;
  }
  const withComa = value.toString().replace('.', ',');
  if (withComa.includes(',')) {
    const split = withComa.split(',');
    return `${formatPrice(split[0])},${getOnlyNums(split[1]) || ''}`;
  }
  return formatPrice(withComa);
};

export const validateDateFormat = (value) => {
  const dateFormatRegex = new RegExp(/(\d{2})\/(\d{2})\/(\d{4})/);
  return dateFormatRegex.test(value)
    ? undefined
    : t('wizard.date.validate_format_message');
};

export const DESKTOP_APP = () => isElectron();

export const PRODUCTION = process.env.REACT_APP_ENV === 'production';
export const STAGING = process.env.REACT_APP_ENV === 'staging';
export const VALIDATION = process.env.REACT_APP_ENV === 'validation';
export const DEVELOPMENT = process.env.REACT_APP_ENV === 'development';
export const TEST = process.env.REACT_APP_ENV === 'cypress';

export const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const ASSETS_PROTOCOL = process.env.REACT_APP_ASSETS_PROTOCOL;
export const ASSETS_DOMAIN = process.env.REACT_APP_ASSETS_DOMAIN;
export const DOMAIN_WITHOUT_PORT = process.env.REACT_APP_DOMAIN_WITHOUT_PORT;
export const ENV = process.env.REACT_APP_ENV;
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const GOOGLE_JAVASCRIPT_API_KEY =
  process.env.REACT_APP_GOOGLE_JAVASCRIPT_API_KEY;
export const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY;
export const CABLE_DOMAIN = process.env.REACT_APP_CABLE_DOMAIN;
export const CABLE_PATH = process.env.REACT_APP_CABLE_PATH;
export const MAINTENANCE_MODE = process.env.MAINTENANCE_MODE === 'ON';
export const REDIRECT_AFTER_SIGN_IN = 'redirect_after_sign_in';
export const INSURGATE_DOMAIN = process.env.REACT_APP_INSURGATE_DOMAIN;
export const INSURGATE_API = process.env.REACT_APP_INSURGATE_API;

export const RECORDING = process.env.RECORDING === 'ON';
export const IS_NETLIFY = location.href.includes('netlify');

export const SIGN_IN_URL = `${API_PROTOCOL}://app.${INSURGATE_DOMAIN}`;
export const INSURGATE_URL = SIGN_IN_URL;

export const FOR_DISTRIBUTOR = process.env.REACT_APP_CONTEXT === 'distributor';

export const DISTRIBUTOR_DOMAIN = process.env.REACT_APP_DISTRIBUTOR_DOMAIN;

export const MAZDA_ACCOUNT_ID = process.env.REACT_APP_MAZDA_ACCOUNT_ID;
export const INSURANDGO_ACCOUNT_ID =
  process.env.REACT_APP_INSURANDGO_ACCOUNT_ID;
