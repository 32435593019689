import React                     from 'react';
import PropTypes                 from 'prop-types';
import Icon                      from 'components/ui/icon';
import ProductTarificationOption from 'components/tarifications/table/product_tarification_option';

const TarificationOptionsTooltip = ({ tarification }) => {
  if (!tarification.selected_options || !tarification.options_configuration) {
    return null;
  }
  return (
    <span className="uk-inline quick-tarification-option-icon">
      <Icon icon="information" />
      <div data-uk-drop="pos: right-center">
        <div
          className="uk-card uk-card-body uk-card-default tarification-option--vertical p-20"
          style={ { width: '300px' } }
        >
          <ProductTarificationOption
            optionsConfiguration={ tarification.options_configuration }
            selectedOptions={ tarification.selected_options }
          />
        </div>
      </div>
    </span>
  );
};

TarificationOptionsTooltip.propTypes = {
  tarification: PropTypes.object.isRequired,
};

export default TarificationOptionsTooltip;
