import React                 from 'react';
import TarificationStep      from 'components/wizard/tarification_step';
import { sortTarifications } from 'models/wizard/reducer';

export const getTarificationSteps = (contractRequest, matchPath, disabledRules = [], displayRules = [], order, formComponent) => {
  if (contractRequest.tarifications && contractRequest.tarifications.length > 0) {
    const sortedTarifications = sortTarifications(contractRequest.tarifications);
    return {
      kind:  'group',
      label: t('wizard.navigation.product_configuration'),
      icon:  'actions',
      slug:  'tarifications',
      order,
      displayRules,
      steps: sortedTarifications.map((tarification) => (
        {
          path:              `${ matchPath }/tarification_${ tarification.id }`,
          title:             t('wizard.navigation.configuration', { name: tarification.product.display_name }),
          disabledRules,
          label:             tarification.product.display_name,
          error:             tarification.last_error_response,
          loading:           tarification.computing,
          tarification_data: {
            updated_at: tarification.updated_at,
            id:         tarification.id,
          },
          purpose:           `tarification_${ tarification.product.slug }`,
          Component:         (props) => {
            return (
              <TarificationStep { ...props } tarificationId={ tarification.id } formComponent={ formComponent } />
            );
          },
        }
      )),
    };
  }
};
