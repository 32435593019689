import Api from 'services/api';

export const getRequiredFieldsForProducts = (contractRequestId) => {
  return new Promise((resolve, reject) => {
    Api.get(`/contract_requests/${ contractRequestId }/required_fields`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });
};
