import { ApiInsurgate } from 'services/api';

export const getBrands = (expand = []) => {
  return new Promise((resolve, reject) => {
    ApiInsurgate.get('/brands', { params: { expand } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
