import React from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import UserSignInForm from 'components/user/sign_in/sign_in_form';
import { userSignIn } from 'models/user/requests';
import { prepareErrorsForReduxForm } from 'services/rails';
import { setUserInStore } from 'models/current_user/store_actions';
import { trackEvent } from 'services/segment';
import { getUrlSearchParam } from 'services/url';
import WarningMessage from 'components/ui/warning_message';
import InsurgateDarkLogo from 'images/insurgate_logo_dark.svg';
import LanguageSwitcher from 'components/language_switcher/language_switcher';
import Button from 'components/ui/buttons/button';
import { getCurrentLocale } from 'services/i18n';

const UserSignInContainer = ({ signIn }) => {
  const onUserSubmit = (values) => signIn(values);
  const sessionExpired = getUrlSearchParam('session_expired') === 'true';

  return (
    <div className="account-container">
      <LanguageSwitcher />
      <div className="account-form">
        <p className="uk-text-center m-0">
          <img src={InsurgateDarkLogo} alt="Openbroker" className="logo" />
        </p>
        <h1 className="title">{t('general.signin.title')}</h1>
        {sessionExpired && (
          <WarningMessage>{t('users.sessions.expired')}</WarningMessage>
        )}
        <UserSignInForm onSubmit={onUserSubmit} />
        <hr className="uk-divider-icon" />
        <p className="uk-text-center">
          <Button primary link={`/${getCurrentLocale()}/account/sign_up`} large>
            {t('account.create_account')}
          </Button>
        </p>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (session) =>
      userSignIn(session)
        .then((currentUser) => {
          trackEvent('SignedIn');
          dispatch(setUserInStore(currentUser));
        })
        .catch((error) => {
          if (error) {
            throw new SubmissionError(prepareErrorsForReduxForm(error));
          }
        }),
  };
};

export default connect(null, mapDispatchToProps)(UserSignInContainer);
