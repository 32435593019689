import numeral              from 'numeral';
import { getCurrentLocale } from 'services/i18n';

export default new class NumberService {
  constructor() {
    numeral.register('locale', 'nl-be', {
      delimiters:    {
        thousands: ' ',
        decimal:   ',',
      },
      abbreviations: {
        thousand: 'k',
        million:  ' mln',
        billion:  ' mld',
        trillion: ' bln',
      },
      ordinal:       (number) => {
        const remainder = number % 100;

        return (number !== 0 && remainder <= 1 || remainder === 8 || remainder >= 20) ? 'ste' : 'de';
      },
      currency:      {
        symbol: '€ ',
      },
    });
    numeral.register('locale', 'fr', {
      delimiters:    {
        thousands: ' ',
        decimal:   ',',
      },
      abbreviations: {
        thousand: 'k',
        million:  'm',
        billion:  'b',
        trillion: 't',
      },
      ordinal:       (number) => {
        return number === 1 ? 'er' : 'ème';
      },
      currency:      {
        symbol: '€',
      },
    });
    const locale = getCurrentLocale();
    numeral.locale(locale === 'nl' ? 'nl-be' : 'fr');
  }

  formatCurrency(value, currency) {
    return currency ? numeral(value).format(getCurrentLocale() === 'nl' ? '$ 0,0.00' : '0,0.00 $') : numeral(value).format('0,0.00');
  }
};
