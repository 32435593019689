import React               from 'react';
import { useDispatch }     from 'react-redux';
import { setSocketStatus } from 'models/config/store_actions';
import ActionCableConsumer from 'services/action_cable/consumer';

const ApplicationActionCableConsumer = (props) => {
  const dispatch = useDispatch();

  const handleOnConnected = () => {
    dispatch(setSocketStatus(true));
  };

  const handleOnDisconnected = () => {
    dispatch(setSocketStatus(false));
  };

  const handleOnRejected = () => {
    dispatch(setSocketStatus(false));
  };

  return (
    <ActionCableConsumer
      onConnected={ handleOnConnected }
      onDisconnected={ handleOnDisconnected }
      onRejected={ handleOnRejected }
      { ...props }
    />
  );
};

export default ApplicationActionCableConsumer;
