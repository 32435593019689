import React     from 'react';
import PropTypes from 'prop-types';

const Title = ({ size, children, className, ...rest }) => {
  switch (size) {
    case 'h1':
      return <h1
        className={ `${ className || '' } ob-title` }
        { ...rest }
      >
        { children }
      </h1>;
    case 'h2':
      return <h2
        className={ `${ className || '' } ob-title` }
        { ...rest }
      >
        { children }
      </h2>;
    case 'h3':
      return <h3
        className={ `${ className || '' } ob-title` }
        { ...rest }
      >
        { children }
      </h3>;
    case 'h4':
      return <h4
        className={ `${ className || '' } ob-title` }
        { ...rest }
      >
        { children }
      </h4>;
    case 'h5':
      return <h5
        className={ `${ className || '' } ob-title` }
        { ...rest }
      >
        { children }
      </h5>;
    case 'h6':
      return <h6
        className={ `${ className || '' } ob-title` }
        { ...rest }
      >
        { children }
      </h6>;
    default:
  }
};

Title.propTypes = {
  size:      PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'default'])
};

export default Title;
