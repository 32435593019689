import React                             from 'react';
import PropTypes                         from 'prop-types';
import { connect }                       from 'react-redux';
import Icon                              from 'components/ui/icon';
import LogoSelector                      from 'components/ui/form/logo_selector';
import Button                            from 'components/ui/buttons/button';
import {
  deleteAccountLogo,
  updateAccountLogo,
}                                        from 'models/current_account/requests';
import { updateCurrentAccount } from 'models/current_account/store_actions';
import openDialogOnClick                 from 'components/ui/buttons/helpers/open_dialog_on_click';

class AccountLogoSelector extends React.Component {
  constructor(props) {
    super(props);
    this.uploadImage = this.uploadImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  uploadImage(image) {
    const { dispatch } = this.props;
    return updateAccountLogo(image).then((account) => {
      dispatch(updateCurrentAccount(account));
      this.toggleDialog();
    });
  }

  deleteImage() {
    const { dispatch } = this.props;
    deleteAccountLogo().then((account) => {
      dispatch(updateCurrentAccount(account));
    });
  }

  render() {
    const {
            selectedLogo,
          } = this.props;

    const OpenDialogButton = openDialogOnClick()(({ onClick, ...rest }) => (
      <Button
        onClick={ onClick }
        { ...rest }
      >
        <div className="select-logo">
          <a
            className="select-item-placeholder small-italic"
            data-purpose="select_image_button"
          >
            { t('general.forms.image_upload.button') }
          </a>
        </div>
      </Button>
    ));
    return (
      <React.Fragment>
        <div className="select-logo">
          <div
            className={ `logo-container ${ selectedLogo.url ? 'has-remove-button' : '' }` }
            style={ {
              width:  '310px',
              height: '80px',
            } }
          >
            <React.Fragment>
              {
                !selectedLogo.url && (
                  <OpenDialogButton>
                    <LogoSelector
                      paramContainer='account'
                      uploadImage={ this.uploadImage }
                    />
                  </OpenDialogButton>
                )
              }
              {
                selectedLogo.url && (
                  <React.Fragment>
                    <Button
                      warning
                      iconOnly
                      type="button"
                      onClick={ this.deleteImage }
                      className="remove-button"
                      round
                    >
                      <Icon icon="delete" />
                    </Button>
                    <img
                      className="logo"
                      src={ selectedLogo.url }
                      data-purpose="selected_image_preview"
                      alt="brokerage account logo"
                    />
                  </React.Fragment>
                ) }
            </React.Fragment>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AccountLogoSelector.propTypes = {
  onImageUpload: PropTypes.func,
  selectedLogo:  PropTypes.object,
};

export default connect()(AccountLogoSelector);
