import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import { useDispatch }                from 'react-redux';
import { useStripe }                  from '@stripe/react-stripe-js';
import { getAccount }                 from 'models/current_account/requests';
import Loader                         from 'components/ui/loader';
import includes                       from 'lodash/includes';
import ApplicationActionCableConsumer from 'components/application/action_cable_consumer';

const ScaForm = ({ paymentIntentClientSecret, stripeSourceId, onFailure, setupIntentClientSecret, onSuccess }) => {
  const [localStripeSourceId, setLocalStripeSourceId] = useState();
  const [showIframe, setShowIframe] = useState();
  const stripe = useStripe();
  const dispatch = useDispatch();

  const injectIFrame = (url) => {
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.width = 600;
    iframe.height = 400;
    $('#sca-iframe-container').append(iframe);
  };

  const handleScaIntent = (intent, error) => {
    if (error) {
      onFailure();
    } else if (intent.status === 'succeeded') {
      setShowIframe(false);
    } else if (includes(['requires_action'], intent.status)) {
      setShowIframe(true);
      injectIFrame(intent.next_action.redirect_to_url.url);
    } else {
      onFailure();
    }
  };

  const confirmParams = () => {
    return {
      return_url:     `${window.location.protocol}//${window.location.hostname}/stripe-sca-callback`,
      payment_method: localStripeSourceId
    };
  };

  const setupIframeForSetupIntent = () => {
    stripe.retrieveSetupIntent(setupIntentClientSecret).then(({ setupIntent }) => {
      if (setupIntent.next_action === null || setupIntent.next_action.redirect_to_url === undefined) {
        stripe.confirmSetupIntent(setupIntentClientSecret, confirmParams()).then((result) => {
          handleScaIntent(result.setupIntent, result.error);
        });
      } else {
        handleScaIntent(setupIntent);
      }
    });
  };

  const setupIframeForPaymentIntent = () => {
    stripe.retrievePaymentIntent(paymentIntentClientSecret).then(({ paymentIntent }) => {
      if (paymentIntent.next_action === null || paymentIntent.next_action.redirect_to_url === undefined) {
        stripe.confirmPaymentIntent(paymentIntentClientSecret, confirmParams()).then((result) => {
          handleScaIntent(result.paymentIntent, result.error);
        });
      } else {
        handleScaIntent(paymentIntent);
      }
    });
  };

  const on3DSComplete = (event) => {
    if (event.data !== '3DS-authentication-complete') { return; }
    setShowIframe(false);

    if (paymentIntentClientSecret !== null) {
      stripe.retrievePaymentIntent(paymentIntentClientSecret).then(({ paymentIntent }) => {
        if (paymentIntent.status !== 'succeeded') {
          onFailure();
        }
      });
    } else {
      stripe.retrieveSetupIntent(setupIntentClientSecret).then(({ setupIntent }) => {
        if (setupIntent.status !== 'succeeded') {
          onFailure();
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', on3DSComplete, false);

    if (stripeSourceId !== null) {
      setLocalStripeSourceId(stripeSourceId);
    }

    if (paymentIntentClientSecret !== null) {
      setupIframeForPaymentIntent();
    } else {
      setupIframeForSetupIntent();
    }
    return () => {
      window.removeEventListener('message', on3DSComplete);
      dispatch(getAccount({ with_stripe_info: true }));
    };
  }, []);

  const handleOnReceived = ({ subscription }) => {
    if (subscription.active) {
      onSuccess();
    }
  };

  return (
    <React.Fragment>
      <ApplicationActionCableConsumer channel="SubscriptionsChannel" onReceived={ handleOnReceived } />
      { showIframe && (
        <div id="sca-iframe-container" />
      ) }
      { !showIframe && (
        <Loader />
      ) }
    </React.Fragment>
  );
};

ScaForm.defaultProps = {
  paymentIntentClientSecret: null,
  setupIntentClientSecret:   null,
  stripeSourceId:            null
};

ScaForm.propTypes = {
  paymentIntentClientSecret: PropTypes.string,
  setupIntentClientSecret:   PropTypes.string,
  stripeSourceId:            PropTypes.string,
  onSuccess:                 PropTypes.func.isRequired,
  onFailure:                 PropTypes.func.isRequired
};

export default ScaForm;
