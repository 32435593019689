import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/buttons/button';
import { connect } from 'react-redux';
import NumberService from 'services/number';
import AccountCouponsForm from 'components/account/coupons/form';
import Alert from 'services/alert';
import Moment from 'react-moment';
import openDialogOnClick from 'components/ui/buttons/helpers/open_dialog_on_click';

class AccountCoupons extends React.Component {
  constructor(props) {
    super(props);

    this.onModalSuccess = this.onModalSuccess.bind(this);

    this.modalRef = null;
  }

  onModalSuccess() {
    Alert.success(t('coupons.success'));
    this.modalRef.closeDialog();
  }

  render() {
    const { account } = this.props;

    const couponListItems = account.coupons.map((coupon) => (
      <tr key={coupon.id}>
        <td>{coupon.code}</td>
        <td>{coupon.partner}</td>
        <td>{NumberService.formatCurrency(coupon.discount_amount, '€')}</td>
        <td>
          <Moment format="LL">{coupon.redeemed_at}</Moment>
        </td>
      </tr>
    ));

    const couponList = (
      <table>
        <thead>
          <tr>
            <th>{t('coupons.table_headers.code')}</th>
            <th>{t('coupons.table_headers.company')}</th>
            <th>{t('coupons.table_headers.discount_amount')}</th>
            <th>{t('coupons.table_headers.date')}</th>
          </tr>
        </thead>
        <tbody>{couponListItems}</tbody>
      </table>
    );

    const AddCouponButton = openDialogOnClick()(({ onClick }) => (
      <Button primary type="button" className="mt-15 ml-0" onClick={onClick}>
        {t('providers.account.coupons.add')}
      </Button>
    ));

    return (
      <div>
        <div className="brokerage-section">
          <h2 className="brokerage-section-title mv-20">
            {t('providers.account.subscription.coupons.title')}
          </h2>
          <div className="row align-middle input-container">
            <div className="columns small-12 medium-4 large-2">
              <label htmlFor="billing-address">
                {t('coupons.labels.your_balance')}
              </label>
            </div>
            <div className="columns input-col small-12 medium-8 large-5">
              {NumberService.formatCurrency(
                account.stripe_customer
                  ? account.stripe_customer.account_balance
                  : 0,
                '€',
              )}
            </div>
          </div>
          <div className="row align-top input-container">
            <div className="columns small-12 medium-4 large-2">
              <label htmlFor="billing-address">
                {t('coupons.labels.your_coupons')}
              </label>
            </div>
            <div className="columns small-12 medium-8 large-8">
              <p>
                <AddCouponButton
                  dialogTitle={t('providers.account.coupons.enter_coupon_code')}
                  ref={(ref) => (this.modalRef = ref)}
                >
                  <AccountCouponsForm onSuccess={this.onModalSuccess} />
                </AddCouponButton>
              </p>
              {account.coupons.length > 0 && couponList}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountCoupons.propTypes = {
  account: PropTypes.object.isRequired,
};

const mapStateToProps = ({ account }) => {
  return {
    account,
  };
};

export default connect(mapStateToProps)(AccountCoupons);
