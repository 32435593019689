import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProviderMainContainer from 'scenes/provider';
import RouteChangeListener from 'helpers/route_change_listener';
import WithApplicationConfig from 'helpers/with_application_config';
import RedirectAfterSignIn from 'helpers/redirect_after_sign_in';
import ScrollToTopOnRouteChange from 'services/scroll_to_top_on_route_change';
import StripeScaCallback from 'components/stripe_sca_callback';
import CheckForLanguage from 'components/language_switcher/check_for_language';
import WithIntegrations from 'components/integrations/with_integrations';
import WithDrawer from 'components/ui/drawer/with_drawer';
import Helmet from 'react-helmet';
import WithBrands from 'components/brand_switcher/with_brands';

const AuthentifiedContainer = () => {
  const account = useSelector((state) => state.account);
  const currentUser = useSelector((state) => state.current_user.profile);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {currentUser.authorizations.is_agent ? account.name : null}
        </title>
      </Helmet>
      <Router>
        <Switch>
          <Route path="/stripe-sca-callback" component={StripeScaCallback} />
          <ScrollToTopOnRouteChange>
            <RouteChangeListener>
              <CheckForLanguage>
                <WithIntegrations>
                  <WithApplicationConfig>
                    <WithDrawer>
                      <WithBrands>
                        <ProviderMainContainer />
                      </WithBrands>
                    </WithDrawer>
                  </WithApplicationConfig>
                </WithIntegrations>
              </CheckForLanguage>
            </RouteChangeListener>
          </ScrollToTopOnRouteChange>
        </Switch>
        <RedirectAfterSignIn />
      </Router>
    </React.Fragment>
  );
};

export default AuthentifiedContainer;
