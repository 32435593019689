import React      from 'react';
import PropTypes  from 'prop-types';
import MemberLine from 'components/members/index/member_line';

const MembersRoleTable = ({ members }) => (
  <div>
    <table className="uk-table">
      <thead>
        <tr>
          <th>{ t('providers.account.members.role_table.name') }</th>
          <th>{ t('providers.account.members.role_table.email') }</th>
          <th>{ t('providers.account.members.role_table.office') }</th>
          <th>{ t('providers.account.members.role_table.role') }</th>
          <th className="uk-text-center">{ t('providers.account.members.role_table.active') }</th>
          <th className="uk-text-center">{ t('providers.account.members.role_table.invitation') }</th>
          <th className="uk-text-center">{ t('providers.account.members.role_table.delete') }</th>
        </tr>
      </thead>
      <tbody>
        {
          members.map((member, id) => (
            <MemberLine member={ member } key={ id } />
          ))
        }
      </tbody>
    </table>
  </div>
);

MembersRoleTable.propTypes = {
  members: PropTypes.array.isRequired,
};

export default MembersRoleTable;
