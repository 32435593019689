import React       from 'react';
import PropTypes   from 'prop-types';
import { NavLink } from 'react-router-dom';

const FolderTab = ({ tabs }) => (
  <div className="folder-tabs">
    <ul>
      {
        tabs.map(({ disabled, label, to, isActive, exact, purpose }, id) => (
          <li className={ `${ !disabled ? '' : 'tab--disabled' }` } key={ id }>
            { !disabled && (
              <NavLink
                className="tab"
                exact={ exact }
                activeClassName="active"
                to={ to }
                isActive={ isActive }
                data-purpose={ purpose }
              >
                { label }
              </NavLink>
            ) }
            {
              disabled && (
                <a className="tab">
                  { label }
                </a>
              )
            }
          </li>
        ))
      }
    </ul>
  </div>
);

FolderTab.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool.isRequired,
    label:    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    to:       PropTypes.string.isRequired,
  })).isRequired,
};

export default FolderTab;
