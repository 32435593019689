import React     from 'react';
import PropTypes from 'prop-types';

const Icon = ({
  icon, className, onDialogClose, onDialogOpen, ...rest
}) => (
  <i className={ `icon ob-icon ob-icon-${ icon } ${ className || '' }` } { ...rest } />
);

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default Icon;
