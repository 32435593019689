import React                        from 'react';
import PropTypes                    from 'prop-types';
import CreditCardForm               from 'components/credit_card/form';

const CreditCardFormContainer = ({ onSuccess }) => (
  <CreditCardForm onSuccess={ onSuccess } />
);

CreditCardFormContainer.defaultProps = {};

CreditCardFormContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default CreditCardFormContainer;
