import React  from 'react';

const Bold = ({ className, children, ...rest }) => {
  return <span className={ `uk-text-bold ${ className }` } { ...rest }>
    { children }
  </span>;
};

export default Bold

