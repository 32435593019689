import React, { useRef, useState, useEffect } from 'react';
import { Row } from 'react-flexbox-grid';
import { useSelector, useDispatch } from 'react-redux';
import { getAccount } from 'models/current_account/requests';
import { getStripePlan } from 'models/stripe_plans/requests';
import SubscriptionGrid from 'components/subscription/grid';
import Title from 'components/ui/titles';
import Alert from 'services/alert';
import ScaForm from 'components/subscription/sca_form';
import ScaFormWithCreditCardContainer from 'components/subscription/sca_form_with_credit_card_container';
import openDialogOnClick from 'components/ui/buttons/helpers/open_dialog_on_click';
import LoadOnClickButton from 'components/ui/buttons/load_on_click_button';
import SubscriptionShowCurrentSubscription from 'components/subscription/show/current_subscription';
import SubscriptionCreationButton from 'components/subscription/creation_button';
import SubscriptionResume from 'components/subscription/subscription_resume';

const SubscriptionShow = () => {
  const currentAccount = useSelector((state) => state.account);
  const subscription = useSelector((state) => state.account.subscription);
  const [customStripePlan, setCustomStripePlan] = useState();
  const [loading, setLoading] = useState(true);

  const scaModalRef = useRef();
  const newSourceModalRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentAccount.custom_stripe_plan_id) {
      getStripePlan(currentAccount.custom_stripe_plan_id).then((response) => {
        setCustomStripePlan(response.data.plan);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const updateAccount = () => {
    dispatch(getAccount({ with_stripe_info: true }));
  };

  const closeScaModal = () => {
    if (scaModalRef.current != null) {
      scaModalRef.current.closeDialog();
    } else if (newSourceModalRef.current != null) {
      newSourceModalRef.current.closeDialog();
    }
  };

  const onScaSuccess = () => {
    closeScaModal();
    Alert.success(t('stripe.sca_success'));
    updateAccount();
  };

  const onScaFailure = () => {
    closeScaModal();
    Alert.error(t('stripe.sca_failure'));
    updateAccount();
  };

  const subscriptionPending = () =>
    subscription.need_sca || subscription.need_new_source;

  const OpenScaFormButton = openDialogOnClick()(({ onClick }) => (
    <LoadOnClickButton primary onClick={onClick}>
      {t('components.subscription.show.need_sca_button_text')}
    </LoadOnClickButton>
  ));

  const OpenScaFormWithCreditCard = openDialogOnClick()(({ onClick }) => (
    <LoadOnClickButton primary onClick={onClick}>
      {t('components.subscription.show.need_source_button_text')}
    </LoadOnClickButton>
  ));

  return (
    <div data-purpose="subscription_container">
      <div className="brokerage-section">
        {!subscriptionPending() && (
          <Row>
            <SubscriptionShowCurrentSubscription subscription={subscription} />
          </Row>
        )}
        <div className="uk-grid">
          <div className="uk-width-1-3@m">
            {subscription.need_sca && (
              <div className="uk-alert-warning uk-alert">
                <Title size="h3">
                  {t('components.subscription.show.need_sca_title')}
                </Title>
                <p>{t('components.subscription.show.need_sca_text')}</p>
                <OpenScaFormButton
                  dialogTitle={t(
                    'components.subscription.show.need_sca_modal_title',
                  )}
                  dialogInnerClass=""
                  ref={scaModalRef}
                >
                  <ScaForm
                    paymentIntentClientSecret={
                      subscription.last_stripe_payment_intent_client_secret
                    }
                    setupIntentClientSecret={
                      subscription.stripe_setup_intent_client_secret
                    }
                    onSuccess={onScaSuccess}
                    onFailure={onScaFailure}
                  />
                </OpenScaFormButton>
              </div>
            )}
            {subscription.need_new_source && (
              <div className="uk-alert-warning uk-alert">
                <Title size="h3">
                  {t('components.subscription.show.need_source_title')}
                </Title>
                <p>{t('components.subscription.show.need_source_text')}</p>
                <OpenScaFormWithCreditCard
                  dialogTitle={t(
                    'components.subscription.show.need_source_modal_title',
                  )}
                  setupIntentClientSecret={
                    subscription.stripe_setup_intent_client_secret
                  }
                  dialogInnerClass=""
                  ref={newSourceModalRef}
                >
                  <ScaFormWithCreditCardContainer
                    paymentIntentClientSecret={
                      subscription.last_stripe_payment_intent_client_secret
                    }
                    setupIntentClientSecret={
                      subscription.stripe_setup_intent_client_secret
                    }
                    onSuccess={onScaSuccess}
                    onFailure={onScaFailure}
                  />
                </OpenScaFormWithCreditCard>
              </div>
            )}
          </div>
        </div>
        {!subscriptionPending() && !loading && (
          <React.Fragment>
            {customStripePlan &&
              (subscription.plan === 'free' || !subscription.active) && (
                <React.Fragment>
                  <Title margin="20px 0" size="h2">
                    {t('components.subscription.show.custom_plan_title')}
                  </Title>
                  <p>{t('components.subscription.show.custom_plan_text')}</p>
                  <p>
                    <SubscriptionResume
                      planId={currentAccount.custom_stripe_plan_id}
                    />
                  </p>
                  <p>
                    <SubscriptionCreationButton
                      planId={currentAccount.custom_stripe_plan_id}
                    />
                  </p>
                </React.Fragment>
              )}
            {!customStripePlan && (
              <React.Fragment>
                <Title margin="20px 0" size="h2">
                  {t('components.subscription.show.grid_title')}
                </Title>
                <SubscriptionGrid currentPlanId={subscription.plan} />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default SubscriptionShow;
