import React                                        from 'react';
import PropTypes                                    from 'prop-types';
import {
  Field,
}                                                   from 'redux-form';
import { connect }                                  from 'react-redux';
import { getTarificationGroupedByCovers, getTotal } from 'models/tarifications/constants';
import TarificationsCoversOptionsTooltip
                                                    from 'components/tarifications/table/covers_options_tooltip';
import ProductLogo                                  from 'components/tarifications/table/product_logo';
import TarificationTableColumnsWithErrors
                                                    from 'components/tarifications/table/table_columns_with_errors';
import TarificationTableColumnsWithLoader
                                                    from 'components/tarifications/table/table_columns_with_loader';
import CheckboxInput                                from 'components/ui/form/checkbox_input';
import { isBuildingCr }                             from 'models/wizard/reducer';

const TarificationTableRow = ({ tarification, columns, toggleAllSelected, isBuilding }) => {
  const byCovers = getTarificationGroupedByCovers(tarification);
  return (
    <tr key={ `${ tarification.id }-tr` }>
      { !isBuilding && (
        <td className="checkbox-col">
          <Field
            name={ `tarification_${ tarification.id }` }
            className="uk-checkbox"
            type="checkbox"
            component={ CheckboxInput }
            onCheck={ toggleAllSelected }
          />
        </td>
      ) }
      <td className="product-col">
        <ProductLogo product={ tarification.product } />
      </td>
      { tarification.computing && (
        <TarificationTableColumnsWithLoader columns={ columns } />
      ) }
      { !tarification.computing && tarification.error_encountered && (
        <TarificationTableColumnsWithErrors tarification={ tarification } columns={ columns } />
      ) }
      { !tarification.computing && !tarification.error_encountered && (
        <React.Fragment>
          { !isBuilding && columns.map(column => (
            <td className="price-col uk-table-middle" key={ column }>
              { byCovers[column] && column !== 'total' && (
                <span className="price-inner">
                  { getTotal(byCovers[column]) || '-' }
                  <TarificationsCoversOptionsTooltip
                    covers={ byCovers[column] }
                    show={ !!getTotal(byCovers[column]) }
                  />
                </span>
              ) }
              { byCovers[column] && column === 'total' && (
                <span className="price-inner uk-text-bold uk-align-right total-price">
                  { byCovers[column] }
                  { tarification.staggering_price && tarification.payment_staggering !== 'annually' && (
                    <span className="staggering-price">
                      ({ t(`provider.tarification.staggering_total.${ tarification.payment_staggering }`, { staggering_price: tarification.staggering_price.toFixed(2) }) })
                    </span>
                  ) }
                </span>
              ) }
              { !byCovers[column] && '-' }
            </td>
          )) }
          { isBuilding && tarification.insurer_quote_id && (
            <td colSpan={ columns.length }>
              <p className="m-0 global-message info">
                { t_html('wizard.tarifications.sent_to_axa_html', { insurer_quote_id: tarification.insurer_quote_id }) }
              </p>
            </td>
          ) }
        </React.Fragment>
      ) }
    </tr>
  );
};

TarificationTableRow.propTypes = {
  tarification: PropTypes.object.isRequired,
  columns:      PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isBuilding: isBuildingCr(state),
  };
};

export default connect(mapStateToProps)(TarificationTableRow);
