import React, {
  useContext,
  useState,
}                           from 'react';
import { Redirect }         from 'react-router';
import WizardStepperContext from 'components/wizard_stepper/wizard_context';

const RedirectOnFirstStepAvailable = () => {
  const { activeStep, wizardNavigation } = useContext(WizardStepperContext);
  if (activeStep) {
    return null;
  }

  const [redirectTo, setRedirectTo] = useState(null);
  if (wizardNavigation.getFirstAvailableStepPath && !redirectTo) {
    setRedirectTo(wizardNavigation.getFirstAvailableStepPath());
  }

  if (!redirectTo) {
    return null;
  }
  return <Redirect to={ redirectTo } />;
};


export default RedirectOnFirstStepAvailable;
