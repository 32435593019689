import Api from 'services/api';

export const fetchVersion = () => {
  return new Promise((resolve, reject) => {
    Api({
      url:     'version.json',
      method:  'get',
      baseURL: '/',
      auth:    {
        username: 'openbroker',
        password: 'D33C1d32019',
      },
    }).then((response) => {
      resolve(response.data);
    }).catch(reject);
  });
};


export const fetchGlobalMessage = () => {
  return Api.get('').then(() => {

  });
};
