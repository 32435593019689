import React                            from 'react';
import PropTypes                        from 'prop-types';
import { connect }                      from 'react-redux';
import {
  removeMemberFromCurrentAccount,
}                                       from 'models/current_account/store_actions';
import { deleteAccountMember } from 'models/current_account/requests';
import loadOnClick                      from 'components/ui/buttons/helpers/load_on_click';
import Button                           from 'components/ui/buttons/button';

const LoadOnClickButton = loadOnClick()(Button);

const DeleteMemberButton = ({ deleteMember, memberId }) => (
  <LoadOnClickButton
    warning
    small
    iconOnly
    onClick={ () => deleteMember(memberId) }
    icon="delete"
    inverted
  />
);

const mapDispatchToProps = (dispatch) => {
  return {
    deleteMember: memberId => deleteAccountMember(memberId).then(() => {
      dispatch(removeMemberFromCurrentAccount(memberId));
    }),
  };
};

DeleteMemberButton.propTypes = {
  deleteMember: PropTypes.func.isRequired,
  memberId:     PropTypes.number.isRequired,
};

export default connect(null, mapDispatchToProps)(DeleteMemberButton);
