import Api                           from 'services/api';
import Alert                         from 'services/alert';
import { prepareErrorsForReduxForm } from 'services/rails';

export const postIntegrationConfiguration = integration => (
  new Promise((resolve, reject) => {
    Api.post('integrations', {
      integration,
    }).then((response) => {
      Alert.success(t('provider.integration.add_integration_success'));
      resolve(response.data.integration);
    }).catch((error) => {
      Alert.error(t('provider.integration.add_integration_error'));
      reject(prepareErrorsForReduxForm(error.data.errors));
    });
  })
);

export const getIntegrations = (params = {}) => {
  return new Promise((resolve, reject) => {
    Api.get('integrations', { params }).then((response) => {
      resolve(response.data.integrations);
    }).catch((error) => {
      Alert.error(t('insurer.fetch_integrations_error'));
      reject(error);
    });
  });
};

export const updateIntegration = (integration) => {
  return new Promise((resolve, reject) => {
    Api.put(`integrations/${ integration.id }`, { integration }).then((response) => {
      Alert.success(t('integrations.update_integration_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('integrations.update_integration_error'));
      reject(error);
    });
  });
};

export const getAvailableIntegrations = () => {
  return new Promise((resolve, reject) => {
    Api.get('integrations/availables').then((response) => {
      resolve(response.data.integrations);
    }).catch((error) => {
      Alert.error('integrations.fetch_available_integrations_error');
      reject(error);
    });
  });
};
