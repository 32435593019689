import React     from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      progress: this.props.progress || 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      progress: nextProps.progress,
    });
  }

  render() {
    const { progress } = this.state;
    return (
      <React.Fragment>
        {
          progress < 100 && (
            <div className="progress-bar-container">
              <div className="progress-bar-inner-container">
                <div className="progress" style={ { width: `${ progress }%` } } />
              </div>
            </div>
          )
        }
      </React.Fragment>
    );
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
};

export default ProgressBar;
