import React                       from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
}                                  from 'redux-form';
import { connect }                 from 'react-redux';
import flatten                     from 'lodash/flatten';
import intersection                from 'lodash/intersection';
import Button                      from 'components/ui/buttons/button';
import TextInput                   from 'components/ui/form/text_input';
import Margin                      from 'components/ui/margin';
import { required, validateEmail } from 'models/application/constants';
import CheckboxGroup               from 'components/ui/form/checkbox_group';
import SelectInput                 from 'components/ui/form/select_input';
import CheckboxInput               from 'components/ui/form/checkbox_input';

const IntermediaryForm = ({
  submitting,
  handleSubmit,
  riskTypes,
  availableProducts,
  initialValues,
  change,
  allRiskObjectIds,
  selectedRiskObjectIds,
  selectedProductIds,
  providers,
}) => {
  const getRiskObjectOptionsList = (riskObjects) => {
    return riskObjects.map((riskObject) => ({
      value: riskObject.id,
      label: riskObject.kind_text,
    }));
  };
  return (
    <form onSubmit={ handleSubmit }>
      <input type="hidden" name="role" value="intermediary" />
      <Margin>
        <label
          htmlFor="name"
          className="mb-20 uk-text-bold"
        >
          { t('providers.intermediaries.form.name') }
        </label>
        <Field
          id="name"
          name="name"
          type="text"
          validate={ [required] }
          component={ TextInput }
        />
      </Margin>
      <Margin>
        <label
          htmlFor="email"
          className="mb-20 uk-text-bold"
        >
          { t('activerecord.attributes.user.email') }
        </label>
        <Field
          id="email"
          name="email"
          type="email"
          validate={ [required, validateEmail] }
          component={ TextInput }
        />
      </Margin>
      <Margin>
        <label
          htmlFor="create_only"
          className="mr-20 uk-text-bold"
        >
          { t('providers.intermediaries.form.hide_lead_index') }
        </label>
        <Field
          id="create_only"
          name="create_only"
          type="create_only"
          validate={ [required] }
          component={ CheckboxInput }
        />
      </Margin>
      <Margin>
        <label
          htmlFor="notify_user_id"
          className="mb-20 uk-text-bold"
        >
          { t('activerecord.attributes.provider.notify_provider') }
        </label>
        <Field
          id="notify_user_id"
          name="notify_user_id"
          list={ providers }
          component={ SelectInput }
        />
      </Margin>
      <Margin>
        <label
          className="mb-20 uk-text-bold"
        >
          { t('providers.intermediaries.form.available_risk_objects') }
          <Button
            className="ml-20 uk-text-light"
            small
            outline
            type="button"
            onClick={ () => change('available_risk_object_ids', selectedRiskObjectIds.length > 0 ? [] : allRiskObjectIds) }
          >
            { t(selectedRiskObjectIds.length === allRiskObjectIds.length ? 'providers.intermediaries.deselect_all' : 'providers.intermediaries.select_all') }
          </Button>
        </label>
        <ul className="uk-list pl-20">
          { riskTypes.map((type) => (
            <li key={ type.kind } className="item">
              <span className="uk-text-bold">{ type.kind_text }</span>
              <Field
                className="uk-list p-0"
                name="available_risk_object_ids"
                options={ getRiskObjectOptionsList(type.risk_objects) }
                component={ CheckboxGroup }
              />
            </li>
          )) }
        </ul>
      </Margin>
      <Margin>
        <label
          className="mb-20 uk-text-bold"
        >
          { t('providers.intermediaries.form.available_products') }
          <Button
            className="ml-20 uk-text-light"
            small
            outline
            type="button"
            onClick={ () => change('available_product_ids', selectedProductIds.length === availableProducts.length ? [] : availableProducts.map((p) => p.value)) }
          >
            { t(selectedProductIds.length === availableProducts.length ? 'providers.intermediaries.deselect_all' : 'providers.intermediaries.select_all') }
          </Button>
        </label>
        <Field
          name="available_product_ids"
          options={ availableProducts }
          component={ CheckboxGroup }
        />
      </Margin>
      <p className="uk-text-center mt-20">
        <Button
          primary
          loading={ submitting }
          type="submit"
          large
        >
          { initialValues && initialValues.id ? t('general.actions.edit') : t('general.actions.create') }
        </Button>
      </p>
    </form>
  );
};

const selector = formValueSelector('intermediary');

const mapStateToProps = (state, { products }) => {
  const selectedRiskObjectIds = selector(state, 'available_risk_object_ids') || [];
  const selectedProductIds = selector(state, 'available_product_ids') || [];
  const availableProducts = products.map((product) => ({
    value:         product.id,
    label:         product.display_name,
    riskObjectIds: product.risk_objects.map((r_o) => r_o.id),
  }));

  let prod = availableProducts;

  if (selectedRiskObjectIds && selectedRiskObjectIds.length) {
    prod = availableProducts.filter((p) => intersection(p.riskObjectIds, selectedRiskObjectIds).length > 0);
  }

  return {
    riskTypes:         state.config.risk_types,
    availableProducts: prod,
    allRiskObjectIds:  flatten(state.config.risk_types.map((type) => type.risk_objects.map((r_o) => r_o.id))),
    providers:         state.account.members.map((member) => ({
      value: member.id,
      label: member.display_name,
    })),
    selectedProductIds,
    selectedRiskObjectIds,
  };
};

export default reduxForm({
  form: 'intermediary',
})(
  connect(mapStateToProps)(IntermediaryForm),
);
