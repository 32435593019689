import { setIntegrationsInStore, setIntegrationsLoader } from 'models/integrations/store_actions';
import {
  getAvailableIntegrations,
  getIntegrations,
  postIntegrationConfiguration,
  updateIntegration,
}                                                        from 'models/integrations/requests';

export const fetchIntegrationsAndUpdateInStore = () => {
  return (dispatch) => {
    dispatch(setIntegrationsLoader(true, 'available'));
    dispatch(setIntegrationsLoader(true, 'active'));
    Promise.all([
      getAvailableIntegrations(),
      getIntegrations(),
    ]).then((responses) => {
      dispatch(setIntegrationsInStore(responses[0], 'available'));
      dispatch(setIntegrationsInStore(responses[1], 'active'));
      dispatch(setIntegrationsLoader(false, 'available'));
      dispatch(setIntegrationsLoader(false, 'active'));
    }).catch(() => {
      dispatch(setIntegrationsLoader(false, 'available'));
      dispatch(setIntegrationsLoader(false, 'active'));
    });
  };
};

export const updateIntegrationConfigurationAndUpdateInStore = (configuration) => {
  const method = configuration.id ? updateIntegration : postIntegrationConfiguration;
  return (dispatch) => {
    return method(configuration).then(() => {
      dispatch(fetchIntegrationsAndUpdateInStore());
    });
  };
};
