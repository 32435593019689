import LogRocket                                from 'logrocket';
import { ENV, PRODUCTION, STAGING, VALIDATION } from 'constants/application_constants';

export const initLogrocket = () => {
  if (PRODUCTION || VALIDATION || STAGING) {
    LogRocket.init(STAGING ? 'lu0k7g/openbroker-staging' : 'lu0k7g/openbroker');
    LogRocket.getSessionURL((sessionURL) => {
      const analytics = window.analytics || null;
      if (analytics) {
        analytics.track('LogRocket', {
          sessionURL,
        });
      }
    });
  }
};
export const identifyLogrocketUser = (user) => {
  if (PRODUCTION || VALIDATION || STAGING) {
    const userToIdentify = {
      email:       user.email,
      name:        user.display_name,
      subdomain:   user.account ? user.account.subdomain_name : 'distributor',
      environment: ENV,
    };
    LogRocket.identify(user.id, userToIdentify);
  }
};

const fieldsToExclude = [
  'password',
  'confirm_password',
];

export const actionSanitizer = (action) => {
  if (action.meta && fieldsToExclude.includes(action.meta.field)) {
    return null;
  }
  return action;
};
