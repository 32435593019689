import groupBy from 'lodash/groupBy';
import has     from 'lodash/has';
import every   from 'lodash/every';
import find    from 'lodash/find';
import compact from 'lodash/compact';

export class ConditionList extends Object {
  constructor(list) {
    super();
    this.list = list;
    this.messages = [];
  }

  addMessage(message) {
    this.messages.push(message);
  }

  resetMessageList() {
    this.messages = [];
  }

  checkList(source) {
    this.resetMessageList();
    if (!source) {
      return false;
    }
    const groupedConditions = groupBy(this.list, 'operator');
    let everyAndConditionsResults = [];
    let everyOrConditionsResults = [];
    if (has(groupedConditions, 'and')) {
      everyAndConditionsResults = groupedConditions.and.map((condition) => {
        const check = condition.check(source);
        if (!check) {
          this.addMessage(condition.message);
        }
        return check;
      });
    }
    if (has(groupedConditions, 'or')) {
      everyOrConditionsResults = groupedConditions.or.map((condition) => {
        const check = condition.check(source);
        if (!check) {
          this.addMessage(condition.message);
        }
        return check;
      });
    }
    return {
      check:    !(!every(everyAndConditionsResults) || (everyOrConditionsResults.length > 0 && !find(everyOrConditionsResults, condition => condition === true))),
      messages: compact(this.messages),
    };
  }
}
