import React                                    from 'react';
import PropTypes                                from 'prop-types';
import { connect }                              from 'react-redux';
import Icon                                     from 'components/ui/icon';
import confirmBeforeAction                      from 'components/ui/buttons/helpers/confirm_before_action';
import { deleteIntermediaryAndRemoveFromStore } from 'models/intermediaries/dispatch_actions';
import Button                                   from 'components/ui/buttons/button';

const ConfirmBeforeActionButton = confirmBeforeAction()((props) => {
  return (
    <Button
      type="button"
      iconOnly
      inverted
      warning
      { ...props }
    />
  );
});

const IntermediaryDeleteButton = ({ deleteIntermediary, intermediary: { authorizations }, ...props }) => {
  return (
    <ConfirmBeforeActionButton
      { ...props }
      onClick={ deleteIntermediary }
      disabled={ authorizations.delete }
    >
      <Icon icon="delete" className="pr-0" />
    </ConfirmBeforeActionButton>
  );
};

IntermediaryDeleteButton.propTypes = {
  intermediary:       PropTypes.object.isRequired,
  deleteIntermediary: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { intermediary }) => {
  return {
    deleteIntermediary: () => (
      dispatch(deleteIntermediaryAndRemoveFromStore(intermediary.id))
    ),
  };
};

export default connect(null, mapDispatchToProps)(IntermediaryDeleteButton);
