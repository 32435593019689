import React     from 'react';
import PropTypes from 'prop-types';

const Callout = ({ children, className, style, ...rest }) => (
  <div
    className={ `uk-alert uk-alert-${ style } ${ className }` }
    { ...rest }
    data-uk-alert=""
  >
    { children }
  </div>
);

Callout.defaultProps = {
  className: '',
  style:     'primary',
};

Callout.propTypes = {
  className: PropTypes.string,
  style:     PropTypes.oneOf(['primary', 'success', 'danger', 'warning']),
};

export default Callout;
