import React           from 'react';
import Title           from 'components/ui/titles';
import IntegrationList from 'components/integrations/integration_list';
import Loader          from 'components/ui/loader';

const IntegrationBlock = ({
  integrations,
  title,
  className,
  purpose,
  forCreation,
}) => {
  if (!integrations.loading && !integrations.list.length) {
    return null;
  }
  return (
    <div className={ `block top-border p-20 ${ className }` } data-purpose={ `${ purpose }_integrations_container` }>
      <Title
        size="h2"
      >
        { title }
      </Title>
      { !integrations.loading && (
        <IntegrationList integrations={ integrations.list } forCreation={ forCreation } />
      ) }
      { integrations.loading && <Loader middleOfPage /> }
    </div>
  );
};

export default IntegrationBlock;
