import React                 from 'react';
import PropTypes             from 'prop-types';
import Title                 from 'components/ui/titles';
import TooltipIcon           from 'components/ui/tooltip/tooltip_icon';
import OpenOfficeAddressForm from 'components/offices/open_office_address_form';
import WarningMessage        from 'components/ui/warning_message';
import AddressImage          from 'images/address_avatar.png';
import Avatar                from 'components/avatar';

const OfficeAddress = ({ office, onEdited, onDeleted }) => {
  return (
    <OpenOfficeAddressForm
      office={ office }
      onSubmitted={ onEdited }
      onDeleted={ onDeleted }
    >
      <div className="office-address">
        <Title size="h5" className="icon-text">
          { office.primary && (
            <TooltipIcon
              icon="grade"
              tooltipContent={ t('providers.account.addresses.primary_address') }
              cssClass="primary-address-icon"
            />
          ) }
          { office.alias }
        </Title>
        { office.address && (
          <div className="card object-card undefined  false" data-purpose="address_card" key={ office.id }>
            <div className="card-icon pl-10">
              <Avatar picture={ AddressImage } />
            </div>
            <div className="card-description">
              <div className="object-name"
                   data-purpose="address_resume">
                { `${ office.address.street_name ? `${ office.address.street_name } ` : '' } ${ office.address.street_number ? `${ office.address.street_number } ` : '' } ${ office.address.street_box ? `${ office.address.street_box } ` : '' && office.address.street_box ? `${ office.address.street_box } ` : '' }` }
              </div>
              <p className="icon-text">{ `${ office.address.zip_code } ${ office.address.city }` }</p>
            </div>
          </div>
        ) }
        { !office.address && (
          <WarningMessage>{ t('providers.account.addresses.address_undefined') }</WarningMessage>
        ) }
      </div>
    </OpenOfficeAddressForm>
  );
};

OfficeAddress.propTypes = {
  office:    PropTypes.object.isRequired,
  onEdited:  PropTypes.func,
  onDeleted: PropTypes.func,
};

export default OfficeAddress;
