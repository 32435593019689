import React       from 'react';
import { connect } from 'react-redux';
import {
  initializeIntermediariesAndUpdateInStore,
}                  from 'models/intermediaries/dispatch_actions';
import Loader      from 'components/ui/loader';

const withIntermediariesAndProducts = Component => {
  class WithIntermediaries extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    componentDidMount() {
      const { initialize } = this.props;
      initialize().then(() => {
        this.setState({
          loading: false,
        });
      }).catch(() => {
        this.setState({
          loading: false,
        });
      });
    }

    render() {
      const { loading } = this.state;
      if (loading) {
        return <Loader middleOfPage />;
      }
      return <Component />;
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      initialize: () => {
        return dispatch(initializeIntermediariesAndUpdateInStore());
      },
    };
  };

  return connect(null, mapDispatchToProps)(WithIntermediaries);
};


export default withIntermediariesAndProducts;
