import React                 from 'react';
import Button                from 'components/ui/buttons/button';
import Alert                 from 'services/alert';
import Box                   from 'components/ui/box';
import { fetchVersion }      from 'models/application/requests';
import { DEVELOPMENT, TEST } from 'constants/application_constants';

const UpdateInfoAlert = () => (
  <Box justify="center" column>
    <p>{ t('general.application.update') }</p>
    <Button
      secondary
      onClick={ () => location.reload(true) }
    >
      { t('general.application.update_action') }
    </Button>
  </Box>
);

class CheckForAppUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.buildTimestamp = null;
    this.state = {
      buildTimestamp: null,
    };
    this.checkAppVersion = this.checkAppVersion.bind(this);
  }

  checkAppVersion() {
    const { buildTimestamp } = this.state;
    fetchVersion().then((result) => {
      const { buildTimestamp: newTimestamp } = result;

      if (!buildTimestamp) {
        this.setState({
          buildTimestamp: newTimestamp,
        });
      } else {
        if (buildTimestamp < newTimestamp) {
          this.setState({
            buildTimestamp: newTimestamp,
          });
          Alert.info(<UpdateInfoAlert />);
        }
      }
    });
  }

  componentDidMount() {
    if (!DEVELOPMENT && !TEST) {
      this.checkAppVersion();
      this.interval = setInterval(this.checkAppVersion, 60 * 1000);
    }
  }

  render() {
    return null;
  }
}

export default CheckForAppUpdate;
