import React     from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const CoverError = ({ meta: { touched, error, warning }, ...restProps }) => {
  const trusted = {
    __html: error,
  };
  return (
    <React.Fragment>
      {
        touched && (
          <React.Fragment>
            { error && (
              <div className="uk-alert uk-alert-danger" { ...restProps }>
                <span dangerouslySetInnerHTML={ trusted } />
              </div>
            ) }
            { warning && (
              <div className="uk-alert uk-alert-danger" { ...restProps }>
                <span dangerouslySetInnerHTML={ trusted } />
              </div>
            ) }
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
};

CoverError.defaultProps = {
  meta: {},
};

CoverError.propTypes = {
  meta: PropTypes.object,
};

const CoverErrorMessage = (props) => (
  <Field name="covers" component={ CoverError } { ...props } />
);

export default CoverErrorMessage;
