import React           from 'react';
import classNames      from 'classnames';
import InlineContainer from 'components/ui/form/inline_container';

const CheckboxGroup = ({
  name,
  options,
  list,
  meta = {},
  input,
  className,
  asButton,
  inline,
  label,
  warning,
  id,
  containerClass,
}) => {
  const array = options || list;
  const field = (
    <ul className={ classNames(className || 'uk-list', {
      'button-list m-0':   asButton,
      'multi-column-list': !asButton,
      'pl-20':             !asButton && !inline,
    }) }>
      {
        array.map((option, index) => (
          <li className="item" key={ index }>
            <label
              className={ classNames('', {
                'select-button': asButton,
                'selected':      asButton && (input.value.indexOf(option.value) !== -1),
              }) }
            >
              <input
                type="checkbox"
                className="uk-checkbox"
                name={ `${ name }[${ index }]` }
                value={ option.label }
                checked={ input.value.indexOf(option.value) !== -1 }
                onChange={ event => {
                  const newValue = [...input.value];
                  if (event.target.checked) {
                    newValue.push(option.value);
                  } else {
                    newValue.splice(newValue.indexOf(option.value), 1);
                  }
                  return input.onChange(newValue);
                } }
              />
              { option.label }
            </label>
          </li>
        ))
      }
    </ul>
  );
  if (!inline) {
    return field;
  }

  return (
    <InlineContainer
      labelFor={ id }
      label={ label }
      warning={ warning }
      field={ field }
      meta={ meta }
      inlineContainerClass={ containerClass }
    />
  );
};

export default CheckboxGroup;
