import React from 'react';

const withTooltip = (pos) => (Component) => {
  return ({ tooltip, tooltipClassName, tooltipPos, ...rest }) => {
    return (
      <div className="uk-inline">
        <Component { ...rest } />
        <div
          data-uk-dropdown={ `pos: ${ tooltipPos || pos }` }
          className={ `${ tooltipClassName || '' } p-20` }
        >
          { tooltip }
        </div>
      </div>
    );
  };
};

export default withTooltip;
