import React          from 'react';
import PropTypes      from 'prop-types';
import { connect }    from 'react-redux';
import Icon           from 'components/ui/icon';
import { withRouter } from 'react-router-dom';
import { setCookie }  from 'services/cookies';

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params: { locale: URLLocale } } } = this.props;
    this.state = {
      selectedLanguage: URLLocale,
    };
    this.onLocaleChange = this.onLocaleChange.bind(this);
  }

  onLocaleChange({ target: { value } }) {
    const { match: { path }, location: { search } } = this.props;
    setCookie('public_page_locale', value);
    log('onLocaleChange', value);
    log('`${ path.replace(\':locale\', value) }${ location.search }`', `${ path.replace(':locale', value) }${ search }`);
    location.href = `${ path.replace(':locale', value) }${ search }`;
  }

  render() {
    const { availableLocales } = this.props;
    const { selectedLanguage } = this.state;
    return (
      <div className="LanguageSelectorContainer" data-purpose="language_selector">
        <Icon icon="outlined_flag" />
        <select
          className="uk-select"
          name="language_selector"
          id="language_selector"
          onChange={ this.onLocaleChange }
          value={ selectedLanguage }
        >
          { availableLocales.map(l => <option key={ l.value } value={ l.value }>{ l.label }</option>) }
        </select>
      </div>
    );
  }
}

const mapStateToProps = ({ config: { available_locales } }) => {
  return {
    availableLocales: Object.keys(available_locales).map((locale) => {
      return {
        value: locale,
        label: available_locales[locale],
      };
    }),
  };
};

LanguageSwitcher.defaultProps = {
  availableLocales: ['fr', 'nl'],
};

LanguageSwitcher.propTypes = {
  availableLocales: PropTypes.array,
};

export default withRouter(connect(mapStateToProps)(LanguageSwitcher));
