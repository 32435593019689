import React                   from 'react';
import PropTypes               from 'prop-types';
import {
  reduxForm,
}                              from 'redux-form';
import TarificationTableHeader from 'components/tarifications/table/table_header';
import TarificationTableRow    from 'components/tarifications/table/table_row';
import TarificationActionsRow  from 'components/tarifications/table/table_actions_row';

const TarificationTable = ({ tarifications, columns }) => (
  <div className="uk-overflow-auto">
    <table className="uk-table uk-table-small quick-tarifications-table mt-20">
      <TarificationTableHeader columns={ columns } />
      <tbody>
        { tarifications.map(tarification => (
          <React.Fragment key={ `table-row-header-${ tarification.id }` }>
            <TarificationTableRow
              tarification={ tarification }
              columns={ columns }
              key={ `table-row-${ tarification.id }` }
            />
            <TarificationActionsRow
              tarification={ tarification }
              columns={ columns }
              key={ `table-actions-row-${ tarification.id }` }
            />
          </React.Fragment>
        )) }
      </tbody>
    </table>
  </div>
);

TarificationTable.propTypes = {
  tarifications: PropTypes.array.isRequired,
  columns:       PropTypes.array.isRequired,
};

export default reduxForm({
  form: 'tarification_table',
})(TarificationTable);
