import React, {
  useEffect,
  useContext,
  useRef,
}                          from 'react';
import WizardContext       from 'components/wizard/wizard_context';
import confirmBeforeAction from 'components/ui/buttons/helpers/confirm_before_action';
import Button              from 'components/ui/buttons/button';

const ConfirmBeforeActionButton = confirmBeforeAction()(Button);

const PreventBeforeNext = ({
  recompute, confirmText, confirmButtonLabel,
}) => {
  const { setNextAction, onNext } = useContext(WizardContext);
  const buttonRef = useRef(null);

  const confirmBeforeNext = () => {
    if (buttonRef) {
      buttonRef.current.toggleShouldConfirm();
      return setNextAction(null);
    }
    return Promise.resolve();
  };
  useEffect(() => {
    setNextAction(confirmBeforeNext);
    return () => {
      setNextAction(null);
    };
  }, []);
  return (
    <ConfirmBeforeActionButton
      className="hidden"
      confirmTitle={ t('wizard.tarifications.change_detected') }
      confirmText={ confirmText || t('wizard.tarifications.change_detected_text') }
      cancelButtonLabel={ t('wizard.tarifications.continue_without_recompute') }
      confirmButtonLabel={ confirmButtonLabel || t('wizard.tarifications.continue_and_recompute') }
      confirmButtonClass="confirm-primary"
      onClick={ () => recompute().then(onNext) }
      onCancel={ () => {
        setNextAction(null);
        onNext();
      } }
      ref={ buttonRef }
    />
  );
};

export default PreventBeforeNext;
