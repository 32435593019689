import map from 'lodash/map';

export const setConfig = config => (
  {
    type: 'CONFIG/SET',
    config,
  }
);

export const setSocketStatus = (is_up) => (
  {
    type: 'CONFIG/SET_SOCKET_STATUS',
    is_up,
  }
);

export const updateConfig = ({ maintenance_mode, global_message, global_message_type }) => (
  {
    type: 'CONFIG/UPDATE_CONFIG',
    maintenance_mode,
    global_message,
    global_message_type,
  }
);

export default setConfig;

export const getLocaleList = (state) => {
  const { config: { available_locales } } = state;
  return map(available_locales, (value, key) => {
    return {
      value: key,
      label: value,
    };
  });
};
