import React                            from 'react';
import PropTypes                        from 'prop-types';
import { connect }                      from 'react-redux';
import MemberForm                       from 'components/members/form/member_form';
import { createAccountMember } from 'models/current_account/requests';

const initialValues = {
  email:      '',
  first_name: '',
  last_name:  '',
};

const MemberFormContainer = ({ onFormSubmitted, dispatch }) => {
  const onSubmitMember = (value) => {
    return dispatch(createAccountMember(value)).then(onFormSubmitted).catch(() => {});
  };

  return (
    <MemberForm onSubmit={ onSubmitMember } initialValues={ initialValues } />
  );
};

MemberFormContainer.propTypes = {
  onFormSubmitted: PropTypes.func.isRequired,
};

export default connect()(MemberFormContainer);
