import compact         from 'lodash/compact';
import uniq            from 'lodash/uniq';
import { trackEvent }  from 'services/segment';
import { DEVELOPMENT } from 'constants/application_constants';

export const NEW_FOLDER_EVENT = 'NewFolderStarted';
export const NEW_LEAD_EVENT = 'NewLeadStarted';
export const RISK_OBJECT_STEP = 'RiskObjectStepSelected';
export const PRODUCT_STEP = 'ProductSelected';
export const COVER_STEP = 'CoverSelected';
export const CUSTOMER_STEP = 'CustomerCreated';
export const BUILDING_STEP = 'BuildingCreated';
export const UPDATE_CONTRACT_REQUEST = 'ContractRequestUpdated';
export const PRODUCT_CONFIGURATION_STEP = 'ProductConfigurationEdited';
export const FINISH_FOLDER = 'FolderEnded';
export const PRINT_SELECTED_TARIFICATIONS = 'SelectedTarificationPrinted';
export const DOCK_SELECTED_TARIFICATIONS = 'SelectedTarificationDocxGenerated';

export const BMS_BUTTON_USED = 'BmsSearchUsed';

export const getProductsFromContractRequest = (contractRequest) => {
  const products = contractRequest.tarifications ? contractRequest.tarifications.map((tarif) => tarif.product) : [];
  return uniq(products);
};

export const trackWizardStep = async (name, properties) => {
  log(`TRACK : ${ name }`, {
    ...properties,
    version: 2,
  });
  if (!DEVELOPMENT) {
    await trackEvent(name, {
      ...properties,
      version: 2,
    });
  }
};

export const getTarificationTableColumns = (tarifications) => {
  let cover_categories = [];
  for (const tarification of tarifications) {
    for (const cover of tarification.covers) {
      const cover_category = cover_categories.find((cc) => cc.category === cover.category);
      if (!cover_category) {
        cover_categories.push(cover);
      } else {
        cover_category.position = Math.max(cover_category.position, cover.position);
      }
    }
  }
  cover_categories = cover_categories.sort((cc1, cc2) => cc1.position - cc2.position);
  return cover_categories.length > 0 ? [...cover_categories.map((cc) => cc.category), 'total'] : ['total'];
};

export const filterTypeForMultiTarif = (riskTypes) => {
  return compact(JSON.parse(JSON.stringify(riskTypes)).map((type) => {
    if (type.kind === 'mobility') {
      return type;
    }
  }));
};

export const DEFAULT_REQUIRED_FIELDS_AXA_CONSTRUCTOR = {
  base:             {
    recoverable_vat:       true,
    vehicle_usage:         true,
    max_annual_mileage_km: true,
  },
  policy_owner:     {
    street_name:   true,
    street_number: true,
    city:          true,
    gender:        true,
    zip_code:      true,
    vat_number:    true,
    date_of_birth: true,
    email:         true,
    phone_number:  true,
    company_type:  true,
  },
  main_driver:      {
    date_of_birth:                true,
    car_license_obtention_at:     true,
    gender:                       true,
    street_name:                  true,
    street_number:                true,
    city:                         true,
    zip_code:                     true,
    vehicle_accidents:            true,
    insured_years_as_main_driver: true,
    occupation:                   true,
  },
  secondary_driver: {
    date_of_birth:            true,
    street_name:              true,
    street_number:            true,
    city:                     true,
    zip_code:                 true,
    car_license_obtention_at: true,
  },
};

export const DEFAULT_REQUIRED_FIELDS_MAZDA_INSURANCE = {
  base:             {
    recoverable_vat:       true,
    max_annual_mileage_km: true,
  },
  vehicle:          {
    brand:                     true,
    model:                     true,
    catalogue_price:           true,
    options_catalogue_price:   true,
    usage:                     true,
    febiac_id:                 true,
    date_of_first_circulation: true,
    power:                     true,
    seats_count:               true,
    fuel_type:                 true,
    driving_aids:              true,
  },
  policy_owner:     {
    street_name:   true,
    street_number: true,
    city:          true,
    zip_code:      true,
    vat_number:    true,
    date_of_birth: true,
    email:         true,
    phone_number:  true,
  },
  main_driver:      {
    date_of_birth:                true,
    car_license_obtention_at:     true,
    street_name:                  true,
    street_number:                true,
    city:                         true,
    zip_code:                     true,
    vehicle_accidents:            true,
    insured_years_as_main_driver: true,
    occupation:                   true,
  },
  secondary_driver: {
    date_of_birth:            true,
    street_name:              true,
    street_number:            true,
    city:                     true,
    zip_code:                 true,
    car_license_obtention_at: true,
  },
};

export const DEFAULT_REQUIRED_FIELDS_AEDES_P500 = {
  policy_owner: {
    date_of_birth: true,
    street_name:   true,
    street_number: true,
    city:          true,
    zip_code:      true,
  },
};
