import React                        from 'react';
import PropTypes                    from 'prop-types';
import sortBy                       from 'lodash/sortBy';
import { useDispatch, useSelector } from 'react-redux';
import MemberInvitationStatus       from 'components/members/index/member_invitation_status';
import { updateAccountMember }      from 'models/current_account/requests';
import DeleteMemberButton           from 'components/members/delete_member_button';

const MemberLine = ({ member }) => {
  const dispatch = useDispatch();
  const offices = useSelector((state) => {
    return sortBy(state.account.offices.map((office) => {
      return {
        value: office.id,
        label: office.alias,
      };
    }), 'label');
  });

  const onRoleChange = (ev) => {
    dispatch(updateAccountMember(member.id, { role: ev.target.value })).then(() => {
      location.reload();
    });
  };

  const onOfficeChange = (ev) => {
    dispatch(updateAccountMember(member.id, { office_id: ev.target.value })).then(() => {
      location.reload();
    });
  };

  return (
    <tr className="member-line" data-purpose="member_line" id={ member.email }>
      <td>
        { member.display_name }
      </td>
      <td>
        { member.email }
      </td>
      <td>
        <select
          className="uk-select uk-form-small"
          name={ `office_id_${ member.id }` }
          id={ `office_id_${ member.id }` }
          defaultValue={ member.office_id || '-1' }
          onChange={ onOfficeChange }
        >
          <option disabled value="-1">
            { t('providers.account.members.select_office') }
          </option>
          {
            offices.map(office => (
              <option key={ office.value } value={ office.value }>{ office.label }</option>
            ))
          }
        </select>
      </td>
      <td>
        { member.role === 'salesman' && t('providers.account.members.roles.salesman') }
        { member.role !== 'salesman' && (
          <select
            className="uk-select uk-form-small"
            name={ `role_${ member.id }` }
            id={ `role_${ member.id }` }
            value={ member.role }
            onChange={ onRoleChange }
          >
            <option value="owner">{ t('providers.account.members.roles.owner') }</option>
            <option value="admin">{ t('providers.account.members.roles.admin') }</option>
            <option value="associate">{ t('providers.account.members.roles.associate') }</option>
          </select>
        ) }
      </td>
      <td className="uk-text-center">
        <MemberInvitationStatus member={ member } />
      </td>
      <td className="uk-text-center">
        { member.authorizations.destroy && (
          <DeleteMemberButton memberId={ member.id } />
        ) }
      </td>
    </tr>
  );
};

MemberLine.propTypes = {
  member:       PropTypes.object.isRequired,
};

export default MemberLine;
