import React     from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';

const WarningMessage = ({ message, children, className, maxHeight, ...rest }) => {
  return (
    <div className={ `warning-message ${ className }` } { ...rest }>
      <SimpleBar style={ { maxHeight: maxHeight || 'auto' } }>
        { message || children }
      </SimpleBar>
    </div>
  );
};

WarningMessage.defaultProps = {
  message:   null,
  children:  null,
  maxHeight: null,
};

WarningMessage.propTypes = {
  message:   PropTypes.string,
  children:  PropTypes.any,
  maxHeight: PropTypes.number,
};

export default WarningMessage;
