import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
  IntermediaryMainMenu,
  ProviderMainMenu,
} from 'models/menus/provider_menu';
import { connect } from 'react-redux';
import Alert from 'services/alert';
import MainHeader from 'components/main_header';
import GetAccount from 'helpers/get_account';
import AccountShow from 'components/account/show';
import LeadContainer from 'components/lead/container';
import FolderContainer from 'components/folder/container';
import Profile from 'components/user/profile';
import GlobalMessage from 'components/global_message';
import IntermediariesIndex from 'components/intermediaries';
import IntegrationIndex from 'components/account/integrations';

import CreateFolderRoute from 'components/folder/create_folder_route';
import SubscriptionContainer from 'components/subscription/container';

class ProviderMainContainer extends React.Component {
  componentDidMount() {
    Alert.preventNotification();
  }

  componentWillUnmount() {
    Alert.enableNotification();
  }

  render() {
    const { currentUserRole } = this.props;
    return (
      <React.Fragment>
        <GetAccount />
        <MainHeader
          mainMenu={
            currentUserRole !== 'intermediary'
              ? ProviderMainMenu
              : IntermediaryMainMenu
          }
        />
        <GlobalMessage />
        <div className="p-20">
          <Switch>
            <Route
              path="/new-folder/:product_slug"
              exact
              render={() => <CreateFolderRoute />}
            />
            {currentUserRole !== 'intermediary' && [
              <Route
                path="/folders"
                render={() => <FolderContainer />}
                key="folders"
              />,
              <Route
                path="/account"
                render={() => <AccountShow />}
                key="account"
              />,
            ]}
            ) }
            <Route path="/leads" key="leads" render={() => <LeadContainer />} />
            <Route exact path="/profile" render={() => <Profile />} />
            <Route
              path="/intermediaries"
              exact
              render={() => <IntermediariesIndex />}
            />
            <Route
              path="/integrations"
              exact
              render={() => <IntegrationIndex />}
            />
            <Route
              path="/subscription"
              render={() => <SubscriptionContainer />}
            />
            <Redirect
              from="/"
              to={currentUserRole === 'intermediary' ? '/leads' : '/folders'}
            />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserRole: state.current_user.profile.role,
  };
};

export default connect(mapStateToProps)(ProviderMainContainer);
