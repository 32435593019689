import React                       from 'react';
import ResetTarificationButton     from 'components/tarifications/reset_tarification_button';
import RecomputeTarificationButton from 'components/tarifications/recompute_tarification_button';
import SendToBackofficeButton      from 'components/backoffice/send_to_backoffice_button';
import AskForHelpButton            from 'components/tarifications/ask_for_help_button';

const TarificationActions = ({ tarification, formName }) => {
  return (
    <div className="uk-width-1-1">
      <div className="uk-flex uk-flex-inline uk-width-1-1">
        <div>
          <AskForHelpButton tarification={ tarification } />
        </div>
        <div className="uk-flex uk-flex-column uk-margin-auto-left">
          <RecomputeTarificationButton tarification={ tarification } formName={ formName } />
          <ResetTarificationButton tarification={ tarification } className="mt-20" />
        </div>
      </div>
      <div className="uk-text-center">
        <SendToBackofficeButton tarification={ tarification } />
      </div>
    </div>
  );
};

export default TarificationActions;
