import React            from 'react';
import PropTypes        from 'prop-types';
import ProductErrorList from 'components/product/product_error_list';
import isEmpty          from 'lodash/isEmpty';

const TarificationTableColumnsWithErrors = ({ tarification, columns }) => {
  const acceptance = tarification.last_error_response.acceptance_errors && !isEmpty(tarification.last_error_response.acceptance_errors);
  const { last_error_response: { message } } = tarification;
  let errors = null;
  if (message === undefined) {
    errors = acceptance ? tarification.last_error_response.acceptance_errors : tarification.last_error_response.errors;
  }

  return (
    <td colSpan={ columns.length }>
      { message && (
        <div className="uk-alert-warning p-10">
          { message }
        </div>
      ) }
      { !message && (
        <div className={ `uk-alert-${ acceptance ? 'disabled' : 'warning' }` }>
          <ul data-uk-accordion>
            <li>
              <a className="uk-accordion-title" href="#">
                { !acceptance && errors && (
                  <React.Fragment>
                    { t('quick_tarification.missing_data', { count: Object.keys(errors).length }) }
                    <button
                      type="button"
                      className="product-missing-data-button show"
                    >
                      { t('general.actions.see') }
                    </button>
                    <button
                      type="button"
                      className="product-missing-data-button hide"
                    >
                      { t('general.actions.hide') }
                    </button>
                  </React.Fragment>
                ) }
                { acceptance && t('quick_tarification.unavailable_product') }
              </a>
              <div className="uk-accordion-content">
                <ProductErrorList errors={ errors } />
              </div>
            </li>
          </ul>
        </div>
      ) }
    </td>
  );
};

TarificationTableColumnsWithErrors.propTypes = {
  tarification: PropTypes.object.isRequired,
  columns:      PropTypes.array.isRequired,
};

export default TarificationTableColumnsWithErrors;
