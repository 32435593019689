import map           from 'lodash/map';
import groupBy       from 'lodash/groupBy';
import reduce        from 'lodash/reduce';
import NumberService from 'services/number';
import moment        from 'moment';

export const mapInErrors = (errors, product, obj = {}) => {
  if (typeof errors === 'object') {
    map(errors, (value, key) => {
      if (Array.isArray(value)) {
        if (obj[key]) {
          obj[key] = {
            products: obj[key].products ? [{
              error: typeof value === 'string' ? [value] : value,
              product,
            }, ...obj[key].products] : [{
              error: typeof value === 'string' ? [value] : value,
              product,
            }],
          };
        } else {
          obj[key] = {
            products: [
              {
                product,
                error: typeof value === 'string' ? [value] : value,
              },
            ],
          };
        }
      } else if (typeof value === 'object') {
        obj[key] = mapInErrors(value, product, obj[key]);
      } else {
        if (obj[key]) {
          obj[key] = {
            products: obj[key].products ? [{
              error: typeof value === 'string' ? [value] : value,
              product,
            }, ...obj[key].products] : [{
              error: typeof value === 'string' ? [value] : value,
              product,
            }],
          };
        } else {
          obj[key] = {
            products: [
              {
                error: typeof value === 'string' ? [value] : value,
                product,
              },
            ],
          };
        }
      }
    });
  }
  return obj;
};

export const getTotal = (covers) => {
  if (covers && covers.length) {
    const selectedItems = covers.filter(item => item.selected);
    if (selectedItems.every((item) => item.annual_price == null)) {
      return null;
    }
    const total = reduce(selectedItems, (sum, item) => {
      return sum + parseFloat(item.annual_price);
    }, 0);
    return NumberService.formatCurrency(total, '€');
  }
  return null;
};

export const getTarificationGroupedByCovers = (tarification) => {
  return {
    ...groupBy(tarification.covers, 'category'),
    total: tarification.annual_price ? NumberService.formatCurrency(tarification.annual_price, '€') : null,
  };
};

export const moreRecent = (oldTarification, newTarification) => (
  moment(newTarification.updated_at).isAfter(oldTarification.updated_at)
);

export const getMoreRecent = (tarifications) => {
  return tarifications.sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  })[0];
};
