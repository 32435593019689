export const setActiveStep = (step) => ({
  type: 'WIZARD_STEPPER/SET_ACTIVE_STEP',
  step,
});

export const setNavigation = (navigation) => ({
  type: 'WIZARD_STEPPER/SET_NAVIGATION',
  navigation,
});

export const cleanWizard = () => ({
  type: 'WIZARD_STEPPER/CLEAN',
});
