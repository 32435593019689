import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import MainMenuItem from 'components/main_menu/main_menu_item';
import { DEVELOPMENT, STAGING } from 'constants/application_constants';

const MainMenu = ({ menu }) => {
  return (
    <ul className="menu main">
      {compact(menu).map((menu_item, id) => (
        <MainMenuItem {...menu_item} key={id} />
      ))}
    </ul>
  );
};

MainMenu.propTypes = {
  menu: PropTypes.array.isRequired,
};

export default MainMenu;
