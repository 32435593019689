import React                        from 'react';
import PropTypes                    from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import ScaFormWithCreditCard        from 'components/subscription/sca_form_with_credit_card';
import { getCurrentLocale }         from 'services/i18n';
import { STRIPE_PUBLISHABLE_KEY }   from 'constants/application_constants';

const ScaFormWithCreditCardContainer = ({ paymentIntentClientSecret, setupIntentClientSecret, onSuccess, onFailure }) => (
  <ScaFormWithCreditCard paymentIntentClientSecret={ paymentIntentClientSecret } setupIntentClientSecret={ setupIntentClientSecret } onSuccess={ onSuccess } onFailure={ onFailure } />
);

ScaFormWithCreditCardContainer.defaultProps = {
  paymentIntentClientSecret: null,
  setupIntentClientSecret:   null
};

ScaFormWithCreditCardContainer.propTypes = {
  paymentIntentClientSecret: PropTypes.string,
  setupIntentClientSecret:   PropTypes.string,
  onSuccess:                 PropTypes.func.isRequired,
  onFailure:                 PropTypes.func.isRequired
};

export default ScaFormWithCreditCardContainer;
