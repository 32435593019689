import React                 from 'react';
import has                   from 'lodash/has';
import snakeCase             from 'lodash/snakeCase';
import openDialogOnClick     from 'components/ui/buttons/helpers/open_dialog_on_click';
import IntegrationForm       from 'distributor/components/integrations/integration_form';
import { updateIntegration } from 'distributor/models/integrations/requests';
import { SubmissionError }   from 'redux-form';

const Item = ({integration, className, ...rest}) => {
  const name = integration.name || integration.insurance_company.name;
  return (
    <div
      className={ `product-card-item ${ className }` }
      data-purpose={ `integration_${ snakeCase(name) }` }
      { ...rest }
    >
      { integration.status && (
        <div className={ `integration-status ${ integration.status }` }>
          { t(`company.integrations.statuses.${ integration.status }`) }
        </div>
      ) }
      { has(integration, 'insurance_company.logo.normal.url') && (
        <img
          src={ integration.insurance_company.logo.normal.url }
          alt={ name }
        />
      ) }
      <div className="product-card-name">
        { name }
      </div>
    </div>
  );
};

const OpenDialogOnClickItem = openDialogOnClick()(Item);

const IntegrationItem = ({integration, className, onIntegrationUpdated, onIntegrationDeleted, onClick, deletable, forCreation, onIntegrationSubmit}) => {
  const onSubmit = (tarificationToUpdate) => {
    if (onIntegrationSubmit) {
      return onIntegrationSubmit(tarificationToUpdate);
    }
    return updateIntegration(tarificationToUpdate).then(({integration}) => {
      if (onIntegrationUpdated) {
        onIntegrationUpdated(integration);
      }
      if (window.closeDialog) {
        window.closeDialog();
      }
    }).catch((errors) => {
      if (errors) {
        throw new SubmissionError(errors);
      }
    });
  };
  if (onClick) {
    return (
      <Item
        integration={ integration }
        onClick={ () => onClick(integration) }
        className={ className }
      />
    );
  }
  return (
    <OpenDialogOnClickItem
      integration={ integration }
      className={ className }
      dialogTitle={ integration.name || integration.insurance_company.name }
    >
      <IntegrationForm
        configurationTemplate={ integration.insurance_company.integration_configuration_template }
        initialValues={ {
          ...integration,
          insurance_company_id: integration.insurance_company.id,
        } }
        onDeleted={ onIntegrationDeleted }
        deletable={ deletable }
        onSubmit={ onSubmit }
        forCreation={ forCreation }
      />
    </OpenDialogOnClickItem>
  );
};

export default IntegrationItem;
