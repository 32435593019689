import React                                              from 'react';
import PropTypes                                          from 'prop-types';
import find                                               from 'lodash/find';
import get                                                from 'lodash/get';
import { getFormSubmitErrors, change, formValueSelector } from 'redux-form';
import { connect }                                        from 'react-redux';
import Button                                             from 'components/ui/buttons/button';
import KeywordFilter                                      from 'components/ui/keyword_filter';
import TarificationOptionList                             from 'components/tarifications/form/tarification_option_list';

import { getCurrentLocale } from 'services/i18n';
import openDialogOnClick    from 'components/ui/buttons/helpers/open_dialog_on_click';

const OpenInDialogButton = openDialogOnClick()(({ onClick, selectedOption }) => (
  <Button
    onClick={ onClick }
    secondary
  >
    { selectedOption ? t('general.actions.change') : t('general.actions.select') }
  </Button>
));

class TarificationFormOptionsDialog extends React.Component {
  constructor(props) {
    super(props);

    const { values } = props;

    this.state = {
      keyword: '',
      values,
    };

    this.onValuesFiltered = this.onValuesFiltered.bind(this);
    this.onKeywordUpdate = this.onKeywordUpdate.bind(this);
    this.reinitFilters = this.reinitFilters.bind(this);
    this.setNewValue = this.setNewValue.bind(this);
  }

  onValuesFiltered(values) {
    this.setState({ values });
  }

  onKeywordUpdate(keyword) {
    this.setState({ keyword });
  }

  reinitFilters() {
    const { values } = this.props;
    this.setState({
      keyword: '',
      values,
    });
  }

  setNewValue(newValue) {
    const { dispatch, name, form } = this.props;
    dispatch(change(form, name, newValue));
    window.closeDialog && window.closeDialog();
  }

  render() {
    const { values: initialValues, disabled, error, selected, optionConfig } = this.props;
    const { values, keyword } = this.state;
    const selectedOption = find(optionConfig.values, value => value.value === selected);
    return (
      <React.Fragment>
        { selectedOption && (
          <Button
            className="selected-option-button mr-10"
            disabled={ disabled }
            outline
            key={ selectedOption.value }
            type="button"
            icon="check"
          >
            { selectedOption.label[getCurrentLocale()] ? selectedOption.label[getCurrentLocale()] : selectedOption.label }
          </Button>
        ) }
        <OpenInDialogButton
          secondary
          disabled={ disabled }
          onDialogClose={ this.reinitFilters }
          selectedOption={ selectedOption }
        >
          <KeywordFilter
            inputPlaceholder={ t('general.actions.search') }
            list={ initialValues }
            labelToFilter={ ['label'] }
            onKeywordUpdate={ this.onKeywordUpdate }
            onFiltered={ this.onValuesFiltered }
            className="mb-20"
          />
          <TarificationOptionList
            values={ values }
            keyword={ keyword }
            selected={ selected }
            onOptionClick={ this.setNewValue }
          />
        </OpenInDialogButton>
        { error && <span className="error">{ error }</span> }
      </React.Fragment>
    );
  }
}

TarificationFormOptionsDialog.defaultProps = {
  disabled:     true,
  optionConfig: null,
  error:        null,
  selected:     null,
};

TarificationFormOptionsDialog.propTypes = {
  values:       PropTypes.array.isRequired,
  name:         PropTypes.string.isRequired,
  optionConfig: PropTypes.object,
  disabled:     PropTypes.bool,
  onChange:     PropTypes.func.isRequired,
  error:        PropTypes.any,
  selected:     PropTypes.any,
};


const mapStateToProps = (state, { name, form }) => {
  const selector = formValueSelector(form);
  const errors = getFormSubmitErrors(form)(state);
  const error = get(errors, name);
  return {
    error,
    selected: selector(state, name),
  };
};

export default connect(mapStateToProps)(TarificationFormOptionsDialog);
