import React, { useState } from 'react';
import moment              from 'moment';

const TogglableDate = ({ date }) => {
  const modes = {
    formatted: 'fromNow',
    fromNow:   'formatted',
  };
  const [active, setActive] = useState('fromNow');
  const formatted = moment(date).format('DD-MM-YYYY HH:mm');
  const fromNow = moment(date).fromNow();
  const toggle = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActive(modes[active]);
  };
  if (active === 'fromNow') {
    return (
      <span data-uk-tooltip={ formatted } onClick={ toggle }>
        { fromNow }
      </span>
    );
  }
  return (
    <span
      data-uk-tooltip={ fromNow }
      onClick={ toggle }
    >
      { formatted }
    </span>
  );
};

export default TogglableDate;
