import React                           from 'react';
import {
  reduxForm,
  Field,
  formValueSelector,
}                                      from 'redux-form';
import { connect }                     from 'react-redux';
import every                           from 'lodash/every';
import { getPaymentStaggeringOptions } from 'models/contract_request/constants';
import SelectInput                     from 'components/ui/form/select_input';
import Title                           from 'components/ui/titles';
import div                             from 'components/ui/margin';
import MultiButton                     from 'components/ui/form/multi_button_input';
import CoverErrorMessage               from 'components/cover/cover_form_error';


const CoverForm = ({
  covers,
  formulaList,
  deductibleAmountList,
  deductibleTypeList,
  derogationList,
  degressionList,
  change,
  selectedCovers,
}) => {

  const { vehicle_protection: vehicleProtectionSelected } = selectedCovers;

  const allCoversAreSelected = every(Object.keys(selectedCovers), key => selectedCovers[key]);

  const toggleSelectAll = () => {
    covers.forEach((cover) => change(cover, !allCoversAreSelected));
  };


  return (
    <div className="uk-width-1-1">
      <button
        className="uk-button uk-button-default mb-20"
        type="button"
        onClick={ toggleSelectAll }
        data-purpose="select_all_covers_button"
      >
        { allCoversAreSelected && (
          t('wizard.covers.deselect_all_cover')
        ) }
        { !allCoversAreSelected && (
          t('wizard.covers.select_all_cover')
        ) }
      </button>
      <form action="" data-purpose="covers_form">
        { covers.map((cover) => (
          <React.Fragment key={ cover }>
            <div className={ `inline-fields ${ (cover === 'vehicle_protection' && vehicleProtectionSelected) ? 'mb-10' : '' }` }>
              <Field
                name={ cover }
                id={ cover }
                type="checkbox"
                className="uk-checkbox"
                component="input"
              />
              <label htmlFor={ cover } className="uk-form-label">
                { t(`providers.contract_request.cover_form.cover_categories.${ cover }`) }
              </label>
            </div>
            { cover === 'vehicle_protection' && vehicleProtectionSelected && (
              <React.Fragment>
                <div className="uk-alert p-10">
                  { t('wizard.covers.product_general_configuration_explanation') }
                </div>
                <div className="uk-flex uk-flex-top mb-20">
                  <div className="config-field uk-width-1-1@s uk-width-1-3@m mr-20">
                    <label htmlFor="product_configuration_formula">
                      { t('quick_tarification.product_configuration_formula') }
                    </label>
                    <Field
                      name="product_configuration_formula"
                      id="product_configuration_formula"
                      list={ formulaList }
                      isClearable={ false }
                      component={ SelectInput }
                    />
                  </div>
                  <div className="config-field uk-width-1-1@s uk-width-1-3@m mr-20">
                    <label htmlFor="product_configuration_deductible_amount">
                      { t('quick_tarification.product_configuration_degression') }
                    </label>
                    <Field
                      name="product_configuration_degression"
                      list={ degressionList }
                      isClearable={ false }
                      component={ SelectInput }
                    />
                  </div>
                  <div className="config-field uk-width-1-1@s uk-width-1-3@m mr-20">
                    <label htmlFor="product_configuration_deductible_type">
                      { t('quick_tarification.product_configuration_deductible_type') }
                    </label>
                    <Field
                      name="product_configuration_deductible_type"
                      id="product_configuration_deductible_type"
                      list={ deductibleTypeList }
                      isClearable={ false }
                      component={ SelectInput }
                    />
                  </div>
                  <div className="config-field uk-width-1-1@s uk-width-1-3@m">
                    <label htmlFor="product_configuration_deductible_amount">
                      { t('quick_tarification.product_configuration_deductible_amount') }
                    </label>
                    <Field
                      name="product_configuration_deductible_amount"
                      id="product_configuration_deductible_amount"
                      list={ deductibleAmountList }
                      isClearable={ false }
                      component={ SelectInput }
                    />
                  </div>
                </div>
              </React.Fragment>
            ) }
          </React.Fragment>
        )) }
        <CoverErrorMessage data-purpose="covers_error_validation_message" />
        <Title
          size="h3"
          className="mt-20"
        >
          { t('quick_tarification.product_configuration_derogation_percentage') }
        </Title>
        <div className="inline-fields">
          <label htmlFor="product_configuration_derogation_percentage" className="uk-form-label">
            { t('quick_tarification.product_configuration_derogation_percentage') }
          </label>
          <Field
            name="product_configuration_derogation_percentage"
            id="product_configuration_derogation_percentage"
            list={ derogationList }
            isClearable={ false }
            component={ SelectInput }
          />
        </div>
        <Title
          size="h3"
          className="mt-20"
        >
          { t('contract_request.payment_staggering') }
        </Title>
        <div className="inline-fields">
          <label htmlFor="payment_staggering">
            { t('contract_request.payment_staggering') }
          </label>
          <Field
            name="payment_staggering"
            id="payment_staggering"
            list={ getPaymentStaggeringOptions() }
            component={ MultiButton }
          />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, { covers, form }) => {
  const selector = formValueSelector(form);
  const coverToSelectInForm = covers || [];
  const {
          config: {
                    options_collections: {
                                           product_configuration_formula,
                                           product_configuration_deductible_amount,
                                           product_configuration_deductible_type,
                                           product_configuration_derogation_percentage,
                                           product_configuration_degression,
                                         },
                  },
        } = state;
  return {
    covers,
    selectedCovers:       selector(state, ...coverToSelectInForm),
    formulaList:          product_configuration_formula,
    deductibleTypeList:   product_configuration_deductible_type,
    deductibleAmountList: product_configuration_deductible_amount,
    derogationList:       product_configuration_derogation_percentage,
    degressionList:       product_configuration_degression,
  };
};

export default reduxForm({
  enableReinitialize: true,
})(connect(mapStateToProps)(CoverForm));
