import React                             from 'react';
import PropTypes                         from 'prop-types';
import { withRouter }                    from 'react-router-dom';
import { updateIntercom }                from 'services/intercom';
import { identifyUser, trackPage }       from 'services/segment';
import { connect }                       from 'react-redux';
import { setUrlSearchParam }             from 'services/url';
import { INTERMEDIARY_URL_SEARCH_PARAM } from 'models/intermediaries/constants';
import { currentUserIsIntermediary }     from 'models/current_user/reducer';

class RouteChangeListener extends React.Component {
  constructor(props) {
    super(props);
    this.identify = this.identify.bind(this);
  }

  componentDidMount() {
    const { history, isIntermediary, intermediary_token } = this.props;
    this.identify();
    history.listen(() => {
      this.identify();
      trackPage();
      updateIntercom();
      if (isIntermediary) {
        setUrlSearchParam(INTERMEDIARY_URL_SEARCH_PARAM, intermediary_token);
      }
    });
  }

  identify() {
    const { current_user, account, noIntercom } = this.props;
    identifyUser(current_user, account, noIntercom);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

RouteChangeListener.defaultProps = {
  current_user: null,
  account:      null,
  noIntercom:   false,
};

RouteChangeListener.propTypes = {
  history:      PropTypes.object.isRequired,
  children:     PropTypes.any.isRequired,
  current_user: PropTypes.object,
  account:      PropTypes.object,
  noIntercom:   PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { current_user, account } = state;
  return {
    current_user:       current_user.profile,
    account,
    isIntermediary:     currentUserIsIntermediary(state),
    intermediary_token: current_user ? current_user.intermediary_token : null,
  };
};

export default withRouter(connect(mapStateToProps)(RouteChangeListener));
