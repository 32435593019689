import React, { useEffect }            from 'react';
import { useDispatch, useSelector }    from 'react-redux';
import Icon                            from 'components/ui/icon';
import classNames                      from 'classnames';
import { Link }                        from 'react-router-dom';
import { goToNewFolder }               from 'models/folders/constants';
import {
  filterTypeForMultiTarif,
  RISK_OBJECT_STEP,
  trackWizardStep,
}                                      from 'models/wizard/constants';
import asWizardStep                    from 'components/wizard_stepper/as_wizard_step';
import Title                           from 'components/ui/titles';
import { initOrUpdateContractRequest } from 'models/wizard/dispatch_actions';

const RiskObjectStep = ({ toggleButton, nextStep }) => {
  const dispatch = useDispatch();
  const riskObjectsByType = useSelector((state) => filterTypeForMultiTarif(state.config.risk_types));
  const selectedRiskObject = useSelector((state) => state.wizard.contract_request.risk_object || {});
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const existingContractRequest = !!contractRequest.id;
  const isSelected = (riskObject) => selectedRiskObject.id === riskObject.id;
  const onRiskObjectClick = (risk_object) => {
    trackWizardStep(RISK_OBJECT_STEP, {
      risk_object: risk_object.kind,
    });
    if (!existingContractRequest) {
      dispatch(initOrUpdateContractRequest({
        ...contractRequest,
        risk_object,
      })).then(() => {
        nextStep();
      });
    }
  };

  useEffect(() => {
    if (!contractRequest.risk_object) {
      toggleButton('next', true);
    }
  }, []);

  return (
    <div data-purpose="risk_object_step">
      { riskObjectsByType.map((type) => {
        return (
          <div className="pt-20" key={ type.kind_text }>
            <Title size="h3" className="uk-text-center">{ type.kind_text }</Title>
            <div className="risk-object-card-list">
              { type.risk_objects.map((risk_object) => {
                return (
                  <button
                    key={ risk_object.id }
                    type="button"
                    onClick={ () => onRiskObjectClick(risk_object) }
                    className={ classNames('risk-object-button-card', {
                      selected: isSelected(risk_object),
                    }) }
                    disabled={ existingContractRequest && !isSelected(risk_object) }
                    data-purpose={ `${ risk_object.kind }_button` }
                  >
                    { isSelected(risk_object) && (<Icon icon="check" className="icon-selected" />) }
                    <Icon icon={ risk_object.icon } />
                    { risk_object.kind_text }
                  </button>
                );
              }) }
            </div>
          </div>

        );
      }) }
      { existingContractRequest && (
        <div className="uk-alert uk-alert-warning uk-text-center">
          { `${ t('wizard.risk_object.not_editable') } ` }
          <Link to="/folders/new" onClick={ goToNewFolder }>
            { t('folders.new_folder') }
          </Link>
        </div>
      ) }
    </div>
  );
};

export default asWizardStep()(RiskObjectStep);
