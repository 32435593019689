import React       from 'react';
import PropTypes   from 'prop-types';
import Icon        from 'components/ui/icon/index';
import withTooltip from 'components/ui/tooltip/with_tooltip';

const TootlipedIcon = withTooltip()(Icon);

const TooltipIcon = ({
  icon, tooltipContent, cssClass, ...rest
}) => (
  <TootlipedIcon
    tooltip={ tooltipContent }
    icon={ icon }
    className={ `tooltip-icon ${ cssClass }` }
    { ...rest }
  />
);

TooltipIcon.defaultProps = {
  icon:           'information',
  tooltipContent: null,
  cssClass:       null,
};

TooltipIcon.propTypes = {
  icon:           PropTypes.string,
  tooltipContent: PropTypes.any,
  cssClass:       PropTypes.string,
};

export default TooltipIcon;
