import React                       from 'react';
import TarificationStep            from 'components/wizard/tarification_step';
import ProductFactoryStep          from 'components/wizard/product_factory/product_factory_step';
import { Condition }               from 'models/condition/condition';
import getAxaFleetTarificationForm from 'components/axa/fleet/tarification_form';

import policyOwnerSchema from 'schemas/product_factory/fleet/policy_owner_schema.json';
import riskTypesSchema   from 'schemas/product_factory/fleet/risk_types_schema.json';
import carsSchema        from 'schemas/product_factory/fleet/cars_schema.json';
import vansSchema        from 'schemas/product_factory/fleet/vans_schema.json';

export const getFleetSteps = (matchPath, contractRequest) => {
  const getTarificationStep = (vehicle_kind) => {
    const tarification = contractRequest.tarifications[0];
    return {
      path:              `${ matchPath }/${vehicle_kind}s_tarification_${ tarification.id }`,
      title:             t(`wizard.${vehicle_kind}s.title`),
      disabledRules:     [
        new Condition({
          property:   `contract_request.data.risk_types.${vehicle_kind}s`,
          assertion: 'isTrue',
        })
      ],
      label:             t(`wizard.navigation.${vehicle_kind}s`),
      error:             tarification.last_error_response,
      loading:           tarification.computing,
      tarification_data: {
        updated_at: tarification.updated_at,
        id:         tarification.id,
      },
      purpose:           `tarification_${ tarification.product.slug }`,
      Component:         (props) => {
        return (
          <TarificationStep { ...props } tarificationId={ tarification.id } formComponent={ getAxaFleetTarificationForm(vehicle_kind) } />
        );
      },
    };
  };

  return [
    {
      order: 1,
      label: t('wizard.navigation.about_contract_request'),
      icon:  'file',
      slug:  'contract_request',
      kind:  'group',
      steps: [
        {
          order:          1,
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          purpose:        'policy_owner_step',
          icon:           'company',
          Component:      ProductFactoryStep,
          componentProps: {
            stepSchema: {
              ...policyOwnerSchema,
            },
            onNextStep: {
              refreshTarifications: false,
            },
          },
        },
        {
          order:          2,
          path:           `${ matchPath }/risk_types`,
          title:          t('wizard.risk_types.title'),
          label:          t('wizard.navigation.risk_types'),
          purpose:        'risk_types_step',
          icon:           'folder',
          Component:      ProductFactoryStep,
          componentProps: {
            stepSchema: {
              ...riskTypesSchema,
            },
            onNextStep: {
              refreshTarifications: false,
            },
          },
        },
        {
          order:          3,
          path:           `${ matchPath }/cars`,
          title:          t('wizard.cars.title'),
          label:          t('wizard.navigation.cars'),
          purpose:        'cars_step',
          icon:           'car',
          displayRules:   [
            new Condition({
              property:  'contract_request.data.risk_types.cars',
              assertion: 'isTrue',
            })
          ],
          Component:      ProductFactoryStep,
          componentProps: {
            stepSchema: {
              ...carsSchema,
            },
            onNextStep: {
              refreshTarifications: true,
            },
          },
        },
        {
          order:          4,
          path:           `${ matchPath }/vans`,
          title:          t('wizard.vans.title'),
          label:          t('wizard.navigation.vans'),
          purpose:        'vans_step',
          icon:           'van',
          displayRules:   [
            new Condition({
              property:  'contract_request.data.risk_types.vans',
              assertion: 'isTrue',
            })
          ],
          Component:      ProductFactoryStep,
          componentProps: {
            stepSchema: {
              ...vansSchema,
            },
            onNextStep: {
              refreshTarifications: true,
            },
          },
        },
      ],
    },

    {
      kind:  'group',
      label: t('wizard.navigation.product_configuration'),
      icon:  'actions',
      slug:  'tarifications',
      order: 20,
      steps: [
        getTarificationStep('car'),
        getTarificationStep('van'),
      ],
    }

  ];
};
