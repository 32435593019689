import {
  addIntermediaryInList,
  updateIntermediaryInList,
  setIntermediariesList,
  setIntermediariesLoader,
  setIntermediariesProducts,
  removeIntermediaryFromList,
}                                    from 'models/intermediaries/store_actions';
import {
  createIntermediary,
  updateIntermediary,
  fetchIntermediaries,
  deleteIntermediary,
}                                    from 'models/intermediaries/requests';
import { getProducts }               from 'models/product/requests';

export const initializeIntermediariesAndUpdateInStore = () => {
  return (dispatch) => {
    dispatch(setIntermediariesLoader(true));
    return Promise.all([
      getProducts({
        includes: 'risk_objects',
      }),
      fetchIntermediaries(),
    ])
      .then((responses) => {
        dispatch(setIntermediariesProducts(responses[0].products));
        dispatch(setIntermediariesList(responses[1]));
        dispatch(setIntermediariesLoader(false));
      }).catch(() => {
        dispatch(setIntermediariesLoader(false));
      });
  };
};

export const createOrUpdateIntermediaryAndUpdateInStore = (intermediary) => {
  return (dispatch) => {
    const method = intermediary.id ? updateIntermediary : createIntermediary;
    const dispatchMethod = intermediary.id ? updateIntermediaryInList : addIntermediaryInList;
    return method(intermediary).then((newIntermediary) => {
      dispatch(dispatchMethod(newIntermediary));
    });
  };
};

export const deleteIntermediaryAndRemoveFromStore = (intermediaryId) => {
  return (dispatch) => {
    return deleteIntermediary(intermediaryId).then(() => {
      dispatch(removeIntermediaryFromList(intermediaryId));
    });
  };
};
