import React                 from 'react';
import Button                from 'components/ui/buttons/button';
import openInDrawer          from 'components/ui/drawer/helpers/open_in_drawer';
import IntermediaryContainer from 'components/intermediaries/intermediary_container';

const IntermediaryFormInDrawer = openInDrawer()(IntermediaryContainer);

const OpenIntermediaryFormButton = ({ buttonLabel, intermediary, ...props }) => {
  return (
    <IntermediaryFormInDrawer
      className="uk-width-2-3"
      orientation="right"
      intermediary={ intermediary }
    >
      <Button
        { ...props }
      >
        { buttonLabel }
      </Button>
    </IntermediaryFormInDrawer>
  );
};

export const CreateIntermediaryButton = () => {
  return (
    <OpenIntermediaryFormButton
      primary
      icon="add"
      buttonLabel={ t('providers.intermediaries.new_intermediary') }
    />
  );
};


export const EditIntermediaryButton = ({ intermediary }) => {
  return (
    <OpenIntermediaryFormButton
      icon="edit"
      iconOnly
      intermediary={ intermediary }
    />
  );
};
