import React, { useEffect, useState } from 'react';
import Button from 'components/ui/buttons/button';
import openDialogOnClick from 'components/ui/buttons/helpers/open_dialog_on_click';
import { getBrands } from 'models/brands/requests';
import {
  API_PROTOCOL,
  INSURGATE_DOMAIN,
} from 'constants/application_constants';

const Card = ({ brand }) => {
  console.log('brand', brand);
  const link =
    brand.link || `${API_PROTOCOL}://${brand.slug}.${INSURGATE_DOMAIN}`;
  return (
    <a key={brand.id} className="product-card-item" href={link}>
      <img
        src={brand.logo_square_url || 'https://via.placeholder.com/150'}
        width={80}
        alt={brand.name}
      />
      <div className="product-card-name">{brand.name}</div>
    </a>
  );
};

const OpenDialogButton = openDialogOnClick()(
  ({ onClick, disabled, loading }) => {
    return (
      <Button
        primary
        onClick={onClick}
        data-purpose="go_to_insurgate"
        disabled={loading || disabled}
        loading={loading}
      >
        {t('insurgate.go_to_index')}
      </Button>
    );
  },
);

const InsurgateIndexButton = ({ alignLeft, forLead, className }) => {
  const [brands, setBrands] = useState([]);
  const [loading, toggleLoading] = useState(true);

  const fetchBrands = () => {
    getBrands()
      .then((response) => {
        toggleLoading(false);
        setBrands(
          response.data.brands.filter((brand) => brand.slug !== 'multi_tarif'),
        );
      })
      .catch((error) => {
        console.error('got error while fetching brands', error);
        toggleLoading(false);
      });
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  if (!brands.length) {
    return null;
  }

  return (
    <div
      className={`uk-inline ${
        alignLeft ? 'uk-margin-auto-left' : ''
      } ${className}`}
    >
      <OpenDialogButton
        dialogTitle={t('insurgate.select_brand')}
        loading={loading}
        forLead={forLead}
      >
        <div
          className="product-card-list uk-flex-center"
          data-purpose="select_brand"
        >
          {brands.map((brand) => (
            <Card brand={brand} key={brand.id} />
          ))}
        </div>
      </OpenDialogButton>
    </div>
  );
};

export default InsurgateIndexButton;
