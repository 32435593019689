import React              from 'react';
import compact            from 'lodash/compact';
import PropTypes          from 'prop-types';
import DropZone           from 'react-dropzone';
import FileItemWithUpload from 'components/file_uploader/file_item_with_upload';
import Icon               from 'components/ui/icon';
import Loader             from 'components/ui/loader';

const flattenArray = arr => (
  [].concat.apply([], arr)
);

const dropzoneStyle = {
  position:       'absolute',
  width:          '100%',
  height:         '100%',
  display:        'flex',
  top:            0,
  left:           0,
  bottom:         0,
  right:          0,
  justifyContent: 'center',
};

class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.fileItemWithUploadRefs = [];
    this.state = {
      onHover: false,
      loading: false,
      files:   [],
    };

    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.startFilesUpload = this.startFilesUpload.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    this.mounted = false;
  }

  onDragOver() {
    this.setState({
      onHover: true,
    });
  }

  onDragLeave() {
    this.setState({
      onHover: false,
    });
  }

  startFilesUpload() {
    const { onFileUploaded } = this.props;
    this.setState({ loading: true }, () => {
      const filePromises = compact(this.fileItemWithUploadRefs).map((fileAndRef) => {
        if (fileAndRef && fileAndRef.ref) {
          return fileAndRef.ref.startUpload();
        }
      });
      return Promise.all(filePromises).then((all) => {
        this.fileItemWithUploadRefs = [];
        if (!this.mounted) {
          return;
        }
        this.setState({
          files:   [],
          loading: false,
        });
        onFileUploaded && onFileUploaded(compact(all));
      });
    });
  }

  handleDrop(files) {
    const currentFiles = this.state.files;
    currentFiles.push(files);
    const flatten = flattenArray(currentFiles);
    this.setState({
      files:   flatten,
      onHover: false,
    }, () => {
      this.startFilesUpload();
    });
  }

  onUploadFile(file) {
    const { uploadFile } = this.props;
    this.setState({
      loading: true,
    });
    return uploadFile(file).then(() => {
      if (!this.mounted) {
        return;
      }
      this.setState({
        loading: false,
      });
    }).catch(() => {
      if (!this.mounted) {
        return;
      }
      this.setState({
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { files, onHover, loading } = this.state;
    const { disabled } = this.props;
    return (
      <React.Fragment>
        <div className={ `dropzone-container ${ loading ? 'center-loader' : '' }` }>
          { !loading && (
            <DropZone
              style={ dropzoneStyle }
              disabled={ disabled || loading }
              disableClick={ disabled || loading }
              data-purpose="select_image_dropzone"
              onDrop={ this.handleDrop }
              onDragOver={ this.onDragOver }
              onDragLeave={ this.onDragLeave }
            >
              { ({ getRootProps, getInputProps }) => (
                <div { ...getRootProps() }>
                  {
                    files.length === 0
                    && (
                      <div className="dropzone-placeholder">
                        { t('general.forms.dropzone.dropzone_placeholder') }
                      </div>
                    )
                  }
                  {
                    files.length > 0
                    && files.map((file, index) => (
                      <FileItemWithUpload
                        key={ index }
                        file={ file }
                        uploadMethod={ this.onUploadFile }
                        ref={ ref => this.fileItemWithUploadRefs.push({
                          file,
                          ref,
                        }) }
                      />
                    ))
                  }
                  {
                    onHover
                    && (
                      <div className="dropzone-hover">
                        <Icon icon="file_upload" />
                      </div>
                    )
                  }
                  <input { ...getInputProps() } />
                </div>
              ) }
            </DropZone>
          ) }
          { loading && <Loader /> }
        </div>
      </React.Fragment>
    );
  }
}

FileUploader.defaultProps = {
  disabled:       false,
  onFileUploaded: null,
};

FileUploader.propTypes = {
  uploadFile:     PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func,
};

export default FileUploader;
