/* global location */

export const getUrlSearchParam = (name) => {
  const { href } = location;
  const splittedURL = href.split('?');
  let param = null;
  if (splittedURL[1]) {
    const params = new URLSearchParams(splittedURL[1]);
    param = params.get(name);
  }
  return param;
};


export const removeUrlSearchParam = (key, sourceURL) => {
  let rtn = sourceURL.split('?')[0];
  let param = null;
  let params_arr = [];
  const queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + '?' + params_arr.join('&');
  }
  return rtn;
};

export const setUrlSearchParam = (key, value) => {
  const splittedPath = window.location.href.split('?');
  const paramString = splittedPath[1] || '';
  const searchParams = new URLSearchParams(paramString);
  searchParams.set(key, value);
  window.history.pushState({}, null, `${ splittedPath[0] }?${ searchParams }`);
};
