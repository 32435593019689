import React                from 'react';
import openDialogOnClick    from 'components/ui/buttons/helpers/open_dialog_on_click';
import Button               from 'components/ui/buttons/button';
import { getCurrentLocale } from 'services/i18n';

const OpenContentInDialogButton = openDialogOnClick()((props) =>
  <Button
    small
    outline
    icon="information"
    iconText
    tiny
    type="button"
    className="ml-10 secondary-light"
    { ...props }
  >
    { t('provider.tarification.cover_more') }
  </Button>,
);

const CoverMoreInDialog = ({ title, description, ...rest }) => {
  return (
    <OpenContentInDialogButton
      dialogTitle={ title }
      { ...rest }
    >
      { description && description.map((extra_information_item, index) => (
        <p
          className="uk-text-meta mt-5 mb-5"
          key={ `extra-informations-${ index }` }
          dangerouslySetInnerHTML={ { __html: extra_information_item[getCurrentLocale()] } }
        />
      )) }
    </OpenContentInDialogButton>
  );
};

export default CoverMoreInDialog;
