import React                            from 'react';
import { connect }                      from 'react-redux';
import { withRouter }                   from 'react-router-dom';
import { getAccount }                   from 'models/current_account/requests';
import { getCurrentLocale }             from 'services/i18n';

class GetAccount extends React.Component {
  componentDidMount() {
    const { dispatch, history } = this.props;
    dispatch(getAccount()).catch(() => {
      history.push(`/${ getCurrentLocale() }/user/sign_in`);
    });
  }

  render() {
    return null;
  }
}

export default connect()(withRouter(GetAccount));
