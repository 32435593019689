import React                      from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
}                                 from 'react-router-dom';
import RouteChangeListener        from 'helpers/route_change_listener';
import RedirectOnSignInPage       from 'helpers/redirect_on_sign_in_page';
import NewAccountContainer        from 'components/account/new/new_account_container';
import UserPasswordResetContainer from 'components/user/password/reset/user_password_reset_container';
import UserNewPasswordContainer   from 'components/user/password/new/user_new_password_container';
import CheckForLanguage           from 'components/language_switcher/check_for_language';
import UserSignInContainer        from 'components/user/sign_in/user_sign_in_container';
import NoAccountFound             from 'components/account/no_account_found';
import Helmet                     from 'react-helmet';
import UserInvitationContainer    from 'components/user/invitation/invitation_container';

const PublicPagesContainer = () => (
  <Router>
    <RouteChangeListener>
      <Helmet>
        <body className="content-no-padding" />
      </Helmet>
      <Switch>
        <Route
          path="/:locale/account/not_found"
          component={ () => (
            <CheckForLanguage>
              <NoAccountFound />
            </CheckForLanguage>
          ) }
        />
        <Route
          path="/:locale/account/sign_up"
          component={ () => (
            <CheckForLanguage>
              <NewAccountContainer />
            </CheckForLanguage>
          ) }
        />
        <Route
          path="/:locale/user/sign_in"
          component={ () => (
            <CheckForLanguage>
              <UserSignInContainer />
            </CheckForLanguage>
          ) }
        />
        <Route
          path="/:locale/user/invitation"
          component={ () => (
            <CheckForLanguage>
              <UserInvitationContainer />
            </CheckForLanguage>
          ) }
        />
        <Route
          path="/:locale/user/password/reset"
          component={ () => (
            <CheckForLanguage>
              <UserPasswordResetContainer />
            </CheckForLanguage>
          ) }
        />
        <Route
          path="/:locale/user/password/new"
          component={ () => (
            <CheckForLanguage>
              <UserNewPasswordContainer />
            </CheckForLanguage>
          ) }
        />
        <RedirectOnSignInPage />
      </Switch>
    </RouteChangeListener>
  </Router>
);

export default PublicPagesContainer;
