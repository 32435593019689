import orderBy           from 'lodash/orderBy';
import forEachRight      from 'lodash/forEachRight';
import compact           from 'lodash/compact';
import slice             from 'lodash/slice';
import last              from 'lodash/last';
import first             from 'lodash/first';
import flatten           from 'lodash/flatten';
import zip               from 'lodash/zip';
import { ConditionList } from 'models/condition/condition_list';
import { getStepPath }   from 'components/wizard_stepper/constants';

export class WizardNavigation extends Object {
  constructor({ schema }) {
    super();
    this.schema = compact(schema);
    this.steps = [];
    this.groups = [];
    this.navigation = [];
  }

  _reset() {
    this.steps = [];
    this.groups = [];
    this.navigation = [];
  }

  _getStep(previous = false, activeStep) {
    let step;
    const compacted = compact(this.navigation.map((item) => {
      if (item.kind === 'group') {
        item.steps = item.steps.filter(sub => sub.visible);
      }
      if (item.visible) {
        return item;
      }
    }));
    let nav = compact(JSON.parse(JSON.stringify(compacted)).map((item) => {
      if (item.kind === 'group') {
        item.steps = item.steps.filter(sub => sub.visible);
      }
      if (item.visible) {
        return item;
      }
    }));

    if (!activeStep) {
      return null;
    }
    nav.map((navItem, index) => {
      if (navItem.kind === 'group') {
        const subStepIndex = navItem.steps.findIndex(substep => substep.origin_path === activeStep.origin_path);
        if (subStepIndex === 0 && previous) {
          return step = nav[index - 1];
        }
        if ((subStepIndex === navItem.steps.length - 1) && !previous) {
          return step = nav[index + 1];
        }
        if (subStepIndex >= 0) {
          const prevSubSteps = orderBy(slice(navItem.steps, 0, subStepIndex).filter(item => item.visible), 'order');
          const nextSubSteps = orderBy(slice(navItem.steps, subStepIndex + 1, navItem.steps.length).filter(item => item.visible), 'order');
          if (previous) {
            return step = last(prevSubSteps);
          } else {
            return step = first(nextSubSteps);
          }
        }
      }
    });
    if (!step) {
      const rootStepIndex = compacted.findIndex(step => step.path === activeStep.path);
      if (rootStepIndex > -1) {
        step = compacted[previous ? rootStepIndex - 1 : rootStepIndex + 1];
      }
    }
    if (step && step.kind === 'group') {
      return step.steps[previous ? step.steps.length - 1 : 0];
    }

    return step;
  };

  _getNextStepPath(activeStep) {
    let nextStep = this._getStep(false, activeStep);
    if (!nextStep) {
      return null;
    }
    return nextStep.path;
  };

  _getPreviousStepPath(activeStep) {
    let previousStep = this._getStep(true, activeStep);
    if (!previousStep) {
      return null;
    }
    return previousStep.path;
  };

  _createNavigationStep(step, source, parentGroup) {
    const navigationStep = {
      path:              getStepPath(step.path, source.contract_request),
      origin_path:       step.path,
      purpose:           step.purpose,
      disabledMessages:  step.disabledRules ? new ConditionList(flatten([...step.disabledRules])).checkList(source).messages || [] : [],
      visible:           step.displayRules ? new ConditionList(flatten([...step.displayRules])).checkList(source).check : true,
      icon:              step.icon,
      order:             step.order,
      loading:           step.loading,
      label:             step.label,
      error:             step.error,
      Component:         step.Component,
      componentProps:    step.componentProps,
      StepMainAction:    step.StepMainAction,
      title:             step.title,
      tarification_data: step.tarification_data,
      parentGroup:       parentGroup,
      forceFirst:        step.forceFirst,
    };
    this.steps.push(navigationStep);
  }

  _createNavigationGroupStep(group, source) {
    const g = {
      order:            group.order,
      label:            group.label,
      icon:             group.icon,
      warningTooltip:   group.warningTooltip,
      slug:             group.slug,
      disabledMessages: group.disabledRules ? new ConditionList(flatten([...group.disabledRules])).checkList(source).messages || [] : [],
      visible:          group.displayRules ? new ConditionList(flatten([...group.displayRules])).checkList(source).check : true,
      kind:             group.kind,
    };
    this.groups.push(g);
  }

  _generateStepsAndGroup(source) {
    this._reset();
    this.schema.forEach((schemaStep) => {
      if (schemaStep.kind === 'group') {
        this._createNavigationGroupStep(schemaStep, source);
        schemaStep.steps.map((subStep) => {
          this._createNavigationStep(subStep, source, schemaStep.slug);
        });
      } else {
        this._createNavigationStep(schemaStep, source);
      }
    });
  }

  _getFirstAvailable(list) {
    let first = null;
    forEachRight(list, (item) => {
      if (item.kind === 'group') {
        const firstInSubs = this._getFirstAvailable(item.steps);
        if (firstInSubs && !first) {
          return first = firstInSubs;
        }
      } else {
        if (item.forceFirst) {
          return first = item;
        }
        if (item.visible && !item.disabledMessages.length && !first) {
          return first = item;
        }
      }
    });
    return first;
  }

  getFirstAvailableStepPath() {
    const firstAvailable = this._getFirstAvailable(this.navigation);
    return firstAvailable ? firstAvailable.path : null;
  }

  getNextAndPreviousPath(activeStep) {
    const previous = this._getPreviousStepPath(activeStep);
    const next = this._getNextStepPath(activeStep);
    return {
      previous,
      next,
    };
  };

  generate(source) {
    this._generateStepsAndGroup(source);
    const groupsWithSteps = this.groups.map((group) => {
      return {
        ...group,
        steps: orderBy(compact(this.steps.filter((step) => step.parentGroup === group.slug)), 'order'),
      };
    });
    const onlyRootSteps = this.steps.filter((step) => !step.parentGroup);
    let index = 0;
    this.steps = orderBy(compact(flatten(zip(onlyRootSteps, groupsWithSteps))), 'order').map((item) => {
      if (item.visible) {
        index++;
        item.humanIndex = `${ index }`;
        if (item.steps) {
          let subIndex = 0;
          item.steps = item.steps.map((sub) => {
            if (sub.visible) {
              subIndex++;
              sub.humanIndex = `${ index }.${ subIndex }`;
            }
            return sub;
          });
        }
      }
      return item;
    });

    this.navigation = JSON.parse(JSON.stringify(this.steps)).map((step) => {
      if (step.kind === 'group') {
        step.steps = step.steps.map(({ Component, componentProps, StepMainAction, ...rest }) => rest);
        return step;
      } else {
        const { Component, componentProps, StepMainAction, ...rest } = step;
        return rest;
      }
    });
    return this;
  }
}
