import { DESKTOP_APP } from 'constants/application_constants';

export const DESKTOP_SIGN_IN = 'sign_in';
export const DESKTOP_SIGN_OUT = 'sign_out';
export const OPEN_FILE = 'open_file';
export const SAVE_FILE = 'save_file';

export const sendDesktopAppData = (type, data) => {
  if (DESKTOP_APP()) {
    window.postMessage({
      type,
      data,
    });
  }
};

export const openFileInDesktopApp = (url) => {
  sendDesktopAppData(OPEN_FILE, { url });
};

export const saveFileInDesktopApp = (file, name) => {
  sendDesktopAppData(SAVE_FILE, {
    file,
    name,
  });
};
