import React                 from 'react';
import PropTypes             from 'prop-types';
import Button                from 'components/ui/buttons/button';
import OpenOfficeAddressForm from 'components/offices/open_office_address_form';


const AddOfficeAddressButton = ({ onCreated }) => {
  return (
    <OpenOfficeAddressForm
      office={ {} }
      onSubmitted={ onCreated }
    >
      <Button
        primary
        data-purpose="add_office_button"
      >
        { t('providers.account.addresses.add_address') }
      </Button>
    </OpenOfficeAddressForm>
  );
};

AddOfficeAddressButton.propTypes = {
  onCreated: PropTypes.func.isRequired,
};

export default AddOfficeAddressButton;
