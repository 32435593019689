import React                                    from 'react';
import PropTypes                                from 'prop-types';
import { connect }                              from 'react-redux';
import Icon                                     from 'components/ui/icon';
import confirmBeforeAction                      from 'components/ui/buttons/helpers/confirm_before_action';
import { deleteTarificationsAndUpdateWarnings } from 'models/wizard/dispatch_actions';
import Button                                   from 'components/ui/buttons/button';

const ConfirmBeforeActionButton = confirmBeforeAction()((props) => {
  return (
    <Button
      type="button"
      iconOnly
      inverted
      warning
      { ...props }
    />
  );
});

const TarificationDeleteButton = ({ tarification, dispatchDeleteTarification, contractRequest, tarifications, ...props }) => {
  const perform = () => {
    dispatchDeleteTarification(contractRequest, tarifications);
  };

  return (
    <ConfirmBeforeActionButton
      { ...props }
      onClick={ perform }
    >
      <Icon icon="delete" className="pr-0" />
    </ConfirmBeforeActionButton>
  );
};

TarificationDeleteButton.propTypes = {
  tarification:               PropTypes.object.isRequired,
  dispatchDeleteTarification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    tarifications:     state.wizard.contract_request.tarifications,
    contractRequest: state.wizard.contract_request,
  };
};

const mapDispatchToProps = (dispatch, { tarification }) => {
  return {
    dispatchDeleteTarification: (contractRequest, tarifications) => (
      dispatch(deleteTarificationsAndUpdateWarnings([tarification], tarifications, contractRequest))
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TarificationDeleteButton);
