import React from 'react';
import BrandContext from 'components/brand_switcher/brand_context';

const BrandProvider = ({ children, brands }) => {
  return (
    <BrandContext.Provider value={{ brands }}>{children}</BrandContext.Provider>
  );
};

export default BrandProvider;
