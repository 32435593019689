import React, { useEffect, useState }    from 'react';
import { useDispatch, useSelector }      from 'react-redux';
import Step                              from 'components/wizard_stepper/step';
import { createNavigationAndSetInStore } from 'models/wizard_stepper/dispatch_actions';
import WizardStepperContext              from 'components/wizard_stepper/wizard_context';
import { Switch }                        from 'react-router';
import RedirectOnFirstStepAvailable      from 'components/wizard_stepper/redirect_on_first_step_available';

const StepList = ({ steps }) => {
  const [wizardNavigation, setWizardNavigation] = useState({ steps: [] });
  const selectedProducts = useSelector((state) => state.wizard.selected_products);
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const dispatch = useDispatch();
  useEffect(() => {
    const wizardNavigation = dispatch(createNavigationAndSetInStore(contractRequest, selectedProducts, steps));
    setWizardNavigation(wizardNavigation);
    window['wizardNavigation'] = wizardNavigation;
  }, [steps]);

  return (
    <WizardStepperContext.Provider value={ {
      wizardNavigation,
    } }>
      <Switch>
        { wizardNavigation.steps.map((step) => {
          if (!step) {return null;}
          if (step.steps) {
            return step.steps.map((subStep, subIndex) => (
              <Step
                order={ `${ step.order }.${ subIndex + 1 }` }
                key={ `${ step.order }.${ subIndex + 1 }` }
                { ...subStep }
              />
            ));
          }
          return (
            <Step
              order={ step.order }
              key={ step.order }
              { ...step }
            />
          );
        }) }
        <RedirectOnFirstStepAvailable />
      </Switch>
    </WizardStepperContext.Provider>
  );
};

export default StepList;
