import React                        from 'react';
import Title                        from 'components/ui/titles';
import IntermediariesIllustration   from 'images/intermediaries.svg';
import { CreateIntermediaryButton } from 'components/intermediaries/open_intermediary_form_button';

const IntermediariesEmptyState = () => {
  return (
    <div className="uk-text-center uk-width-1-1">
      <img
        src={ IntermediariesIllustration }
        alt="No folder illustration"
        className="uk-width-1-5"
      />
      <Title
        size="h3"
        className="uk-text-center"
      >
        { t('providers.intermediaries.empty_state.title') }
      </Title>
      <p className="uk-text-center mt-20 pl-40 pr-40">
        { t('providers.intermediaries.empty_state.explanation') }
      </p>
      <p className="uk-text-center">
        <CreateIntermediaryButton />
      </p>
    </div>
  );
};

export default IntermediariesEmptyState;
