export const getCompanyTypes = (orderedRequiredTypes = null) => {
  const listTypes = [
    {
      value: 'AISBL',
      id: 'AISBL',
      label: t('general.company_type.AISBL'),
    },
    {
      value: 'ASBL',
      id: 'ASBL',
      label: t('general.company_type.ASBL'),
    },
    {
      value: 'ACP',
      id: 'ACP',
      label: t('general.company_type.ACP'),
    },
    {
      value: 'PHYSICAL_PERSON',
      id: 'PHYSICAL_PERSON',
      label: t('general.company_type.PHYSICAL_PERSON'),
    },
    {
      value: 'SA',
      id: 'SA',
      label: t('general.company_type.SA'),
    },
    {
      value: 'SCA',
      id: 'SCA',
      label: t('general.company_type.SCA'),
    },
    {
      value: 'SCRI',
      id: 'SCRI',
      label: t('general.company_type.SCRI'),
    },
    {
      value: 'SCRL',
      id: 'SCRL',
      label: t('general.company_type.SCRL'),
    },
    {
      value: 'SCS',
      id: 'SCS',
      label: t('general.company_type.SCS'),
    },
    {
      value: 'SNC',
      id: 'SNC',
      label: t('general.company_type.SNC'),
    },
    {
      value: 'SPRL',
      id: 'SPRL',
      label: t('general.company_type.SPRL'),
    },
    {
      value: 'SPRL_S',
      id: 'SPRL_S',
      label: t('general.company_type.SPRL-S'),
    },
    {
      value: 'SRL',
      id: 'SRL',
      label: t('general.company_type.SRL'),
    },
  ];
  if (orderedRequiredTypes) {
    const ordered = orderedRequiredTypes.map((item) =>
      listTypes.find((type) => type.id === item),
    );
    return ordered;
  }
  return listTypes;
};

export const getAxaConstructorCompanyTypes = () => {
  const types = [
    {
      value: 'Public_Limited_Liability_Company',
      label: t('axa.constructor.legal_forms.public_limited_liability_company'),
    },
    {
      value: 'Private_Limited_Liability_Company',
      label: t('axa.constructor.legal_forms.private_limited_liability_company'),
    },
    {
      value: 'Cooperative',
      label: t('axa.constructor.legal_forms.cooperative'),
    },
    {
      value: 'Partnership_Limited_by_Shares',
      label: t('axa.constructor.legal_forms.partnership_limited_by_shares'),
    },
    {
      value: 'General_Partnership',
      label: t('axa.constructor.legal_forms.general_partnership'),
    },
    {
      value: 'Cooperative_Limited_Liability_Company',
      label: t(
        'axa.constructor.legal_forms.cooperative_limited_liability_company',
      ),
    },
    {
      value: 'Cooperative_Partnership_with_Unlimited_Liability',
      label: t(
        'axa.constructor.legal_forms.cooperative_partnership_with_unlimited_liability',
      ),
    },
    {
      value: 'Agricultural_Partnership',
      label: t('axa.constructor.legal_forms.agricultural_partnership'),
    },
    {
      value: 'Economic_Interest_Grouping',
      label: t('axa.constructor.legal_forms.economic_interest_grouping'),
    },
    {
      value: 'Non_Profit_Organisation',
      label: t('axa.constructor.legal_forms.non_profit_organisation'),
    },
    {
      value: 'European_Economic_Interest_Grouping',
      label: t(
        'axa.constructor.legal_forms.european_economic_interest_grouping',
      ),
    },
    {
      value: 'International_Non_Profit_Organisation',
      label: t(
        'axa.constructor.legal_forms.international_non_profit_organisation',
      ),
    },
    {
      value: 'Partnership_with_Social_Object',
      label: t('axa.constructor.legal_forms.partnership_with_social_object'),
    },
    {
      value: 'Unknown',
      label: t('axa.constructor.legal_forms.unknown'),
    },
    {
      value: 'De_Facto_Partnership',
      label: t('axa.constructor.legal_forms.de_facto_partnership'),
    },
    {
      value: 'Joint_Ownership',
      label: t('axa.constructor.legal_forms.joint_ownership'),
    },
    {
      value: 'Public_Institution',
      label: t('axa.constructor.legal_forms.public_institution'),
    },
    {
      value: 'Equity_Joint_Venture',
      label: t('axa.constructor.legal_forms.equity_joint_venture'),
    },
    {
      value: 'Single_Person_Private_Limited_Liability_Company',
      label: t(
        'axa.constructor.legal_forms.single_person_private_limited_liability_company',
      ),
    },
    {
      value: 'Establishment_of_Public_Utility',
      label: t('axa.constructor.legal_forms.establishment_of_public_utility'),
    },
    {
      value: 'International_Associations_with_Philanthropic_Aims',
      label: t(
        'axa.constructor.legal_forms.international_associations_with_philanthropic_aims',
      ),
    },
    {
      value: 'Civil_Law_Company',
      label: t('axa.constructor.legal_forms.civil_law_company'),
    },
    {
      value: 'Professional_Union',
      label: t('axa.constructor.legal_forms.professional_union'),
    },
    {
      value: 'European_Company_under_Belgian_Law',
      label: t(
        'axa.constructor.legal_forms.european_company_under_belgian_law',
      ),
    },
    {
      value: 'Limited_Liability_Company_under_Public_Law',
      label: t(
        'axa.constructor.legal_forms.limited_liability_company_under_public_law',
      ),
    },
    {
      value: 'Foundation',
      label: t('axa.constructor.legal_forms.foundation'),
    },
    {
      value: 'Mutuality',
      label: t('axa.constructor.legal_forms.mutuality'),
    },
    {
      value: 'Association_under_Foreign_Law',
      label: t('axa.constructor.legal_forms.association_under_foreign_law'),
    },
    {
      value: 'European_Cooperative',
      label: t('axa.constructor.legal_forms.european_cooperative'),
    },
    {
      value: 'Common_Law_Association',
      label: t('axa.constructor.legal_forms.common_law_association'),
    },
    {
      value: 'Internal_Association',
      label: t('axa.constructor.legal_forms.internal_association'),
    },
    {
      value: 'Equity_Partnership',
      label: t('axa.constructor.legal_forms.equity_partnership'),
    },
    {
      value: 'Single_Member_Limited_Liability_Company_with_Social_Object',
      label: t(
        'axa.constructor.legal_forms.single_member_limited_liability_company_with_social_object',
      ),
    },
    {
      value: 'Limited_Liability_Company_with_Social_Object',
      label: t(
        'axa.constructor.legal_forms.limited_liability_company_with_social_object',
      ),
    },
    {
      value: 'Public_Limited_Liability_Company_with_Social_Object',
      label: t(
        'axa.constructor.legal_forms.public_limited_liability_company_with_social_object',
      ),
    },
    {
      value:
        'Cooperative_Partnership_with_Unlimited_Liability_with_Social_Object',
      label: t(
        'axa.constructor.legal_forms.cooperative_partnership_with_unlimited_liability_with_social_object',
      ),
    },
    {
      value: 'General_Partnership_with_Social_Object',
      label: t(
        'axa.constructor.legal_forms.general_partnership_with_social_object',
      ),
    },
    {
      value: 'Ordinary_Limited_Partnership_with_Social_Object',
      label: t(
        'axa.constructor.legal_forms.ordinary_limited_partnership_with_social_object',
      ),
    },
    {
      value: 'Marital_Heritage',
      label: t('axa.constructor.legal_forms.marital_heritage'),
    },
    {
      value: 'Partnership_Limited_by_Shares_with_Social_Object',
      label: t(
        'axa.constructor.legal_forms.partnership_limited_by_shares_with_social_object',
      ),
    },
    {
      value: 'Cooperative_Limited_Liability_Company_with_Social_Object',
      label: t(
        'axa.constructor.legal_forms.cooperative_limited_liability_company_with_social_object',
      ),
    },
    {
      value: 'Organisation_for_funding_of_pensions',
      label: t(
        'axa.constructor.legal_forms.organisation_for_funding_of_pensions',
      ),
    },
    {
      value: 'Limited_Liability_company',
      label: t('axa.constructor.legal_forms.limited_liability_company'),
    },
    {
      value: 'Private_Foundation',
      label: t('axa.constructor.legal_forms.private_foundation'),
    },
    {
      value: 'Limited_Partnership',
      label: t('axa.constructor.legal_forms.limited_partnership'),
    },
  ];
  return types;
};
