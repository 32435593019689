import React                   from 'react';
import PropTypes               from 'prop-types';
import TextInput               from 'components/ui/form/text_input';
import { checkCouponValidity } from 'models/coupons/requests';

class CouponField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      couponCode:    '',
      errorMessage:  '',
      validating:    false,
      touched:       false,
      couponMessage: '',
    };

    this.handleCouponCodeChange = this.handleCouponCodeChange.bind(this);
    this.checkValidity = this.checkValidity.bind(this);
    this.timeout = null;
  }

  checkValidity(couponCode) {
    const { onValidOrEmpty, onInvalid } = this.props;
    checkCouponValidity(couponCode).then((coupon) => {
      onValidOrEmpty(coupon);
      this.setState({
        touched:       true,
        validating:    false,
        errorMessage:  null,
        couponMessage: coupon.message,
      });
    }).catch((err) => {
      const { error_message: errorMessage } = err;
      onInvalid();
      this.setState({
        validating:    false,
        errorMessage,
        touched:       true,
        couponMessage: null,
      });
    });
  }

  handleCouponCodeChange({ target: { value: couponCode } }) {
    const { onValidOrEmpty } = this.props;
    this.setState({ couponCode });
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (couponCode === '') {
      onValidOrEmpty();
      this.setState({
        validating:    false,
        errorMessage:  null,
        couponMessage: null,
      });
    } else {
      this.timeout = setTimeout(() => this.checkValidity(couponCode), 600);
    }
  }

  render() {
    const { couponCode, errorMessage, validating, touched, couponMessage } = this.state;
    return (
      <React.Fragment>
        <p>
          <TextInput
            id="coupon_field"
            name="coupon_field"
            input={ {
              onChange: this.handleCouponCodeChange,
              value:    couponCode,
            } }
            meta={ {
              asyncValidating: validating,
              error:           errorMessage,
              touched,
            } }
            style={ {
              width:   '100%',
              padding: '5px',
            } }
          />
        </p>
        { couponMessage && <span className="mt-5" data-purpose="coupon_value">{ couponMessage }</span> }
      </React.Fragment>
    );
  }
}

CouponField.propTypes = {
  onValidOrEmpty: PropTypes.func.isRequired,
  onInvalid:      PropTypes.func.isRequired,
};

export default CouponField;
