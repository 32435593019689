import React         from 'react';
import PropTypes     from 'prop-types';
import {
  reduxForm,
  Field,
  FormSection,
}                    from 'redux-form';
import { Row, Col }  from 'react-flexbox-grid';
import SmallItalic   from 'components/ui/text/small_italic';
import SubmitButton  from 'components/ui/form/submit_button';
import TextInput     from 'components/ui/form/text_input';
import PasswordInput from 'components/ui/form/password_input';
import CheckboxInput from 'components/ui/form/checkbox_input';
import Margin        from 'components/ui/margin';
import { required }  from 'models/application/constants';

const UserInvitationForm = ({ submitting, handleSubmit, className }) => {
  return (
    <form onSubmit={ handleSubmit } data-purpose="user_invitation_form" className={ className }>
      <Row>
        <Col sm={ 12 }>
          <Field
            name="invitation_token"
            type="hidden"
            component="input"
          />
          <FormSection name="user">
            <Row>
              <Col sm={ 12 } md={ 6 }>
                <Margin>
                  <Field
                    label={ t('activerecord.attributes.user.first_name') }
                    name="first_name"
                    type="text"
                    component={ TextInput }
                    validate={ [required] }
                  />
                </Margin>
              </Col>
              <Col sm={ 12 } md={ 6 }>
                <Margin>
                  <Field
                    label={ t('activerecord.attributes.user.last_name') }
                    name="last_name"
                    type="text"
                    component={ TextInput }
                    validate={ [required] }
                  />
                </Margin>
              </Col>
            </Row>
            <Margin>
              <Field
                label={ t('activerecord.attributes.user.password') }
                name="password"
                component={ PasswordInput }
                validate={ [required] }
              />
            </Margin>
            <Margin>
              <Field
                name="has_read_general_conditions"
                id="has_read_general_conditions"
                label={ t_html('general.signup.general_conditions_customer_html') }
                component={ CheckboxInput }
              />
            </Margin>
          </FormSection>
          <p className="mt-10 mb-10 uk-text-center">
            <SmallItalic>{ t('general.signup.disclaimer') }</SmallItalic>
          </p>
          <SubmitButton
            loading={ submitting }
            onClick={ handleSubmit }
            data-purpose="submit_user_invitation_form"
            text={ t('user.invitations.accept') }
            large
          />
        </Col>
      </Row>
    </form>
  );
};

UserInvitationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting:   PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'user_invitation',
})(UserInvitationForm);
