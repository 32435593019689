import React, { useEffect, useState }                           from 'react';
import PropTypes                                                from 'prop-types';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import CreditCardLogo                                           from 'components/credit_card/logo';
import FormErrorMessage                                         from 'components/ui/form/form_error_message';
import Icon                                                     from 'components/ui/icon';

const CreditCardFormFields = ({ readOnly, readOnlyBrand, lastFour, expMonth, expYear, onClick }) => {
  const [cardBrand, setCardBrand] = useState();
  const [cardNumberError, setCardNumberError] = useState();
  const [cardExpiryError, setCardExpiryError] = useState();
  const [cardCvcError, setCardCvcError] = useState();

  const options = {
    style: {
      base: {
        color:           '#0a0a0a',
        fontWeight:      'normal',
        fontFamily:      '\'Noto Sans\', sans-serif',
        fontSize:        '16px',
        lineHeight:      '21px',
        fontSmoothing:   'antialiased',
        '::placeholder': {
          color: '#cacaca',
        },
      },
      invalid: {
        color: '#c4202a'
      }
    }
  };

  useEffect(() => {
    $('.card-number input[type="tel"]').trigger('focus');
  }, []);

  const onChangeCardNumber = (event) => {
    setCardBrand(event.brand);
    setCardNumberError(event.error ? event.error.message : null);
  };

  const onChangeCardExpiry = (event) => {
    setCardExpiryError(event.error ? event.error.message : null);
  };

  const onChangeCardCVC = (event) => {
    setCardCvcError(event.error ? event.error.message : null);
  };

  const hasError = () => (
    cardNumberError || cardExpiryError || cardCvcError
  );

  return (
    <div
      className={ `credit-card-form credit-card ${ hasError() ? 'error' : '' } ${ readOnly ? 'readonly' : '' }` }
      onClick={ onClick }
    >
      <div className={ `card-number ${ !cardNumberError ? 'has-error' : '' }` }>
        <label htmlFor="card_number">{ t('credit_cards.form.credit_card_number') }</label>
        <div className="number-logo">
          { !readOnly && (
            <CardNumberElement
              className="number-input"
              options={ options }
              onChange={ (event) => { onChangeCardNumber(event); } }
            />
          ) }
          {
            readOnly && <input type="text" readOnly value={ `**** **** **** ${ lastFour }` } />
          }
          <CreditCardLogo brand={ !readOnly ? cardBrand : readOnlyBrand } width="40px" />
        </div>
        <FormErrorMessage touched={ !cardNumberError } error={ cardNumberError } />
      </div>
      <div className="validity-cvc">
        <div className="date has-error">
          <label htmlFor="card_expiry">{ t('credit_cards.form.expiry_date') }</label>
          { !readOnly && (
            <CardExpiryElement
              options={ options }
              onChange={ (event) => { onChangeCardExpiry(event); } }
            />
          ) }
          {
            readOnly && (<input type="text" readOnly value={ `${ expMonth } / ${ expYear }` } />)
          }
          <FormErrorMessage touched={ !cardExpiryError } error={ cardExpiryError } />
        </div>
        <div className="cvc has-error">
          <label htmlFor="cvc">{ t('credit_cards.form.cvc') }</label>
          { !readOnly && (
            <CardCvcElement
              options={ options }
              onChange={ (event) => { onChangeCardCVC(event); } }
            />
          ) }
          {
            readOnly && (<input type="text" readOnly value="***" />)
          }
          <FormErrorMessage touched={ !cardCvcError } error={ cardCvcError } />
        </div>
      </div>
      { readOnly && <span className="edit-hover"><Icon icon="edit" /></span> }
    </div>
  );
};

CreditCardFormFields.defaultProps = {
  readOnly:      false,
  lastFour:      undefined,
  expMonth:      undefined,
  expYear:       undefined,
  readOnlyBrand: undefined,
  onClick:       undefined,
};

CreditCardFormFields.propTypes = {
  readOnly:      PropTypes.bool,
  lastFour:      PropTypes.any,
  expMonth:      PropTypes.any,
  expYear:       PropTypes.any,
  readOnlyBrand: PropTypes.any,
  onClick:       PropTypes.func,
};

export default CreditCardFormFields;
