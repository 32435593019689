import update           from 'immutability-helper';
import find             from 'lodash/find';
import sortBy           from 'lodash/sortBy';
import compact          from 'lodash/compact';
import concat           from 'lodash/concat';
import has              from 'lodash/has';
import flatten          from 'lodash/flatten';
import { moreRecent }   from 'models/tarifications/constants';
import { defaultState } from 'models/wizard/default_state';

const existingTarification = (tarifications, tarification) => (
  find(tarifications, (tarificationFromList) => tarificationFromList.id === tarification.id)
);

export const sortTarifications = (tarifications) => {
  return sortBy(tarifications, (tarification) => `${ has(tarification, 'product.insurance_company.name') ? tarification.product.insurance_company.name.toLowerCase() : 'default_name' } ${ has(tarification, 'product.name') ? tarification.product.name.toLowerCase() : 'default' }`);
};

const convertSelectedCoversInContractRequest = (selectedCovers) => {
  const {
          product_configuration_formula,
          product_configuration_deductible_type,
          product_configuration_deductible_amount,
          product_configuration_derogation_percentage,
          product_configuration_degression,
          payment_staggering,
          ...covers
        } = selectedCovers;
  return compact(Object.keys(covers).map((key) => {
    if (covers[key]) {
      return key;
    }
  }));
};

const wizardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'WIZARD/SET_RISK_OBJECT':
      return update(state, {
        contract_request: {
          risk_object: {
            $set: action.risk_object,
          },
        },
      });
    case 'WIZARD/SET_SELECTED_COVERS':
      const {
              product_configuration_formula,
              product_configuration_deductible_type,
              product_configuration_deductible_amount,
              product_configuration_derogation_percentage,
              product_configuration_degression,
              payment_staggering,
            } = action.selected_covers;
      return update(state, {
        contract_request: {
          $set: {
            ...state.contract_request,
            product_configuration_formula,
            product_configuration_deductible_type,
            product_configuration_deductible_amount,
            product_configuration_derogation_percentage,
            product_configuration_degression,
            payment_staggering,
            selected_cover_categories: convertSelectedCoversInContractRequest(action.selected_covers),
          },
        },
      });
    case 'WIZARD/SET_CONTRACT_REQUEST': {
      const newContractRequest = {
        ...action.contractRequest,
      };
      if (newContractRequest.tarifications.length > 1) {
        newContractRequest.tarifications = sortTarifications(compact(newContractRequest.tarifications.map((tarif) => {
          if (state.contract_request.tarifications) {
            const existingTarification = state.contract_request.tarifications.find(t => t.id === tarif.id);
            if (existingTarification && moreRecent(existingTarification, tarif)) {
              return tarif;
            } else {
              return existingTarification;
            }
          } else {
            return tarif;
          }
        })));
      }
      return update(state, {
        contract_request: {
          $set: newContractRequest,
        },
      });
    }
    case 'WIZARD/UPDATE_TARIFICATION': {
      const { contract_request: { tarifications } } = state;
      if (state.contract_request.id && action.tarification.contract_request_id !== state.contract_request.id) {
        return state;
      }
      const existingTarif = existingTarification(tarifications, action.tarification);
      if (!existingTarif) {
        return update(state, {
          contract_request: {
            tarifications: { $set: sortTarifications(compact(flatten(concat(tarifications, [action.tarification])))) },
          },
        });
      }
      if (existingTarif.id === action.tarification.id) {
        if (moreRecent(existingTarif, action.tarification)) {
          return update(state, {
            contract_request: {
              tarifications: {
                $set: sortTarifications(tarifications.map((tarification) => {
                  if (tarification.id === action.tarification.id) {
                    return action.tarification;
                  }
                  return tarification;
                })),
              },
            },
          });
        } else {
          return state;
        }
      }
      return update(state, {
        contract_request: {
          tarifications: { $set: sortTarifications(compact(flatten(concat(tarifications, [action.tarification])))) },
        },
      });
    }
    case 'WIZARD/SET_REQUIRED_FIELDS': {
      return update(state, {
        required_fields: {
          $set: action.required_fields,
        },
      });
    }
    case 'WIZARD/SET_DEFAULT_REQUIRED_FIELDS': {
      return update(state, {
        default_required_fields: {
          $set: action.default_required_fields,
        },
      });
    }
    case 'WIZARD/REMOVE_TARIFICATION': {
      return update(state, {
        contract_request: {
          tarifications: {
            $set: state.contract_request.tarifications.filter((tarif) => tarif.id !== action.tarification.id),
          },
        },
      });
    }

    case 'WIZARD/SET_CONTRACT_REQUEST_LOADING': {
      return update(state, {
        loaders: {
          contract_request: {
            $set: action.loading,
          },
        },
      });
    }
    case 'WIZARD/SET_REQUIRED_FIELDS_LOADING': {
      return update(state, {
        loaders: {
          required_fields: {
            $set: action.loading,
          },
        },
      });
    }
    case 'WIZARD/SET_CONTRACT_REQUEST_STATUS': {
      return update(state, {
        contract_request: {
          status: { $set: action.status },
        },
      });
    }
    case 'WIZARD/RESET':
      return defaultState;

    case 'WIZARD/SET_WARNINGS':
      return update(state, {
        warnings: {
          $set: action.warnings,
        },
      });
    case 'WIZARD/SET_DEFAULT_CR_FOR_LEAD': {
      return update(state, {
        contract_request: {
          selected_cover_categories: { $set: ['public_liability'] },
          status:                    { $set: 'draft_lead' },
          tarifications:             {
            $set: [{
              product: { id: 'default_product' },
            },
              {
                product: { id: 'default_product_2' },
              }],
          },
        },
      });
    }
    case 'WIZARD/SET_CONTRACT_REQUEST_AUTHORIZATIONS':
      return update(state, {
        contract_request: {
          authorizations: { $set: { 
            ...state.contract_request.authorizations,
            ...action.authorizations
          } },
        },
      });
    default:
      return state;
  }
};

export default wizardReducer;

export const getCoversFromCr = (state) => {
  if (state.wizard.contract_request.selected_cover_categories) {
    return {
      public_liability:   state.wizard.contract_request.selected_cover_categories.includes('public_liability'),
      vehicle_protection: state.wizard.contract_request.selected_cover_categories.includes('vehicle_protection'),
      legal_protection:   state.wizard.contract_request.selected_cover_categories.includes('legal_protection'),
      assistance:         state.wizard.contract_request.selected_cover_categories.includes('assistance'),
      people_protection:  state.wizard.contract_request.selected_cover_categories.includes('people_protection'),
      other:              state.wizard.contract_request.selected_cover_categories.includes('other'),
      base:               state.wizard.contract_request.selected_cover_categories.includes('base'),
      theft:              state.wizard.contract_request.selected_cover_categories.includes('theft'),
      natural_disasters:  state.wizard.contract_request.selected_cover_categories.includes('natural_disasters'),
      garden:             state.wizard.contract_request.selected_cover_categories.includes('garden'),
      indirect_losses:    state.wizard.contract_request.selected_cover_categories.includes('indirect_losses'),

    };
  } else {
    return {
      public_liability:   false,
      vehicle_protection: false,
      legal_protection:   false,
      assistance:         false,
      people_protection:  false,
      other:              false,
      base:               false,
      theft:              false,
      natural_disasters:  false,
      garden:             false,
      indirect_losses:    false,
    };
  }
};

export const getSelectedCoversFromContractRequest = (state) => {
  return {
    ...getCoversFromCr(state),
    product_configuration_formula:               state.wizard.contract_request.product_configuration_formula,
    product_configuration_deductible_type:       state.wizard.contract_request.product_configuration_deductible_type,
    product_configuration_deductible_amount:     state.wizard.contract_request.product_configuration_deductible_amount,
    product_configuration_derogation_percentage: state.wizard.contract_request.product_configuration_derogation_percentage,
    product_configuration_degression:            state.wizard.contract_request.product_configuration_degression,
    payment_staggering:                          state.wizard.contract_request.payment_staggering,
  };
};

export const isBuildingCr = (state) => {
  return state.wizard.contract_request.risk_object && state.wizard.contract_request.risk_object.kind === 'building';
};
