import abexList             from 'models/application/abex.json';
import isNil                from 'lodash/isNil';
import upperFirst           from 'lodash/upperFirst';
import moment               from 'moment';
import { getCurrentLocale } from 'services/i18n';

export const getAbexList = () => {
  moment.locale(getCurrentLocale());
  return abexList.map((abex) => {
    return {
      value: abex.index,
      label: `${ abex.year } - ${ upperFirst(moment(abex.date).format('MMMM')) } - ${ abex.index }`,
    };
  });
};

export const requiredData = (requiredMessageLabel) => (value) => {
  if (typeof value === 'boolean') {
    return undefined;
  }
  return !isNil(value) ? undefined : t(requiredMessageLabel);
};

export const required = requiredData('general.forms.required');

export const validateEmail = (value) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase()) ? undefined : t('activerecord.attributes.user.invalid_email');
};

const minX = (x) => ((value) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return value < x ? t('wizard.warning.must_be_min', { x: x }) : undefined;
});
export const min0 = minX(0);
export const min1 = minX(1);

const maxX = (x) => ((value) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return value > x ? t('wizard.warning.must_be_max', { x: x }) : undefined;
});
export const maxLength = (x) => ((value) => {
  let v = value;
  if (value === undefined || value === null) {
    return undefined;
  }
  return `${ value }`.length > x ? t('wizard.warning.must_be_max_length', { x: x }) : undefined;
});
const minLength = (x) => ((value) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return `${ value }`.length < x ? t('wizard.warning.must_be_min_length', { x: x }) : undefined;
});
const exactLength = (x) => ((value) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return `${ value }`.length != x ? t('wizard.warning.must_be_exact_length', { x: x }) : undefined;
});

export const min17years = (date) => {
  if (date.length < 10) {
    return null;
  }
  return moment().diff(moment(date), 'years') > 17 ? null : t('schemas.axa.constructor.axa_constructor_vehicle_schema.must_have_17_years');
};

export const maxLength500 = maxLength(500);
export const maxLength200 = maxLength(200);
export const maxLength33 = maxLength(33);
export const maxLength35 = maxLength(35);
export const maxLength20 = maxLength(20);
export const maxLength12 = maxLength(12);
export const maxLength10 = maxLength(10);
export const maxLength7 = maxLength(7);
export const maxLength6 = maxLength(6);
export const maxLength5 = maxLength(5);
export const maxLength4 = maxLength(4);
export const maxLength3 = maxLength(3);
export const maxLength2 = maxLength(2);
export const maxLength1 = maxLength(1);

export const minLength4 = minLength(4);
export const minLength10 = minLength(10);
export const minLength17 = minLength(17);

export const exactLength12 = exactLength(12);

export const max99 = maxX(99);

export const onlyInt = (value) => {
  return /^\d+$/.test(value) ? undefined : t('wizard.warning.only_integer');
};

export const onlyIntPrice = (value) => {
  return /^[\d\u202F\u00A0\s]+$/.test(value) ? undefined : t('wizard.warning.only_integer'); //space is for special character present which is not a real space
};

export const onlyLetter = (value) => {
  return /[^a-zA-Z]+/.test(value) ? t('wizard.warning.only_letter') : undefined;
};
export const onlyLetterAndSpace = (value) => {
  return /[^a-zA-Z\s]+/.test(value) ? t('wizard.warning.only_letter') : undefined;
};

export const getYearOptionList = (fromYear) => {
  let y = moment().year();
  const years = [];
  while (y >= fromYear) {
    years.push({
      value: y,
      label: y,
    });
    y--;
  }
  return years;
};

export const not0 = (value) => {
  return value && value.toString() === '0' ? t('wizard.warning.cannot_be_zero') : undefined;
}
