import React                      from 'react';
import { connect }                from 'react-redux';
import { withRouter }             from 'react-router-dom';
import Loader                     from 'components/ui/loader';
import { getAccount }             from 'models/current_account/requests';
import { fetchOffices }           from 'models/offices/requests';
import { setAccountOffices }      from 'models/current_account/store_actions';

const withAccount = () => Component => {
  class WithAccount extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    componentDidMount() {
      const { dispatch, accountAccess, history } = this.props;
      if (!accountAccess) {
        history.push('/');
      }
      Promise.all([
        dispatch(getAccount({ with_stripe_info: true })),
        fetchOffices(),
      ]).then((responses) => {
        dispatch(setAccountOffices(responses[1]));
        this.setState({
          loading: false,
        });
      }).catch((error) => {
        this.setState({
          loading: false,
        });
      });
    }

    render() {
      const { loading } = this.state;
      if (loading) {
        return <Loader />;
      }
      return <Component />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      accountAccess: state.current_user.profile.authorizations.account_admin,
    };
  };

  return withRouter(connect(mapStateToProps)(WithAccount));
};

export default withAccount;
