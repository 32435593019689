import React, { useState }                        from 'react';
import PropTypes                                  from 'prop-types';
import moment                                     from 'moment';
import TooltipIcon                                from 'components/ui/tooltip/tooltip_icon';
import Button                                     from 'components/ui/buttons/button';
import { resendAccountMemberInvitation }          from 'models/current_account/requests';

const MemberInvitationStatus = ({ member }) => {

  const { invitation_accepted_at, invitation_sent_at } = member;
  const [showInvitationLink, setShowInvitationLink] = useState(true);
  const resendInvitation = () => (
    resendAccountMemberInvitation(member.id).then(() => {
      setShowInvitationLink(false);
    })
  );

  if (invitation_accepted_at) {
    return (
      <TooltipIcon
        icon="check"
        tooltipContent={ t('providers.account.members.invitation_accepted') }
        cssClass="success"
      />
    );
  }
  if (invitation_sent_at && showInvitationLink) {
    return (
      <span className="invitation-status">
        <Button
          small
          secondary
          icon="refresh"
          onClick={ resendInvitation }
        >
          { t('providers.account.members.resend_invitation') }
        </Button>
      </span>
    );
  }
  if (invitation_sent_at && !showInvitationLink) {
    return (
      <span className="uk-text-italic">
        { t('providers.account.members.invitation_sent_at', {
          invitation_sent_at: moment(invitation_sent_at).format('DD/MM/YYYY - HH:mm'),
        }) }
      </span>
    );
  }
  return null;
};

MemberInvitationStatus.propTypes = {
  member:                PropTypes.object.isRequired
};

export default MemberInvitationStatus;
