import Alert                         from 'services/alert';
import Api                           from 'services/api';
import { prepareErrorsForReduxForm } from 'services/rails';

export const updateBillingAddress = params => (
  new Promise((resolve, reject) => {
    Api.put('account/billing_address', { billing_address: params }).then((response) => {
      const { account } = response.data;
      resolve(account);
    }).catch((error) => {
      if (error.data.error_message) {
        return reject(Alert.error(error.data.error_message));
      }
      Alert.error(t('providers.account.billing_address.update_error'));
      if (error.data.errors) {
        reject(prepareErrorsForReduxForm(error.data.errors));
      }
    });
  })
);

export default updateBillingAddress;
