import React, { useEffect } from 'react';
import PropTypes            from 'prop-types';
import $                    from 'jquery';
import PlacesAutocomplete, {
  geocodeByAddress,
}                           from 'react-places-autocomplete';
import Loader               from 'components/ui/loader';
import Icon                 from 'components/ui/icon';
import FormErrorMessage     from 'components/ui/form/form_error_message';
import { TEST }             from 'constants/application_constants';

const AddressPartWithAutocomplete = ({
  input: {
           value,
           onChange,
           name,
         },
  disabled,
  componentRestrictions = {},
  searchOptions = {},
  autoFocus,
  className,
  precompleteAddress,
  meta = {},
}) => {

  useEffect(() => {
    if (autoFocus) {
      $('.autocomplete-input').trigger('focus');
    }
  }, []);

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then(results => {
        const { address_components, geometry: { location } } = results[0];
        if (address_components) {
          const streetName = address_components.find((comp) => comp.types.includes('route'));
          const streetNumber = address_components.find((comp) => comp.types.includes('street_number'));
          const city = address_components.find((comp) => comp.types.includes('locality'));
          const postalCode = address_components.find((comp) => comp.types.includes('postal_code'));
          if (precompleteAddress) {
            const address = {
              street_name:   streetName && streetName.long_name,
              street_number: streetNumber && streetNumber.long_name,
              city:          city && city.long_name,
              zip_code:      postalCode && postalCode.long_name,
              lat:           location.lat(),
              lng:           location.lng(),
            };
            precompleteAddress(address);
          }
        }
      })
      .catch(error => console.error('Error', error));
  };

  const { asyncValidating, ...restMeta } = meta;
  return (
    <PlacesAutocomplete
      value={ value }
      onChange={ (v => {
        onChange(v);
      }) }
      onSelect={ handleSelect }
      shouldFetchSuggestions={ !TEST }
      searchOptions={ {
        ...searchOptions,
        componentRestrictions: {
          country: 'be',
          ...componentRestrictions,
        },
      } }
    >
      { ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="field-suggestions">
          <div className="input-container">
            <div className={ `uk-inline uk-width-1-1` }>
              <input
                { ...getInputProps({
                  placeholder:  t('activerecord.attributes.address.search_address'),
                  className:    `uk-input ${ className } ${ meta.touched && meta.error ? 'uk-form-danger' : '' } symbol-input`,
                  autoComplete: 'new-password',
                }) }
                type="search"
                disabled={ disabled }
                name={ `${ name }_without_auto_fill` }
                data-purpose={ name }
              />
              <span className="uk-form-icon uk-form-icon-flip">
              <Icon icon="search" />
            </span>
            </div>
            <FormErrorMessage { ...restMeta } name={ name } label={ t('general.address.street') } />
          </div>
          <div className="suggestion-list">
            { loading && <Loader /> }
            { suggestions.map(suggestion => {
              const className = `suggestion-item ${ suggestion.active ? 'active' : '' }`;
              return (
                <div
                  { ...getSuggestionItemProps(suggestion, {
                    className,
                  }) }
                >
                  <span>{ suggestion.description }</span>
                </div>
              );
            }) }
          </div>
        </div>
      ) }
    </PlacesAutocomplete>
  );
};

AddressPartWithAutocomplete.defaultProps = {
  precompleteAddress: null,
  autoFocus:          true,
};

AddressPartWithAutocomplete.propTypes = {
  precompleteAddress:    PropTypes.func,
  autoFocus:             PropTypes.bool,
  componentRestrictions: PropTypes.object,
};

export default AddressPartWithAutocomplete;
