import React, { useState }       from 'react';
import PropTypes                 from 'prop-types';
import Button                    from 'components/ui/buttons/button';
import AppModal                  from 'components/ui/app_modal';
import SubscriptionFormContainer from 'components/subscription/form_container';

const SubscriptionCreationButton = ({ planId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        primary
        data-purpose="add_credit_card_button"
        onClick={ () => setModalIsOpen(true) }
      >
        { t('subscriptions.create') }
      </Button>
      <AppModal isOpen={ modalIsOpen } setIsOpen={ setModalIsOpen } width="large">
        <SubscriptionFormContainer onSuccess={ () => { setModalIsOpen(false); } } selectedPlan={ planId } />
      </AppModal>
    </React.Fragment>
  );
};

SubscriptionCreationButton.propTypes = {
  planId: PropTypes.string.isRequired
};

export default SubscriptionCreationButton;
