import React           from 'react';
import PropTypes       from 'prop-types';
import compact         from 'lodash/compact';
import concat          from 'lodash/concat';
import uniq            from 'lodash/uniq';
import SymbolTextInput from 'components/ui/form/symbol_text_input';
import Icon            from 'components/ui/icon';

class KeywordFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: props.list,
    };

    this.handleChange = this.handleChange.bind(this);
    this.filterForLabel = this.filterForLabel.bind(this);
  }

  filterForLabel(value, label) {
    return new Promise((resolve) => {
      const filteredItems = [];
      const { list } = this.state;
      const { filterMethod } = this.props;
      if (filterMethod && filterMethod[label]) {
        filteredItems.push(...filterMethod[label](list, value));
      } else {
        list.forEach((item) => {
          if (!item[label]) {
            return;
          }
          if (item[label].trim().toLowerCase().includes(value.trim().toLowerCase())) {
            filteredItems.push(item);
          }
        });
      }
      resolve(filteredItems);
    });
  }

  handleChange(ev) {
    const { target: { value } } = ev;
    const {
            onKeywordUpdate,
            labelToFilter,
            onFiltered,
          } = this.props;
    if (onKeywordUpdate) {
      onKeywordUpdate(value);
    }
    const filterPromises = labelToFilter.map(label => (
      this.filterForLabel(value, label)
    ));
    Promise.all(filterPromises).then((results) => {
      onFiltered(
        uniq(compact(concat(...results)), value.trim().toLowerCase()),
        value,
      );
    });
    return undefined;
  }

  render() {
    const { style, inputPlaceholder, className } = this.props;
    return (
      <div className={ className }>
        <SymbolTextInput
          input={ { onChange: this.handleChange } }
          symbol={ <Icon icon="search" /> }
          style={ style }
          meta={ {} }
          type="text"
          placeholder={ inputPlaceholder }
        />
      </div>
    );
  }
}

KeywordFilter.propTypes = {
  labelToFilter:    PropTypes.array.isRequired,
  list:             PropTypes.array.isRequired,
  onFiltered:       PropTypes.func.isRequired,
  filterMethod:     PropTypes.object,
  inputPlaceholder: PropTypes.string,
  onKeywordUpdate:  PropTypes.func,
};

export default KeywordFilter;
