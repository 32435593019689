import React                  from 'react';
import PropTypes              from 'prop-types';
import {
  reduxForm,
  Field,
}                             from 'redux-form';
import { Row, Col }           from 'react-flexbox-grid';
import SubmitButton           from 'components/ui/form/submit_button';
import TextInput              from 'components/ui/form/text_input';
import GlobalFormErrorMessage from 'components/ui/form/global_form_error_message';
import { required }           from 'models/application/constants';

const UserSignInForm = ({ submitting, handleSubmit }) => (
  <form onSubmit={ handleSubmit } data-purpose="user_password_reset_form">
    <GlobalFormErrorMessage />
    <Row>
      <Col sm={ 12 }>
        <Field
          label={ t('activerecord.attributes.user.email') }
          name="email"
          type="email"
          component={ TextInput }
          validate={ [required] }
        />
        <SubmitButton
          containerClassName="mt-20"
          loading={ submitting }
          onClick={ handleSubmit }
          text={ t('general.actions.send') }
          data-purpose="submit_user_password_reset_form"
          large
        />
      </Col>
    </Row>
  </form>
);

UserSignInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting:   PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'user_sign_in',
})(UserSignInForm);
