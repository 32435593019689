import React                        from 'react';
import PropTypes                    from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Col }                      from 'react-flexbox-grid';
import { getAccount }               from 'models/current_account/requests';
import Title                        from 'components/ui/titles';
import Callout                      from 'components/ui/callout';
import CreditCardFormFields         from 'components/credit_card/form/fields';
import CreditCardFormContainer      from 'components/credit_card/form/container';
import openDialogOnClick            from 'components/ui/buttons/helpers/open_dialog_on_click';
import SubscriptionCallout          from 'components/subscription/subscription_callout';

const SubscriptionShowCurrentSubscription = ({ subscription }) => {
  const stripe_customer = useSelector((state) => state.account.stripe_customer);
  const dispatch = useDispatch();

  const sendInvoice = () => (
    subscription && subscription.collection_method === 'send_invoice'
  );

  const subscriptionPending = () => (
    subscription && (subscription.need_sca || subscription.need_new_source)
  );

  const onCreditCardModalSuccess = () => {
    dispatch(getAccount({ with_stripe_info: true }));
  };

  const CreditCardFormFieldsButton = openDialogOnClick()(({ onClick }) => (
    <CreditCardFormFields
      onClick={ onClick }
      readOnly
      lastFour={ stripe_customer.credit_card.last4 }
      expMonth={ stripe_customer.credit_card.exp_month }
      expYear={ stripe_customer.credit_card.exp_year }
      readOnlyBrand={ stripe_customer.credit_card.brand }
    >
      { t('components.subscription.show.current_subscription.credit_card_validation') }
    </CreditCardFormFields>
  ));

  return (
    <React.Fragment>
      { subscription.plan !== 'free' && (
        <Col style={ { marginRight: '40px' } }>
          <Title
            className="mb-20"
            size="h2"
          >
            { t('components.subscription.show.current_subscription.payment_method') }
          </Title>
          { sendInvoice() && (
            <Callout className="primary" style={ { marginBottom: '20px' } }>
              { t('components.subscription.show.current_subscription.transfert_payment_method') }
            </Callout>
          ) }
          { stripe_customer && stripe_customer.credit_card && (
            <div className="mb-20">
              <CreditCardFormFieldsButton
                dialogTitle={ sendInvoice() ? t('components.subscription.show.current_subscription.credit_card_add') : t('components.subscription.show.current_subscription.credit_card_update') }
              >
                <CreditCardFormContainer onSuccess={ onCreditCardModalSuccess } />
              </CreditCardFormFieldsButton>
            </div>
          ) }
        </Col>
      ) }
      <Col>
        <Title
          className="mb-20"
          size="h2"
        >
          { t('components.subscription.show.current_subscription.current_subscription_title') }
        </Title>
        <SubscriptionCallout subscription={ subscription } />
      </Col>
    </React.Fragment>
  );
};

SubscriptionShowCurrentSubscription.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default SubscriptionShowCurrentSubscription;
