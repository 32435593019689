import React         from 'react';
import PropTypes     from 'prop-types';
import {
  reduxForm,
  Field,
}                    from 'redux-form';
import { Row, Col }  from 'react-flexbox-grid';
import SubmitButton  from 'components/ui/form/submit_button';
import PasswordInput from 'components/ui/form/password_input';
import Margin        from 'components/ui/margin';
import { required }  from 'models/application/constants';

const UserNewPasswordForm = ({ submitting, handleSubmit }) => {
  return (
    <form onSubmit={ handleSubmit } data-purpose="user_new_password_form">
      <Row>
        <Col sm={ 12 }>
          <Field
            type="hidden"
            component="input"
            name="reset_password_token"
          />
          <Margin>
            <Field
              label={ t('activerecord.attributes.user.password') }
              name="password"
              component={ PasswordInput }
              validate={ [required] }
            />
          </Margin>
          <SubmitButton
            loading={ submitting }
            onClick={ handleSubmit }
            text={ t('general.actions.save') }
            data-purpose="submit_user_new_password_form"
            large
          />
        </Col>
      </Row>
    </form>
  );
};

UserNewPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting:   PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'user_new_password',
})(UserNewPasswordForm);
