import React                  from 'react';
import PropTypes              from 'prop-types';
import {
  reduxForm,
  Field,
}                             from 'redux-form';
import { Row, Col }           from 'react-flexbox-grid';
import { Link }               from 'react-router-dom';
import Box                    from 'components/ui/box';
import SubmitButton           from 'components/ui/form/submit_button';
import TextInput              from 'components/ui/form/text_input';
import PasswordInput          from 'components/ui/form/password_input';
import GlobalFormErrorMessage from 'components/ui/form/global_form_error_message';
import Margin                 from 'components/ui/margin';
import { getCurrentLocale }   from 'services/i18n';
import { required }           from 'models/application/constants';


const UserSignInForm = ({ submitting, handleSubmit }) => (
  <form onSubmit={ handleSubmit } data-purpose="user_sign_in_form" id="user-sign-in-form" autoComplete="on">
    <GlobalFormErrorMessage />
    <Row>
      <Col sm={ 12 }>
        <Field
          label={ t('activerecord.attributes.user.email') }
          name="email"
          type="email"
          component={ TextInput }
          validate={ [required] }
        />
        <Margin>
          <Field
            label={ t('activerecord.attributes.user.password') }
            name="password"
            component={ PasswordInput }
            validate={ [required] }
          />
        </Margin>
        <Box className="uk-text-right mb-20">
          <Link
            to={ `/${ getCurrentLocale() }/user/password/reset` }
            className="uk-text-right"
            data-purpose="reset_password_link"
          >
            { t('devise.sessions.new.forgot_your_password') }
          </Link>
        </Box>
        <SubmitButton
          loading={ submitting }
          onClick={ handleSubmit }
          text={ t('users.sessions.sign_in') }
          data-purpose="submit_user_sign_in_form"
          large
        />
      </Col>
    </Row>
  </form>
);

UserSignInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting:   PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'user_sign_in',
})(UserSignInForm);
