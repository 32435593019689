import React                                                     from 'react';
import PropTypes                                                 from 'prop-types';
import { connect }                                               from 'react-redux';
import Button                                                    from 'components/ui/buttons/button';
import { updateTarification }                                    from 'models/tarifications/requests';
import { updateTarification as updateTarificationInWizardStore } from 'models/wizard/store_actions';

const TarificationRefreshButton = ({ tarification, updateQuickTarificationAndDispatch, ...props }) => {
  const perform = () => {
    updateQuickTarificationAndDispatch();
  };

  return (
    <Button
      secondary
      { ...props }
      onClick={ perform }
      className="mr-10"
      icon="refresh"
    >
      { t('general.actions.refresh') }
    </Button>
  );
};

TarificationRefreshButton.propTypes = {
  tarification:                       PropTypes.object.isRequired,
  updateQuickTarificationAndDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { tarification }) => {
  return {
    updateQuickTarificationAndDispatch: () => (
      updateTarification(tarification).then((updatedTarification) => {
        dispatch(updateTarificationInWizardStore(updatedTarification));
      })
    ),
  };
};

export default connect(null, mapDispatchToProps)(TarificationRefreshButton);
