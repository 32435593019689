import React        from 'react';
import PropTypes    from 'prop-types';
import { connect }  from 'react-redux';
import flatten      from 'lodash/flatten';
import groupBy      from 'lodash/groupBy';
import camelCase    from 'lodash/camelCase';
import compact      from 'lodash/compact';
import get          from 'lodash/get';
import map          from 'lodash/map';
import some         from 'lodash/some';
import TooltipIcon  from 'components/ui/tooltip/tooltip_icon';
import Avatar       from 'components/avatar';
import Icon         from 'components/ui/icon';
import { isCrLead } from 'models/contract_request/constants';
import SimpleBar    from 'simplebar-react';

const WarningTooltip = ({ content, name, className, icon, setWarningPresence, loading, isLead }) => {
  if (isLead) {
    return null;
  }

  if (!content || !content.length) {
    setWarningPresence && setWarningPresence(false);
    return null;
  }

  if (loading) {
    return (
      <Icon icon="loading" className="ml-10 be-spin" />
    );
  }

  setWarningPresence && setWarningPresence(true);

  const tooltipContent = (
    <SimpleBar className="warning-tooltip-content">
      <ul className="uk-list uk-text-left p-0 m-0">
        { content.map(({ product, error }) => {
          return (
            <li key={ camelCase(`${ product.insurance_company.name }_${ product.name }_${ name }_${ camelCase(error) }`) }>
              <ul className="p-0 m-0">
                <li className="uk-flex uk-flex-middle mb-5">
                  { product.insurance_company.logo && (
                    <Avatar
                      picture={ product.insurance_company.logo.extra_small.url }
                      name={ product.insurance_company.name }
                      size={ 15 }
                      className="mr-10"
                    />
                  ) }
                  <h3 className="mb-0 mt-0">{ product.name }</h3>
                </li>
                <li>
                  <ul className="uk-list">
                    { typeof error === 'string' && (
                      <li>{ error }</li>
                    ) }
                    { Array.isArray(error) && error.map((item, id) => (
                      <li key={ id }>{ item }</li>
                    )) }
                  </ul>
                </li>
              </ul>
            </li>
          );
        }) }
      </ul>
    </SimpleBar>
  );

  return (
    <TooltipIcon
      tooltipPos="right"
      tooltipClassName="wizard-warning-tooltip"
      cssClass={ `warning-icon warning ${ className }` }
      icon={ icon }
      tooltipContent={ tooltipContent }
    />
  );
};

const getWarnings = (warnings) => {
  if (!warnings) {
    return null;
  }
  if (warnings.products) {
    return warnings.products;
  }
  const warn = flatten(map(warnings, (warn) => {
    if (warn.products) {
      return warn.products;
    }
  }));
  const grouped = groupBy(warn, (w) => w.product.insurance_company.id);
  const groupedByProduct = Object.keys(grouped).map((companyId) => {
    const errors = flatten(grouped[companyId].map(({ error }) => error));
    return {
      error:   errors,
      product: grouped[companyId][0].product,
    };
  });
  return groupedByProduct;
};

const mapStateToProps = (state, { name }) => {
  const store = state.wizard.warnings;
  if (!store) {
    return {
      content: null,
    };
  }
  const multiple = Array.isArray(name);
  if (multiple) {
    const warnings = compact(name.map((n) => getWarnings(get(store, n))));
    return {
      content: flatten(warnings),
    };
  }
  const warning = get(store, name);
  return {
    content:         getWarnings(warning),
    loading:         some(state.wizard.contract_request.tarifications, tarif => tarif.computing),
    isLead:          isCrLead(state.wizard.contract_request),
    contractRequest: state.wizard.contract_request,
  };
};

WarningTooltip.defaultProps = {
  icon:               'warning',
  warningStorePath:   'provider.wizard.warnings',
  setWarningPresence: null,
};

WarningTooltip.propTypes = {
  name:               PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  content:            PropTypes.any,
  icon:               PropTypes.string,
  warningStorePath:   PropTypes.string,
  setWarningPresence: PropTypes.func,
};

export default connect(mapStateToProps)(WarningTooltip);
