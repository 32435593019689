import React                from 'react';
import Title                from 'components/ui/titles';
import ErrorImage           from 'images/error_encountered.svg';
import { MAINTENANCE_MODE } from 'constants/application_constants';
import { useSelector }      from 'react-redux';
import ConfigSocket         from 'scenes/application/config_socket';

const MaintenanceMode = ({ children }) => {
  const maintenance = useSelector((state) => state.config.maintenance_mode);
  const params = new URLSearchParams(window.location.search);
  const obParam = params.get('openbroker');
  if ((maintenance || MAINTENANCE_MODE) && (obParam !== 'test')) {
    return (
      <div className="error-container" data-purpose="maintenance_mode">
        <ConfigSocket />
        <img src={ ErrorImage } alt="Error encountered" />
        <Title size="h2" className="mb-40">
          { t('maintenance.title') }
        </Title>
        <p>{ t_html('maintenance.explanation_html') }</p>
      </div>
    );
  }
  return children;
};

MaintenanceMode.defaultProps = {
  locale: 'nl',
};

export default MaintenanceMode;
