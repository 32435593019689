import React            from 'react';
import PropTypes        from 'prop-types';
import {
  change,
  Field, formValueSelector,
}                       from 'redux-form';
import { connect }      from 'react-redux';
import CheckboxInput    from 'components/ui/form/checkbox_input';
import { isBuildingCr } from 'models/wizard/reducer';

class TarificationTableHeader extends React.Component {
  shouldComponentUpdate({ tarifications, selectedTarifications, allSelected, toggleAll }) {
    if (tarifications.length === selectedTarifications.length && !allSelected) {
      toggleAll(true);
    } else if (tarifications.length > selectedTarifications.length && allSelected) {
      toggleAll(false);
    }
    return true;
  }

  render() {
    const { columns, tarifications, toggleTarifications, isBuilding } = this.props;
    return (
      <thead>
        <tr className="with-border-bottom">
          <th colSpan={ !isBuilding ? 2 : 1 }>
            { !isBuilding && (
              <React.Fragment>
                <Field
                  name="all"
                  id="all"
                  className="uk-checkbox"
                  type="checkbox"
                  component={ CheckboxInput }
                  data-purpose="check-all"
                  onCheck={ (allCheck) => {
                    toggleTarifications(tarifications, allCheck);
                  } }
                />
                <label htmlFor="all">{ t('wizard.resume.select_all') }</label>
              </React.Fragment>
            ) }
          </th>
          { columns.map(col => (
            <th
              key={ col }
              className={ `price-col ${ col === 'total' ? 'uk-text-right' : 'uk-text-center' }` }
            >
              { t(`providers.contract_request.quick_tarification_table.${ col }`) }
            </th>
          )) }
        </tr>
      </thead>
    );
  }
};

TarificationTableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTarifications: (tarifications, check) => {
      tarifications.forEach(tarif => dispatch(change('tarification_table', `tarification_${ tarif.id }`, check)));
    },
    toggleAll:           (checked) => {
      dispatch(change('tarification_table', 'all', checked));
    },
  };
};

const selector = formValueSelector('tarification_table');

const mapStateToProps = (state) => {
  const tarifications = state.wizard.contract_request.tarifications;
  const selectedTarifications = tarifications.filter((tarif) => {
    if (selector(state, `tarification_${ tarif.id }`)) {
      return tarif;
    }
  });
  return {
    tarifications,
    selectedTarifications,
    allSelected: selector(state, 'all'),
    isBuilding:  isBuildingCr(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TarificationTableHeader);
