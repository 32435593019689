import Api   from 'services/api';
import Alert from 'services/alert';

export const getAccountFinancialDocuments = () => (
  new Promise((resolve, reject) => {
    Api.get('account/financial_documents').then((response) => {
      resolve(response);
    }).catch((error) => {
      Alert.error(t('providers.account.invoices.invoices_index_error'));
      reject(error);
    });
  })
);

export default getAccountFinancialDocuments;
