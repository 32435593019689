import { GOOGLE_JAVASCRIPT_API_KEY }                from 'constants/application_constants';
import React, { useEffect }                         from 'react';
import PropTypes                                    from 'prop-types';
import { JsonForm }                                 from '@deecide/redux-json-form';
import { useDispatch, useSelector }                 from 'react-redux';
import { prepareDataToUpdateContractRequest }       from 'models/contract_request/constants';
import { trackWizardStep, UPDATE_CONTRACT_REQUEST } from 'models/wizard/constants';
import { updateContractRequestAndUpdateInStore }    from 'models/wizard/dispatch_actions';
import asWizardStep                                 from 'components/wizard_stepper/as_wizard_step';
import { submit }                                   from 'redux-form';
import { getCurrentLocale }                         from 'services/i18n';

let nextStepOverride = null;

const ProductFactoryStep = (props) => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const dispatch = useDispatch();
  const {
          nextStep,
          setNextLoading,
          onNextStep,
          stepSchema,
          setNextAction,
        } = props;

  const updateContractRequest = (contractRequestToUpdate) => {
    trackWizardStep(UPDATE_CONTRACT_REQUEST, { contract_request_id: contractRequestToUpdate.id });
    setNextLoading(true);
    if (contractRequestToUpdate) {
      if (!contractRequestToUpdate.authorizations.update) {
        setNextLoading(false);
        return Promise.resolve();
      }
      return dispatch(updateContractRequestAndUpdateInStore(contractRequestToUpdate))
        .then(() => {
          setNextLoading(false);
          if (onNextStep.recomputeTarifications) {
            onNextStep.recomputeTarifications();
          }
        })
        .catch(() => {
          setNextLoading(false);
        });
    }
  };

  const onSubmit = (data) => {
    updateContractRequest(prepareDataToUpdateContractRequest(data, contractRequest), contractRequest.id).then(() => {
      nextStep();
    });
  };

  const submitForm = () => {
    dispatch(submit(stepSchema.form));
  };

  useEffect(() => {
    setNextAction((onNextStepOverride) => {
      if (onNextStepOverride) {
        nextStepOverride = onNextStepOverride;
      }
      return submitForm();
    });
  }, []);
  const locale = getCurrentLocale();
  return (
    <JsonForm
      { ...props }
      { ...stepSchema }
      locale={ locale }
      initialValues={ contractRequest.data }
      options={
        {
          googleMap: {
            lang:   locale,
            apiKey: GOOGLE_JAVASCRIPT_API_KEY,
          },
        }
      }
      onSubmit={ onSubmit }
    />
  );
};

ProductFactoryStep.propTypes = {
  stepSchema: PropTypes.object.isRequired,
};

export default asWizardStep(true)(ProductFactoryStep);
