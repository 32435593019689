import Api   from 'services/api';
import Alert from 'services/alert';

export const archiveContractRequest = (contractRequestId) => {
  return new Promise((resolve, reject) => {
    Api.post(`contract_requests/${ contractRequestId }/archive`).then((response) => {
      Alert.success(t('clients.contract_requests.index.archive_contract_request_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('clients.contract_requests.index.archive_contract_request_error'));
      reject(error);
    });
  })
}

export const updateContractRequest = (contractRequestId, data, params) => (
  new Promise((resolve, reject) => {
    Api.put(`contract_requests/${ contractRequestId }`, { contract_request: data, ...params }).then((response) => {
      Alert.success(t('clients.contract_requests.index.update_contract_request_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('clients.contract_requests.index.update_contract_request_error'));
      reject(error);
    });
  })
);

export const fetchContractRequest = (contractRequestId, params) => {
  return new Promise((resolve, reject) => {
    Api.get(`contract_requests/${ contractRequestId }`, { params }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('clients.contract_requests.fetch_contract_request_error'));
      reject(error);
    });
  });
};

export const createContractRequest = (
  contractRequestToCreate,
  selectProducts,
) => {
  const {
          payment_staggering,
          product_configuration_deductible_amount,
          product_configuration_deductible_type,
          product_configuration_degression,
          product_configuration_derogation_percentage,
          product_configuration_formula,
          selected_cover_categories,
        } = contractRequestToCreate;
  let data = {
    risk_object_id:           contractRequestToCreate.risk_object.id,
    product_ids:              selectProducts.map((p) => p.id),
    payment_staggering,
    product_configuration_deductible_amount,
    product_configuration_deductible_type,
    product_configuration_degression,
    product_configuration_derogation_percentage,
    product_configuration_formula,
    selected_cover_categories,
  };

  return new Promise((resolve, reject) => {
    Api.post('contract_requests', {
      contract_request: data,
    }).then((response) => {
      Alert.success(t('clients.contract_requests.create_contract_request_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('clients.contract_requests.create_contract_request_error'));
      reject(error);
    });
  });
};

export const createJsonContractRequest = (riskObject, products = []) => {
  return new Promise((resolve, reject) => {
    Api.post('contract_requests', {
      contract_request: {
        risk_object_id:                              riskObject.id,
        product_ids:                                 products.map(p => p.id),
        selected_cover_categories:                   ['public_liability'],
        product_configuration_formula:               'full_omnium',
        product_configuration_deductible_type:       'amount',
        product_configuration_deductible_amount:     '0',
        product_configuration_derogation_percentage: 'max',
        product_configuration_degression:            'more_than_36_months',
        payment_staggering:                          'annually',
      },
    }).then((response) => {
      Alert.success(t('clients.contract_requests.create_contract_request_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('clients.contract_requests.create_contract_request_error'));
      reject(error);
    });
  });
};

export const deleteContractRequestDocument = (documentId, contractRequestId) => {
  return new Promise((resolve, reject) => {
    Api.delete(`contract_requests/${ contractRequestId }/documents/${ documentId }`).then((response) => {
      Alert.success(t('contract_request.delete_document_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('contract_request.delete_document_error'));
      reject(error);
    });
  });
};

export const recomputeContractRequest = (contractRequest) => {
  return new Promise((resolve, reject) => {
    Api.post(`contract_requests/${ contractRequest.id }/recompute`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('contract_request.recompute_error'));
      reject(error);
    });
  });
};
