import { mapInErrors } from 'models/tarifications/constants';

export const setRiskObject = (risk_object) => (
  {
    type: 'WIZARD/SET_RISK_OBJECT',
    risk_object,
  }
);

export const resetWizard = () => (
  {
    type: 'WIZARD/RESET',
  }
);

export const setSelectedCovers = (selected_covers) => (
  {
    type: 'WIZARD/SET_SELECTED_COVERS',
    selected_covers,
  }
);

export const setContractRequest = (contractRequest) => (
  {
    type: 'WIZARD/SET_CONTRACT_REQUEST',
    contractRequest,
  }
);

export const setTarifications = (tarifications) => {
  return (dispatch) => {
    tarifications.forEach((tarif) => {
      dispatch(updateTarification(tarif));
    });
  };
};

export const updateTarification = (tarification) => (
  {
    type: 'WIZARD/UPDATE_TARIFICATION',
    tarification,
  }
);

export const removeTarification = (tarification) => (
  {
    type: 'WIZARD/REMOVE_TARIFICATION',
    tarification,
  }
);

export const setContractRequestLoading = (loading) => (
  {
    type: 'WIZARD/SET_CONTRACT_REQUEST_LOADING',
    loading,
  }
);

export const setRequiredFields = ({ required_fields }) => {
  const requiredFields = {};
  Object.keys(required_fields).map((key) => {
    const sub = {};
    required_fields[key].map((req) => {
      sub[req] = true;
    });
    requiredFields[key] = sub;
  });
  return {
    type:            'WIZARD/SET_REQUIRED_FIELDS',
    required_fields: requiredFields,
  };
};

export const setDefaultRequiredFields = (default_required_fields) => {
  return {
    type: 'WIZARD/SET_DEFAULT_REQUIRED_FIELDS',
    default_required_fields,
  };
};

export const setWarningsFromTarifications = (tarifications) => {
  let warnings = {};
  tarifications.forEach((tarification) => {
    if (tarification.last_error_response) {
      warnings = mapInErrors(tarification.last_error_response.errors, {
        insurance_company: tarification.product.insurance_company,
        name:              tarification.product.name,
      }, warnings);
    }
  });
  return {
    type: 'WIZARD/SET_WARNINGS',
    warnings,
  };
};

export const setRequiredFieldsLoading = (loading) => {
  return {
    type: 'WIZARD/SET_REQUIRED_FIELDS_LOADING',
    loading,
  };
};

export const setDefaultLeadCr = (products) => {
  return {
    type: 'WIZARD/SET_DEFAULT_CR_FOR_LEAD',
    products,
  };
};

export const setContractRequestStatus = (status) => {
  return {
    type: 'WIZARD/SET_CONTRACT_REQUEST_STATUS',
    status,
  };
};

export const setContractRequestAuthorizations = (authorizations) => {
  return {
    type: 'WIZARD/SET_CONTRACT_REQUEST_AUTHORIZATIONS',
    authorizations,
  };
};
