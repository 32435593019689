import React                  from 'react';
import PropTypes              from 'prop-types';
import {
  reduxForm,
  Field,
}                             from 'redux-form';
import { Row, Col }           from 'react-flexbox-grid';
import TextInput              from 'components/ui/form/text_input';
import PasswordInput          from 'components/ui/form/password_input';
import CheckboxInput          from 'components/ui/form/checkbox_input';
import GlobalFormErrorMessage from 'components/ui/form/global_form_error_message';
import Margin                 from 'components/ui/margin';
import InputLabel             from 'components/ui/form/input_label';
import Button                 from 'components/ui/buttons/button';
import { getCurrentLocale }   from 'services/i18n';

const AccountForm = ({ submitting, handleSubmit }) => {

  return (
    <form
      onSubmit={ handleSubmit }
      data-purpose="account_form"
    >
      <Row>
        <Col sm={ 12 }>
          <GlobalFormErrorMessage />
          <Margin>
            <Field
              label={ t('activerecord.attributes.user.email') }
              name="email"
              type="email"
              component={ TextInput }
            />
          </Margin>
          <Margin>
            <Row>
              <Col sm={ 12 } md={ 6 }>
                <Field
                  label={ t('activerecord.attributes.user.first_name') }
                  name="first_name"
                  type="text"
                  component={ TextInput }
                />
              </Col>
              <Col sm={ 12 } md={ 6 }>
                <Field
                  label={ t('activerecord.attributes.user.last_name') }
                  name="last_name"
                  type="text"
                  component={ TextInput }
                />
              </Col>
            </Row>
          </Margin>
          <Margin>
            <Field
              label={ t('activerecord.attributes.account.name') }
              name="name"
              type="text"
              component={ TextInput }
            />
          </Margin>
          <Margin>
            <Field
              label={ t('activerecord.attributes.user.password') }
              name="password"
              component={ PasswordInput }
            />
          </Margin>
          <Margin>
            <Field
              name="has_read_general_conditions"
              id="has_read_general_conditions"
              label={ t_html('general.signup.general_conditions_html') }
              component={ CheckboxInput }
            />
          </Margin>
          <div className="uk-text-center mb-20 mt-20">
            <Button
              primary
              large
              disabled={ submitting }
              loading={ submitting }
              onClick={ handleSubmit }
              data-purpose="submit_account_form"
            >
              { t('account.create_account_button') }
            </Button>
          </div>
          <hr className="uk-divider-icon" />
          <p className="uk-text-center">
            <Button
              primary
              link={ `/${ getCurrentLocale() }/user/sign_in` }
              large
            >
              { t('devise.registrations.new.i_already_have_an_account') }
            </Button>
          </p>
        </Col>
      </Row>
    </form>
  );
};

AccountForm.propTypes = {
  change:       PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting:   PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'brokerage_registration',
})(AccountForm);
