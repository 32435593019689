import React            from 'react';
import FormErrorMessage from 'components/ui/form/form_error_message';
import InlineContainer  from 'components/ui/form/inline_container';

class SymbolTextInput extends React.Component {
  componentDidMount() {
    const {
            input: {
                     onChange,
                     value,
                   },
            normalizeOnMount,
          } = this.props;
    if (normalizeOnMount) {
      onChange(normalizeOnMount(value));
    }
  }

  render() {
    const {
            input: {
                     onChange,
                     ...restInput
                   },
            label,
            labelAddition,
            type,
            symbol,
            meta,
            disabled,
            id,
            purpose,
            className,
            noClass,
            inline,
            warning,
            normalizeOnMount,
            fieldAddition,
            onValueChange,
            formName,
            change,
            InputAddition,
            ...    rest
          } = this.props;
    const replaceCommaByDot = (ev) => (
      onChange(ev.target.value.replace(',', '.'))
    );

    const enhancedOnChange = (ev) => {
      if (onValueChange) {
        onValueChange(ev.target.value);
      }
      if (type === 'number') {
        return replaceCommaByDot(ev);
      } else {
        return onChange(ev);
      }
    };

    const symbolTextInput = (
      <React.Fragment>
        <div className={ `${ noClass ? '' : 'full-width' } input-container` }>
          <div className={ `uk-inline ${ noClass ? '' : 'full-width' }` }>
            <input
              className={ `uk-input uk-form-small ${ meta.touched && meta.error ? 'uk-form-danger' : '' } ${ className } symbol-input` }
              id={ id }
              { ...restInput }
              onChange={ enhancedOnChange }
              placeholder={ label }
              type={ type }
              disabled={ disabled }
              data-purpose={ purpose }
              min="0"
              step="0.01"
              { ...rest }
            />
            <span className="uk-form-icon uk-form-icon-flip">
              { symbol }
            </span>
          </div>
          <FormErrorMessage { ...meta } />
          { fieldAddition && fieldAddition }
        </div>
      </React.Fragment>
    );

    if (!inline) {
      return symbolTextInput;
    }

    return (
      <InlineContainer
        labelFor={ id }
        label={ label }
        warning={ warning }
        meta={ meta }
        field={ symbolTextInput }
        labelAddition={ labelAddition }
      />
    );
  }
};

export default SymbolTextInput;
