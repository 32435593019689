import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form';
import snakeCase from 'lodash/snakeCase';
import reject from 'lodash/reject';
import classNames from 'classnames';
import TextInput from 'components/ui/form/text_input';
import Button from 'components/ui/buttons/button';
import DeleteIntegrationButton from 'distributor/components/integrations/delete_integration_button';
import IntegrationsTextTemplate from 'distributor/components/integrations/text_template';
import IntegrationsCheckboxTemplate from 'distributor/components/integrations/checkbox_template';

const IntegrationForm = ({
  handleSubmit,
  submitting,
  initialValues,
  deletable,
  onDeleted,
  configurationTemplate,
  forCreation,
}) => {
  console.log('configurationTemplate', configurationTemplate);
  return (
    <form
      className="form integration-form"
      data-purpose={`integration_form_${
        initialValues &&
        snakeCase(initialValues.name || initialValues.insurance_company.name)
      }`}
      onSubmit={handleSubmit}
    >
      <Field name="id" type="hidden" component="input" />
      <div className="mb-20">
        <div className="mb-10">
          <p className="mb-10">
            <label htmlFor="name">
              {t(
                'distributor.components.integrations.integration_form.integration_name',
              )}
            </label>
          </p>
          <Field name="name" component={TextInput} id="name" type="text" />
        </div>
        <FormSection name="configuration">
          {configurationTemplate &&
            reject(configurationTemplate, (item) => item.hidden).map(
              (template, index) => {
                if (template.kind === 'checkbox') {
                  return (
                    <IntegrationsCheckboxTemplate
                      template={template}
                      key={index}
                      forCreation={forCreation}
                    />
                  );
                }
                if (template.kind === 'text_field') {
                  return (
                    <IntegrationsTextTemplate
                      template={template}
                      key={index}
                      forCreation={forCreation}
                    />
                  );
                }
              },
            )}
        </FormSection>
      </div>
      <div
        className={classNames('uk-flex mt-20 mb-20', {
          'uk-flex-between': deletable,
          'uk-flex-center': !deletable,
        })}
      >
        {deletable && (
          <DeleteIntegrationButton
            integration={initialValues}
            onDeleted={onDeleted}
          />
        )}
        <Button
          primary
          onClick={handleSubmit}
          loading={submitting}
          type="submit"
          data-purpose="submit_integration_button"
        >
          {forCreation
            ? t(
                'distributor.components.integrations.integration_form.save_integration',
              )
            : t(
                'distributor.components.integrations.integration_form.update_integration',
              )}
        </Button>
      </div>
    </form>
  );
};

IntegrationForm.defaultProps = {
  deletable: true,
};

IntegrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  deletable: PropTypes.bool,
  onDeleted: PropTypes.func.isRequired,
  configurationTemplate: PropTypes.object.isRequired,
  forCreation: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'integration_form',
})(IntegrationForm);
