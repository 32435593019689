import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import { getStripePlan }              from 'models/stripe_plans/requests';
import Loader                         from 'components/ui/loader';
import NumberService                  from 'services/number';
import SmallItalic                    from 'components/ui/text/small_italic';

const SubscriptionResume = ({ planId }) => {
  const [plan, setPlan] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    getStripePlan(planId).then((response) => {
      setPlan(response.data.plan);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [planId]);

  return (
    <React.Fragment>
      { loading && <Loader /> }
      { !loading && plan && (
        <React.Fragment>
          <p>
            { t('components.subscription.subscription_resume.plan', { plan_name: plan.name }) }
            { ' - ' }
            <strong>{ `${NumberService.formatCurrency(plan.amount, '€')} /${plan.interval_count !== 1 ? ` ${plan.interval_count}` : ''} ${t(`components.subscription.subscription_resume.interval_${plan.interval}`)}` }</strong>
            { ' ' }
            { t('components.subscription.subscription_resume.vat_included') }
          </p>
          <div className="mb-20">
            <SmallItalic>{ t_html(`components.subscription.subscription_resume.subcription_text_${plan.interval}`) }</SmallItalic>
          </div>
        </React.Fragment>
      ) }
    </React.Fragment>
  );
};

SubscriptionResume.propTypes = {
  planId: PropTypes.string.isRequired,
};

export default SubscriptionResume;
