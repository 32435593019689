import Api                                from 'services/api';
import Alert                              from 'services/alert';
import { prepareErrorsForReduxForm }      from 'services/rails';
import merge                              from 'lodash/merge';
import { getAuthorizationToken }          from 'services/authorization_token';
import { ASSETS_DOMAIN, ASSETS_PROTOCOL } from 'constants/application_constants';
import { openLinkOrDownloadFile }         from 'services/file';

const handleTarificationError = (error, reject, defaultMessage) => {
  if (!error) {
    return reject();
  }
  if (error.error_message) {
    Alert.error(error.error_message);
    return reject();
  } else {
    //Alert.error(defaultMessage);
    return reject({ tarification: prepareErrorsForReduxForm(error.errors) });
  }
};

export const deleteTarification = tarification => (
  new Promise((resolve, reject) => {
    Api.delete(`tarifications/${ tarification.id }`).then(() => {
      Alert.success(t('provider.tarification.delete.success'));
      resolve();
    }).catch((error) => {
      Alert.error(t('provider.tarification.delete.error'));
      reject(error);
    });
  })
);

export const updateTarification = (tarification, otherParams = {}) => (
  new Promise((resolve, reject) => {
    Api.put(`tarifications/${ tarification.id }`, merge({
      tarification,
    }, otherParams)).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.save.error', { count: 1 }));
    });
  })
);

export const helpTarification = (tarificationId, message) => {
  return new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarificationId }/help`, { message }).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.help.error'));
    });
  });
};

export const sendToBackoffice = (tarificationId, message) => {
  return new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarificationId }/backoffice`, { message }).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('tarifications.backoffice.send_error'));
    });
  });
};

export const resetTarification = (tarificationId) => {
  return new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarificationId }/reset`).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.reset.error'));
    });
  });
};

export const recomputeTarification = (tarificationId) => (
  new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarificationId }/recompute`).then((response) => {
      Alert.success(t('provider.tarification.saving.success'));
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.saving.error'));
    });
  })
);

export const updateTarificationOffer = (tarificationId, data) => (
  new Promise((resolve, reject) => {
    Api.put(`tarifications/${ tarificationId }/offer`, {
      offer: {
        data,
      },
    }).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.update.error'));
    });
  })
);

export const updateTarificationContract = (tarificationId, data) => (
  new Promise((resolve, reject) => {
    Api.put(`tarifications/${ tarificationId }/contract`, {
      contract: {
        data,
      },
    }).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.update.error'));
    });
  })
);

export const sendTarificationOffer = (tarification) => (
  new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarification.id }/offer/send`).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.sync.error'));
    });
  })
);

export const sendTarificationContract = (tarification) => (
  new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarification.id }/contract/send`).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.sync.error'));
    });
  })
);

export const saveQuote = (tarification) => (
  new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarification.id }/save_quote`, {
      tarification,
    }).then((response) => {
      resolve(response.data.tarification);
    }).catch((response) => {
      handleTarificationError(response.data, reject, t('provider.tarification.sync.error'));
    });
  })
);

export const getTarificationsDocx = (contractRequestId, tarifications) => {
  return new Promise((resolve, reject) => {
    const ids = tarifications.map((tarification) => tarification.id).join(',');
    const path = `${ ASSETS_PROTOCOL }://${ ASSETS_DOMAIN }/docx/contract_requests/${ contractRequestId }/tarifications.docx?ids=${ ids }&authorization_token=${ getAuthorizationToken() }`;
    resolve(openLinkOrDownloadFile(path, 'template.docx'));
  });
};

export const getTarificationsPdf = (contractRequestId, documents = [], tarifications = []) => {
  const ids = [...tarifications.map((tarif) => `tarif-${ tarif.id }`), ...documents.map((doc) => doc.id)];
  const path = `${ ASSETS_PROTOCOL }://${ ASSETS_DOMAIN }/pdfs/contract_requests/${ contractRequestId }/merge.pdf?ids=${ ids.join(',') }&authorization_token=${ getAuthorizationToken() }`;
  return openLinkOrDownloadFile(path);
};

export const fetchTarification = (tarification_id) => {
  return new Promise((resolve, reject) => {
    Api.get(`tarifications/${ tarification_id }`,
    ).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('provider.tarification.fetch_tarification_error'));
      reject(error);
    });
  });
};
