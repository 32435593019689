import React     from 'react';
import PropTypes from 'prop-types';

const openInDrawer = () => Component => {
  class OpenInDrawer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        open: props.open,
      };
      this.openDrawer = this.openDrawer.bind(this);
      this.onDrawerClose = this.onDrawerClose.bind(this);
      this.mounted = false;
    }

    componentDidMount() {
      const { open, fixedBottom } = this.props;
      this.mounted = true;
      if (open) {
        this.openDrawer();
      }

      if (fixedBottom) {
        $('.drawer-inner').addClass('has-fixed-bottom');
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    onDrawerClose() {
      const { onClose } = this.props;
      if (onClose) {
        onClose();
      }
      if (!this.mounted) {
        return;
      }
      this.setState({
        open: false,
      }, window.closeDrawer);
    }

    openDrawer(ev) {
      if (ev) {
        ev.preventDefault();
      }
      const { children, orientation, className, onDrawerOpenStart, fixedBottom, ...restProps } = this.props;
      if (onDrawerOpenStart) {
        onDrawerOpenStart();
      }
      this.setState({
        open: true,
      });
      window.openInDrawer(
        <div className="pt-20 pb-20">
          <Component { ...restProps } closeDrawer={ window.closeDrawer } />
        </div>,
        {
          orientation,
          className,
        },
        this.onDrawerClose,
      );
    }

    render() {
      const { open } = this.state;
      const { children } = this.props;
      if (!children) {
        return <React.Fragment />;
      }
      return (
        <React.Fragment>
          { React.cloneElement(children, {
            onClick:   this.openDrawer,
            className: `${ children.props && children.props.className } ${ open ? 'drawer-open' : '' }`,
          }) }
        </React.Fragment>
      );
    }
  }

  OpenInDrawer.defaultProps = {
    orientation:       'left',
    className:         null,
    open:              false,
    onDrawerOpenStart: null,
    fixedBottom:       false,
  };

  OpenInDrawer.propTypes = {
    orientation:       PropTypes.oneOf(['left', 'right']),
    className:         PropTypes.string,
    open:              PropTypes.bool,
    onDrawerOpenStart: PropTypes.func,
    fixedBottom:       PropTypes.bool,
  };

  return OpenInDrawer;
};

export default openInDrawer;
