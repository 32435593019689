import React                         from 'react';
import PropTypes                     from 'prop-types';
import { Field }                     from 'redux-form';
import map                           from 'lodash/map';
import { getCurrentLocale }          from 'services/i18n';
import SelectInput                   from 'components/ui/form/select_input';
import RadioInputList                from 'components/ui/form/radio_input_list';
import InputLabel                    from 'components/ui/form/input_label';
import TextInput                     from 'components/ui/form/text_input';
import TarificationFormOptionsDialog from 'components/tarifications/form/tarification_form_options_dialog';
import TarificationFormVehicleProtectionDialog
                                     from 'components/tarifications/form/tarification_form_vehicle_protection_dialog';
import CoverMoreInDialog             from 'components/tarifications/cover_more_dialog';

const TarificationFormOptionItem = ({
  optionObject,
  optionConfig,
  index,
  option,
  disabled,
  optionChanged,
  form,
}) => {
  const locale = getCurrentLocale();
  const fieldType = optionConfig.field_type || 'select';
  const selectableListValues = map(optionConfig.values, (value) => {
    return {
      ...value,
      value: value.value,
      label: value.label[locale] ? value.label[locale] : value.label,
    };
  });
  return (
    <div key={ `option-${ optionObject.id }-${ index }` } className="tarification-form-option">
      <Field
        name={ `${ option }.id` }
        id={ `${ option }.id` }
        type="hidden"
        component="input"
        value={ optionObject.id }
      />
      <React.Fragment>
        <div className="option-label-field">
          { optionConfig.display_label && fieldType !== 'title' && (
            <p className="option-label mr-10 mb-0">
              <InputLabel className="mb-0" htmlFor={ `${ option }.id` }>
                { optionConfig.label[locale] ? optionConfig.label[locale] : optionConfig.label }
              </InputLabel>
              { optionConfig.help_text && (
                <CoverMoreInDialog
                  title={ optionConfig.label[locale] ? optionConfig.label[locale] : optionConfig.label }
                  description={ [optionConfig.help_text] }
                />
              ) }
            </p>
          ) }
          {
            fieldType === 'title' && (
              <h3 className="mb-0">{ optionConfig.label[locale] ? optionConfig.label[locale] : optionConfig.label }</h3>
            )
          }
          {
            fieldType === 'separator' && (
              <div className="mt-20"></div>
            )
          }
          {
            fieldType === 'select' && (
              <Field
                component={ SelectInput }
                disabled={ disabled || (optionConfig.values.length === 1 && optionConfig.values[0].value === optionObject.value) }
                list={ selectableListValues }
                name={ `${ option }.value` }
                isClearable={ false }
                onChange={ optionChanged }
              />
            )
          }
          {
            fieldType === 'text' && (
              <Field
                component={ TextInput }
                disabled={ disabled }
                name={ `${ option }.value` }
                onChange={ optionChanged }
              />
            )
          }
          {
            fieldType === 'radio' && (
              <Field
                component={ RadioInputList }
                disabled={ disabled }
                list={ selectableListValues }
                name={ `${ option }.value` }
                onChange={ optionChanged }
              />
            )
          }
          {
            fieldType === 'modal_select' && (
              <TarificationFormOptionsDialog
                form={ form }
                key={ Date.now() }
                values={ selectableListValues }
                optionConfig={ optionConfig }
                name={ `${ option }.value` }
                disabled={ disabled || (optionConfig.values.length === 1 && optionConfig.values[0].value === optionObject.value) }
                onChange={ optionChanged }
              />
            )
          }
          {
            fieldType === 'vehicle_protection_select' && (
              <TarificationFormVehicleProtectionDialog
                form={ form }
                key={ Date.now() }
                values={ selectableListValues }
                optionConfig={ optionConfig }
                name={ `${ option }.value` }
                disabled={ disabled || (optionConfig.values.length === 1 && optionConfig.values[0].value === optionObject.value) }
                onChange={ optionChanged }
              />
            )
          }
        </div>
      </React.Fragment>
    </div>
  );
};

TarificationFormOptionItem.defaultProps = {
  selectedOption: undefined,
  disabled:       false,
};

TarificationFormOptionItem.propTypes = {
  optionObject:   PropTypes.any.isRequired,
  optionConfig:   PropTypes.any.isRequired,
  index:          PropTypes.any.isRequired,
  option:         PropTypes.any.isRequired,
  disabled:       PropTypes.any,
  optionChanged:  PropTypes.any.isRequired,
  selectedOption: PropTypes.any,
};

export default TarificationFormOptionItem;
