import React       from 'react';
import PropTypes   from 'prop-types';
import IconSuccess from 'components/ui/icon/icon_success';
import IconError   from 'components/ui/icon/icon_error';

const IconErrorSuccess = ({ value, ...restProps }) => {
  return value ? <IconSuccess { ...restProps } /> : <IconError { ...restProps } />;
};

IconErrorSuccess.propTypes = {
  value: PropTypes.any,
};

export default IconErrorSuccess;
