import React, { useState }      from 'react';
import {
  blur,
  Field,
  FieldArray,
}                               from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import compact                  from 'lodash/compact';
import classNames               from 'classnames';
import NumberService            from 'services/number';
import { getCurrentLocale }     from 'services/i18n';
import SymbolTextInput          from 'components/ui/form/symbol_text_input';
import TarificationFormOptions  from 'components/tarifications/form/tarification_form_options';
import CoverDisabled            from 'components/tarifications/form/cover_disabled';
import CheckboxInput            from 'components/ui/form/checkbox_input';
import { maxLength3 }           from 'models/application/constants';
import CoverMoreInDialog        from 'components/tarifications/cover_more_dialog';

const getMax = (coverObject, maxFor) => {
  const {
          handle_derogation,
        } = coverObject;
  if (!handle_derogation || coverObject[maxFor] === 0) {
    return null;
  }
  return (val) => {
    if (val > coverObject[maxFor]) {
      return `Max ${ coverObject[maxFor] }%`;
    }
    return null;
  };
};

const TarificationFormCover = (props) => {
  const { cover, coverObject, tarification, form, vehicleProtectionAvailable, disabled } = props;
  const isSelected = coverObject.selected;
  const disableCover = (coverObject.category === 'vehicle_protection' && !vehicleProtectionAvailable) && !isSelected && tarification.product.multi_tarif;
  const showCoverWarning = (coverObject.category === 'vehicle_protection' && !vehicleProtectionAvailable) && !isSelected && tarification.product.multi_tarif;
  const [totalDero, setTotalDero] = useState(null);
  const dispatch = useDispatch();

  const computeTotalDerogation = (dero, other, validate = false, defaultOther) => {
    if (!validate) {
      const defaultO = defaultOther ? parseInt(defaultOther) : 0;
      const d = dero ? parseInt(dero) : 0;
      const o = other ? parseInt(other) : defaultO || 0;
      dispatch(blur(form, `${ cover }.derogation_percentage`));
      dispatch(blur(form, `${ cover }.additional_derogation_percentage`));
      return setTotalDero(d + o);
    }
    if (!dero || !other) {
      return null;
    }
    return (parseFloat(other) + parseFloat(dero)) <= 100 ? null : t('wizard.tarifications.derogation_over_100');
  };
  return (
    <React.Fragment>
      <tr key={ `cover-${ coverObject.id }` } style={ { verticalAlign: 'top' } }>
        <td style={ { width: '50px' } }>
          <Field name={ `${ cover }.id` } type="hidden" component="input" value={ coverObject.id } />
          <Field
            name={ `${ cover }.selected` }
            id={ `${ cover }.selected` }
            component={ CheckboxInput }
            disabled={ disabled || (disableCover || coverObject.disabled || coverObject.mandatory) }
          />
        </td>
        <td className="uk-width-1-1">
          <label
            htmlFor={ `${ cover }.selected` }
            className="text-icon mb-10"
          >
            { coverObject.name }
            { coverObject.extra_information && coverObject.extra_information.length > 0 && (
              <CoverMoreInDialog description={ coverObject.extra_information } title={ coverObject.name } />
            ) }
          </label>
          { coverObject.warnings && coverObject.warnings.length > 0 && (
            <span className="small-italic">
              { coverObject.warnings.map((warning, index) => (
                <React.Fragment key={ `warnings-${ index }` }>
                  { warning[getCurrentLocale()] }
                  <br />
                </React.Fragment>
              )) }
            </span>
          ) }
          { coverObject.errors && coverObject.errors.length > 0 && (
            <p className="uk-alert-danger p-10">
              { coverObject.errors.map((warn) => warn[getCurrentLocale()]).join(', ') }
            </p>
          ) }
          { isSelected && (
            <React.Fragment>
              { !disableCover && (
                <FieldArray
                  name={ `${ cover }.selected_options` }
                  form={ form }
                  component={ TarificationFormOptions }
                  disabled={ disabled || showCoverWarning }
                  optionsConfiguration={ coverObject.options_configuration }
                  tarification={ tarification }
                  cover={ cover }
                />
              ) }
            </React.Fragment>
          ) }
          { showCoverWarning && (
            <CoverDisabled cover={ coverObject.category } />
          ) }
        </td>
        { !coverObject.hide_derogation && (
          <td>
            { isSelected && coverObject.annual_price && (
              <React.Fragment>
                <Field
                  type="number"
                  name={ `${ cover }.derogation_percentage` }
                  symbol="%"
                  component={ SymbolTextInput }
                  noClass
                  step={ 1 }
                  onValueChange={ (dero) => computeTotalDerogation(dero, coverObject.additional_derogation_percentage, false, coverObject.max_additional_derogation_percentage) }
                  validate={ compact([
                    maxLength3,
                    getMax(coverObject, 'max_derogation_percentage'),
                  ]) }
                  disabled={ disableCover || !coverObject.handle_derogation || !isSelected }
                  placeholder={ coverObject.derogation_percentage === null || coverObject.derogation_percentage === '' ? coverObject.max_derogation_percentage : '' }
                />
                <Field type="hidden" name={ `${ cover }.max_derogation_percentage` } component="input" />
                { coverObject.handle_derogation && coverObject.max_derogation_percentage > 0
                && (
                  <span className="small-italic">
                    { `Max ${ coverObject.max_derogation_percentage } %` }
                  </span>
                ) }
              </React.Fragment>
            ) }
          </td>
        ) }
        { !coverObject.hide_additional_derogation && (
          <td>
            { isSelected && coverObject.annual_price && (
              <React.Fragment>
                <Field
                  type="number"
                  name={ `${ cover }.additional_derogation_percentage` }
                  symbol="%"
                  component={ SymbolTextInput }
                  noClass
                  step={ 1 }
                  onValueChange={ (dero) => computeTotalDerogation(dero, coverObject.derogation_percentage, false, coverObject.max_derogation_percentage) }
                  validate={ compact([(dero) => computeTotalDerogation(dero, coverObject.derogation_percentage, true), maxLength3, getMax(coverObject, 'max_additional_derogation_percentage')]) }
                  disabled={ disableCover || !coverObject.handle_additional_derogation || !isSelected }
                  placeholder={ coverObject.additional_derogation_percentage === null || coverObject.additional_derogation_percentage === '' ? coverObject.max_additional_derogation_percentage : '' }
                />
                { totalDero > 0 && coverObject.additional_derogation_percentage > 0 && (
                  <span className="small-italic">
                    { t('tarifications.covers.dero_total') }
                    { ': ' }
                    <strong>{ `${ totalDero } %` }</strong>
                  </span>
                ) }
                { coverObject.handle_additional_derogation && coverObject.max_additional_derogation_percentage > 0 && (
                  <span className="small-italic">
                    { `Max ${ coverObject.max_additional_derogation_percentage } %` }
                  </span>
                ) }
              </React.Fragment>
            ) }
          </td>
        ) }
        { !coverObject.hide_commission && (
          <td>
            { isSelected && coverObject.annual_price && (
              <React.Fragment>
                <Field
                  type="number"
                  name={ `${ cover }.commission_percentage` }
                  symbol="%"
                  component={ SymbolTextInput }
                  noClass
                  disabled={ disableCover || !coverObject.handle_commission || !isSelected }
                  placeholder={ coverObject.commission_percentage === null || coverObject.commission_percentage === '' ? coverObject.max_commission_percentage : '' }
                  validate={ compact([getMax(coverObject, 'max_commission_percentage')]) }
                />
                <Field type="hidden" name={ `${ cover }.max_commission_percentage` } component="input" />
                { coverObject.handle_commission && coverObject.max_commission_percentage > 0
                && (
                  <span className="small-italic">
                    { `Max ${ coverObject.max_commission_percentage } %` }
                  </span>
                ) }
              </React.Fragment>
            ) }
          </td>
        ) }
        <td
          className={ classNames('cover-price v-top uk-text-bold', {
            'price--unselected': disableCover || !isSelected,
          }) }
        >
          { isSelected && coverObject.annual_price && (
            <React.Fragment>
              { !coverObject.free && NumberService.formatCurrency(parseFloat(coverObject.annual_price), '€') }
              { coverObject.free && t('tarifications.covers.included') }
            </React.Fragment>
          ) }
        </td>
      </tr>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    vehicleProtectionAvailable: state.wizard.contract_request.selected_cover_categories && state.wizard.contract_request.selected_cover_categories.includes('vehicle_protection'),
  };
};

export default connect(mapStateToProps)(TarificationFormCover);
