import React, { useState } from 'react';
import snakeCase           from 'lodash/snakeCase';
import classNames          from 'classnames';
import has                 from 'lodash/has';
import Icon                from 'components/ui/icon';
import withTooltip         from 'components/ui/tooltip/with_tooltip';
import Loader              from 'components/ui/loader';

const tooltipText = {
  'coming_soon':   'wizard.products.coming_soon',
  'down':          'wizard.products.unavailable',
  'with_discount': 'wizard.products.with_discount',
};

const productStatusIcon = {
  'coming_soon':   'coming-soon',
  'down':          'uncheck-circle',
  'with_discount': 'discount',
};


const Item = ({ product, isSelected, onClick, comingSoon, down, title, riskObjectIcon, loading }) => {
  const icon = productStatusIcon[product.status];
  const logoUrl = has(product, 'logo.normal.url') && product.logo.normal.url;
  return (
    <div
      role="select-product"
      data-purpose={ `product_${ snakeCase(product.display_name) }` }
      className={ classNames(`product-card-item ${ product.status }`, {
        'selected': isSelected,
        'disabled': (comingSoon || down),
        'loading':  loading,
      }) }
      onClick={ onClick }
      title={ title }
    >
      { icon && (
        <span className="icon-container">
          <Icon icon={ icon } />
        </span>
      ) }
      { isSelected && (
        <Icon className="icon-selected" icon="check" />
      ) }
      { product.insurance_company.logo && !riskObjectIcon && (
        <img
          src={ product.insurance_company.logo.big.url }
          alt={ `${ product.insurance_company.name } - ${ product.name }` }
          width="80px"
        />
      ) }
      { riskObjectIcon && !logoUrl && (
        <Icon
          className="product-card-icon"
          icon={ riskObjectIcon }
        />
      ) }
      { logoUrl && (
        <img src={ logoUrl } alt={ product.name } />
      ) }
      <div className="product-card-name">
        { product.display_name }
      </div>
      { loading && (
        <div className="loader-container">
          <Loader />
        </div>
      ) }
    </div>
  );
};

const WithTooltipProduct = withTooltip('bottom-center')(Item);

const ProductItem = ({ product, isSelected, selectProduct, riskObjectIcon }) => {
  const comingSoon = product.status === 'coming_soon';
  const down = product.status === 'down';
  const tooltipItem = tooltipText[product.status];
  const [loading, setLoading] = useState(false);
  const onItemClick = async (ev) => {
    if (loading) {
      return;
    }
    if (comingSoon || down) {
      return;
    }
    ev.preventDefault();
    setLoading(true);
    try {
      await selectProduct(product, 'toggle');
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <Item
      product={ product }
      isSelected={ isSelected ? isSelected(product) : false }
      onClick={ onItemClick }
      comingSoon={ comingSoon }
      down={ down }
      title={ tooltipItem ? t(tooltipItem) : '' }
      riskObjectIcon={ riskObjectIcon }
      loading={ loading }
    />
  );
};

export default ProductItem;

