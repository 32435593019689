import React                 from 'react';
import { deleteIntegration } from 'distributor/models/integrations/requests';
import confirmBeforeAction   from 'components/ui/buttons/helpers/confirm_before_action';
import Button                from 'components/ui/buttons/button';

const ConfirmButton = confirmBeforeAction()(Button);

const DeleteIntegrationButton = ({ integration, onDeleted }) => {
  const onDelete = () => {
    return deleteIntegration(integration).then(() => {
      if (onDeleted) {
        onDeleted(integration);
      }
    });
  };

  return (
    <ConfirmButton
      onClick={ onDelete }
      icon="delete"
      warning
    >
      { t('distributor.components.integrations.delete_integration_button.delete_integration') }
    </ConfirmButton>
  );
};

export default DeleteIntegrationButton;
