import React            from 'react';
import Loader           from 'components/ui/loader';
import ProductErrorList from 'components/product/product_error_list';
import Title            from 'components/ui/titles';

const TarificationErrors = ({ tarification, title }) => {
  const { last_error_response, computing } = tarification;
  if (!last_error_response) {
    return null;
  }
  if (computing) {
    return <Loader />;
  }
  if (last_error_response.errors) {
    return (
      <React.Fragment>
        <Title size="h3" className="warning">{ title }</Title>
        <div className="uk-alert-danger p-20">
          <ProductErrorList errors={ last_error_response.errors } />
        </div>
      </React.Fragment>
    );
  }
  if (last_error_response.message) {
    return (
      <React.Fragment>
        <Title size="h3" className="warning">{ title }</Title>
        <div className="uk-alert-warning p-20 pb-5 mt-20 mb-20">
          { (last_error_response.message instanceof Array ? last_error_response.message : last_error_response.message.split('\n')).map((line) => (<p>{ line }</p>)) }
        </div>
      </React.Fragment>
    );
  }
};

export default TarificationErrors;
