import React  from 'react';
import moment from 'moment';

const SentToBackofficeMessage = ({ tarification, contractRequest }) => {
  if (!tarification.sent_to_backoffice || contractRequest.status === 'returned_from_backoffice') {
    return null;
  }
  return (
    <div className="p-20 uk-alert mt-20" data-purpose="sent_to_backoffice_message">
      <p>
        { t('wizard.tarifications.sent_to_backoffice_message', { date: moment(tarification.sent_to_backoffice_at).format('DD/MM/YYYY - HH:mm') }) }
      </p>
      <p>{ t('wizard.tarifications.sent_backoffice_message') }</p>
      <div className="quote-container">
        <blockquote className="quote mb-0">
          { tarification.backoffice_message }
        </blockquote>
      </div>
    </div>
  );
};

export default SentToBackofficeMessage;
