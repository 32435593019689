import React, { useEffect }         from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isNil                        from 'lodash/isNil';
import {
  PRODUCT_CONFIGURATION_STEP,
  trackWizardStep,
}                                   from 'models/wizard/constants';
import {
  setPollingForTarifications,
  updateWizardTarification,
}                                   from 'models/wizard/dispatch_actions';
import asWizardStep                 from 'components/wizard_stepper/as_wizard_step';
import TarificationForm             from 'components/tarifications/form/tarification_form';
import { updateTarification }       from 'models/wizard/store_actions';
import { isSocketConnected }        from 'models/config/reducer';
import TarificationErrors           from 'components/tarifications/tarification_errors';
import TarificationActions          from 'components/tarifications/tarification_actions';
import SentToBackofficeMessage      from 'components/backoffice/sent_to_backoffice_message';

const TarificationStep = (props) => {
  const dispatch = useDispatch();
  const {
          setNextAction,
          tarificationId,
          nextStep,
          toggleButton,
          disabled,
          formComponent,
        } = props;

  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const tarification = useSelector((state) => state.wizard.contract_request.tarifications.find((tarif) => tarif.id === tarificationId));
  const isSocketUp = useSelector((state) => isSocketConnected(state));

  const refreshTarification = (tarifToUpdate) => {
    dispatch(updateTarification({
      ...tarifToUpdate,
      computing:  true,
      updated_at: Date.now(),
    }));
    trackWizardStep(PRODUCT_CONFIGURATION_STEP, {
      contract_request_id: contractRequest.id,
      product_id:          tarification.product.id,
      product_name:        tarification.product.name,
    });
    return dispatch(updateWizardTarification(tarifToUpdate)).then(() => {
      if (!isSocketUp) {
        dispatch(setPollingForTarifications([tarifToUpdate]));
      }
    });
  };

  useEffect(() => {
    setNextAction(() => {
      return trackWizardStep(PRODUCT_CONFIGURATION_STEP, {
        contract_request_id: contractRequest.id,
        product_name:        tarification.product.name,
        product_id:          tarification.product.id,
      }).then(nextStep);
    });
  }, []);

  const { last_error_response } = tarification;

  const toggleNext = (disabled) => {
    toggleButton('next', disabled);
  };

  const FormComponent = isNil(formComponent) ? TarificationForm : formComponent;

  return (
    <React.Fragment>
      { props.subtitle && (
        <h3 className="uk-text-center">{ props.subtitle }</h3>
      ) }
      { last_error_response && (
        <TarificationErrors tarification={ tarification } />
      ) }
      { !last_error_response && contractRequest.status !== 'backoffice' && (
        <FormComponent
          form={ `tarification_form_${ tarification.id }` }
          initialValues={ tarification }
          toggleNext={ toggleNext }
          onSubmit={ refreshTarification }
          disabled={ !tarification.authorizations.update || disabled }
        />
      ) }
      <SentToBackofficeMessage tarification={ tarification } contractRequest={ contractRequest } />
      <TarificationActions
        tarification={ tarification }
        formName={ `tarification_form_${ tarification.id }` }
      />
    </React.Fragment>
  );
};

export default asWizardStep()(TarificationStep);
