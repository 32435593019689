import React             from 'react';
import {
  reduxForm,
  Field,
  FormSection,
}                        from 'redux-form';
import SubmitButton      from 'components/ui/form/submit_button';
import TextInput         from 'components/ui/form/text_input';
import Margin            from 'components/ui/margin';
import InputLabel        from 'components/ui/form/input_label';
import Icon              from 'components/ui/icon';
import LoadOnClickButton from 'components/ui/buttons/load_on_click_button';
import { deleteOffice }  from 'models/offices/requests';
import AddressForm       from 'components/address/address_form';
import CheckboxInput     from 'components/ui/form/checkbox_input';

const OfficeAddressForm = ({ handleSubmit, submitting, change, initialValues, onDeleted }) => {
  return (
    <form onSubmit={ handleSubmit } data-purpose="office_address_form">
      <Margin>
        <InputLabel htmlFor="alias">
          { t('providers.account.addresses.address_name') }
        </InputLabel>
        <Field
          name="alias"
          id="alias"
          type="text"
          required
          component={ TextInput }
        />
      </Margin>
      <Margin>
        <InputLabel htmlFor="phone_number">
          { t('providers.public_page.phone_number_placeholder') }
        </InputLabel>
        <Field
          name="phone_number"
          id="phone_number"
          type="text"
          component={ TextInput }
        />
      </Margin>
      <Margin>
        <InputLabel htmlFor="email">
          { t('providers.public_page.email_address') }
        </InputLabel>
        <Field
          name="email"
          id="email"
          type="email"
          component={ TextInput }
        />
      </Margin>
      <Margin>
        <InputLabel htmlFor="fsma_number">
          { t('providers.public_page.fsma_number') }
        </InputLabel>
        <Field
          name="fsma_number"
          id="fsma_number"
          type="text"
          component={ TextInput }
        />
      </Margin>
      { !initialValues.primary && (
        <Margin>
          <InputLabel htmlFor="alias">
            { t('providers.account.addresses.primary_address') }
          </InputLabel>
          <Field
            name="primary"
            id="alias"
            type="text"
            component={ CheckboxInput }
          />
        </Margin>
      ) }
      <FormSection name="address">
        <AddressForm
          requiredFields={ {
            street_name:   true,
            street_number: true,
            city:          true,
            street_box:    true,
            zip_code:      true,
          } }
          change={ change }
          inline={ false }
          formFor="office_address"
          form="office_address"
        />
      </FormSection>
      <SubmitButton
        className="mt-20"
        loading={ submitting }
        data-purpose="office_address_form_submit_button"
      />
      <LoadOnClickButton
        warning
        className="icon-text"
        onClick={ () => deleteOffice(initialValues).then(() => onDeleted(initialValues)) }
      >
        <Icon icon="delete" />
        { t('general.actions.delete') }
      </LoadOnClickButton>
    </form>
  );
};

export default reduxForm({
  form: 'office_address',
})(OfficeAddressForm);
