import React from 'react';
import Button from 'components/ui/buttons/button';
import {
  PRODUCT_CONFIGURATION_STEP,
  trackWizardStep,
} from 'models/wizard/constants';
import {
  setPollingForTarifications,
  updateWizardTarification,
} from 'models/wizard/dispatch_actions';
import { updateTarification } from 'models/wizard/store_actions';
import { useDispatch, useSelector } from 'react-redux';
import { isSocketConnected } from 'models/config/reducer';
import { getFormValues } from 'redux-form';
import { getMoreRecent } from 'models/tarifications/constants';

const RecomputeTarificationButton = ({ tarification, formName, ...rest }) => {
  const dispatch = useDispatch();
  const tarificationValues = useSelector((state) =>
    getFormValues(formName)(state),
  );
  const tarifToRecompute = getMoreRecent([tarificationValues, tarification]);
  const isSocketUp = useSelector((state) => isSocketConnected(state));
  if (
    !tarifToRecompute.authorizations ||
    !tarifToRecompute.authorizations.recompute
  ) {
    return null;
  }
  const refreshTarification = () => {
    dispatch(
      updateTarification({
        ...tarifToRecompute,
        computing: true,
        updated_at: Date.now(),
      }),
    );
    trackWizardStep(PRODUCT_CONFIGURATION_STEP, {
      contract_request_id: tarifToRecompute.contract_request_id,
      product_id: tarifToRecompute.product.id,
      product_name: tarifToRecompute.product.name,
    });
    return dispatch(updateWizardTarification(tarifToRecompute, true)).then(
      () => {
        if (!isSocketUp) {
          dispatch(setPollingForTarifications([tarifToRecompute]));
        }
      },
    );
  };

  return (
    <Button
      primary
      loading={tarifToRecompute.computing}
      disabled={tarifToRecompute.computing}
      icon="refresh"
      {...rest}
      onClick={(ev) => {
        ev.preventDefault();
        refreshTarification();
      }}
    >
      {t('provider.tarification.actions.recompute')}
    </Button>
  );
};

export default RecomputeTarificationButton;
