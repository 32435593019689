export const updateIntercom = () => {
  if (window.Intercom !== undefined) {
    window.Intercom('update');
  }
};

export const newIntercomMessage = (message) => (
  new Promise((resolve) => {
    if (window.Intercom !== undefined) {
      window.Intercom('showNewMessage', message);
    }
    setTimeout(resolve, 200);
  })
);
