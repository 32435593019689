import React     from 'react';
import PropTypes from 'prop-types';

import VisaCardImage       from './images/credit-cards/visa.png';
import AmexCardImage       from './images/credit-cards/amex.png';
import DinersCardImage     from './images/credit-cards/diners.png';
import DiscoverCardImage   from './images/credit-cards/discover.png';
import JcbCardImage        from './images/credit-cards/jcb.png';
import MastercardCardImage from './images/credit-cards/mastercard.png';
import DefaultImage        from './images/credit-cards/default.png';

const CreditCardLogo = ({ brand, width }) => {
  const formattedBrand = brand ? brand.toLowerCase() : '';

  const cardLogoSrc = () => {
    switch (formattedBrand) {
      case 'visa':
        return VisaCardImage;
      case 'amex':
        return AmexCardImage;
      case 'american express':
        return AmexCardImage;
      case 'diners':
        return DinersCardImage;
      case 'discover':
        return DiscoverCardImage;
      case 'jcb':
        return JcbCardImage;
      case 'mastercard':
        return MastercardCardImage;
      default:
        return DefaultImage;
    }
  };

  return (
    <img src={ cardLogoSrc() } alt={ brand } width={ width } />
  );
};

CreditCardLogo.defaultProps = {
  width: '50px',
  brand: null,
};

CreditCardLogo.propTypes = {
  width: PropTypes.string,
  brand: PropTypes.string,
};

export default CreditCardLogo;
