import React from 'react';

const Box = ({ className, ...rest }) => (
  <div
    { ...rest }
    className={ `${ className } box` }
  />
);

export default Box;
