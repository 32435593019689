import React, { useContext }                      from 'react';
import Button                                     from 'components/ui/buttons/button';
import openDialogOnClick                          from 'components/ui/buttons/helpers/open_dialog_on_click';
import BackofficeDeviationForm                    from 'components/backoffice/backoffice_deviation_form';
import { sendToBackoffice }                       from 'models/tarifications/requests';
import { setContractRequest, updateTarification } from 'models/wizard/store_actions';
import { useDispatch }                            from 'react-redux';
import { fetchContractRequest }                   from 'models/contract_request/requests';
import WizardContext                              from 'components/wizard/wizard_context';
import { fetchContractRequestAndUpdateInStore }   from 'models/wizard/dispatch_actions';

const SendButton = ({ onClick, disabled, className }) => {
  return (
    <Button
      primary
      disabled={ disabled }
      className={ className }
      large
      data-purpose="send_to_backoffice_button"
      onClick={ () => {
        onClick();
      } }
      icon="assistance"
    >
      { t('wizard.tarifications.send_to_backoffice') }
    </Button>
  );
};
const OpenBackofficeFormButton = openDialogOnClick()(SendButton);

const SendToBackofficeButton = ({ disabled, className, tarification, removeStepAction = true }) => {
  const { toggleStepMainActionAvailable } = useContext(WizardContext);
  const dispatch = useDispatch();
  if (tarification.computing) {
    return null;
  }
  if (!tarification.authorizations || !tarification.authorizations.send_to_backoffice) {
    if (toggleStepMainActionAvailable && removeStepAction) {
      toggleStepMainActionAvailable(false);
    }
    return null;
  }
  const sendRequest = ({ message }) => {
    return sendToBackoffice(tarification.id, message).then((updatedTarification) => {
      dispatch(updateTarification(updatedTarification));
      dispatch(fetchContractRequestAndUpdateInStore(updatedTarification.contract_request_id));
    });
  };

  return (
    <OpenBackofficeFormButton
      disabled={ disabled }
      dialogTitle={ t('wizard.tarifications.request_detail') }
      className={ className }
    >
      <BackofficeDeviationForm onSubmit={ sendRequest } />
    </OpenBackofficeFormButton>
  );
};

export default SendToBackofficeButton;
