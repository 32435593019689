import React             from 'react';
import openDialogOnClick from 'components/ui/buttons/helpers/open_dialog_on_click';
import Button            from 'components/ui/buttons/button';

const OpenSyncAccessButton = ({ explanation, smart_link_name, ...rest }) => {
  if (!explanation) {
    return null;
  }
  const DialogButton = openDialogOnClick()(({ onClick }) => (
    <Button onClick={ onClick } { ...rest }>
      { t('wizard.tarifications.synchronisation_explanation', { smart_link_name }) }
    </Button>
  ));

  return (
    <DialogButton
      dialogTitle={ t('wizard.tarifications.synchronisation_explanation', { smart_link_name }) }
    >
      <div dangerouslySetInnerHTML={ { __html: explanation } } />
    </DialogButton>
  );
};

export default OpenSyncAccessButton;
