import React            from 'react';
import FormErrorMessage from 'components/ui/form/form_error_message';
import InlineContainer  from 'components/ui/form/inline_container';

const TextInput = ({
  input: {
           onChange,
           ...restInput
         },
  label,
  placeholder,
  type,
  meta = {},
  id,
  disabled,
  purpose,
  inline,
  warning,
  onInput,
  noClass,
  containerClass,
  formName,
  symbol,
  onValueChange,
  change,
  loading,
  InputAddition,
  ...    rest
}) => {
  const replaceCommaByDot = ev => (
    onChange(ev.target.value.replace(',', '.'))
  );
  const { asyncValidating, ...restMeta } = meta;
  const enhancedOnChange = type === 'number' ? replaceCommaByDot : onChange;
  const textInput = (
    <React.Fragment>
      <div className="input-container">
        { label && !inline && (
          <label htmlFor={ id }>{ label }</label>
        ) }
        <div className={ symbol && 'uk-inline' }>
          <input
            id={ id }
            className={ `uk-input uk-form-small ${ meta.touched && meta.error ? 'uk-form-danger' : '' } ${ meta.touched && meta.warning ? 'input--warning' : '' }` }
            onChange={ (ev) => {
              enhancedOnChange(ev);
              if (onInput) {
                onInput(ev.target.value);
              }
            } }
            { ...restInput }
            placeholder={ placeholder || label }
            type={ type }
            data-purpose={ purpose }
            disabled={ disabled || asyncValidating }
            min="0"
            { ...rest }
          />
          { symbol && (
            <span className="uk-form-icon uk-form-icon-flip">
            { symbol }
          </span>
          ) }
        </div>
        <FormErrorMessage { ...restMeta } />
      </div>
    </React.Fragment>
  );

  if (!inline) {
    return textInput;
  }

  return (
    <InlineContainer
      labelFor={ id }
      label={ label }
      warning={ warning }
      field={ textInput }
      meta={ meta }
      inlineContainerClass={ containerClass }
    />
  );
};

export default TextInput;
