import React                            from 'react';
import PropTypes                        from 'prop-types';
import { connect }                      from 'react-redux';
import $                                from 'jquery';
import CouponField                      from 'components/coupon_field';
import { redeemAccountCoupon } from 'models/coupons/requests';
import { getAccount }                   from 'models/current_account/requests';
import Button                           from 'components/ui/buttons/button';
import Box                              from 'components/ui/box';
import loadOnClick                      from 'components/ui/buttons/helpers/load_on_click';

const LoadOnClickButton = loadOnClick()(Button);

class AccountCouponsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitDisabled: true,
      coupon:         null,
    };
    this.onValidOrEmpty = this.onValidOrEmpty.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.redeemCoupon = this.redeemCoupon.bind(this);
  }

  componentDidMount() {
    $('#coupon_field').trigger('focus');
  }

  onValidOrEmpty(coupon) {
    this.setState({
      submitDisabled: !coupon,
      coupon,
    });
  }

  onInvalid() {
    this.setState({ submitDisabled: true });
  }

  redeemCoupon() {
    const { onSuccess, dispatch } = this.props;
    const { coupon } = this.state;
    return redeemAccountCoupon(coupon).then(() => {
      dispatch(getAccount({ with_stripe_info: true })).then(() => {
        onSuccess();
      });
    });
  }

  render() {
    const { submitDisabled } = this.state;
    return (
      <React.Fragment>
        <div className="mb-20">
          <CouponField onValidOrEmpty={ this.onValidOrEmpty } onInvalid={ this.onInvalid } />
        </div>
        <p className="uk-text-center">
          <LoadOnClickButton secondary onClick={ this.redeemCoupon } disabled={ submitDisabled }>
            { t('subscriptions.validate_coupon') }
          </LoadOnClickButton>
        </p>
      </React.Fragment>
    );
  }
}

AccountCouponsForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  dispatch:  PropTypes.func.isRequired,
};

export default connect()(AccountCouponsForm);
