import React from 'react';

const t_html = (text, params) => {
  const trusted = {
    __html: I18n.t(text, { ...params }),
  };
  return <span dangerouslySetInnerHTML={ trusted } />;
};

export default t_html;

