import React            from 'react';
import classNames       from 'classnames';
import Loader           from 'components/ui/loader';
import FormErrorMessage from 'components/ui/form/form_error_message';
import InlineContainer  from 'components/ui/form/inline_container';

const RadioInputList = ({
  input,
  list,
  loading,
  onSelected,
  meta,
  label,
  warning,
  inline,
  asButton,
  disabled
}) => {

  const radioInputList = (
    <React.Fragment>
      { !loading && (
        <div
          className={
            classNames('', {
              'uk-form-danger':                                  meta.touched && meta.error,
              'uk-list multi-column-list button-list m-0':       asButton,
              'field uk-grid-small uk-child-width-auto uk-grid': !asButton,
            })
          }
        >
          {
            list.map((item, id) => (
              <label
                htmlFor={ `${ input.name }_${ item.id || item.value }` }
                key={ id }
                className={ classNames('', {
                  'select-button': asButton,
                  'selected':      asButton && (`${ input.value }` === `${ item.value }`),
                }) }
              >
                <input
                  className="uk-radio mr-10"
                  type="radio"
                  value={ item.value }
                  name={ input.name }
                  id={ `${ input.name }_${ item.id || item.value }` }
                  checked={ `${ input.value }` === `${ item.value }` }
                  disabled={ disabled || item.disabled }
                  onChange={ (ev) => {
                    input.onChange(ev.target.value);
                    if (onSelected) {
                      onSelected(ev.target.value);
                    }
                  } }
                />
                { item.label }
              </label>
            ))
          }
          <FormErrorMessage { ...meta } />
        </div>
      ) }
      {
        loading && <Loader />
      }
    </React.Fragment>
  );

  if (!inline) {
    return radioInputList;
  }

  return (
    <InlineContainer
      warning={ warning }
      label={ label }
      meta={ meta }
      field={ radioInputList }
    />
  );

};

export default RadioInputList;
