import Api   from 'services/api';
import Alert from 'services/alert';

export const fetchIntermediaries = () => {
  return new Promise((resolve, reject) => {
    Api.get('account/intermediaries').then((response) => {
      resolve(response.data.intermediaries);
    }).catch((error) => {
      Alert.error(t('providers.intermediaries.fetch_intermediaries_error'));
      reject(error);
    });
  });
};

export const createIntermediary = (intermediary) => {
  return new Promise((resolve, reject) => {
    Api.post('account/intermediaries', { intermediary }).then((response) => {
      resolve(response.data.intermediary);
    }).catch((error) => {
      Alert.error(t('providers.intermediaries.create_intermediaries_error'));
      reject(error);
    });
  });
};

export const updateIntermediary = (intermediary) => {
  return new Promise((resolve, reject) => {
    Api.put(`account/intermediaries/${ intermediary.id }`, { intermediary }).then((response) => {
      resolve(response.data.intermediary);
    }).catch((error) => {
      Alert.error(t('providers.intermediaries.create_intermediaries_error'));
      reject(error);
    });
  });
};

export const deleteIntermediary = (intermediaryId) => {
  return new Promise((resolve, reject) => {
    Api.delete(`account/intermediaries/${ intermediaryId }`).then(() => {
      resolve();
    }).catch((error) => {
      Alert.error(t('providers.intermediaries.delete_intermediaries_error'));
      reject(error);
    });
  });
};
