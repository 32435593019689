import React                         from 'react';
import { SubmissionError }           from 'redux-form';
import { prepareErrorsForReduxForm } from 'services/rails';
import { getCurrentLocale }          from 'services/i18n';
import { userResetPassword }         from 'models/user/requests';
import OpenbrokerLogo                from 'images/open_broker_logo_dark.svg';
import UserPasswordResetForm         from 'components/user/password/reset/password_reset_form';
import LanguageSwitcher              from 'components/language_switcher/language_switcher';
import Button                        from 'components/ui/buttons/button';

class UserSignInContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sentEmail: null,
    };

    this.onPasswordResetSubmit = this.onPasswordResetSubmit.bind(this);
  }

  onPasswordResetSubmit(values) {
    return userResetPassword(values).then(() => {
      this.setState({
        sentEmail: values.email,
      });
    }).catch((error) => {
      throw new SubmissionError(prepareErrorsForReduxForm(error));
    });
  }

  render() {
    const { sentEmail } = this.state;

    return (
      <div className="account-container">
        <LanguageSwitcher />
        <div className="account-form">
          <p className="uk-text-center m-0">
            <img src={ OpenbrokerLogo } alt="Openbroker" className="logo" />
          </p>
          <h1 className="title">{ t('general.new_password.title') }</h1>
          <div className="explanation-text uk-text-center mb-20">
            { sentEmail ? t('general.password_reset.sent_email', { email: sentEmail }) : t('general.password_reset.explanation') }
          </div>
          { !sentEmail && (
            <React.Fragment>
              <UserPasswordResetForm onSubmit={ this.onPasswordResetSubmit } />
            </React.Fragment>
          ) }
          <p className="uk-text-center">
            <Button
              link
              secondary
              to={ `/${ getCurrentLocale() }/user/sign_in` }
            >
              { sentEmail ? t('general.actions.back') : t('general.button.cancel') }
            </Button>
          </p>
        </div>
      </div>
    );
  }
}

export default UserSignInContainer;
