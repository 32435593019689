import React                 from 'react';
import { Field }             from 'redux-form';
import WarningMessage        from 'components/ui/warning_message';
import PropTypes             from 'prop-types';

const ErrorMessageComponent = ({ meta: { touched, error, warning } }) => {
  const trusted = {
    __html: error,
  };
  return (
    <React.Fragment>
      {
        touched && (
          <React.Fragment>
            { error && (
              <WarningMessage>
                <span dangerouslySetInnerHTML={ trusted } />
              </WarningMessage>
            ) }
            { warning && (
              <WarningMessage>
                <span dangerouslySetInnerHTML={ trusted } />
              </WarningMessage>
            ) }
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
};

ErrorMessageComponent.defaultProps = {
  meta: {},
};

ErrorMessageComponent.propTypes = {
  meta: PropTypes.object,
};

const GlobalFormErrorMessage = () => (
  <Field name="base" component={ ErrorMessageComponent } />
);

export default GlobalFormErrorMessage;
