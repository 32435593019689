import React, { useState, useEffect }                                    from 'react';
import { useDispatch, useSelector }                                      from 'react-redux';
import find                                                              from 'lodash/find';
import { getProducts }                                                   from 'models/product/requests';
import Loader                                                            from 'components/ui/loader';
import asWizardStep
                                                                         from 'components/wizard_stepper/as_wizard_step';
import {
  initOrUpdateContractRequest,
}                                                                        from 'models/wizard/dispatch_actions';
import ProductEmptyState                                                 from 'components/product/product_empty_state';
import { getProductsFromContractRequest, PRODUCT_STEP, trackWizardStep } from 'models/wizard/constants';
import ProductItem                                                       from 'components/product/product_item';
import { useHistory, useLocation }                                       from 'react-router';

const ProductStep = ({ setNextAction, toggleButton, nextStep }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedProductsForContractRequest = useSelector((state) => getProductsFromContractRequest(state.wizard.contract_request));
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const riskObjectId = useSelector((state) => state.wizard.contract_request.risk_object ? state.wizard.contract_request.risk_object.id : null);
  const [selectedProducts, setSelectedProducts] = useState(selectedProductsForContractRequest);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const isProductSelectable = (product) => (
    product.is_up && ['with_discount', 'available'].includes(product.status)
  );

  const selectableProducts = products.filter(product => isProductSelectable(product));

  const selectOrUnSelect = (product) => {
    if (!isProductSelectable(product)) {
      return;
    }
    setSelectedProducts((previousSelected) => {
      const existing = find(previousSelected, (item) => item.id === product.id);
      if (!existing) {
        return [...previousSelected, product];
      }
      return [...previousSelected.filter((item) => item.id !== product.id)];
    });
  };

  const updateWizard = () => {
    trackWizardStep(PRODUCT_STEP, {
      selected_product_ids: selectedProducts.map((product) => product.id),
    });
    return dispatch(initOrUpdateContractRequest(contractRequest, selectedProducts)).then(nextStep);
  };

  const isProductSelected = (product) => {
    return find(selectedProducts, (selectedProduct) => (selectedProduct.id === product.id)) !== undefined;
  };

  const toggleAll = () => {
    setSelectedProducts(selectedProducts.length < selectableProducts.length ? selectableProducts : []);
  };

  useEffect(() => {
    if (!riskObjectId) {
      history.push(location.pathname.replace('products', 'risk_object'));
    }
    setLoading(true);
    getProducts({
      contract_request_id: null,
      risk_object_id:      riskObjectId,
      includes:            'insurance_company',
    }).then(({ products }) => {
      setProducts(products);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
    toggleButton('next', selectedProducts.length === 0);
  }, []);

  useEffect(() => {
    toggleButton('next', selectedProducts.length === 0);
    setNextAction(updateWizard);
  }, [selectedProducts]);

  return (
    <React.Fragment>
      { loading && <Loader /> }
      { !loading && !products.length && (
        <ProductEmptyState />
      ) }
      { !loading && products.length > 0 && (
        <React.Fragment>
          <div className="uk-flex uk-flex-center">
            <button
              className="uk-button uk-button-default mb-20"
              type="button"
              onClick={ toggleAll }
              data-purpose="select_all_products_button"
            >
              { selectedProducts.length >= selectableProducts.length && (
                t('wizard.products.deselect_all_products')
              ) }
              { selectedProducts.length < selectableProducts.length && (
                t('wizard.products.select_all_products')
              ) }
            </button>
          </div>
          <div className="product-card-list centered">
            { products.map((product) => (
              <ProductItem
                key={ `product-item-${ product.id }` }
                isSelected={ isProductSelected }
                product={ product }
                selectProduct={ selectOrUnSelect }
              />
            )) }
          </div>
        </React.Fragment>
      ) }
    </React.Fragment>
  );
};

export default asWizardStep()(ProductStep);
