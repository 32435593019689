import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FolderTabs from 'components/tabs/folder_tabs';

const SubscriptionTabs = () => {
  const currentUserProfile = useSelector((state) => state.current_user.profile);
  const location = useLocation();

  const tabs = [
    {
      label: t('providers.account.subscription.tab_title'),
      to: '/subscription',
      disabled: false,
      isActive: () => location.pathname.endsWith('/subscription'),
    },
    {
      label: t('providers.account.subscription.coupons.tab_title'),
      to: '/subscription/coupons',
      disabled: !currentUserProfile.authorizations.redeem_coupon,
      isActive: () => location.pathname.endsWith('/coupons'),
    },
    {
      label: t('providers.account.subscription.invoices.tab_title'),
      to: '/subscription/invoices',
      disabled: false,
      isActive: () => location.pathname.endsWith('/invoices'),
    },
  ];

  return <FolderTabs tabs={tabs} />;
};

export default SubscriptionTabs;
