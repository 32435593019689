export const setIntegrationsLoader = (loader, listType) => (
  {
    type: 'INTEGRATIONS/SET_LOADER',
    loader,
    listType,
  }
);

export const setIntegrationsInStore = (integrations, listType) => (
  {
    type: 'INTEGRATIONS/SET_LIST',
    integrations,
    listType,
  }
);
