import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIntegrationsAndUpdateInStore } from 'models/integrations/dispatch_actions';
import IntegrationBlock from 'components/integrations/integration_block';
import Header from 'components/header';

const AccountIntegrations = () => {
  const dispatch = useDispatch();
  const availableIntegrations = useSelector(
    (state) => state.integrations.available,
  );
  const activeIntegrations = useSelector((state) => state.integrations.active);
  useEffect(() => {
    dispatch(fetchIntegrationsAndUpdateInStore());
  }, []);

  return (
    <React.Fragment>
      <Header title={t('providers.account.integrations.title')} />
      <IntegrationBlock
        integrations={activeIntegrations}
        title={t('providers.account.integrations.active_integrations', {
          count: activeIntegrations.list.length,
        })}
        className="mb-20"
        purpose="active"
      />
      <IntegrationBlock
        purpose="available"
        integrations={availableIntegrations}
        title={t('providers.account.integrations.available_integrations', {
          count: availableIntegrations.list.length,
        })}
        forCreation
      />
    </React.Fragment>
  );
};

export default AccountIntegrations;
