import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import UIkit from 'uikit';
import { withRouter } from 'react-router-dom';
import {
  AssociateSubMenu,
  ProviderMainUserSubMenu,
} from 'models/menus/provider_menu';
import { logOutCurrentUser } from 'models/current_user/requests';
import Icon from 'components/ui/icon';
import confirmBeforeAction from 'components/ui/buttons/helpers/confirm_before_action';
import WarningMessage from 'images/subscription_warning.svg';
import withTooltip from 'components/ui/tooltip/with_tooltip';
import { INSURGATE_URL } from 'constants/application_constants';

const Img = ({ src, alt, children, ...rest }) => (
  <div {...rest}>
    {children}
    <img src={src} alt={alt} />
  </div>
);

const TooltipedImg = withTooltip()(Img);

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.logoutUser = this.logoutUser.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
    this.dropdown = null;
  }

  componentDidMount() {
    const dropdown = UIkit.dropdown('#user-menu-dropdown', {
      mode: 'click',
      pos: 'bottom-right',
    });
    $('.user-menu-link').on('click', () => {
      dropdown.hide();
    });
  }

  logoutUser() {
    const { dispatch } = this.props;
    dispatch(logOutCurrentUser());
  }

  redirectTo(path) {
    const { history } = this.props;
    history.push(path);
  }

  render() {
    const ConfirmNavLink = confirmBeforeAction()((props) => <a {...props} />);
    const {
      displayName,
      activeSubscription,
      subscriptionPending,
      authorizations,
      is_admin,
    } = this.props;
    return (
      <div className="menu-right">
        {subscriptionPending && (
          <TooltipedImg
            className="subscription-warning"
            src={WarningMessage}
            alt="warning_subscription_icon"
            data-purpose="warning_subscription_icon"
            tooltip={t('providers.account.subscription.subscription_pending')}
          />
        )}
        {!activeSubscription && !authorizations.is_agent && (
          <TooltipedImg
            className="subscription-warning"
            src={WarningMessage}
            alt="warning_subscription_icon"
            data-purpose="warning_subscription_icon"
            tooltip={t('providers.account.subscription.subscription_ended')}
          />
        )}
        <div className="user-menu">
          <button
            type="button"
            className="user-menu-button"
            data-purpose="user_menu_button"
          >
            <Icon icon="profile" className="uk-hidden@m profile-icon" />
            <span className="uk-visible@m uk-flex uk-flex-middle">
              <span className="user-name">{`${displayName} `}</span>
              <Icon icon="arrow-down" className="user-menu-dropdown-icon" />
            </span>
          </button>
          <div id="user-menu-dropdown">
            <ul className="uk-nav uk-dropdown-nav user-menu-nav">
              <li>
                <a className="user-menu-link" href={`${INSURGATE_URL}/profile`}>
                  <Icon icon="profile" />
                  {t('general.my_profile')}
                </a>
              </li>
              {is_admin && (
                <li>
                  <a
                    className="user-menu-link"
                    href={`${INSURGATE_URL}/account/subaccounts`}
                  >
                    <Icon icon="subaccounts" />
                    {t('general.subaccounts')}
                  </a>
                </li>
              )}
              {is_admin && (
                <li>
                  <a className="user-menu-link" href={`${INSURGATE_URL}/users`}>
                    <Icon icon="employees" />
                    {t('general.users')}
                  </a>
                </li>
              )}
              <li>
                <ConfirmNavLink
                  href={INSURGATE_URL}
                  className="user-menu-link error"
                  onClick={(event) => {
                    event.preventDefault();
                    this.logoutUser();
                  }}
                >
                  <Icon icon="profile" />
                  {t('general.sign_out')}
                </ConfirmNavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { current_user, account } = state;
  const showAccountMenu = current_user.profile.authorizations.account_admin;
  const subscription = account && account.subscription;
  const subscriptionPending =
    subscription && (subscription.need_sca || subscription.need_new_source);
  return {
    is_admin: current_user.profile.authorizations.account_admin,
    menu: showAccountMenu ? ProviderMainUserSubMenu : AssociateSubMenu,
    displayName: current_user.profile.display_name,
    activeSubscription: subscription && subscription.active,
    subscriptionPending,
    authorizations: current_user.profile.authorizations,
  };
};

export default withRouter(connect(mapStateToProps)(UserMenu));
