import React       from 'react';
import PropTypes   from 'prop-types';
import NumberUtils from 'services/number';
import Avatar      from 'components/avatar';
import TooltipIcon from 'components/ui/tooltip/tooltip_icon';

const ProductHeader = ({ tarification, alias, children, extraInformation, readOnly }) => {
  return (
    <React.Fragment>
      { tarification && (
        <div className="product-header">
          <div className="product-avatar">
            <Avatar
              name={ tarification.alias || tarification.product.display_name }
              picture={ tarification.insurance_company.logo ? tarification.insurance_company.logo.small.url : null }
              border
              size={ 30 }
            />
          </div>
          <div className="product-name">
            { !alias && `${ tarification.insurance_company.name } - ${ tarification.product.name } ` }
            { alias && tarification.alias }
            { `${ tarification.insurer_quote_id ? ` - ${ tarification.insurance_company.smart_link_name }: ${ tarification.insurer_quote_id }` : '' }` }
            { children }
          </div>
          {
            extraInformation && (
              <div className={ `product-price ${ !tarification.annual_price ? 'error' : '' }` }>
                { NumberUtils.formatCurrency(tarification.annual_price, '€') }
              </div>
            )
          }
          { tarification.out_of_date && !readOnly && (
            <TooltipIcon
              icon="warning"
              cssClass="warning out-of-date"
              tooltipContent={ t('providers.offers.show.offer_product.out_of_date') }
            />
          ) }
        </div>
      ) }
    </React.Fragment>
  );
};

ProductHeader.defaultProps = {
  extraInformation: false,
  alias:            false,
};

ProductHeader.propTypes = {
  tarification:     PropTypes.object.isRequired,
  extraInformation: PropTypes.bool,
  alias:            PropTypes.bool,
};

export default ProductHeader;
