import React                 from 'react';
import TarificationFormCover from 'components/tarifications/form/tarification_form_cover';


const TarificationFormCovers = (props) => {
  const { fields, tarification, form, disabled, filter } = props;
  return fields.map((cover, index) => {
    const coverObject = fields.get(index);
    if (filter && !filter(coverObject)) {
      return null;
    }
    return (
      <TarificationFormCover
        key={ `tarification-cover-${ index }` }
        cover={ cover }
        form={ form }
        disabled={ disabled }
        coverObject={ coverObject }
        tarification={ tarification }
        index={ index }
      />
    );
  });
};

export default TarificationFormCovers;
