import React                from 'react';
import { Field, reduxForm } from 'redux-form';
import Button               from 'components/ui/buttons/button';
import TextareaInput        from 'components/ui/form/textarea_input';

const SendToBackofficeForm = ({ submitting, handleSubmit }) => {
  return (
    <form onSubmit={ handleSubmit }>
      <div>
        <Field
          name="message"
          label={ t('wizard.tarifications.description') }
          placeholder={ t('wizard.tarifications.description_placeholder') }
          component={ TextareaInput }
          className="uk-height-medium"
        />
      </div>
      <div className="uk-text-center mt-20">
        <Button
          primary
          large
          loading={ submitting }
          disabled={ submitting }
          data-purpose="send_message_button"
        >
          { t('general.actions.send') }
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'deviation',
})(SendToBackofficeForm);
