import React             from 'react';
import PropTypes         from 'prop-types';
import Modal             from 'react-modal';
import SimpleBar         from 'simplebar-react';
import DialogContext     from 'components/ui/buttons/helpers/dialog_context';
import Title             from 'components/ui/titles';
import TitleActionHeader from 'components/ui/title_action_header/title_action_header';
import Icon              from 'components/ui/icon';

Modal.setAppElement(document.getElementById('root'));
Modal.defaultStyles.content.bottom = '';
Modal.defaultStyles.content.left = '';
Modal.defaultStyles.content.right = '';
Modal.defaultStyles.content.height = 'auto';
Modal.defaultStyles.content.transform = 'translate(-50.1%, -50.1%)';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,.4)';

const openDialogOnClick = () => Component => {
  class OpenDialogOnClick extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showDialog: false,
      };
      this.toggleDialog = this.toggleDialog.bind(this);
      this.openDialog = this.openDialog.bind(this);
      this.closeDialog = this.closeDialog.bind(this);
    }

    componentWillUnmount() {
      const { onDialogClose } = this.props;
      if (onDialogClose) {
        onDialogClose();
      }
      window['closeDialog'] = null;
    }

    openDialog() {
      const { onDialogOpen, beforeOpenDialog } = this.props;
      if (beforeOpenDialog) {
        beforeOpenDialog();
      }
      this.setState({
        showDialog: true,
      });
      window['closeDialog'] = this.closeDialog;
      if (onDialogOpen) {
        onDialogOpen();
      }
    }

    toggleDialog(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const { showDialog } = this.state;
      if (showDialog) {
        this.closeDialog();
      } else {
        this.openDialog();
      }
      return Promise.resolve();
    }

    closeDialog(ev) {
      if (ev && ev.preventDefault) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      const { onDialogClose } = this.props;
      if (onDialogClose) {
        onDialogClose();
      }
      this.setState({
        showDialog: false,
      });
    }

    render() {
      const { dialogTitle, dialogClass, dialogInnerClass, children, modalStyles, onDialogClose, onDialogOpen, ...rest } = this.props;
      const { showDialog } = this.state;
      return (
        <React.Fragment>
          <Modal
            className={ `ob-modal uk-width-1-1@s ${ dialogClass || '' }` }
            isOpen={ showDialog }
            onRequestClose={ this.closeDialog }
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
          >
            <TitleActionHeader className={ `ob-modal-title ${ dialogTitle ? 'bottom-border' : '' }` }>
              <Title size="h2">
                { dialogTitle || '' }
              </Title>
              <button
                className="close-modal-button"
                type="button"
                onClick={ this.closeDialog }
              >
                <Icon icon="close" />
              </button>
            </TitleActionHeader>
            <SimpleBar className={ `${ dialogInnerClass || '' } ob-modal-content` }>
              <div className="inner">
                <DialogContext.Provider value={ { closeDialog: this.closeDialog } }>
                  { children }
                </DialogContext.Provider>
              </div>
            </SimpleBar>
          </Modal>
          <Component onClick={ this.toggleDialog } { ...rest } />
        </React.Fragment>
      );
    }
  }

  OpenDialogOnClick.defaultProps = {
    onDialogClose: null,
    onDialogOpen:  null,
  };

  OpenDialogOnClick.propTypes = {
    dialogCloseLabel: PropTypes.string,
    onDialogClose:    PropTypes.func,
    onDialogOpen:     PropTypes.func,
  };

  return OpenDialogOnClick;
};

export default openDialogOnClick;
