import React, { useEffect }                     from 'react';
import { useDispatch, useSelector }             from 'react-redux';
import { submit }                               from 'redux-form';
import some                                     from 'lodash/some';
import CoverForm                                from 'components/cover/cover_form';
import asWizardStep                             from 'components/wizard_stepper/as_wizard_step';
import { setSelectedCovers }                    from 'models/wizard/store_actions';
import { updateContractRequestPreferences }     from 'models/wizard/dispatch_actions';
import { getSelectedCoversFromContractRequest } from 'models/wizard/reducer';
import { COVER_STEP, trackWizardStep }          from 'models/wizard/constants';
import { useHistory, useLocation }              from 'react-router';

const CoverStep = ({
  setNextAction,
  nextStep,
  setNextLoading,
  onNextStep,
}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const covers = useSelector(state => state.wizard.contract_request.risk_object ? state.wizard.contract_request.risk_object.available_cover_categories : []);
  const contractRequestId = useSelector(state => state.wizard.contract_request.id);
  const riskObject = useSelector(state => state.wizard.contract_request.risk_object);
  const initialValues = useSelector(state => getSelectedCoversFromContractRequest(state));
  const submitCoverForm = () => {
    dispatch(submit('wizard_covers'));
  };

  const setCoverConfiguration = async (values) => {
    setNextLoading(true);
    if (values) {
      trackWizardStep(COVER_STEP, {
        covers: values,
      });
      if (contractRequestId) {
        dispatch(updateContractRequestPreferences(contractRequestId, values, covers))
          .then(() => {
            if (onNextStep && onNextStep.recomputeTarifications) {
              onNextStep.recomputeTarifications();
            }
            setNextLoading(false);
          });
      }
      await dispatch(setSelectedCovers(values));
      setNextLoading(false);
    }
  };

  const atLeastOneSelectedCover = (formValues) => {
    if (some(covers, (cover) => formValues[cover])) {
      return {};
    }
    return {
      covers: [t('wizard.covers.at_least_one_cover_must_be_selected')],
    };
  };

  const submitSelectedCovers = (values) => {
    setCoverConfiguration(values).then(nextStep);
  };

  useEffect(() => {
    setNextAction(submitCoverForm);
    if (!riskObject || !covers.length) {
      history.push(location.pathname.replace('covers', 'risk_object'));
    }
  }, []);
  if (!covers.length) {
    return null;
  }
  return (
    <CoverForm
      covers={ covers }
      onSubmit={ submitSelectedCovers }
      form="wizard_covers"
      initialValues={ initialValues }
      validate={ atLeastOneSelectedCover }
    />
  );
};

export default asWizardStep(true)(CoverStep);
