import React, { useState }    from 'react';
import { useDispatch }        from 'react-redux';
import { newIntercomMessage } from 'services/intercom';
import { helpTarification }   from 'models/tarifications/requests';
import { updateTarification } from 'models/wizard/store_actions';
import { DEVELOPMENT }        from 'constants/application_constants';
import Button                 from 'components/ui/buttons/button';

const AskForHelpButton = ({ tarification, ...rest }) => {
  if (!tarification.authorizations || !tarification.authorizations.request_help) {
    return null;
  }
  const [loading, toggleLoading] = useState(false);
  const dispatch = useDispatch();
  const askForHelp = () => {
    toggleLoading(true);
    return helpTarification(tarification.id).then((tarification) => {
      dispatch(updateTarification(tarification));
      if (!DEVELOPMENT) {
        newIntercomMessage(t('wizard.tarifications.intercom_message_placeholder'));
      }
      toggleLoading(false);
    }).catch(() => {
      toggleLoading(false);
    });
  };

  return (
    <Button
      disabled={ loading || tarification.help_requested }
      loading={ loading }
      icon={ tarification.help_requested ? 'check' : 'help' }
      onClick={ askForHelp }
      { ...rest }
    >
      { tarification.help_requested ? t('wizard.tarifications.help_requested') : t('wizard.tarifications.ask_for_help') }
    </Button>
  );
};

export default AskForHelpButton;
