import StepList          from 'components/wizard_stepper/step_list';
import React             from 'react';
import { useSelector }   from 'react-redux';
import { getFleetSteps } from 'schemas/product_factory/fleet/fleet_steps';
import { getTPESteps }   from 'schemas/product_factory/tpe/tpe_steps';

const ProductFactorySteps = ({ matchPath }) => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const productSlug = useSelector((state) => state.wizard.contract_request.tarifications[0].product.slug);
  let steps = null;
  switch (productSlug) {
    case 'axa-fleet':
      steps = getFleetSteps(matchPath, contractRequest);
      break;
    case 'vivium-tpe':
      steps = getTPESteps(matchPath, contractRequest);
      break;
    default:
      steps = [];
  }
  return (
    <StepList steps={ steps } />
  );
};

export default ProductFactorySteps;
