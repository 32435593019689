import Api from 'services/api';

export const fetchCountries = () => {
  return new Promise((resolve, reject) => {
    Api.get('/config/countries').then((response) => {
      resolve(response.data.countries);
    }).catch((error) => {
      reject(error);
    });
  });
};
