import React, { useRef, useEffect } from 'react';
import PropTypes                    from 'prop-types';
import UIkit                        from 'uikit';

const AppModal = ({ isOpen, setIsOpen, children, footer, title, options, width }) => {
  const ref = useRef();

  const widthValues = {
    xsmall: 300,
    small:  500,
    large:  700,
    xlarge: 900
  };

  const handleHiddenEvent = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (ref.current) {
      UIkit.modal(ref.current, options);
      ref.current.addEventListener('hidden', handleHiddenEvent);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('hidden', handleHiddenEvent);
      }
    };
  }, [ref]);

  useEffect(() => {
    if (ref.current) {
      if (isOpen) {
        UIkit.modal(ref.current).show();
      } else {
        UIkit.modal(ref.current).hide();
      }
    }
  }, [isOpen]);

  return (
    <div uk-modal="true" ref={ ref }>
      <div className="uk-modal-dialog uk-margin-auto-vertical" style={ { width: widthValues[width] } }>
        { isOpen && (
          <React.Fragment>
            <button className="uk-modal-close-default" type="button" aria-label="Close" uk-close="true" />
            { title && (
              <div className="uk-modal-header">
                <h2 className="uk-modal-title">{ title }</h2>
              </div>
            ) }
            <div className="uk-modal-body">
              { children }
            </div>
            { footer && (
              <div className="uk-modal-footer">
                { footer }
              </div>
            ) }
          </React.Fragment>
        ) }
      </div>
    </div>
  );
};

AppModal.defaultProps = {
  footer:  null,
  title:   null,
  options: {},
  width:   'small'
};

AppModal.propTypes = {
  isOpen:    PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  children:  PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  footer:    PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  title:     PropTypes.string,
  options:   PropTypes.object,
  width:     PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AppModal;
