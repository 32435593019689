import React                                                 from 'react';
import Icon                                                  from 'components/ui/icon';
import moment                                                from 'moment';
import { getCurrentLocale }                                  from 'services/i18n';
import TogglableDate                                         from 'components/ui/form/togglable_date';
import { NEW_FOLDER_EVENT, NEW_LEAD_EVENT, trackWizardStep } from 'models/wizard/constants';
import { createJsonContractRequest }                         from 'models/contract_request/requests';

export const goToNewFolder = (ev, riskObject, product, forLead) => {
  const target = forLead ? 'leads' : 'folders';
  ev && ev.preventDefault();
  trackWizardStep(forLead ? NEW_LEAD_EVENT : NEW_FOLDER_EVENT);
  if (riskObject && product) {
    return createJsonContractRequest(riskObject, [product], forLead).then(({ contract_request }) => {
      window.location = `/${ target }/${ contract_request.id }`;
    });
  }
  window.location = `/${ target }/new`;
};

export const convertForTable = (contractRequests) => {
  moment.locale(getCurrentLocale());
  return contractRequests.map((contractRequest) => {
    return {
      id:                        contractRequest.id,
      link:                      `/folders/${ contractRequest.id }`,
      risk_object:               (
                                   <Icon
                                     icon={ contractRequest.risk_object.icon }
                                     data-uk-tooltip={ contractRequest.risk_object.kind_text }
                                   />
                                 ),
      name:                      contractRequest.display_name,
      technical_reference:       contractRequest.technical_reference,
      customer:                  contractRequest.customer_name,
      tarification_count:        contractRequest.tarifications_count,
      manager_user_display_name: contractRequest.manager_user_display_name,
      intermediary_display_name: contractRequest.intermediary_display_name,
      products:                  contractRequest.products,
      status:                    contractRequest.status,
      authorizations:            contractRequest.authorizations,
      updated_at:                (
                                   <TogglableDate date={ contractRequest.updated_at } />
                                 ),
    };
  });
};
