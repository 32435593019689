import React                                   from 'react';
import PropTypes                               from 'prop-types';
import SubmitButton                            from 'components/ui/form/submit_button';
import { connect }                             from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import TextInput                               from 'components/ui/form/text_input';
import { Row, Col }                            from 'react-flexbox-grid';
import Margin                                  from 'components/ui/margin';
import CheckboxInput                           from 'components/ui/form/checkbox_input';

const BillingAddressForm = ({ submitting, handleSubmit, billingRecoverableVat }) => (
  <React.Fragment>
    <form className="form billing-address-form"
          onSubmit={ handleSubmit }
          id="subscription_form"
          data-purpose="billing_address_form">
      <Row>
        <Col xs={ 12 }>
          <Margin>
            <Field
              name="billing_company_name"
              type="text"
              component={ TextInput }
              placeholder={ t('billing_address_form.placeholders.company_name') }
            />
          </Margin>
        </Col>
      </Row>
      <Row>
        <Col xs={ 6 }>
          <Margin>
            <Field
              name="billing_street_name"
              type="text"
              component={ TextInput }
              placeholder={ t('billing_address_form.placeholders.street') }
            />
          </Margin>
        </Col>
        <Col xs={ 3 }>
          <Margin>
            <Field
              name="billing_street_number"
              type="text"
              component={ TextInput }
              placeholder={ t('billing_address_form.placeholders.street_number') }
            />
          </Margin>
        </Col>
        <Col xs={ 3 }>
          <Margin>
            <Field
              name="billing_box_number"
              type="text"
              component={ TextInput }
              placeholder={ t('billing_address_form.placeholders.box_number') }
            />
          </Margin>
        </Col>
      </Row>
      <Row>
        <Col xs={ 8 }>
          <Margin>
            <Field
              name="billing_zip_code"
              type="text"
              component={ TextInput }
              placeholder={ t('billing_address_form.placeholders.zip_code') }
            />
          </Margin>
        </Col>
        <Col xs={ 4 }>
          <Margin>
            <Field
              name="billing_city"
              type="text"
              component={ TextInput }
              placeholder={ t('billing_address_form.placeholders.city') }
            />
          </Margin>
        </Col>
      </Row>
      <Row>
        <Col xs={ 12 }>
          <Margin>
            <Field
              name="billing_email"
              type="text"
              component={ TextInput }
              placeholder={ t('billing_address_form.placeholders.email') }
            />
          </Margin>
        </Col>
      </Row>
      <Row middle="xs">
        <Col xs={ 6 }>
          <Margin>
            <Field
              name="billing_recoverable_vat"
              label={ t('billing_address_form.labels.recoverable_vat') }
              component={ CheckboxInput }
            />
          </Margin>
        </Col>
        { billingRecoverableVat && (
          <Col xs={ 6 }>
            <Margin>
              <Field
                name="billing_vat_number"
                type="text"
                component={ TextInput }
                placeholder={ t('billing_address_form.placeholders.vat_number') }
              />
            </Margin>
          </Col>
        ) }
      </Row>
      <Row>
        <Col xs={ 12 }>
          <SubmitButton
            disabled={ submitting }
            loading={ submitting }
            margin="20px 0"
            large
            text={ t('billing_address_form.submit') }
            data-purpose="submit_billing_address_form_button"
          />
        </Col>
      </Row>
    </form>
  </React.Fragment>
);

BillingAddressForm.propTypes = {
  submitting:            PropTypes.bool.isRequired,
  handleSubmit:          PropTypes.func.isRequired,
  billingRecoverableVat: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const billingRecoverableVat = formValueSelector('billing_address')(state, 'billing_recoverable_vat');
  return {
    billingRecoverableVat,
  };
};

export default reduxForm({
  form: 'billing_address',
})(
  connect(mapStateToProps)(BillingAddressForm),
);
