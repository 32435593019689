import React                      from 'react';
import PropTypes                  from 'prop-types';
import { connect }                from 'react-redux';
import snakeCase                  from 'lodash/snakeCase';
import TarificationOptionsTooltip from 'components/tarifications/table/options_tooltip';
import TarificationDeleteButton   from 'components/tarifications/table/delete_button';
import TarificationInsuranceCompanySyncButton
                                  from 'components/tarifications/table/insurance_company_sync_button';
import TarificationRefreshButton  from 'components/tarifications/table/refresh_button';
import moment                     from 'moment';
import TarificationQuoteOutdated
                                  from 'components/tarifications/table/tarification_quote_outdated';
import OpenSyncAccessButton       from 'components/tarifications/table/open_sync_access_button';
import { isBuildingCr }           from 'models/wizard/reducer';

const TarificationTableRow = ({ tarification, columns, isBuilding }) => {
  const { error_encountered, error_encountered_type, computing, updated_at, insurer_quote_id, insurance_company: { smart_link_name }, insurer_quote_outdated, insurer_quote_message } = tarification;
  const createdDayCount = moment().diff(updated_at, 'days');
  return (
    <React.Fragment>
      <tr className={ (!insurer_quote_outdated || !insurer_quote_id) ? 'footer-row with-border-bottom' : '' }>
        <td colSpan={ columns.length + 2 }>
          <span className="text mr-20 uk-float-left tarification-name">
            <strong className="mt-5">{ tarification.alias || tarification.product.display_name }</strong>
            <TarificationOptionsTooltip tarification={ tarification } />
          </span>
          { insurer_quote_id && !insurer_quote_outdated && !isBuilding && (
            <React.Fragment>
              <span className="text uk-text-warning">
                { t('quick_tarification.table.row.identifier') }
                { ' ' }
                { smart_link_name }
                { ': ' }
                { insurer_quote_id }
              </span>
              { insurer_quote_message && !isBuilding && (
                <OpenSyncAccessButton
                  explanation={ insurer_quote_message }
                  smart_link_name={ smart_link_name }
                  secondary
                  small
                  className="ml-10"
                />
              ) }
            </React.Fragment>
          ) }
          { error_encountered && (
            <React.Fragment>
              <span className="uk-float-right">
                { error_encountered_type === 'message' && (
                  <TarificationRefreshButton
                    tarification={ tarification }
                    disabled={ computing }
                    small
                    outline
                    className="mr-10"
                  />
                ) }
                { tarification.authorizations.destroy && (
                  <TarificationDeleteButton
                    tarification={ tarification }
                    disabled={ computing }
                  />
                ) }
              </span>
            </React.Fragment>
          ) }
          { !error_encountered && (
            <React.Fragment>
              <span className="uk-float-left">
                <span className={ `text mr-20 ${ createdDayCount > 30 ? 'uk-text-warning' : '' }` }>
                  { t('quick_tarification.table.row.tarification_created_at', { count: createdDayCount }) }
                </span>
              </span>
              <span className="uk-float-right">
                { tarification.authorizations.save_quote && (
                  <TarificationInsuranceCompanySyncButton
                    tarification={ tarification }
                    disabled={ computing }
                    small
                    className="mr-10"
                    primary
                  />
                ) }
                { tarification.authorizations.destroy && (
                  <TarificationDeleteButton
                    tarification={ tarification }
                    disabled={ computing }
                    data-purpose={ `delete_${ snakeCase(tarification.product.name) }_button` }
                  />
                ) }
              </span>
            </React.Fragment>
          ) }
          <span className="uk-clearfix" />
        </td>
      </tr>
      { insurer_quote_outdated && insurer_quote_id && !isBuilding && (
        <tr className="footer-row with-border-bottom">
          <td colSpan={ columns.length + 2 } className="pb-20 uk-text-center">
            <TarificationQuoteOutdated tarification={ tarification } />
          </td>
        </tr>
      ) }
      { insurer_quote_outdated && insurer_quote_id && isBuilding && (
        <tr className="footer-row with-border-bottom">
          <td />
          <td colSpan={ columns.length + 1 } className="pb-20">
            <p className="uk-alert uk-alert-warning uk-width-1-1">
              { t('wizard.warning.tarification_sync_outdated_building', { insurer_quote_id }) }
            </p>
          </td>
        </tr>
      ) }
    </React.Fragment>
  );
};

TarificationTableRow.propTypes = {
  tarification: PropTypes.object.isRequired,
  columns:      PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isBuilding: isBuildingCr(state),
  };
};

export default connect(mapStateToProps)(TarificationTableRow);
