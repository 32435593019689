import React        from 'react';
import {
  reduxForm,
  Field,
}                   from 'redux-form';
import {
  Row,
  Col,
}                   from 'react-flexbox-grid';
import TextInput    from 'components/ui/form/text_input';
import SubmitButton from 'components/ui/form/submit_button';
import Margin       from 'components/ui/margin';
import InputLabel   from 'components/ui/form/input_label';

const MemberForm = ({ handleSubmit, submitting }) => {

  return (
    <form onSubmit={ handleSubmit } data-purpose="member_form">
      <Row>
        <Col xs={ 12 } md={ 6 }>
          <Margin>
            <InputLabel>
              { t('providers.account.members.add_member_modal.first_name') }
            </InputLabel>
            <Field
              name="first_name"
              component={ TextInput }
              placeholder={ t('providers.account.members.add_member_modal.first_name') }
              type="text"
            />
          </Margin>
        </Col>
        <Col xs={ 12 } md={ 6 }>
          <Margin>
            <InputLabel>
              { t('providers.account.members.add_member_modal.last_name') }
            </InputLabel>
            <Field
              name="last_name"
              component={ TextInput }
              placeholder={ t('providers.account.members.add_member_modal.last_name') }
              type="text"
            />
          </Margin>
        </Col>
      </Row>
      <Margin>
        <InputLabel>
          { t('providers.account.members.add_member_modal.email') }
        </InputLabel>
        <Field
          name="email"
          component={ TextInput }
          label={ t('providers.account.members.add_member_modal.email') }
          placeholder={ t('providers.account.members.add_member_modal.email') }
          type="email"
        />
      </Margin>
      <SubmitButton
        loading={ submitting }
        data-purpose="member_form_submit_button"
        text={ t('providers.account.members.send_invitation') }
      />
    </form>
  );
};

export default reduxForm({
  form: 'member_form',
})(MemberForm);
