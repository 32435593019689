import React                                              from 'react';
import IntegrationItem                                    from 'distributor/components/integrations/integration_item';
import { updateIntegrationConfigurationAndUpdateInStore } from 'models/integrations/dispatch_actions';
import { useDispatch }                                    from 'react-redux';

const IntegrationList = ({ integrations, forCreation }) => {
  const dispatch = useDispatch();
  if (!integrations.length) {
    return t('components.integrations.integration_list.no_integration');
  }
  const onIntegrationSubmit = (integrationToSubmit) => {
    dispatch(updateIntegrationConfigurationAndUpdateInStore(integrationToSubmit));
  };

  return (
    <div
      className="product-card-list"
    >
      { integrations.map((integration, index) => (
        <IntegrationItem
          onIntegrationSubmit={ onIntegrationSubmit }
          deletable={ false }
          integration={ integration }
          key={ index }
          forCreation={ forCreation }
        />
      )) }
    </div>
  );
};

export default IntegrationList;
