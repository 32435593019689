import React     from 'react';
import PropTypes from 'prop-types';
import isArray   from 'lodash/isArray';

const FormErrorMessage = ({ error, warning, touched, className, name, label }) => {
  if (!touched) {
    return null;
  }
  return (
    <React.Fragment>
      { error && (
        <span
          data-error={ name }
          data-error-label={ label }
          className={ `uk-text-danger form-error-message ${ className || '' }` }
        >
          { isArray(error) ? error.join(', ') : error }
        </span>
      ) }
      { warning && (
        <span
          data-error={ name }
          data-error-label={ label }
          className={ `uk-text-error form-error-message ${ className || '' }` }
        >
          { warning }
        </span>
      ) }
    </React.Fragment>
  );
};

FormErrorMessage.propTypes = {
  error:   PropTypes.any,
  warning: PropTypes.any,
  touched: PropTypes.bool,
};

FormErrorMessage.defaultProps = {
  touched: false,
  error:   null,
  warning: null,
};

export default FormErrorMessage;
