import React                   from 'react';
import PropTypes               from 'prop-types';
import find                    from 'lodash/find';
import { useSelector }         from 'react-redux';
import {
  FieldArray,
  reduxForm,
  Field,
}                              from 'redux-form';
import NumberService           from 'services/number';
import {
  getActiveIntegrationForCompanyId,
}                              from 'models/integrations/reducer';
import ProductHeader           from 'components/tarifications/form/product_header';
import TooltipIcon             from 'components/ui/tooltip/tooltip_icon';
import SelectInput             from 'components/ui/form/select_input';
import TarificationFormCovers
                               from 'components/tarifications/form/tarification_form_covers';
import TarificationFormOptions
                               from 'components/tarifications/form/tarification_form_options';
import PreventBeforeNext       from 'components/tarifications/form/prevent_before_next';
import { getCurrentLocale }    from 'services/i18n';
import TarificationFormContext from 'components/tarifications/form/tarification_form_context';
import RecomputeWarning        from 'components/tarifications/recompute_warning';

const TarificationForm = (props) => {
  const {
          handleSubmit,
          initialValues,
          pristine,
          form,
          disabled,
        } = props;

  if (!initialValues) return null;
  const availableIntegrations = useSelector((state) => getActiveIntegrationForCompanyId(state, initialValues.insurance_company.id, initialValues.product.id).integrations.map((integration) => {
    return {
      value: integration.id,
      label: integration.name,
    };
  }));

  const {
          computing,
          announcement,
          external_url,
          options_configuration,
          warnings,
          extra_information,
          annual_price,
          staggering_price,
          payment_staggering,
        } = initialValues;

  const showDerogationColumn = !find(initialValues.covers, (cover) => cover.hide_derogation);
  const showAdditionalDerogationColumn = !find(initialValues.covers, (cover) => cover.hide_additional_derogation);
  const showCommission = !find(initialValues.covers, (cover) => cover.hide_commission);
  const columnCount = 2 + (showDerogationColumn ? 1 : 0) + (showAdditionalDerogationColumn ? 1 : 0) + (showCommission ? 1 : 0);
  return (
    <TarificationFormContext.Provider value={ { disabled } }>
      <form
        data-loading-message={ t('wizard.tarifications.recomputing') }
        className="tarification-form"
        data-purpose="tarification_form"
        data-disable={ pristine && computing ? 'disabled' : '' }
      >
        <div className="mb-20 uk-flex uk-flex-between tarification-header">
          <ProductHeader tarification={ initialValues } showOptions={ false } />
          <div className="uk-flex tarification-header-options">
            { availableIntegrations.length > 1 && (
              <Field
                name="integration_id"
                list={ availableIntegrations }
                placeholder={ t('provider.tarification.actions.select_integration') }
                disabled={ disabled }
                component={ SelectInput }
              />
            ) }
          </div>
        </div>
        { announcement && (
          <div className="mb-20 p-10 global-message info">
            <p
              dangerouslySetInnerHTML={ { __html: announcement[getCurrentLocale()] } }
              className="m-0 mb-10"
            />
          </div>
        ) }
        { external_url && (
          <div className="mb-20">
            <a
              href={ external_url }
              target="_blank"
              rel="noopener noreferrer"
            >
              { external_url }
            </a>
          </div>
        ) }
        <div className="tarification-options-configuration">
          <FieldArray
            name="selected_options"
            form={ form }
            disabled={ disabled }
            component={ TarificationFormOptions }
            optionsConfiguration={ options_configuration }
            tarification={ initialValues }
          />
        </div>
        { (warnings || extra_information) && (
          <div className="mb-20">
            {
              warnings && warnings.map((warning, index) => (
                <p
                  key={ `extra-information-item-${ index }` }
                  dangerouslySetInnerHTML={ { __html: warning[getCurrentLocale()] } }
                  className="m-0 mb-10"
                />
              ))
            }
            {
              extra_information && extra_information.map((extra_information, index) => (
                <p
                  key={ `extra-information-item-${ index }` }
                  dangerouslySetInnerHTML={ { __html: extra_information[getCurrentLocale()] } }
                  className="m-0 mb-10"
                />
              ))
            }
          </div>
        ) }
        <table className="tarification-table">
          <thead>
          <tr>
            <th />
            <th>{ t('provider.tarification.cover_options_title') }</th>
            { showDerogationColumn && (
              <th>
                  <span className="inner">
                    { t('provider.tarification.cover_derogations_title') }
                    <TooltipIcon
                      icon="information"
                      tooltipContent={ t('provider.tarification.cover_derogations_title_message') }
                    />
                  </span>
              </th>
            ) }
            { showAdditionalDerogationColumn && (
              <th>
                  <span className="inner">
                    { t('provider.tarification.cover_additional_derogations_title') }
                    <TooltipIcon
                      icon="information"
                      tooltipContent={ t('provider.tarification.cover_additional_derogations_title_message') }
                    />
                  </span>
              </th>
            ) }
            { showCommission && (
              <th>{ t('provider.tarification.cover_commissions_title') }</th>
            ) }
            <th className="uk-text-right">
              { t('provider.tarification.cover_amount_title') }
            </th>
          </tr>
          </thead>
          <tbody>
          <FieldArray
            name="covers"
            form={ form }
            component={ TarificationFormCovers }
            tarification={ initialValues }
            disabled={ disabled }
          />
          </tbody>
          <tfoot>
          <tr>
            <td colSpan={ columnCount } />
            <td
              className="uk-text-nowrap uk-text-right"
            >
              <p className="mb-0">
                { `${ t('provider.tarification.total') } : ${ NumberService.formatCurrency(annual_price, '€') }` }
              </p>
              { staggering_price && payment_staggering !== 'annually' && (
                <p className="mb-0 mt-0">
                  { t(`provider.tarification.staggering_total.${ payment_staggering }`, { staggering_price: NumberService.formatCurrency(staggering_price) }) }
                </p>
              ) }
            </td>
          </tr>
          </tfoot>
        </table>
      </form>
      { !computing && !pristine && (
        <PreventBeforeNext recompute={ handleSubmit } />
      ) }
      { !pristine && (
        <RecomputeWarning />
      ) }
    </TarificationFormContext.Provider>
  );
};

TarificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting:   PropTypes.bool.isRequired,
};

export default reduxForm({
  enableReinitialize:       true,
  destroyOnUnmount:         false,
  forceUnregisterOnUnmount: false,
})(TarificationForm);
