import update from 'immutability-helper';
import isNil  from 'lodash/isNil';

const defaultState = {
  available: {
    loading: false,
    list:    [],
  },
  active:    {
    loading: false,
    list:    [],
  },
};

const integrationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'INTEGRATIONS/SET_LOADER':
      return update(state, {
        [action.listType]: {
          loading: {
            $set: action.loader,
          },
        },
      });
    case 'INTEGRATIONS/SET_LIST':
      return update(state, {
        [action.listType]: {
          list: {
            $set: action.integrations,
          },
        },
      });
    default:
      return state;
  }
};

export default integrationsReducer;

export const getActiveIntegrationForCompanyId = (state, companyId, productId) => {
  return {
    integrations: state.integrations.active.list.filter((integration) => {
      return (integration.insurance_company.id === companyId && integration.status === 'verified' && (isNil(integration.product_id) || integration.product_id === productId));
    }),
  };
};
