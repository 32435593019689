import React       from 'react';
import PropTypes   from 'prop-types';
import ProgressBar from 'components/ui/progress_bar';
import Alert       from 'services/alert';
import Icon        from 'components/ui/icon';
import Api         from 'services/api';

class FileItemWithUpload extends React.Component {
  constructor(props) {
    super(props);
    const { file, pathToUpload } = this.props;
    this.state = {
      file:         file,
      pathToUpload: pathToUpload,
      progress:     0,
      uploaded:     false,
      error:        false,
    };
    this.startUpload = this.startUpload.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }


  onProgress(progress) {
    this.setState({
      progress,
    });
  }

  onSuccess(response, resolve) {
    const tempFile = this.state.file;
    this.setState({
      uploaded: true,
      file:     response.document,
    }, () => {
      Alert.success(t('clients.typed_contract_requests.show.file_add_success'));
      if (this.props.updateParentFile) {
        this.props.updateParentFile(tempFile, this.state.file, resolve);
      } else {
        resolve(this.state.file);
      }
    });
  }

  onError(error, reject) {
    Alert.error(t('clients.typed_contract_requests.show.file_add_error'));
    this.setState({
      error: true,
    });
    reject(error);
  }

  startUpload() {
    const { uploadMethod } = this.props;
    const { pathToUpload, file } = this.state;

    if (uploadMethod) {
      return uploadMethod(file);
    }

    return new Promise((resolve, reject) => {
      Api.put(pathToUpload, file)
        .then((response) => { this.onSuccess.call(this, response, resolve); })
        .catch((error) => { this.onError.call(this, error, reject); });
    });
  }

  render() {
    const { uploaded, file, progress } = this.state;
    return (
      <div className={ `file-item ${ uploaded ? 'file--success' : '' }` } title={ file.name }>
        {
          uploaded
          && (
            <span className="file-upload-success">
              <Icon icon="check" />
            </span>
          )
        }
        <p className="file-icon">
          <Icon icon="file" />
        </p>
        <p className="file-name">{ file.name }</p>
        {
          (file.id === undefined)
          && <ProgressBar progress={ progress } />
        }
      </div>
    );
  }
}

FileItemWithUpload.propTypes = {
  pathToUpload:     PropTypes.string,
  uploadMethod:     PropTypes.func,
  file:             PropTypes.object,
  updateParentFile: PropTypes.func,
};

export default FileItemWithUpload;
