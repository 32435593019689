import update from 'immutability-helper';

const defaultState = {
  intermediary_token: null,
  profile:            null,
};

const currentUserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'CURRENT_USER/DELETE':
      return update(state, {
        profile: { $set: null },
      });
    case 'CURRENT_USER/UPDATE':
      return update(state, {
        profile: {
          $set: {
            ...action.current_user,
          },
        },
      });
    case 'CURRENT_USER/STORE_INTERMEDIARY_TOKEN':
      return update(state, {
        intermediary_token: { $set: action.token },
      });
    default:
      return state;
  }
};

export default currentUserReducer;

export const currentUserIsIntermediary = (state) => {
  return (
    state.current_user.profile && state.current_user.profile.role === 'intermediary'
  );
};

export const currentUserRoleIsDisabled = (state) => {
  const roles = ['customer', 'insurer'];
  return state.current_user.profile && roles.includes(state.current_user.profile.role);
};

export const getCurrentUserFromState = (state) => {
  const { current_user: { profile } } = state;
  return profile;
};
