import React              from 'react';
import PropTypes          from 'prop-types';
import Callout            from 'components/ui/callout';
import Moment             from 'react-moment';
import SubscriptionResume from 'components/subscription/subscription_resume';

const SubscriptionCallout = ({ subscription }) => {
  if (!subscription) {
    return null;
  }
  if (subscription && subscription.under_free_trial) {
    return (
      <Callout className="primary">
        <p>
          { t('subscriptions.under_free_trial_message') }
          { ' ' }
          <strong><Moment format="LL">{ subscription.trial_ends_at }</Moment></strong>
        </p>
      </Callout>
    );
  }
  if (subscription && subscription.plan === 'free' && !subscription.under_free_trial) {
    return (
      <Callout className="alert">
        <p>
          <strong>{ t('subscriptions.end_of_trial_message') }</strong>
        </p>
      </Callout>
    );
  }
  return <SubscriptionResume planId={ subscription.plan } />;
};

SubscriptionCallout.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default SubscriptionCallout;
