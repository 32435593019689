import React from 'react';
import SubscriptionTabs from 'components/subscription/tabs';
import { Route, Switch } from 'react-router';
import AccountSubscriptionInvoices from 'components/account/subscription_invoices';
import AccountCoupons from 'components/account/coupons';
import SubscriptionShow from 'components/subscription/show';
import Header from 'components/header';

const SubscriptionContainer = () => {
  return (
    <>
      <Header title={t('providers.account.subscription.tab_title')} />
      <SubscriptionTabs />
      <Switch>
        <Route
          exact
          path="/subscription"
          render={() => {
            return <SubscriptionShow />;
          }}
        />
        <Route
          path="/subscription/invoices"
          exact
          render={() => <AccountSubscriptionInvoices />}
        />
        <Route
          path="/subscription/coupons"
          exact
          render={() => <AccountCoupons />}
        />
      </Switch>
    </>
  );
};
export default SubscriptionContainer;
