import Alert from 'services/alert';
import Api, { ApiInsurgate } from 'services/api';
import ApiDistributor from 'distributor/services/api';
import {
  setUserInStore,
  removeCurrentUser,
} from 'models/current_user/store_actions';
import { removeAuthorizationToken } from 'services/authorization_token';
import { FOR_DISTRIBUTOR, SIGN_IN_URL } from 'constants/application_constants';

export const getCurrentUser = () => (dispatch) =>
  new Promise((resolve, reject) => {
    Api.get('profile')
      .then((response) => {
        const { profile } = response.data;
        dispatch(setUserInStore(profile));
        resolve(profile);
      })
      .catch((error) => {
        console.error('error', error);
        Alert.error('Error while fetching user data');
        reject();
      });
  });

export const updateCurrentUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    Api.put('profile', data)
      .then((response) => {
        const { profile } = response.data;
        dispatch(setUserInStore(profile));
        Alert.success(t('profiles.actions.update.success'));
        resolve(profile);
      })
      .catch(() => {
        Alert.error(t('profiles.actions.update.error'));
        reject();
      });
  });

export const logOutCurrentUser = (andRedirect = true) => (dispatch) => {
  if (FOR_DISTRIBUTOR) {
    return ApiDistributor.delete('sessions').then(() => {
      removeAuthorizationToken();
      dispatch(removeCurrentUser());
      location.href = '/';
    });
  }
  ApiInsurgate.delete('sessions').then(() => {
    removeAuthorizationToken();
    location.href = SIGN_IN_URL;
  });
};
