import React                       from 'react';
import * as ROUTES                 from 'components/account/routes/constants';
import MembersIndex                from 'components/members/index/members_index';
import SubscriptionShow            from 'components/subscription/show';
import AccountSettings             from 'components/account/settings';
import AccountSubscriptionInvoices from 'components/account/subscription_invoices';
import AccountCoupons              from 'components/account/coupons';
import IntegrationIndex            from 'components/account/integrations';
import IntermediariesIndex         from 'components/intermediaries';

export const routes = [
  {
    path:      ROUTES.MEMBERS_INDEX,
    component: MembersIndex,
    exact:     true,
  },
  {
    path:      ROUTES.SETTINGS,
    component: AccountSettings,
    exact:     true,
  },
  {
    path:      ROUTES.INTEGRATIONS,
    component: IntegrationIndex,
    exact:     true,
  },
  {
    path:      ROUTES.SUBSCRIPTION,
    component: SubscriptionShow,
    exact:     true,
  },
  {
    path:      ROUTES.SUBSCRIPTION_COUPONS,
    component: AccountCoupons,
    exact:     true,
  },
  {
    path:      ROUTES.SUBSCRIPTION_INVOICES,
    component: AccountSubscriptionInvoices,
    exact:     true,
  },
  {
    path:      ROUTES.INTERMEDIARIES,
    component: IntermediariesIndex,
    exact:     true,
  },
];

export default routes;
