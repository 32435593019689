import React                         from 'react';
import PropTypes                     from 'prop-types';
import {
  SubmissionError,
}                                    from 'redux-form';
import openInDrawer                  from 'components/ui/drawer/helpers/open_in_drawer';
import OfficeAddressForm             from 'components/offices/form/office_address_form';
import {
  updateOffice,
  createOffice,
}                                    from 'models/offices/requests';
import { prepareErrorsForReduxForm } from 'services/rails';

const OpenOfficeFormInDrawer = openInDrawer()(OfficeAddressForm);

const OpenOfficeAddressForm = ({ office, onSubmitted, children, onDeleted }) => {
  const onSubmitAddress = (officeToSubmit) => {
    if (officeToSubmit.id) {
      return updateOffice(officeToSubmit).then(onSubmitted).catch((error) => {
        throw new SubmissionError(prepareErrorsForReduxForm(error));
      });
    }
    return createOffice(officeToSubmit).then(onSubmitted).catch((error) => {
      throw new SubmissionError(prepareErrorsForReduxForm(error));
    });
  };
  return (
    <OpenOfficeFormInDrawer
      className="uk-width-2-5"
      orientation="right"
      onSubmit={ onSubmitAddress }
      initialValues={ office }
      onDeleted={ onDeleted }
    >
      { children }
    </OpenOfficeFormInDrawer>
  );
};

OpenOfficeAddressForm.defaultProps = {
  onSubmitted: null,
  onDeleted:   null,
};

OpenOfficeAddressForm.propTypes = {
  office:      PropTypes.object.isRequired,
  onSubmitted: PropTypes.func,
  onDeleted:   PropTypes.func,
};

export default OpenOfficeAddressForm;
