import React from 'react';
import { connect } from 'react-redux';
import Title from 'components/ui/titles';
import Icon from 'components/ui/icon';
import IntermediariesEmptyState from 'components/intermediaries/intermediaries_empty_state';
import withIntermediariesAndProducts from 'components/intermediaries/with_intermediaries_and_products';
import Loader from 'components/ui/loader';
import { CreateIntermediaryButton } from 'components/intermediaries/open_intermediary_form_button';
import IntermediariesTable from 'components/intermediaries/intermediaries_table';
import Header from 'components/header';

const IntermediariesIndex = ({ intermediaries, loading }) => {
  return (
    <>
      <Header title={t('providers.intermediaries.title')} />
      <div
        className="block top-border p-20"
        data-purpose="intermediaries_index"
      >
        <div className="uk-flex uk-flex-middle uk-width-1-1">
          <Title size="h2" className="mb-0 uk-margin-auto-right">
            <Icon icon="sales" className="mr-10" />
            {t('providers.intermediaries.title')}
          </Title>
          {intermediaries.length > 0 && <CreateIntermediaryButton />}
        </div>
        {loading && <Loader />}
        {!loading && (
          <React.Fragment>
            {!intermediaries.length && <IntermediariesEmptyState />}
            {intermediaries.length > 0 && (
              <IntermediariesTable intermediaries={intermediaries} />
            )}
          </React.Fragment>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    intermediaries: state.intermediaries.list,
    loading: state.intermediaries.loading,
  };
};

export default withIntermediariesAndProducts(
  connect(mapStateToProps)(IntermediariesIndex),
);
