export const contractRequestIsLead = (wizard) => {
  return ['draft_lead', 'lead'].includes(wizard.contract_request.status);
};


export const getStepPath = (path, contractRequest) => {
  if (!path) {
    return null;
  }
  if (!contractRequest) {
    return path.replace(':folder_id', 'new').replace(':lead_id', 'new');
  }
  return path.replace(':folder_id', contractRequest.id || 'new').replace(':lead_id', contractRequest.id || 'new');
};
