import React from 'react';
import { Field } from 'redux-form';
import TextInput from 'components/ui/form/text_input';
import CheckboxInput from 'components/ui/form/checkbox_input';

const IntegrationsCheckboxTemplate = ({ template, forCreation }) => {
  return (
    <div className="mb-10">
      <p className="mb-10">
        <label htmlFor={template.id}>{template.label}</label>
      </p>
      <Field
        name={template.id}
        component={CheckboxInput}
        id={template.id}
        required={template.mandatory}
        disabled={forCreation ? false : !template.editable}
        type="text"
      />
    </div>
  );
};

export default IntegrationsCheckboxTemplate;
