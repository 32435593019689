import React            from 'react';
import Icon             from 'components/ui/icon';
import FormErrorMessage from 'components/ui/form/form_error_message';

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'password',
    };
    this.toggleDisplayPassword = this.toggleDisplayPassword.bind(this);
  }

  toggleDisplayPassword(ev) {
    ev.preventDefault();
    const { type } = this.state;
    this.setState({
      type: type === 'password' ? 'text' : 'password',
    });
  }

  render() {
    const { type } = this.state;
    const {
            input,
            label,
            placeholder,
            meta,
            id,
            disabled,
            ...rest
          } = this.props;
    const { asyncValidating, ...restMeta } = meta;
    return (
      <React.Fragment>
        <div className="input-container">
          <div className="uk-inline full-width">
            <input
              className={ `uk-input uk-form-small ${ meta.touched && meta.error ? 'uk-form-danger' : '' }` }
              id={ id }
              { ...input }
              placeholder={ placeholder || label }
              type={ type }
              disabled={ disabled || asyncValidating }
              min="0"
              data-private="lipsum"
              { ...rest }
            />
            { input.value.length > 0 && (
              <a
                href=""
                className="uk-form-icon uk-form-icon-flip"
                onClick={ this.toggleDisplayPassword }
                data-purpose="toggle_password_visibility_button"
              >
                <Icon icon={ `${ type === 'password' ? 'eye' : 'eye-off' }` } />
              </a>
            ) }
          </div>
          <FormErrorMessage { ...restMeta } />
        </div>
      </React.Fragment>);
  }

};

export default PasswordInput;
