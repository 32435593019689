import React                from 'react';
import PropTypes            from 'prop-types';
import find                 from 'lodash/find';
import Bold                 from 'components/ui/text/bold';
import { getCurrentLocale } from 'services/i18n';

export const getTarificationOptionText = (optionsConfiguration, selectedOption) => {
  const config = find(optionsConfiguration, configurationItem => (configurationItem.id === selectedOption.id));
  if (config && selectedOption.value != null) {
    const label = config.label[getCurrentLocale()] || config.label;
    const v = find(config.values, valueItem => (valueItem.value.toString() === selectedOption.value.toString()));
    const value = v && (v.label[getCurrentLocale()] ? v.label[getCurrentLocale()] : v.label);
    if (!value) {
      return null;
    }

    return {
      value,
      label,
    };
  }
};

const ProductTarificationOption = ({ optionsConfiguration, selectedOptions }) => {
  return (
    selectedOptions.map((selectedOption) => {
      const tarificationOptionText = getTarificationOptionText(optionsConfiguration, selectedOption);
      if (!tarificationOptionText) {
        return;
      }
      const { label, value } = tarificationOptionText;
      return (
        <span key={ selectedOption.id } className="mr-20 tarification-option">
          <Bold>{ label }</Bold>
          { ' : ' }
          { value }
        </span>
      );
    })
  );
};

ProductTarificationOption.propTypes = {
  optionsConfiguration: PropTypes.array.isRequired,
  selectedOptions:      PropTypes.array.isRequired,
};

export default ProductTarificationOption;
