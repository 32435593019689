import React, { useEffect, useState }                from 'react';
import PropTypes                                     from 'prop-types';
import { useDispatch, useSelector }                  from 'react-redux';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import SubmitButton                                  from 'components/ui/form/submit_button';
import Alert                                         from 'services/alert';
import CreditCardFormFields                          from 'components/credit_card/form/fields';
import BillingAddressFormContainer                   from 'components/billing_address_form/container';
import CouponField                                   from 'components/coupon_field';
import Title                                         from 'components/ui/titles';
import { createSubscription }                        from 'models/subscriptions/requests';
import { getCurrentUser }                            from 'models/current_user/requests';
import { getAccount }                                from 'models/current_account/requests';
import { trackEvent }                                from 'services/segment';
import Button                                        from 'components/ui/buttons/button';
import SmallItalic                                   from 'components/ui/text/small_italic';
import SubscriptionResume                            from 'components/subscription/subscription_resume';
import ScaForm                                       from 'components/subscription/sca_form';

const SubscriptionForm = ({ onSuccess, selectedPlan }) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const stripe = useStripe();
  const elements = useElements();

  const [step, setStep] = useState(1);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [couponId, setCouponId] = useState();
  const [payWithCreditCard, setPayWithCreditCard] = useState(true);
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    trackEvent('SeenCreditCardForm');
  }, []);

  const getAccountAndCurrentUser = () => {
    onSuccess();
    dispatch(getAccount({ with_stripe_info: true }));
    dispatch(getCurrentUser());
  };

  const onCouponValidOrEmpty = (coupon) => {
    setSubmitDisabled(false);
    setCouponId(coupon ? coupon.id : null);
  };

  const onCouponInvalid = () => {
    setSubmitDisabled(true);
    setCouponId(null);
  };

  const onScaSuccess = () => {
    Alert.success(t('stripe.sca_success'));
    getAccountAndCurrentUser();
  };

  const onScaFailure = () => {
    Alert.error(t('stripe.sca_failure'));
    getAccountAndCurrentUser();
  };

  const billingAddressSuccess = () => {
    setStep(2);
  };

  const createSubscriptionWithCreditCard = () => {
    stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardNumberElement) }).then(({ paymentMethod }) => {
      if (paymentMethod) {
        createSubscription({
          billing:           'charge_automatically',
          payment_method_id: paymentMethod.id,
          coupon_id:         couponId,
          plan_id:           selectedPlan
        }).then((localSubscription) => {
          setSubmitting(false);
          setSubscription(localSubscription);
          if (localSubscription.need_sca) {
            setStep(3);
          } else {
            getAccountAndCurrentUser();
            Alert.success(t('providers.account.subscription.activation_success'));
          }
        }).catch((error) => {
          setSubmitting(false);
          Alert.error(error.error_message);
        });
      } else {
        setSubmitting(false);
        Alert.error(t('providers.account.subscription.activation_error'));
      }
    });
  };

  const createSubscriptionWithoutCreditCard = () => {
    createSubscription({
      billing:   'send_invoice',
      coupon_id: couponId,
      plan_id:   selectedPlan
    }).then(() => {
      setSubmitting(false);
      getAccountAndCurrentUser();
      Alert.success(t('providers.account.subscription.activation_success'));
    }).catch((error) => {
      setSubmitting(false);
      Alert.error(error.error_message);
    });
  };

  const submitSubscriptionForm = (event) => {
    event.preventDefault();
    setSubmitting(true);
    if (payWithCreditCard) {
      createSubscriptionWithCreditCard();
    } else {
      createSubscriptionWithoutCreditCard();
    }
  };

  const toggleCreditCard = () => {
    setPayWithCreditCard((prevValue) => !prevValue);
  };

  return (
    <React.Fragment>
      { step === 1 && (
        <React.Fragment>
          <Title size="h3" className="mb-20">{ t('subscriptions.your_billing_address') }</Title>
          <BillingAddressFormContainer onSuccess={ billingAddressSuccess } />
        </React.Fragment>
      ) }
      { step === 2 && (
        <React.Fragment>
          <form
            onSubmit={ submitSubscriptionForm }
            id="credit_card_form"
            data-purpose="credit_card_form"
          >
            <Title size="h3" marginBottom="20px">{ t('subscriptions.your_credit_card') }</Title>
            { payWithCreditCard && <CreditCardFormFields /> }
            { !payWithCreditCard && (
              <span data-purpose="invoice_pay_explanation">{ t('subscriptions.invoice_pay') }</span>
            ) }
            <p className="uk-text-center mt-20 mb-20">
              <Button
                small
                outline
                type="button"
                onClick={ toggleCreditCard }
                data-purpose="no_credit_card_button"
              >
                <SmallItalic>{ payWithCreditCard ? t('subscriptions.no_credit_card') : t('subscriptions.pay_by_credit_card') }</SmallItalic>
              </Button>
            </p>
            <Title size="h3" marginBottom="20px">{ t('subscriptions.your_coupon') }</Title>
            <CouponField
              onValidOrEmpty={ onCouponValidOrEmpty }
              onInvalid={ onCouponInvalid }
            />

            <div className="uk-text-center mb-20">
              <SubscriptionResume planId={ selectedPlan } />
            </div>

            <SubmitButton
              disabled={ submitting || submitDisabled }
              loading={ submitting }
              large
              className="mt-20"
              text={ t('subscriptions.activate') }
              data-purpose="activate_subscription_button"
            />
          </form>
          <div className="uk-text-center mt-20">
            <Button
              secondary
              onClick={ () => setStep(1) }
            >
              { t('general.actions.back') }
            </Button>
          </div>
        </React.Fragment>
      ) }
      { step === 3 && (
        <React.Fragment>
          <Title size="h3" className="mb-20">{ t('sca_modal.title') }</Title>
          <ScaForm paymentIntentClientSecret={ subscription.last_stripe_payment_intent_client_secret } setupIntentClientSecret={ subscription.stripe_setup_intent_client_secret } onSuccess={ onScaSuccess } onFailure={ onScaFailure } />
        </React.Fragment>
      ) }
    </React.Fragment>
  );
};

SubscriptionForm.defaultProps = {};

SubscriptionForm.propTypes = {
  onSuccess:    PropTypes.func.isRequired,
  selectedPlan: PropTypes.string.isRequired
};

export default SubscriptionForm;
