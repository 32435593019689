import React                    from 'react';
import { connect, useSelector } from 'react-redux';
import {
  NavLink,
  useLocation,
}                               from 'react-router-dom';
import isNil                    from 'lodash/isNil';
import classNames               from 'classnames';
import Icon                     from 'components/ui/icon';
import { getStepPath }          from 'components/wizard_stepper/constants';
import WarningTooltip           from 'components/wizard/warning_tooltip';

const NavigationItem = ({ step, index, contractRequest }) => {
  let item = step;
  const isGroup = step.kind === 'group';
  if (isGroup) {
    item = step.steps[0];
  }
  if (!item) {
    return null;
  }
  const itemLabel = `${ step.humanIndex }. ${ step.label }`;
  const disabled = item.disabledMessages && item.disabledMessages.length > 0;
  const location = useLocation();
  return (
    <li key={ isGroup ? step.slug : item.path }>
      <NavLink
        to={ disabled ? '#' : item.path }
        data-purpose={ !isGroup && `${ item.purpose }_wizard_nav_button` }
        className={ classNames('wizard-step-nav', {
          'disabled': disabled,
        }) }
        isActive={ (match) => {
          if (isGroup) {
            return !isNil(step.steps.find(step => getStepPath(step.path, contractRequest) === location.pathname));
          }
          if (!match) {
            return false;
          }
          return match.url === item.path;
        } }
      >
        { (item.icon || isGroup) && (
          <Icon
            icon={ isGroup ? step.icon : item.icon }
          />
        ) }
        <span
          className="bold-on-hover"
          title={ itemLabel }
        >
          { itemLabel }
        </span>
        <div className="wizard-step-addition">
          { item.loading && (<Icon icon="loading" className="be-spin ml-10" />) }
          { !item.loading && (
            <React.Fragment>
              { disabled && (
                <Icon icon="information"
                      className="information ml-10"
                      data-uk-tooltip={ t(item.disabledMessages[0]) } />
              ) }
              { item.error && (<Icon icon="warning" className="error ml-10" />) }
              { step.warningTooltip && (
                <WarningTooltip name={ step.warningTooltip } className="error" />
              ) }
            </React.Fragment>
          ) }
        </div>
      </NavLink>
      {
        isGroup && (
          <Navigation
            steps={ step.steps }
            parentIndex={ index }
            contractRequest={ contractRequest }
          />
        )
      }
    </li>
  );
};

const Navigation = ({ steps, parentIndex, contractRequest }) => {
  return (
    <ol className="wizard-navigation">
      { steps.map((step, index) => {
        if (!step.visible) {
          return null;
        }
        return (
          <NavigationItem
            step={ step }
            key={ step.path ? step.path : step.slug }
            index={ !isNil(parentIndex) ? `${ parentIndex }.${ index + 1 }` : index + 1 }
            contractRequest={ contractRequest }
          />
        );
      }) }
    </ol>
  );
};


const WizardNavigationStep = ({ contractRequest }) => {
  const navigation = useSelector(state => state.wizardStepper.navigation);
  if (!navigation) {
    return null;
  }
  return (
    <Navigation steps={ navigation } contractRequest={ contractRequest } />
  );
};

const mapStateToProps = (state) => {
  return {
    contractRequest: state.wizard.contract_request,
  };
};

export default connect(mapStateToProps)(WizardNavigationStep);
