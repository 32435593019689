import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import LogoWhite from 'images/insurgate_logo_white.svg';
import LogoDark from 'images/insurgate_logo_dark.svg';
import { useSelector } from 'react-redux';
import { getCurrentUserFromState } from 'models/current_user/reducer';
import {
  INSURANDGO_ACCOUNT_ID,
  MAZDA_ACCOUNT_ID,
} from 'constants/application_constants';

const Logo = ({ url, className, to, dark }) => {
  const currentUser = useSelector(getCurrentUserFromState);
  let logo = url || (dark ? LogoDark : LogoWhite);
  if (currentUser.account && currentUser.account.id == MAZDA_ACCOUNT_ID) {
    //MAZDA
    logo =
      'https://insurgate-public-files.s3.eu-central-1.amazonaws.com/virtual_brand/mazda-insurance.png';
  }
  if (currentUser.account && currentUser.account.id == INSURANDGO_ACCOUNT_ID) {
    //INSURANDGO
    logo =
      'https://insurgate-public-files.s3.eu-central-1.amazonaws.com/virtual_brand/insur-and-go-logo.png';
  }
  return (
    <NavLink className={classNames('logo', className)} to={to}>
      <img src={logo} data-purpose="main_header_logo_image" alt="broker logo" />
    </NavLink>
  );
};

Logo.defaultProps = {
  dark: false,
  url: null,
  className: null,
  to: '/',
};

Logo.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  dark: PropTypes.bool,
  to: PropTypes.string,
};

export default Logo;
