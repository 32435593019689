import React                  from 'react';
import Select, { components } from 'react-select';
import Loader                 from 'components/ui/loader';
import FormErrorMessage       from 'components/ui/form/form_error_message';
import InlineContainer        from 'components/ui/form/inline_container';

const SelectInput = ({
  input,
  id,
  list = [],
  loading,
  onSelected,
  meta,
  className,
  placeholder,
  disabled,
  isClearable,
  inline,
  label,
  warning,
  purpose,
  InputAddition,
  optionListWarning,
  disabledIfEmptyList,
}) => {

  const MenuList = (props) => {
    return (
      <components.MenuList { ...props }>
        <div className="p-5">
          <div className="global-message info p-5 mb-10 xs">
            { optionListWarning }
          </div>
        </div>
        { props.children }
      </components.MenuList>
    );
  };

  const onSelect = (selected) => {
    input.onChange(selected && selected.value.toString());
    if (onSelected) {
      onSelected(selected && selected);
    }
  };

  const getValue = () => {
    if (!list) {
      return null;
    }
    if (!input.value && input.value !== 0) {
      return null;
    }
    return list.find(item => item.value.toString() === input.value.toString());
  };

  const selectInput = (
    <React.Fragment>
      {
        !loading && (
          <div data-purpose={ purpose }>
            <Select
              name={ input.name }
              onChange={ onSelect }
              value={ getValue() }
              options={ list }
              isClearable={ isClearable }
              id={ id }
              isDisabled={ disabled || (disabledIfEmptyList && !list.length) }
              placeholder={ placeholder || t('general.actions.select') }
              data-purpose={ input.name }
              className={ `Select ${ id } react-select-${ input.name } react-select-${ className } ${ className }` }
              components={ optionListWarning ? {
                MenuList,
              } : null }
            />
            { InputAddition && (
              <InputAddition />
            ) }
          </div>
        ) }
      {
        loading && <Loader />
      }
      <FormErrorMessage { ...meta } />
    </React.Fragment>
  );

  if (!inline && !label) {
    return selectInput;
  }

  if (!inline && label) {
    return (
      <div className="input-container">
        { label && (
          <label htmlFor={ id }>{ label }</label>
        ) }
        { selectInput }
      </div>
    );
  }

  return (
    <InlineContainer
      label={ label }
      labelFor={ id }
      warning={ warning }
      meta={ meta }
      field={ selectInput }
    />
  );
};

export default SelectInput;
