export const updateCurrentAccount = account => (
  {
    type: 'ACCOUNT/UPDATE',
    account,
  }
);


export const updateMemberInCurrentAccount = member => (
  {
    type: 'ACCOUNT/UPDATE_MEMBER',
    member,
  }
);

export const removeMemberFromCurrentAccount = memberId => (
  {
    type: 'ACCOUNT/REMOVE_MEMBER',
    memberId,
  }
);

export const addMemberFromCurrentAccount = member => (
  {
    type: 'ACCOUNT/ADD_MEMBER',
    member,
  }
);

export const setAccountOffices = offices => (
  {
    type: 'ACCOUNT/SET_OFFICES',
    offices,
  }
);
