import { Condition } from 'models/condition/condition';

export const isNotLead = [
  new Condition({
    property:  'contract_request.status',
    assertion: 'notEqual',
    value:     'lead',
  }),
  new Condition({
    property:  'contract_request.status',
    assertion: 'notEqual',
    value:     'draft_lead',
  }),
];

export const isOffer = new Condition({
  property:  'contract_request.status',
  assertion: 'equal',
  value:     'offer',
  message:   'wizard.warning.contract_request_is_not_offer',
});

export const isNotOffer = new Condition({
  property:  'contract_request.status',
  assertion: 'notEqual',
  value:     'offer',
  message:   'wizard.warning.contract_request_is_offer',
});

export const haveRiskObject = new Condition({
  property:  'contract_request.risk_object',
  assertion: 'exists',
  message:   'wizard.warning.risk_object_must_be_selected',
});

export const haveAtLeastOneProduct = new Condition({
  property:  'contract_request.tarifications',
  assertion: 'isNotEmpty',
  message:   'wizard.warning.product_must_be_selected',
});

export const notLeadAndHaveAtLeastOneProduct = [
  ...isNotLead,
  haveAtLeastOneProduct,
];

export const haveSelectedCover = new Condition({
  property:  'contract_request.selected_cover_categories',
  assertion: 'isNotEmpty',
  message:   'wizard.warning.cover_must_be_selected',
});

export const notLeadAndHaveSelectedCover = [
  ...isNotLead,
  haveAtLeastOneProduct,
  haveSelectedCover,
];

export const haveSelectedPolicyOwner = new Condition({
  property:  'contract_request.data.policy_owner',
  assertion: 'exists',
  message:   'wizard.warning.no_policy_owner',
});

export const haveSelectedMainDriver = new Condition({
  property:  'contract_request.data.main_driver',
  assertion: 'exists',
  message:   'wizard.warning.no_main_driver',
});

export const haveSelectedOffer = new Condition({
  property:  'contract_request.tarifications[0].offer',
  assertion: 'exists',
  message:   'wizard.warning.no_offer',
});

export const haveSelectedContract = new Condition({
  property:  'contract_request.tarifications[0].contract',
  assertion: 'exists',
  message:   'wizard.warning.no_contract',
});

export const haveSignedOffer = new Condition({
  property:  'contract_request.tarifications[0].offer.signed_at',
  assertion: 'exists',
  message:   'models.wizard_navigation.constants.have_signed_offer_message',
});

export const haveSignedContract = new Condition({
  property:  'contract_request.tarifications[0].contract.signed_at',
  assertion: 'exists',
  message:   'models.wizard_navigation.constants.have_signed_contract_message',
});

export const haveSelectedSecondaryDriver = new Condition({
  property:  'contract_request.data.misc.can_have_secondary_driver',
  assertion: 'isTrue',
});

export const haveSelectedVehicle = new Condition({
  property:  'contract_request.data.vehicle',
  assertion: 'exists',
  message:   'wizard.warning.no_vehicle',
});

export const canHaveSecondaryDriver = new Condition({
  property:  'contract_request.data.misc.can_have_secondary_driver',
  assertion: 'isTrue',
});

export const isCarContractRequest = new Condition({
  property:  'contract_request.risk_object.kind',
  assertion: 'equal',
  value:     'car',
});

export const isMotorbikeContractRequest = new Condition({
  property:  'contract_request.risk_object.kind',
  assertion: 'equal',
  value:     'motorbike',
});

export const isBikeContractRequest = new Condition({
  property:  'contract_request.risk_object.kind',
  assertion: 'equal',
  value:     'bike',
});

export const isVanContractRequest = new Condition({
  property:  'contract_request.risk_object.kind',
  assertion: 'equal',
  value:     'van',
});

export const haveSelectedHousing = new Condition({
  property:  'contract_request.data.housing',
  assertion: 'exists',
  message:   'wizard.warning.no_building',
});

export const haveSelectedCoOwnedSyndicate = new Condition({
  property:  'contract_request.data.co_owned_syndicate',
  assertion: 'exists',
  message:   'wizard.warning.no_co_owned_syndicate',
});

export const haveSelectedAxaBuilding = new Condition({
  property:  'contract_request.data.building',
  assertion: 'exists',
  message:   'wizard.warning.no_building',
});

export const haveSelectedAxaBuildingEvaluation = new Condition({
  property:  'contract_request.data.building.evaluation',
  assertion: 'exists',
  message:   'wizard.warning.no_building_evaluation',
});

export const haveSelectedAxaBuildingPreviousContract = new Condition({
  property:  'contract_request.data.previous_contract',
  assertion: 'exists',
  message:   'wizard.warning.no_previous_contract',
});

export const canCreateTarification = new Condition({
  property:  'contract_request.authorizations.create_tarification',
  assertion: 'isTrue',
});

export const cannotCreateTarification = new Condition({
  property:  'contract_request.authorizations.create_tarification',
  assertion: 'isFalse',
});

export const haveSelectedRisk = new Condition({
  property:  'contract_request.data.risk',
  assertion: 'exists',
  message:   'wizard.warning.no_risk',
});

export const haveTarification = [
  new Condition({
    operator:  'and',
    property:  'contract_request.tarifications[0].covers',
    assertion: 'isNotEmpty',
    message:   'models.wizard_navigation.constants.have_tarification',
  }),
  new Condition({
    operator:  'and',
    property:  'contract_request.tarifications[0].last_error_response.message',
    assertion: 'notExists',
    message:   'models.wizard_navigation.constants.have_tarification',
  }),
];
