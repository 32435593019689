import React     from 'react';
import has       from 'lodash/has';
import PropTypes from 'prop-types';
import Avatar    from 'components/avatar';

const ProductLogo = ({ product, className, size = 35 }) => {
  const logoUrl = has(product, 'logo.normal.url') && product.logo.normal.url;
  const companyLogoUrl = has(product, 'insurance_company.logo.big.url') && product.insurance_company.logo.big.url;
  return (
    <span className={ `product-logo ${ className }` }>
      <Avatar
        key={ logoUrl || companyLogoUrl }
        picture={ logoUrl || companyLogoUrl }
        size={ size }
        name={ product.insurance_company.name }
        border
      />
    </span>

  );
};
ProductLogo.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductLogo;
