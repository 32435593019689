import React                                          from 'react';
import { connect }                                    from 'react-redux';
import { SubmissionError }                            from 'redux-form';
import { prepareErrorsForReduxForm }                  from 'services/rails';
import flatten                                        from 'lodash/flatten';
import Title                                          from 'components/ui/titles';
import IntermediaryForm                               from 'components/intermediaries/intermediary_form';
import { createOrUpdateIntermediaryAndUpdateInStore } from 'models/intermediaries/dispatch_actions';

const IntermediaryContainer = ({
  submitIntermediary,
  products,
  intermediary,
  available_risk_object_ids,
}) => {
  return (
    <React.Fragment>
      <Title size="h3">
        { t('providers.intermediaries.new_intermediary_title') }
      </Title>
      <IntermediaryForm
        products={ products }
        initialValues={ {
          create_only: false,
          available_risk_object_ids,
          ...intermediary,
        } }
        onSubmit={ submitIntermediary }
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch, { closeDrawer }) => {
  return {
    submitIntermediary: (values) => dispatch(createOrUpdateIntermediaryAndUpdateInStore(values)).then(() => {
      if (closeDrawer) {
        closeDrawer();
      }
    }).catch((response) => {
      if (response.data && response.data.errors) {
        throw new SubmissionError(prepareErrorsForReduxForm(response.data.errors));
      }
    }),
  };
};

const mapStateToProps = (state) => {
  return {
    products:                  state.intermediaries.products,
    available_risk_object_ids: flatten(state.config.risk_types.map((type) => type.risk_objects.map((r_o) => r_o.id))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntermediaryContainer);
