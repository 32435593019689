import update from 'immutability-helper';

const defaultState = {
  available_locales: {},
  available_tags:    [],
  risk_objects:      [],
  plans:             [],
  maintenance_mode:  false,
  socket_is_up:      false,
};

const configReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'CONFIG/SET':
      return action.config;
    case 'CONFIG/UPDATE_CONFIG':
      return update(state, {
        global_message:      { $set: action.global_message },
        global_message_type: { $set: action.global_message_type },
        maintenance_mode:    { $set: action.maintenance_mode },
      });
    case 'CONFIG/SET_SOCKET_STATUS':
      return update(state, {
        socket_is_up: { $set: action.is_up },
      });
    default:
      return state;
  }
};

export default configReducer;

export const isSocketConnected = (state) => {
  return state.config.socket_is_up;
};
