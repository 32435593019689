import axios from 'axios';
import {
  getAuthorizationHeader,
  removeAuthorizationToken,
} from 'services/authorization_token';
import { getCurrentLocale } from 'services/i18n';
import Alert from 'services/alert';
import {
  API_DOMAIN,
  API_PROTOCOL,
  INSURGATE_API,
  SIGN_IN_URL,
} from 'constants/application_constants';

const setHeaders = () => {
  const headers = {
    Accept: 'application/json',
    'API-Version': 1,
    'Content-Type': 'application/json',
  };
  if (getAuthorizationHeader()) {
    headers.Authorization = getAuthorizationHeader();
  }
  if (getCurrentLocale()) {
    headers.Locale = getCurrentLocale();
  }
  return headers;
};

const Api = axios.create({
  baseURL: `${API_PROTOCOL}://${API_DOMAIN}`,
  headers: setHeaders(),
});

export const ApiInsurgate = axios.create({
  baseURL: `${API_PROTOCOL}://${INSURGATE_API}`,
  headers: setHeaders(),
});

const onInterceptSuccess = (config) => {
  config.headers = setHeaders();
  return config;
};

const onInterceptError = (error) => {
  return Promise.reject(error);
};

const onResponseSuccess = (response) => {
  return response;
};

const onResponseError = (error) => {
  if (error.response && error.response.status === 401) {
    removeAuthorizationToken();
    if (location.href.includes('?session_expired')) {
      return Promise.reject(error);
    }
    location.href = `${SIGN_IN_URL}?session_expired`;
  } else if (error.response && error.response.status === 403) {
    Alert.error(t('api.error_messages.forbidden'));
    return Promise.reject(error.response);
  }
  if (error.response) {
    return Promise.reject(error.response);
  }
  return Promise.reject(error);
};

// Add a request interceptor

Api.interceptors.request.use(onInterceptSuccess, onInterceptError);

ApiInsurgate.interceptors.request.use(onInterceptSuccess, onInterceptError);

// Add a response interceptor
Api.interceptors.response.use(onResponseSuccess, onResponseError);
ApiInsurgate.interceptors.response.use(onResponseSuccess, onResponseError);

export default Api;
