import React                         from 'react';
import { Redirect }                  from 'react-router-dom';
import { connect }                   from 'react-redux';
import { SubmissionError }           from 'redux-form';
import UserNewPasswordForm           from 'components/user/password/new/user_new_password_form';
import { getUrlSearchParam }         from 'services/url';
import { userCreateNewPassword }     from 'models/user/requests';
import { setUserInStore }            from 'models/current_user/store_actions';
import { prepareErrorsForReduxForm } from 'services/rails';
import { getCurrentLocale }          from 'services/i18n';
import LanguageSwitcher              from 'components/language_switcher/language_switcher';
import OpenbrokerLogo                from 'images/open_broker_logo_dark.svg';
import Button                        from 'components/ui/buttons/button';

class UserNewPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: getUrlSearchParam('password_token'),
    };
    this.onNewPasswordSubmit = this.onNewPasswordSubmit.bind(this);
  }

  onNewPasswordSubmit(values) {
    const { submitNewPasswordAndSignIn } = this.props;
    return submitNewPasswordAndSignIn(values);
  }

  render() {
    const { token } = this.state;

    return (
      <div className="account-container">
        <LanguageSwitcher />
        <div className="account-form">
          <p className="uk-text-center m-0">
            <img src={ OpenbrokerLogo } alt="Openbroker" className="logo" />
          </p>
          <h1 className="title">{ t('general.new_password.title') }</h1>
          <UserNewPasswordForm
            onSubmit={ this.onNewPasswordSubmit }
            initialValues={ { reset_password_token: token } }
          />
          { !token && <Redirect to={ `/${ getCurrentLocale() }/user/sign_in` } /> }
          <p className="uk-text-center">
            <Button
              link
              secondary
              data-purpose="sign_in_button"
              to={ `/${ getCurrentLocale() }/user/sign_in` }
            >
              { t('devise.registrations.new.i_already_have_an_account') }
            </Button>
          </p>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitNewPasswordAndSignIn: user => (
      userCreateNewPassword(user)
        .then((session) => {
          dispatch(setUserInStore(session));
        }).catch((error) => {
        if (error) {
          throw new SubmissionError(prepareErrorsForReduxForm(error));
        }
      })
    ),
  };
};
export default connect(null, mapDispatchToProps)(UserNewPasswordContainer);
