import React                                                   from 'react';
import Button                                                  from 'components/ui/buttons/button';
import { PRODUCT_CONFIGURATION_STEP, trackWizardStep }         from 'models/wizard/constants';
import { resetWizardTarification, setPollingForTarifications } from 'models/wizard/dispatch_actions';
import { useDispatch, useSelector }                            from 'react-redux';
import { isSocketConnected }                                   from 'models/config/reducer';

const ResetTarificationButton = ({ tarification, ...rest }) => {
  const dispatch = useDispatch();
  const isSocketUp = useSelector(state => isSocketConnected(state));
  if (!tarification.authorizations || !tarification.authorizations.reset) {
    return null;
  }
  const resetTarification = () => {
    trackWizardStep(PRODUCT_CONFIGURATION_STEP, {
      contract_request_id: tarification.contract_request_id,
      product_id:          tarification.product.id,
      product_name:        tarification.product.name,
    });
    return dispatch(resetWizardTarification(tarification.id)).then(() => {
      if (!isSocketUp) {
        dispatch(setPollingForTarifications([tarification]));
      }
    });
  };

  return (
    <Button
      warning
      loading={ tarification.computing }
      disabled={ tarification.computing }
      icon="uncheck-circle"
      { ...rest }
      onClick={ (ev) => {
        ev.preventDefault();
        resetTarification();
      } }
    >
      { t('provider.tarification.actions.reset') }
    </Button>
  );
};

export default ResetTarificationButton;
