import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import ActionCableContext from 'services/action_cable/context';

const ActionCableConsumer = ({
  channel,
  onConnected,
  onDisconnected,
  onInitialized,
  onRejected,
  onReceived,
}) => {
  const context = useContext(ActionCableContext);
  const channelObject = useRef();

  const subscribe = () => {
    channelObject.current = context.cable.subscriptions.create(channel, {
      connected: onConnected,
      disconnected: onDisconnected,
      rejected: onRejected,
      received: onReceived,
      initialized: onInitialized,
    });
  };

  const unsubscribe = () => {
    if (channelObject.current) {
      channelObject.current.unsubscribe();
    }
  };

  useEffect(() => {
    if (has(context, 'cable.subscriptions')) {
      subscribe();
    }
    return () => {
      unsubscribe();
    };
  }, [context]);

  return null;
};

ActionCableConsumer.defaultProps = {
  onConnected: null,
  onDisconnected: null,
  onInitialized: null,
  onRejected: null,
};

ActionCableConsumer.propTypes = {
  channel: PropTypes.string.isRequired,
  onReceived: PropTypes.func.isRequired,
  onConnected: PropTypes.func,
  onDisconnected: PropTypes.func,
  onInitialized: PropTypes.func,
  onRejected: PropTypes.func,
};

export default ActionCableConsumer;
