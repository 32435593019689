import React     from 'react';
import PropTypes from 'prop-types';

const ProductErrorList = ({ errors }) => {
  if (!errors) {
    return null;
  }
  return (
    <ul className="uk-list">
      {
        Object.keys(errors).map(key => (
          <li key={ key }>
            { t(`contract_request.errors.${ key }`) }
            <ul className="uk-list">
              { typeof errors[key] === 'object' && (
                Object.keys(errors[key]).map((k, id) => (
                  <li key={ id }>{ errors[key][k] }</li>
                ))
              ) }
              {
                typeof errors[key] !== 'object' && (
                  <li>{ errors[key] }</li>
                )
              }
            </ul>
          </li>
        ))
      }
    </ul>
  );
};

ProductErrorList.defaultProps = {
  errors: null,
};

ProductErrorList.propTypes = {
  errors: PropTypes.any,
};

export default ProductErrorList;
