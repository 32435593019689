import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

const MainMenuItem = ({
  label,
  path,
  onClick,
  purpose,
  authorization,
  authorizations,
}) => {
  if (authorization && !authorizations[authorization]) {
    return null;
  }

  return (
    <li>
      <NavLink
        activeClassName="active"
        to={path}
        title={label()}
        onClick={onClick}
        data-purpose={purpose}
      >
        {label()}
      </NavLink>
    </li>
  );
};

MainMenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  purpose: PropTypes.string,
  authorization: PropTypes.string,
};

const mapStateToProps = ({
  current_user: {
    profile: { authorizations },
  },
}) => ({
  authorizations,
});

export default withRouter(connect(mapStateToProps)(MainMenuItem));
