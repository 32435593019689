import React, {
  useContext,
}                           from 'react';
import { useSelector }      from 'react-redux';
import { Route }            from 'react-router';
import WizardStepperContext from 'components/wizard_stepper/wizard_context';

const InnerStep = (props) => {
  const { step, componentProps, Component, StepMainAction } = props;
  const data = useContext(WizardStepperContext);

  return (
    <WizardStepperContext.Provider value={ {
      activeStep: step,
      ...data,
    } }>
      <div data-purpose={ step.purpose }>
        { step.title && (
          <h2 className="wizard-step-title">{ step.title }</h2>
        ) }
        <Component
          currentStepPath={ step.path }
          StepMainAction={ StepMainAction }
          { ...componentProps }
        />
      </div>
    </WizardStepperContext.Provider>
  );
};

const Step = (props) => {
  const {
          path,
          Component,
          componentProps,
          StepMainAction,
        } = props;
  const navigationStep = useSelector((state) => {
    let s = null;

    state.wizardStepper.navigation.forEach((item) => {
      if (item.kind === 'group') {
        item.steps.forEach((sub) => {
          if (sub.path === path) {
            s = sub;
            return;
          }
        });
      } else {
        if (item.path === path) {
          s = item;
          return;
        }
      }
    });
    return s;
  });

  if (!navigationStep) {
    return null;
  }

  return (
    <Route
      path={ navigationStep.path }
    >
      { navigationStep.visible && (
        <InnerStep
          Component={ Component }
          componentProps={ componentProps }
          StepMainAction={ StepMainAction }
          step={ navigationStep }
        />
      ) }
    </Route>
  );

};

export default Step;
