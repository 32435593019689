import Api from 'services/api';

export const getProducts = (params) => {
  return new Promise((resolve, reject) => {
    Api.get('products', { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
