import Api                                     from 'services/api';
import Alert                                   from 'services/alert';
import { setAuthorizationToken }               from 'services/authorization_token';
import { DESKTOP_SIGN_IN, sendDesktopAppData } from 'services/desktop_app';

export const handleProfileResponse = (response) => {
  try {
    const { authorization } = response.headers;
    const { profile } = response.data;
    if (!authorization) {
      throw new Error('No authorization');
    }
    setAuthorizationToken(authorization.split(' ')[1]);

    const profileResponse = {
      ...profile,
      token: authorization.split(' ')[1],
    };

    sendDesktopAppData(DESKTOP_SIGN_IN, profileResponse);

    return profileResponse;
  } catch (error) {
    console.error('error profile response', error);
    throw error;
  }
};

export const userSignIn = session => (
  new Promise((resolve, reject) => {
    Api.post('sessions', { session }).then((response) => {
      Alert.success(t('general.signin.signin_success'));
      resolve(handleProfileResponse(response));
    }).catch((error) => {
      Alert.error(t('general.signin.signin_error'));
      reject(error.data ? error.data.errors : error);
    });
  })
);

export const userResetPassword = user => (
  new Promise((resolve, reject) => {
    Api.post('password_resets', {
      user: user,
    }).then(() => {
      Alert.success(t('general.password_reset.email_sent_success'));
      resolve();
    }).catch((error) => {
      Alert.error(t('general.password_reset.email_sent_error'));
      reject(error.data.errors);
    });
  })
);

export const userCreateNewPassword = user => (
  new Promise((resolve, reject) => {
    Api.put('password_resets', {
      user,
    }).then((response) => {
      Alert.success(t('general.password_reset.new_password_success'));
      resolve(handleProfileResponse(response));
    }).catch((error) => {
      Alert.error(t('general.password_reset.new_password_error'));
      reject(error.data.errors);
    });
  })
);

export const intermediarySignIn = token => (
  new Promise((resolve, reject) => {
    Api.post('sessions', { session: { token } }).then((response) => {
      Alert.success(t('general.signin.signin_success'));
      resolve(handleProfileResponse(response));
    }).catch((error) => {
      Alert.error(t('general.signin.signin_error'));
      reject(error.data ? error.data.errors : error);
    });
  })
);
