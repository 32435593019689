import React, { useState }           from 'react';
import PropTypes                     from 'prop-types';
import {
  Field,
  FormSection, formValueSelector,
  getFormMeta,
}                                    from 'redux-form';
import { connect }                   from 'react-redux';
import GoogleMapReact                from 'google-map-react';
import ImageMarker                   from 'images/map_marker.svg';
import { onlyInt, required }         from 'models/application/constants';
import label                         from 'components/ui/form/input_label';
import TextInput                     from 'components/ui/form/text_input';
import InlineContainer               from 'components/ui/form/inline_container';
import AddressPartWithAutocomplete   from 'components/address/address_part_with_autocomplete';
import { GOOGLE_JAVASCRIPT_API_KEY } from 'constants/application_constants';

const createMapOptions = (maps) => {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style:    maps.ZoomControlStyle.SMALL,
    },
    streetViewControl:  true,
    mapTypeId:          maps.MapTypeId.SATELLITE,
  };
};

const OpenbrokerMapMarker = () => (
  <img
    src={ ImageMarker }
    alt=""
    style={ {
      height:    '60px',
      transform: 'translate(-50%, -50%)',
    } }
  />
);

const AddressForm = ({
  change,
  requiredFields,
  formFor,
  formMetas,
  inline,
  sectionName,
  coords,
  validates = {},
  address,
  label,
  displayMap = false,
}) => {
  const [map, setMap] = useState(null);

  const {
          street_name,
          street_number,
          city,
          street_box,
          zip_code,
          letter,
        } = requiredFields;

  const showAddressForm = () => (
    street_name || street_number || city || street_box || zip_code
  );

  const precompleteAddress = (address) => {
    Object.keys(address).forEach((part) => {
      change(`${ sectionName }.${ part }`, address[part]);
    });
  };

  if (!showAddressForm()) {
    return null;
  }

  const updateMap = (value, label, firstMap) => {
    const mapToUse = map || firstMap;
    if (!mapToUse) {
      return;
    }
    const addressToLook = {
      ...address,
      [label]: value,
    };
    const service = new google.maps.places.PlacesService(mapToUse);
    service.findPlaceFromQuery({
      query:  Object.values(addressToLook).join(' '),
      fields: ['geometry'],
    }, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        change(`${ sectionName }.lat`, results[0].geometry.location.lat());
        change(`${ sectionName }.lng`, results[0].geometry.location.lng());
      }
    });
  };

  const fields = (
    <div className="uk-width-1-1">
      <div data-uk-grid="" className="uk-grid-small address-form">

        <Field
          type="hidden"
          name="lat"
          id="lat"
          component="input"
        />
        <Field
          type="hidden"
          name="lng"
          id="lng"
          component="input"
        />
        <div className={ letter ? 'uk-width-3-6' : 'uk-width-3-5' }>
          <label htmlFor="street_name">
            { t('activerecord.attributes.address.street_name') }
          </label>
          <Field
            type="text"
            disabled={ !street_name }
            placeholder={ t('general.address.street') }
            name="street_name"
            id="street_name"
            precompleteAddress={ precompleteAddress }
            validate={ street_name && (validates.street_name || [required]) }
            component={ AddressPartWithAutocomplete }
            searchOptions={ { types: ['address'] } }
            precompleteInputs={ ['street_number', 'zip_code', 'city'] }
            autoComplete="not-now"
          />
        </div>
        <div className={ letter ? 'uk-width-1-6' : 'uk-width-1-5' }>
          <label htmlFor="street_number">
            { t('activerecord.attributes.address.street_number') }
          </label>
          <Field
            type="text"
            placeholder={ t('general.address.number') }
            name="street_number"
            id="street_number"
            disabled={ !street_number }
            validate={ street_number && (validates.street_number || [required, onlyInt]) }
            component={ TextInput }
            onInput={ (val) => updateMap(val, 'street_number') }
            autoComplete="not-now"
          />
        </div>
        <div className={ letter ? 'uk-width-1-6' : 'uk-width-1-5' }>
          <label htmlFor="street_box">
            { t('activerecord.attributes.address.street_box') }
          </label>
          <Field
            type="text"
            name="street_box"
            id="street_box"
            disabled={ !street_number }
            placeholder={ t('general.address.street_box') }
            validate={ street_number && (validates.street_box || []) }
            component={ TextInput }
            onInput={ (val) => updateMap(val, 'street_box') }
            autoComplete="not-now"
            maxLength="3"
          />
        </div>
        { letter && (
          <div className="uk-width-1-6">
            <label htmlFor="letter">
              { t('activerecord.attributes.address.letter') }
            </label>
            <Field
              type="text"
              name="letter"
              id="letter"
              disabled={ !street_number }
              placeholder={ t('general.address.letter') }
              validate={ street_number && (validates.letter || []) }
              component={ TextInput }
              onInput={ (val) => updateMap(val, 'letter') }
              autoComplete="not-now"
              maxLength="3"
            />
          </div>
        ) }
        <div className="uk-width-3-5">
          <label htmlFor="city">
            { t('activerecord.attributes.address.city') }
          </label>
          <Field
            type="text"
            placeholder={ t('general.address.city') }
            validate={ city && (validates.city || [required]) }
            name="city"
            disabled={ !city }
            id="city"
            component={ TextInput }
            onInput={ (val) => updateMap(val, 'city') }
            autoComplete="not-now"
          />
        </div>
        <div className="uk-width-2-5">
          <label htmlFor="zip_code">
            { t('activerecord.attributes.address.zip_code') }
          </label>
          <Field
            type="text"
            placeholder={ t('general.address.postal_code') }
            validate={ zip_code && (validates.zip_code || [required]) }
            name="zip_code"
            disabled={ !zip_code }
            id="zip_code"
            component={ TextInput }
            onInput={ (val) => updateMap(val, 'zip_code') }
            autoComplete="not-now"
          />
        </div>
      </div>
      { displayMap && (
        <div
          className="mt-20"
          style={ {
            height: '300px',
            width:  '100%',
          } }
        >
          <GoogleMapReact
            bootstrapURLKeys={ { key: GOOGLE_JAVASCRIPT_API_KEY } }
            defaultCenter={ {
              lat: 50.503887,
              lng: 4.469936,
            } }
            defaultZoom={ 15 }
            center={ coords }
            zoom={ coords ? 19 : 15 }
            onGoogleApiLoaded={ ({ map }) => {
              setMap(map);
              updateMap(null, null, map);
            } }
            options={ (maps) => {
              return createMapOptions(maps);
            } }
          >
            { coords && (
              <OpenbrokerMapMarker
                lat={ coords.lat }
                lng={ coords.lng }
              />
            ) }
          </GoogleMapReact>
        </div>
      ) }
    </div>
  );

  if (!inline) {
    return fields;
  }

  return (
    <FormSection name={ sectionName }>
      <InlineContainer
        inlineContainerClass="top"
        label={ label || t('driver.label.address') }
        warning={ [
          `${ formFor }.street_name`,
          `${ formFor }.street_number`,
          `${ formFor }.street_box`,
          `${ formFor }.city`,
          `${ formFor }.zip_code`,
        ] }
        meta={ {
          dirty: !!formMetas.address,
        } }
        field={ fields }
      />
    </FormSection>
  );
};


const mapStateToProps = (state, { form, formFor }) => {
  if (!form) {
    return {
      formMetas: {},
    };
  }
  const selector = formValueSelector(form);
  const lat = selector(state, `${ formFor }.lat`);
  const lng = selector(state, `${ formFor }.lng`);
  const coords = lat && lng ? {
    lat,
    lng,
  } : null;
  return {
    formMetas: getFormMeta(form)(state),
    coords,
    address:   {
      street_name:   selector(state, `${ formFor }.street_name`),
      street_number: selector(state, `${ formFor }.street_number`),
      zip_code:      selector(state, `${ formFor }.zip_code`),
      street_box:    selector(state, `${ formFor }.street_box`),
      city:          selector(state, `${ formFor }.city`),
    },
  };
};

AddressForm.propTypes = {
  inline: PropTypes.bool,
};

AddressForm.defaultProps = {
  inline:      true,
  sectionName: 'address',
};

export default connect(mapStateToProps)(AddressForm);
