import { toast } from 'react-toastify';

const autoClose = 5000;

export default new class Alert {
  constructor() {
    window.notificationEnabled = true;
  }

  preventNotification() {
    window.notificationEnabled = false;
  }

  enableNotification() {
    window.notificationEnabled = true;
  }

  success(message) {
    if (!window.notificationEnabled) {
      return;
    }
    toast.success(message, {
      position:         'top-right',
      autoClose,
      hideProgressBar:  true,
      closeOnClick:     true,
      pauseOnHover:     true,
      draggable:        false,
      draggablePercent: 10,
      className:        'notif--success',
    });
  }

  error(message) {
    toast.error(message, {
      position:         'top-right',
      autoClose,
      hideProgressBar:  true,
      closeOnClick:     true,
      pauseOnHover:     true,
      draggable:        false,
      draggablePercent: 10,
      className:        'notif--error',
    });
  }

  info(component) {
    if (!window.notificationEnabled) {
      return;
    }
    toast.info(component, {
      position:        'bottom-left',
      autoClose:       false,
      hideProgressBar: true,
      closeOnClick:    false,
      pauseOnHover:    false,
      draggable:       false,
    });
  }
}();

export const alertMessage = (message) => {
  toast(message, {
    position:        'top-right',
    autoClose:       false,
    hideProgressBar: true,
    closeOnClick:    false,
    pauseOnHover:    false,
    draggable:       false,
  });
}
