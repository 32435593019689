import React               from 'react';
import PropTypes           from 'prop-types';
import { useSelector }     from 'react-redux';
import SimpleBar           from 'simplebar-react';
import MembersRoleTable    from 'components/members/index/members_role_table';
import MemberFormContainer from 'components/members/form';
import Button              from 'components/ui/buttons/button';
import openDialogOnClick   from 'components/ui/buttons/helpers/open_dialog_on_click';

const MembersIndex = () => {
  let memberModalRef = null;

  const members = useSelector((state) => state.account.members);

  const OpenMemberFormOnClick = openDialogOnClick()(({ onClick }) => {
    return <Button className="uk-hidden" onClick={ onClick } />;
  });

  const closeModal = () => {
    if (memberModalRef) {
      memberModalRef.closeDialog();
    }
  };

  return (
    <div className="block top-border p-20" data-purpose="members_index">
      <div className="brokerage-section-header">
        <h2 className="brokerage-section-title">
          { t('providers.account.members.title') }
        </h2>
        <Button
          primary
          data-purpose="add_member_button"
          onClick={ () => memberModalRef.openDialog() }
        >
          { t('providers.account.members.add_member') }
        </Button>
      </div>
      <SimpleBar>
        <MembersRoleTable members={ members } />
      </SimpleBar>
      <OpenMemberFormOnClick
        dialogTitle={ t('providers.account.members.add_member') }
        ref={ (ref) => memberModalRef = ref }
      >
        <MemberFormContainer onFormSubmitted={ closeModal } />
      </OpenMemberFormOnClick>
    </div>
  );
};

export default MembersIndex;
