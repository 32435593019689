import Api   from 'services/api';
import Alert from 'services/alert';

export const createSubscription = params => (
  new Promise((resolve, reject) => {
    Api.post('account/subscription', { subscription: params }).then((response) => {
      const { subscription } = response.data;
      resolve(subscription);
    }).catch((response) => {
      reject(response.data);
    });
  })
);
