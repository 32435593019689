import React      from 'react';
import classNames from 'classnames';
import Icon       from 'components/ui/icon';
import { Link }   from 'react-router-dom';

const BtnComponent = ({ link, onDialogClose, onDialogOpen, ...restProps }) => {
  if (link) {
    return <Link to={ link } { ...restProps } />;
  }
  return <button
    { ...restProps }
  />;
};

const Button = ({
  className,
  primary,
  secondary,
  warning,
  iconOnly,
  large,
  loading,
  children,
  iconText,
  round,
  inverted,
  icon,
  outline,
  link,
  fullWidth,
  small,
  textCenter,
  tiny,
  ...rest
}) => {
  const buttonInner = (
    <React.Fragment>
      { icon && !loading && (<Icon icon={ icon } />) }
      { loading && (
        <span className="loading-content">
          <Icon icon="loading" className="be-spin" />
        </span>
      ) }
      { !loading && children }
    </React.Fragment>
  );

  return (
    <BtnComponent
      link={ link }
      className={ classNames(`uk-button uk-button-small ${ className || '' }`, {
        'uk-button-primary':   primary,
        'uk-button-secondary': secondary,
        'uk-button-danger':    warning,
        'uk-button-large':     large,
        'uk-button-default':   outline,
        'icon-only-button':    iconOnly,
        'round-button':        round,
        'button-inverted':     inverted,
        'uk-width-1-1':        fullWidth,
        'btn-tiny':            tiny,
      }) }
      disabled={ loading }
      { ...rest }
    >
      { (iconText || icon) && (
        <span
          className={ classNames('', {
            'icon-text':       !loading,
            'loading-content': loading,
            'justify-center':  textCenter,
          }) }
        >
          { buttonInner }
        </span>
      ) }
      { !iconText && !icon && buttonInner }
    </BtnComponent>
  );
};

export default Button;
