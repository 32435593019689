import React            from 'react';
import FormErrorMessage from 'components/ui/form/form_error_message';
import InlineContainer  from 'components/ui/form/inline_container';

const TextareaInput = ({
  input: {
           onChange,
           ...restInput
         },
  label,
  placeholder,
  type,
  meta = {},
  id,
  disabled,
  purpose,
  inline,
  warning,
  noClass,
  className,
  containerClass,
  ...    rest
}) => {
  const replaceCommaByDot = ev => (
    onChange(ev.target.value.replace(',', '.'))
  );
  const { asyncValidating, ...restMeta } = meta;
  const enhancedOnChange = type === 'number' ? replaceCommaByDot : onChange;
  const textInput = (
    <React.Fragment>
      <div className="input-container">
        { label && !inline && (
          <label htmlFor={ id }>{ label }</label>
        ) }
        <textarea
          id={ id }
          className={ `uk-textarea uk-form-small ${ meta.touched && meta.error ? 'uk-form-danger' : '' } ${ meta.touched && meta.warning ? 'input--warning' : '' } ${ className ? className : '' }` }
          onChange={ enhancedOnChange }
          { ...restInput }
          placeholder={ placeholder || label }
          type={ type }
          data-purpose={ purpose }
          disabled={ disabled || asyncValidating }
          min="0"
          { ...rest }
        />
        <FormErrorMessage { ...restMeta } />
      </div>
    </React.Fragment>
  );

  if (!inline) {
    return textInput;
  }

  return (
    <InlineContainer
      labelFor={ id }
      label={ label }
      warning={ warning }
      field={ textInput }
      meta={ meta }
      inlineContainerClass={ containerClass }
    />
  );
};

export default TextareaInput;
