import React       from 'react';
import PropTypes   from 'prop-types';
import ReactAvatar from 'react-avatar';

const Avatar = ({
  name, picture, size, className, ...rest
}) => (
  <span className={ `avatar ${ className }` }>
    <ReactAvatar
      name={ name }
      size={ size }
      round
      src={ picture }
      { ...rest }
    />
  </span>
);

Avatar.defaultProps = {
  size:    40,
  picture: '',
  name:    '',
  border:  false,
};

Avatar.propTypes = {
  name:    PropTypes.string,
  picture: PropTypes.string,
  size:    PropTypes.number,
  border:  PropTypes.bool,
};

export default Avatar;
