import React            from 'react';
import FormErrorMessage from 'components/ui/form/form_error_message';
import InlineContainer  from 'components/ui/form/inline_container';

const MultiButton = ({
  input,
  list,
  onSelected,
  meta,
  className,
  disabled,
  label,
  warning,
  inline,
  labelAddition,
  labelClass,
}) => {

  const onSelect = (selected) => {
    input.onChange(selected && selected.value.toString());
    if (onSelected) {
      onSelected(selected && selected);
    }
  };
  const multiButton = (
    <div
      className="field uk-button-group"
      data-purpose={ input.name }
    >
      { list.map(item => (
        <button
          key={ item.value }
          className={ `uk-button uk-button-small ${ item.value.toString() === input.value.toString() ? 'uk-button-primary' : 'uk-button-default' } ${ className || '' }` }
          type="button"
          disabled={ disabled }
          data-purpose={ `${ item.value.toLowerCase() }_button` }
          onClick={ () => onSelect(item) }
        >
          { item.label }
        </button>
      )) }
      <FormErrorMessage { ...meta } />
    </div>
  );

  if (!inline) {
    return multiButton;
  }

  return (
    <InlineContainer
      label={ label }
      warning={ warning }
      meta={ meta }
      field={ multiButton }
      labelAddition={ labelAddition }
      labelClass={ labelClass }
    />
  );
};

export default MultiButton;
