import React            from 'react';
import PropTypes        from 'prop-types';
import SubscriptionForm from 'components/subscription/form';

const SubscriptionFormContainer = ({ onSuccess, selectedPlan }) => (
  <SubscriptionForm onSuccess={ onSuccess } selectedPlan={ selectedPlan } />
);

SubscriptionFormContainer.defaultProps = {};

SubscriptionFormContainer.propTypes = {
  onSuccess:    PropTypes.func.isRequired,
  selectedPlan: PropTypes.string,
};

export default SubscriptionFormContainer;
