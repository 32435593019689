import Api   from 'services/api';
import Alert from 'services/alert';

export const fetchOffices = () => {
  return new Promise((resolve, reject) => {
    Api.get('offices').then((response) => {
      resolve(response.data.offices);
    }).catch((error) => {
      reject(error);
    });
  });
};

export const updateOffice = ({ primary, ...officeToUpdate }) => {
  const officeToSend = {
    office: {
      ...officeToUpdate,
    },
  };
  if (primary) {
    officeToSend.office.primary = primary;
  }
  return new Promise((resolve, reject) => {
    Api.put(`offices/${ officeToUpdate.id }`, officeToSend).then((response) => {
      Alert.success(t('providers.account.addresses.update.success'));
      resolve(response.data.office);
    }).catch((error) => {
      Alert.error(t('providers.account.addresses.update.error'));
      reject(error.data.errors);
    });
  });
};

export const createOffice = ({ primary, ...officeToCreate }) => {
  const officeToSend = {
    office: {
      ...officeToCreate,
    },
  };
  if (primary) {
    officeToSend.office.primary = primary;
  }
  return new Promise((resolve, reject) => {
    Api.post('offices', officeToSend).then((response) => {
      Alert.success(t('providers.account.addresses.update.success'));
      resolve(response.data.office);
    }).catch((error) => {
      Alert.error(t('providers.account.addresses.update.error'));
      reject(error.data.errors);
    });
  });
};

export const deleteOffice = (officeToDelete) => {
  return new Promise((resolve, reject) => {
    Api.delete(`offices/${ officeToDelete.id }`).then(() => {
      Alert.success(t('providers.account.addresses.delete.success'));
      resolve();
    }).catch((error) => {
      Alert.error(t('providers.account.addresses.delete.error'));
      reject(error);
    });
  });
};
