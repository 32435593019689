import Api                       from 'services/api';
import Alert                     from 'services/alert';
import { handleProfileResponse } from 'models/user/requests';

export const getInvitation = (invitation_token) => {
  return new Promise((resolve, reject) => {
    Api.get('invitations', { params: { invitation_token } }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('providers.account.members.get_invitation_error'));
      reject(error);
    });
  });
};

export const acceptInvitation = (invitation) => {
  return new Promise((resolve, reject) => {
    Api.put('invitations', invitation).then((response) => {
      Alert.success(t('providers.account.members.invitation_accepted'));
      resolve(handleProfileResponse(response));
    }).catch((error) => {
      Alert.error(t('general.invitation.invitation_error'));
      reject(error.data ? error.data.errors : error);
    });
  });
};
