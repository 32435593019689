import React                          from 'react';
import PropTypes                      from 'prop-types';
import { connect }                    from 'react-redux';
import Button                         from 'components/ui/buttons/button';
import { saveQuote }                  from 'models/tarifications/requests';
import { updateTarification }         from 'models/wizard/store_actions';
import { isSocketConnected }          from 'models/config/reducer';
import { setPollingForTarifications } from 'models/wizard/dispatch_actions';

const TarificationInsuranceCompanySyncButton = ({ tarification, syncQuickTarificationAndDispatch, label, isSocketUp, ...props }) => {
  const perform = () => {
    syncQuickTarificationAndDispatch(isSocketUp);
  };

  const buttonLabel = label || tarification.insurance_company.smart_link_name ? t('general.actions.sync_on', { tool_name: tarification.insurance_company.smart_link_name }) : t('components.tarifications.table.insurance_company_sync_button.sync');

  return (
    <Button
      { ...props }
      onClick={ perform }
      icon="api"
    >
      { buttonLabel }
    </Button>
  );
};

TarificationInsuranceCompanySyncButton.propTypes = {
  tarification:                     PropTypes.object.isRequired,
  syncQuickTarificationAndDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { tarification }) => {
  return {
    syncQuickTarificationAndDispatch: (isSocketUp) => (
      saveQuote(tarification).then((newTarification) => {
        dispatch(updateTarification(newTarification));
        if (!isSocketUp) {
          dispatch(setPollingForTarifications([newTarification]));
        }
      })
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    isSocketUp: isSocketConnected(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TarificationInsuranceCompanySyncButton);
