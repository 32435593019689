import React, { useContext } from 'react';
import classNames            from 'classnames';
import Icon                  from 'components/ui/icon';
import WizardStepperContext  from 'components/wizard_stepper/wizard_context';

const ChangeStepButton = ({
  loading,
  disabled,
  forPrevious = false,
  onClick,
}) => {

  const { activeStep, wizardNavigation } = useContext(WizardStepperContext);
  const { previous, next } = wizardNavigation.getNextAndPreviousPath(activeStep);
  const targetStepPath = forPrevious ? previous : next;
  if (!targetStepPath) {
    return null;
  }

  return (
    <a
      href={ disabled ? '#' : targetStepPath }
      className={
        classNames('wizard-button', {
          'previous mr-20': forPrevious,
          'disabled':       loading || disabled,
        })
      }
      disabled={ loading || disabled }
      onClick={ (e) => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        if (!disabled) {
          onClick(e, targetStepPath);
        }
      } }
      data-purpose={ forPrevious ? 'previous_step_button' : 'next_step_button' }
    >
      { loading && (<Icon icon="loading" className="be-spin loading-icon" />) }
      { !loading && (
        <React.Fragment>
          { forPrevious && (<Icon icon="keyboard-arrow-left" />) }
          { t(forPrevious ? 'wizard.actions.previous' : 'wizard.actions.next') }
          { !forPrevious && (<Icon icon="keyboard-arrow-right" />) }
        </React.Fragment>
      ) }
    </a>
  );
};


export default ChangeStepButton;
