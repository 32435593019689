import Api from 'services/api';

export const getStripePlan = (planId) => (
  new Promise((resolve, reject) => {
    Api.get(`/stripe_plans/${planId}`).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  })
);
