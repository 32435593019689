import React     from 'react';
import PropTypes from 'prop-types';
import Button    from 'components/ui/buttons/button';

const SubmitButton = (props) => {
  const { disabled, loading, text, icon, justify, normal, onClick, containerClassName, ...rest } = props;
  return (
    <div className={ `submit-button-content ${ containerClassName || '' }` }>
      <Button
        type="submit"
        primary
        link={ false }
        { ...rest }
        loading={ loading }
        iconText={ !!icon }
        disabled={ disabled || loading }
        icon={ icon }
      >
        { text || t('general.actions.save') }
      </Button>
    </div>
  );
};

SubmitButton.defaultProps = {
  disabled:  false,
  loading:   false,
  secondary: false,
  normal:    false,
  primary:   true,
};

SubmitButton.propTypes = {
  disabled:  PropTypes.bool,
  loading:   PropTypes.bool,
  secondary: PropTypes.bool,
  text:      PropTypes.string,
  normal:    PropTypes.bool,
};

export default SubmitButton;
