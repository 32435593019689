import React              from 'react';
import PropTypes          from 'prop-types';
import ScaForm            from 'components/subscription/sca_form';
import CreditCardForm     from 'components/credit_card/form';
import { connect }        from 'react-redux';
import SubscriptionResume from 'components/subscription/subscription_resume';

class ScaFormWithCreditCard extends React.Component {
  constructor(props) {
    super(props);
    this.onCreditCardFormSuccess = this.onCreditCardFormSuccess.bind(this);
    this.state = { stripeSourceId: null };
    this.stripeSourceId = null;
  }

  onCreditCardFormSuccess(creditCard, source) {
    this.setState({ stripeSourceId: source.id });
  }

  render() {
    const { stripeSourceId } = this.state;
    const { onSuccess, onFailure, paymentIntentClientSecret, setupIntentClientSecret, account, account: { subscription } } = this.props;
    return (
      <React.Fragment>
        { !stripeSourceId && (
          <React.Fragment>
            <CreditCardForm onSuccess={ this.onCreditCardFormSuccess } />
            <h3>{ t('subscriptions.sca_form.reminder') }</h3>
            { !account.custom_stripe_plan_id && <SubscriptionResume selectedPlan={ subscription.plan } /> }
          </React.Fragment>
        ) }
        { stripeSourceId && (
          <ScaForm paymentIntentClientSecret={ paymentIntentClientSecret } setupIntentClientSecret={ setupIntentClientSecret } stripeSourceId={ stripeSourceId } onSuccess={ onSuccess } onFailure={ onFailure } />
        ) }
      </React.Fragment>
    );
  }
}

ScaFormWithCreditCard.defaultProps = {
  paymentIntentClientSecret: PropTypes.string,
  setupIntentClientSecret:   PropTypes.string
};

ScaFormWithCreditCard.propTypes = {
  paymentIntentClientSecret: PropTypes.string,
  setupIntentClientSecret:   PropTypes.string,
  onSuccess:                 PropTypes.func.isRequired,
  onFailure:                 PropTypes.func.isRequired,
  account:                   PropTypes.object.isRequired
};

const mapStateToProps = ({ account }) => {
  return {
    account,
  };
};

export default connect(mapStateToProps)(ScaFormWithCreditCard);
