import React, { useState, useEffect } from 'react';
import Title from 'components/ui/titles';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import CheckboxInput from 'components/ui/json_form/fields/checkbox';
import LoadOnClickButton from 'components/ui/buttons/load_on_click_button';
import { openLinkOrDownloadFile } from 'services/file';
import Loader from 'components/ui/loader';
import { getTarificationsPdf } from 'models/tarifications/requests';
import DocumentType from 'components/documents/document_type';

const DocumentsTable = ({
  tarifications,
  contractRequestId,
  noTitle = false,
  canDownload = true,
  onSelectedDocumentChange,
  filters = [],
}) => {
  const tarificationsByProduct = groupBy(
    tarifications,
    (tarif) => tarif.product.display_name,
  );
  const [selectedDocumentsToPrint, setSelectedDocumentsToPrint] = useState([]);
  const documentsByProduct = {};
  let allDocuments = [];
  const computing = tarifications.find((tarif) => tarif.computing);
  Object.keys(tarificationsByProduct).forEach((productName) => {
    documentsByProduct[productName] = compact(
      flatten(
        tarificationsByProduct[productName].map((tarif) => {
          allDocuments = [...tarif.documents, ...allDocuments];
          return tarif.documents;
        }),
      ),
    );
  });

  const productKeys = Object.keys(documentsByProduct);

  const onDocumentChange = (ev, doc) => {
    if (!ev) {
      return;
    }
    if (ev.target.checked) {
      setSelectedDocumentsToPrint((prev) => [...prev, doc]);
    } else {
      setSelectedDocumentsToPrint(
        selectedDocumentsToPrint.filter((selected) => selected.id !== doc.id),
      );
    }
  };

  useEffect(() => {
    if (onSelectedDocumentChange) {
      onSelectedDocumentChange(selectedDocumentsToPrint);
    }
  }, [selectedDocumentsToPrint]);

  const downloadCombinedDocuments = () => {
    return getTarificationsPdf(contractRequestId, selectedDocumentsToPrint);
  };

  if (computing) {
    return <Loader />;
  }

  if (filters.length > 0) {
    allDocuments = allDocuments.filter((document) =>
      filters.includes(document.type),
    );
  }

  if (!allDocuments.length) {
    return null;
  }

  return (
    <div className="mt-20">
      {!noTitle && (
        <Title size="h2">{t('components.documents.table.index.title')}</Title>
      )}
      <table className="uk-table uk-table-small uk-table-hover uk-table-middle">
        <thead>
          <tr>
            <th className="uk-table-shrink uk-text-center" />
            <th className="uk-table-expand">
              {t('components.documents.table.index.document_name')}
            </th>
            <th className="uk-table-shrink uk-text-center">
              {t('components.documents.table.index.type')}
            </th>
            <th className="uk-table-shrink uk-text-right">
              {t('components.documents.table.index.actions')}
            </th>
          </tr>
        </thead>
        {!allDocuments.length && (
          <tbody>
            <tr>
              <td colSpan={3} className="uk-text-center small-italic">
                {t('components.documents.table.index.no_document_available')}
              </td>
            </tr>
          </tbody>
        )}
        {allDocuments.length > 0 &&
          productKeys.map((productKey, id) => {
            if (documentsByProduct[productKey].length > 0) {
              return (
                <tbody key={id}>
                  {productKeys.length > 1 && (
                    <tr>
                      <td />
                      <td colSpan={4} className="uk-text-bold">
                        {productKey}
                      </td>
                    </tr>
                  )}
                  {documentsByProduct[productKey].map((doc) => {
                    if (filters.length > 0) {
                      if (!filters.includes(doc.type)) {
                        return null;
                      }
                    }
                    return (
                      <tr key={doc.id}>
                        <td>
                          <CheckboxInput
                            input={{
                              onChange: (ev) => onDocumentChange(ev, doc),
                              name: `doc_${doc.id}`,
                            }}
                          />
                        </td>
                        <td>
                          <label htmlFor={`doc_${doc.id}`}>{doc.name}</label>
                        </td>
                        <td>
                          <DocumentType type={doc.type} />
                        </td>
                        <td className="uk-text-right">
                          <a
                            href="#"
                            onClick={(ev) => {
                              ev.preventDefault();
                              return openLinkOrDownloadFile(doc.url, doc.name);
                            }}
                          >
                            {t('general.actions.download')}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              );
            }
          })}
        {canDownload && (
          <tfoot>
            <tr>
              <td colSpan={4}>
                <LoadOnClickButton
                  secondary
                  disabled={selectedDocumentsToPrint.length === 0}
                  onClick={downloadCombinedDocuments}
                  className="mr-10"
                >
                  {t(
                    'components.documents.table.index.download_selected_documents',
                  )}
                </LoadOnClickButton>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default DocumentsTable;
