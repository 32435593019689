import React from 'react';

const Status = ({ status, className, statuses = [] }) => {
  const getStatusLabel = () => {
    if (statuses.length > 0) {
      const stat = statuses.find((s) => s.value === status);
      return stat ? stat.label : '';
    } else {
      return t(`components.status.statuses.${status}`);
    }
  };
  return (
    <span className={`ob-status ${status} ${className}`}>
      {getStatusLabel(status)}
    </span>
  );
};

export default Status;
