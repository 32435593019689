import React                                           from 'react';
import PropTypes                                       from 'prop-types';
import {
  reduxForm,
  Field,
}                                                      from 'redux-form';
import { connect }                                     from 'react-redux';
import { Row, Col }                                    from 'react-flexbox-grid';
import TextInput                                       from 'components/ui/form/text_input';
import SymbolTextInput                                 from 'components/ui/form/symbol_text_input';
import SubmitButton                                    from 'components/ui/form/submit_button';
import SelectInput                                     from 'components/ui/form/select_input';
import { convertAccountMembersToManagerList } from 'models/current_account/helpers';
import Title                                           from 'components/ui/titles';
import Icon                                            from 'components/ui/icon';
import { fetchCountries }                              from 'models/countries/requests';
import AccountLogoSelector
                                                       from 'components/account/form/account_logo_selector';
import InputLabel                                      from 'components/ui/form/input_label';
import Margin                                          from 'components/ui/margin';
import { fetchOffices }                                from 'models/offices/requests';
import TitleActionHeader                               from 'components/ui/title_action_header/title_action_header';
import OfficeAddress                                   from 'components/offices/office_address';
import AddOfficeAddressButton                          from 'components/offices/add_office_address_button';
import Loader                                          from 'components/ui/loader';

class SettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      offices:   [],
      loading:   true,
    };
    this.onOfficeEdited = this.onOfficeEdited.bind(this);
    this.addOffice = this.addOffice.bind(this);
    this.removeOffice = this.removeOffice.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    Promise.all([
      fetchOffices(),
      fetchCountries(),
    ]).then((responses) => {
      this.setState({
        loading:   false,
        offices:   responses[0],
        countries: Object.keys(responses[1]).map((countryKey) => {
          return {
            value: countryKey,
            label: responses[1][countryKey],
          };
        }),
      });
    }).catch(() => {
      this.setState({
        loading: false,
      });
    });
  }

  onOfficeEdited(editedOffice) {
    this.setState({
      offices: this.state.offices.map((office) => {
        if (office.id === editedOffice.id) {
          return editedOffice;
        } else {
          if (editedOffice.primary) {
            return {
              ...office,
              primary: false,
            };
          }
          return office;
        }
      }),
    }, () => {
      if (window.closeDrawer) {
        window.closeDrawer();
      }
    });
  }

  addOffice(officeToAdd) {
    this.setState({
      offices: [
        ...this.state.offices,
        officeToAdd,
      ],
    }, () => {
      if (window.closeDrawer) {
        window.closeDrawer();
      }
    });
  }

  removeOffice(officeToRemove) {
    this.setState({
      offices: this.state.offices.filter((office) => office.id !== officeToRemove.id),
    }, () => {
      if (window.closeDrawer) {
        window.closeDrawer();
      }
    });
  }

  render() {
    const { handleSubmit, submitting, initialValues, providerList, societyTypes } = this.props;
    const { offices, loading } = this.state;
    return (
      <form
        className="form"
        onSubmit={ handleSubmit }
        data-purpose="account_settings_form"
      >
        <div className="block top-border p-20 mb-20">
          <Title size="h2" className="icon-text">
            <Icon icon="about-account" />
            { t('providers.public_page.about') }
          </Title>
          <Row>
            <Col xs={ 12 } md={ 8 }>
              <Margin>
                <InputLabel htmlFor="name">
                  { t('activerecord.attributes.account.name') }
                </InputLabel>
                <Field
                  name="name"
                  type="text"
                  placeholder={ t('activerecord.attributes.account.name') }
                  component={ TextInput }
                />
              </Margin>
            </Col>
            <Col xs={ 12 } md={ 4 }>
              <Margin>
                <InputLabel htmlFor="society_type">
                  { t('providers.show.society_type') }
                </InputLabel>
                <Field
                  name="society_type"
                  type="text"
                  list={ societyTypes }
                  placeholder={ t('providers.show.society_type') }
                  component={ SelectInput }
                />
              </Margin>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 } md={ 6 }>
              <Margin>
                <InputLabel htmlFor="fsma_number">
                  { t('providers.public_page.fsma_number') }
                </InputLabel>
                <Field
                  name="fsma_number"
                  type="text"
                  placeholder={ t('providers.public_page.fsma_number') }
                  component={ TextInput }
                />
              </Margin>
            </Col>
            <Col sm={ 12 } md={ 6 }>
              <Margin>
                <InputLabel htmlFor="enterprise_number">
                  { t('shared.provider_show.about.enterprise_number') }
                </InputLabel>
                <Field
                  name="enterprise_number"
                  type="text"
                  placeholder={ t('shared.provider_show.about.enterprise_number') }
                  component={ TextInput }
                />
              </Margin>
            </Col>
          </Row>
          <Row>
            <Col xs={ 12 } md={ 6 }>
              <Margin>
                <InputLabel htmlFor="website">
                  { t('providers.public_page.website') }
                </InputLabel>
                <Field
                  name="website"
                  type="text"
                  placeholder={ t('providers.public_page.website') }
                  component={ SymbolTextInput }
                  symbol={ <Icon icon="internet" /> }
                />
              </Margin>
            </Col>
          </Row>
          <Row>
            <Col xs={ 12 } md={ 6 }>
              <Margin>
                <InputLabel htmlFor="phone_number">
                  { t('providers.public_page.phone_number_placeholder') }
                </InputLabel>
                <Field
                  name="phone_number"
                  type="tel"
                  placeholder={ t('providers.public_page.phone_number_placeholder') }
                  component={ SymbolTextInput }
                  symbol={ <Icon icon="phone" /> }
                />
              </Margin>
            </Col>
            <Col xs={ 12 } md={ 6 }>
              <Margin>
                <InputLabel htmlFor="email">
                  { t('providers.public_page.email_address') }
                </InputLabel>
                <Field
                  name="email"
                  type="email"
                  placeholder={ t('providers.public_page.email_address') }
                  component={ SymbolTextInput }
                  symbol={ <Icon icon="mail" /> }
                />
              </Margin>
            </Col>
          </Row>
        </div>
        <div className="block top-border p-20 mb-20">
          <TitleActionHeader>
            <Title size="h2" className="icon-text">
              <Icon icon="location" />
              { t('providers.show.address', { count: offices.length }) }
            </Title>
            <AddOfficeAddressButton onCreated={ this.addOffice } />
          </TitleActionHeader>
          { loading && <Loader /> }
          { !loading && (
            <div className="office-address-list">
              { offices.map((office) => {
                return (
                  <OfficeAddress
                    office={ office }
                    key={ office.id }
                    onEdited={ this.onOfficeEdited }
                    onDeleted={ this.removeOffice }
                  />
                );
              }) }
            </div>
          ) }
        </div>
        <div className="block top-border p-20">
          <Title size="h2" className="icon-text">
            <Icon icon="application-settings" />
            { t('providers.account.settings.application_settings') }
          </Title>
          <Row>
            <Col sm={ 12 }>
              <Margin>
                <InputLabel htmlFor="default_manager_user_id">
                  { t('activerecord.attributes.account.default_manager_user_id') }
                </InputLabel>
                <Field
                  name="default_manager_user_id"
                  placeholder={ t('clients.typed_contract_requests.show.contract_request_manager') }
                  list={ providerList }
                  component={ SelectInput }
                  type="text"
                />
              </Margin>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <InputLabel htmlFor="account_logo">
                { t('activerecord.attributes.account.logo') }
              </InputLabel>
              <AccountLogoSelector selectedLogo={ initialValues.logo } />
            </Col>
          </Row>
          <SubmitButton
            large
            loading={ submitting }
            data-purpose="account_settings_form_submit_button"
          />
        </div>
      </form>
    );
  }
}

SettingsForm.defaultProps = {
  submitting:   false,
  providerList: [],
  societyTypes: [],
};

SettingsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleSubmit:  PropTypes.func.isRequired,
  submitting:    PropTypes.bool,
  providerList:  PropTypes.array,
  societyTypes:  PropTypes.array,
};


export default reduxForm({
  form: 'account_settings',
})(connect((state) => {
  if (state.account) {
    return {
      providerList: convertAccountMembersToManagerList(state.account.members),
      societyTypes: state.config.options_collections.society_types.map((type) => {
        return {
          value: type,
          label: t(`general.company_type.${ type }`),
        };
      }),
    };
  }
  return {};
})(SettingsForm));
