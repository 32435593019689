import React                         from 'react';
import { connect }                   from 'react-redux';
import { withRouter }                from 'react-router-dom';
import { SubmissionError }           from 'redux-form';
import OpenbrokerLogo                from 'images/open_broker_logo_dark.svg';
import AccountForm          from 'components/account/form/account_form';
import { registerAccount }  from 'models/account/requests';
import { prepareErrorsForReduxForm } from 'services/rails';
import { aliasUser, trackEvent }     from 'services/segment';
import LanguageSwitcher              from 'components/language_switcher/language_switcher';
import { setUserInStore }            from 'models/current_user/store_actions';

class NewAccountContainer extends React.Component {
  componentDidMount() {
    $('input[name="email"]').trigger('focus');
  }

  render() {
    const { registerAccount } = this.props;
    const onAccountSubmit = ({ ...values }) => (
      registerAccount({
        ...values,
      })
    );

    return (
      <div className="account-container">
        <LanguageSwitcher />
        <div className="account-form">
          <p className="uk-text-center m-0">
            <img src={ OpenbrokerLogo } alt="Openbroker" className="logo" />
          </p>
          <h1 className="title">{ t('account.register.welcome') }</h1>
          <AccountForm onSubmit={ onAccountSubmit } />
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerAccount: (account) => {
      return registerAccount(account).then((profile) => {
        aliasUser(profile);
        trackEvent('SignedUp');
        dispatch(setUserInStore(profile));
      }).catch((error) => {
        throw new SubmissionError(prepareErrorsForReduxForm(error));
      });
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(NewAccountContainer));
