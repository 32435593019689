import React from 'react';

const QUICK_TARIFICATION_WAIT_MESSAGE_TIMEOUT = 5000;

class TarificationWaitWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLongWarning: false,
    };
    this.timeout = null;
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        showLongWarning: true,
      });
    }, QUICK_TARIFICATION_WAIT_MESSAGE_TIMEOUT);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  render() {
    const { showLongWarning } = this.state;
    const { className } = this.props;
    if (!showLongWarning) {
      return null;
    }
    return (
      <span className={ `small-italic ${ className }` }>
        { t('providers.contract_request.quick_tarification_long_time_warning') }
      </span>
    );
  }
}

export default TarificationWaitWarning;
