import groupBy            from 'lodash/groupBy';
import get                from 'lodash/get';
import has                from 'lodash/has';
import compact            from 'lodash/compact';
import isNil              from 'lodash/isNil';
import { assert, expect } from 'chai';
import every              from 'lodash/every';
import find               from 'lodash/find';


const expectationMethod = (property, expectation, value) => {
  const t = new Function('expect', 'property', 'expectation', 'value', 'eval(`expect(property).${expectation}(...value)`)');
  return t(expect, property, expectation, value);
};

const assertionMethod = (property, assertion, value) => {
  return assert[assertion](property, value);
};
export const checkCondition = (condition) => {
  let result = null;
  if (condition.assertion) {
    assertionMethod(condition.property, condition.assertion, condition.value);
  } else if (condition.expectation) {
    expectationMethod(condition.property, condition.expectation, condition.value);
  } else {
    //console.warn('check condition no condition type passed', condition);
  }
  return result;
};

const availableFormat = {
  int: (val) => parseInt(val),
};

export class Condition extends Object {
  constructor({
                operator = 'and',
                property = '',
                assertion = '',
                expectation = '',
                value = '',
                message = null,
                debug = false,
                propertyAs = null,
              }) {
    super();
    this.operator = operator;
    this.property = property;
    this.assertion = assertion;
    this.expectation = expectation;
    this.value = value;
    this.message = message;
    this.debug = debug;
    this.propertyAs = propertyAs;
  }

  formatProperty(formatAs, property) {
    if (isNil(property)) {
      return null;
    }
    if (availableFormat[formatAs]) {
      return availableFormat[formatAs](property);
    }
    return property;
  }

  check(source) {
    if (this.debug) {
      log('source', source);
    }
    const condition = {
      property:    this.formatProperty(this.propertyAs, get(source, this.property)),
      assertion:   this.assertion,
      expectation: this.expectation,
      value:       this.value,
    };
    try {
      checkCondition(condition);
      return true;
    } catch (e) {
      if (this.debug) {
        console.warn('e', e);
        console.warn('Condition did not passed', condition);
      }
      return false;
    }
  }
}
