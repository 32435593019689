export const setIntermediariesList = list => ({
  type: 'INTERMEDIARIES/SET_LIST',
  list,
});

export const addIntermediaryInList = intermediary => ({
  type: 'INTERMEDIARIES/ADD_IN_LIST',
  intermediary,
});

export const updateIntermediaryInList = intermediary => ({
  type: 'INTERMEDIARIES/UPDATE_IN_LIST',
  intermediary,
});

export const setIntermediariesLoader = loading => ({
  type: 'INTERMEDIARIES/SET_LOADER',
  loading,
});

export const setIntermediariesProducts = products => ({
  type: 'INTERMEDIARIES/SET_PRODUCTS',
  products,
});

export const removeIntermediaryFromList = intermediaryId => ({
  type: 'INTERMEDIARIES/REMOVE_FROM_LIST',
  intermediaryId,
});
