import React                      from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
}                                 from 'react-router-dom';
import Helmet                     from 'react-helmet';
import Alert                      from 'services/alert';
import { routes }                 from 'components/account/routes';
import AccountNavigation from 'components/account/navigation';
import withAccount       from 'components/account/with_account';

class AccountContainer extends React.Component {
  componentDidMount() {
    Alert.enableNotification();
  }

  componentWillUnmount() {
    Alert.preventNotification();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <body data-no-breadcrumb="no-breadcrumb" className="content-no-padding no-global-message" />
        </Helmet>
        <div className="brokerage-container">
          <AccountNavigation />
          <div className="brokerage-content">
            <Switch>
              { routes.map((route, id) => <Route key={ id } { ...route } />) }
              <Redirect to={ routes[0].path } />
            </Switch>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withAccount()(AccountContainer));
