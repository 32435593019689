import compact from 'lodash/compact';
import omit    from 'lodash/omit';
import merge   from 'lodash/merge';

export const createOptionsList = (list, filters) => {
  return compact(Object.keys(list).map(key => {
    if (filters) {
      if (!filters.includes(key)) {
        return null;
      }
    }
    return {
      value: key,
      id:    key,
      label: list[key],
    };
  }));
};

export const getUsageList = () => {
  return [
    {
      value: 'private_only',
      label: t('general.vehicle_usage.private_only'),
      id:    'private_only',
    },
    {
      value: 'private_and_professional',
      label: t('general.vehicle_usage.private_and_professional'),
      id:    'private_and_professional',
    },
    {
      value: 'professional',
      label: t('general.vehicle_usage.professional'),
      id:    'professional',
    },
  ];
};


export const getPaymentStaggeringOptions = (filters) => {
  const options = {
    monthly:       t('general.payment_staggering.monthly'),
    trimestrially: t('general.payment_staggering.trimestrially'),
    semestrially:  t('general.payment_staggering.semestrially'),
    annually:      t('general.payment_staggering.annually'),
  };
  return createOptionsList(options, filters);
};

export const isCrLead = (contractRequest) => {
  return contractRequest.status === 'draft_lead' || contractRequest.status === 'lead';
};

export const prepareDataToUpdateContractRequest = (data, contractRequest) => {
  if (data.contract_request) {
    const d = omit(data, 'contract_request');
    return {
      ...merge(contractRequest, data.contract_request),
      data: d,
    };
  }
  return {
    ...contractRequest,
    data,
  };
};
