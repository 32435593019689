import React, { useState, useEffect } from 'react';
import Loader from 'components/ui/loader';
import BrandProvider from 'components/brand_switcher/brand_provider';
import { getBrands } from 'models/brands/requests';

const WithBrands = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    setLoading(true);
    getBrands()
      .then((response) => {
        setBrands([...response.data.brands]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return <BrandProvider brands={brands}>{children}</BrandProvider>;
};

export default WithBrands;
