import React, { useContext, useState } from 'react';
import BrandContext from 'components/brand_switcher/brand_context';
import Icon from 'components/ui/icon';
import openInDrawer from 'components/ui/drawer/helpers/open_in_drawer';
import BrandList from 'components/brand_switcher/brand_list';

const OpenBrandListInDrawer = openInDrawer()(BrandList);

const BrandSwitcher = () => {
  const { brands } = useContext(BrandContext);
  const [isOpen, setIsOpen] = useState(false);
  if (!brands.length) {
    return null;
  }
  return (
    <span className="brand-switcher">
      <OpenBrandListInDrawer
        orientation="right"
        onDrawerOpenStart={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        brands={brands}
      >
        <button
          type="button"
          onClick={() => setIsOpen((prevOpen) => !prevOpen)}
        >
          <Icon icon={isOpen ? 'arrow-right' : 'arrow-down'} />
        </button>
      </OpenBrandListInDrawer>
    </span>
  );
};

export default BrandSwitcher;
