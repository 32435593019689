import { getCookie, setCookie, removeCookie } from 'services/cookies';
import { FOR_DISTRIBUTOR } from 'constants/application_constants';

export const WINDOWS_TEST_DOMAIN = 'serveo.net';

const COOKIE_TOKEN_NAME = FOR_DISTRIBUTOR
  ? 'distributor_authorization_token'
  : 'authorization_token';

export const getAuthorizationToken = () => {
  return getCookie(COOKIE_TOKEN_NAME);
};

export const setAuthorizationToken = (authorization_token) => {
  setCookie(COOKIE_TOKEN_NAME, authorization_token);
};

export const removeAuthorizationToken = () => {
  removeCookie(COOKIE_TOKEN_NAME);
};

export const getAuthorizationHeader = () => {
  if (getAuthorizationToken()) {
    return `Bearer ${getAuthorizationToken()}`;
  }
  return undefined;
};
