import Api   from 'services/api';
import Alert from 'services/alert';

export const checkCouponValidity = couponCode => (
  new Promise((resolve, reject) => {
    Api.get(`account/coupons/${ couponCode }`).then((response) => {
      const { coupon } = response.data;
      resolve(coupon);
    }).catch((error) => {
      reject(error.data);
    });
  })
);

export const redeemAccountCoupon = couponToRedeem => (
  new Promise((resolve) => {
    Api.post(`account/coupons/${ couponToRedeem.id }/redeems`).then((response) => {
      const { coupon } = response.data;
      resolve(coupon);
    }).catch(() => {
      Alert.error(t('coupons.redeem_error'));
    });
  })
);
