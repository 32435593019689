import React                    from 'react';
import PropTypes                from 'prop-types';
import BillingAddressForm       from 'components/billing_address_form';
import { SubmissionError }      from 'redux-form';
import { connect }              from 'react-redux';
import { updateBillingAddress } from 'models/billing_addresses/requests';
import { trackEvent }           from 'services/segment';

class BillingAddressFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    trackEvent('SeenBillingAddressForm');
  }

  setFormErrors(errors) {
    throw new SubmissionError(errors);
  }

  submitForm(data) {
    const { onSuccess } = this.props;
    return updateBillingAddress(data).then((account) => {
      onSuccess(account);
    }).catch((errors) => {
      if (errors) {
        this.setFormErrors(errors);
      }
    });
  }

  render() {
    const { account, current_user } = this.props;

    const initialValues = {
      billing_company_name:    (account.billing_company_name || account.name || ''),
      billing_email:           (account.billing_email || current_user.email || ''),
      billing_street_name:     (account.billing_street_name || (account.address ? account.address.street_name : '')),
      billing_street_number:   (account.billing_street_number || (account.address ? account.address.street_number : '')),
      billing_box_number:      (account.billing_box_number || (account.address ? account.address.box_number : '')),
      billing_zip_code:        (account.billing_zip_code || (account.address ? account.address.zip_code : '')),
      billing_city:            (account.billing_city || (account.address ? account.address.city : '')),
      billing_vat_number:      (account.billing_vat_number || account.enterprise_number || ''),
      billing_recoverable_vat: (account.billing_vat_number !== null && account.billing_vat_number !== '') || account.recoverable_vat,
    };

    return (
      <BillingAddressForm initialValues={ initialValues } onSubmit={ this.submitForm } />
    );
  }
}

BillingAddressFormContainer.propTypes = {
  account:      PropTypes.object.isRequired,
  current_user: PropTypes.object.isRequired,
  onSuccess:    PropTypes.func.isRequired,
};

const mapStateToProps = ({ account, current_user }) => {
  return {
    account,
    current_user: current_user.profile,
  };
};

export default connect(mapStateToProps)(BillingAddressFormContainer);
