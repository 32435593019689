import sortBy from 'lodash/sortBy';

export const getGenders = () => [
  {
    value: 'male',
    id:    'male',
    label: t('general.gender.male'),
  },
  {
    value: 'female',
    id:    'female',
    label: t('general.gender.female'),
  },
];

export const getOccupations = () => {
  return [
    {
      value: 'employee',
      label: t('models.person.constants.occupation.employee'),
    },
    {
      value: 'official',
      label: t('models.person.constants.occupation.official'),
    },
    {
      value: 'retired',
      label: t('models.person.constants.occupation.retired'),
    },
    {
      value: 'self-employed',
      label: t('models.person.constants.occupation.self-employed'),
    },
    {
      value: 'student',
      label: t('models.person.constants.occupation.student'),
    },
    {
      value: 'teacher',
      label: t('models.person.constants.occupation.teacher'),
    },
    {
      value: 'unemployed',
      label: t('models.person.constants.occupation.unemployed'),
    },
    {
      value: 'worker',
      label: t('models.person.constants.occupation.worker'),
    },
    {
      value: 'other',
      label: t('models.person.constants.occupation.other'),
    },
  ];
};

export const getActivities = () => {
  return sortBy([
    {
      value: 'Other_activity',
      label: t('activity_sector.activities.other_activity'),
    },
    {
      value: 'Real_estate_activities',
      label: t('activity_sector.activities.real_estate_activities'),
    },
    {
      value: 'Automotive_trade',
      label: t('activity_sector.activities.automotive_trade'),
    },
    {
      value: 'Retail_business',
      label: t('activity_sector.activities.retail_business'),
    },
    {
      value: 'Wholesale',
      label: t('activity_sector.activities.wholesale'),
    },
    {
      value: 'Building_sector',
      label: t('activity_sector.activities.building_sector'),
    },
    {
      value: 'Finishing_business',
      label: t('activity_sector.activities.finishing_business'),
    },
    {
      value: 'Catering_industry',
      label: t('activity_sector.activities.catering_industry'),
    },
    {
      value: 'Transport_of_things',
      label: t('activity_sector.activities.transport_of_things'),
    },
    {
      value: 'Transport_of_persons',
      label: t('activity_sector.activities.transport_of_persons'),
    },
    {
      value: 'Landscape_cleaning_and_care',
      label: t('activity_sector.activities.landscape_cleaning_and_care'),
    },
    {
      value: 'Industries',
      label: t('activity_sector.activities.industries'),
    },
  ], 'label');
};

export const getAxaConstructorRecoverableRateList = () => {
  return [
    {
      value: 'VAT_recoverable_at_100%',
      label: t('models.person.constants.axa_constructor_recoverable_list.vat_recoverable_at_100'),
    },
    {
      value: 'VAT_recoverable_at_85%',
      label: t('models.person.constants.axa_constructor_recoverable_list.vat_recoverable_at_85'),
    },
    {
      value: 'VAT_recoverable_at_75%',
      label: t('models.person.constants.axa_constructor_recoverable_list.vat_recoverable_at_75'),
    },
    {
      value: 'VAT_recoverable_for_50%',
      label: t('models.person.constants.axa_constructor_recoverable_list.vat_recoverable_for_50'),
    },
    {
      value: 'VAT_recoverable_<_50%',
      label: t('models.person.constants.axa_constructor_recoverable_list.vat_recoverable_below_50'),
    },
  ];
};
